import React, { Component } from 'react';

import AccountPassword from '../common/AccountPassword';
import AccountSecurityQuestion from '../common/AccountSecurityQuestion';

import CurrentUserUpdate from '../common/CurrentUserUpdate';

export default function Account() {
    return (
        <>

        <CurrentUserUpdate />

        <hr/>
        <AccountPassword />
        <hr/>
        <AccountSecurityQuestion />

        </>
    )
}
