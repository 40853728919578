import React, {useState, useEffect} from 'react';
import useUserContext from '../common/UserContext';
import { apiGet, getAccountsApiUrl } from '../common/AppApi';

import MerchantSearch from './MerchantSearch';

export default function Merchants() {
	let {user} = useUserContext();
	let [programs, setPrograms] = useState([]);

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programId, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
		});
	};

	useEffect(() => {
		getPrograms();
	}, []);

    return (
        <>
        <MerchantSearch programs={programs} basePath="/program"/>
        </>
    )
}
