import React from 'react';
import useUserContext from '../common/UserContext';
import { Link } from 'react-router-dom';
import { DisplayName } from '../common/UserUtils';

export default function Header() {
	let {user} = useUserContext();

    return (
	<>
		<Link to="/admin" className="navbar-brand">MyMarketLink</Link>
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>

		<div className="collapse navbar-collapse" id="navbarMenu">
			<ul className="navbar-nav mr-auto">
				<li className="nav-item">
					<Link to="/admin/programs" className="nav-link">Programs</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/agencies" className="nav-link">Agencies</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/recipients" className="nav-link">Recipients</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/benefits" className="nav-link">Benefits</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/incentives" className="nav-link">Incentives</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/transactions" className="nav-link">Transactions</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/merchants" className="nav-link">Merchants</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/merchantlocator" className="nav-link" title="Find Merchants by location">Locator</Link>
				</li>
				<li className="nav-item">
					<Link to="/admin/users" className="nav-link">Users</Link>
				</li>
				<li className="nav-item dropdown">
					<a className="nav-link dropdown-toggle" id="admMenu" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Other</a>
					<div className="dropdown-menu">
						<Link to="/admin/dash" className="dropdown-item">Dashboard</Link>
						<div className="dropdown-divider"></div>
						<Link to="/admin/roles" className="dropdown-item">Roles</Link>
						<Link to="/admin/privileges" className="dropdown-item">Privileges</Link>
						<div className="dropdown-divider"></div>
						<Link to="/admin/configurations" className="dropdown-item">Configuration</Link>
						<div className="dropdown-divider"></div>
						<Link to="/admin/account" className="dropdown-item">Account</Link>
						<div className="dropdown-divider"></div>
						<Link to="/logout" className="dropdown-item">Logout</Link>
					</div>
				</li>
			</ul>
			<Link to="/admin/account" className="nav-link btn-primary"><DisplayName user={user}/></Link>
		</div>
	</>
    )
}
