import React from 'react';

export default function PasswordChecklist(props) {
	let lowerRegex = new RegExp("[a-z]");
	let upperRegex = new RegExp("[A-Z]");
	let numberRegex = new RegExp("[0-9]");
	let specialRegex = /[!"#\\\$%&'()*+,-.\/:;<>=?@\[\]\\^_`|{}~]/
	let lengthRegex = new RegExp(".{8,}");

	let OutputItem = (props) => {
		let symbol = props.valid ? <>&#x02713;</> : <>&#x02717;</>;
		let className = props.valid ? "text-success" : "text-danger";
		return <> {props.text} <span className={className}>{symbol}</span></>
	};

	let output = [];
	output.push(<OutputItem text="Lowercase" valid={lowerRegex.test(props.password)}/>);
	output.push(<OutputItem text="Uppercase" valid={upperRegex.test(props.password)}/>);
	output.push(<OutputItem text="Number" valid={numberRegex.test(props.password)}/>);
	output.push(<OutputItem text="Special" valid={specialRegex.test(props.password)}/>);
	output.push(<OutputItem text="Length" valid={lengthRegex.test(props.password)}/>);

	let outputMessage = () => {
		let outputResult = output.map( (data, index) => {
			return <span key={index}> {data} </span>;
		})
		return <> {outputResult} </>
	};

	return (
		<>
			{outputMessage()}
		</>
	)
}
