import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getUserApiUrl, apiPost } from './common/AppApi';

import { patternMessage, invalidInputStyle, notEqualMessage } from './common/Forms';

import Strings from './common/Strings';

import Processing from './common/Processing';
import MessageTag from './common/MessageTag';

export default function ForgotUsername(props) {
    
	let [forgotForm, setForgotForm] = useState({email:''});
	let [securityForm, setSecurityForm] = useState({questionId:'', questionText:'', answer:'', otpCode:''});
	let [username, setUsername] = useState("");

	let [whichForm, setWhichForm] = useState(1);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [notFoundCount, setNotFoundCount] = useState(0);
    
    const history = useHistory();

	useEffect( () => {
		patternMessage("#password", Strings.validation.password);
        notEqualMessage("#confirmPassword", Strings.validation.confirmPassword, "#password");
    },[]);
	useEffect( () => {
        invalidInputStyle();
    });

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...forgotForm, [id]:value};
		setForgotForm(cloned);
	}
    
	let handleFieldChange2 = (event) => {
		const {id, value} = event.target;
		let cloned = {...securityForm, [id]:value};
		setSecurityForm(cloned);
	}
    
    let handleEmailSubmit = function(e) {
        e.preventDefault();

        setSpinner(true);

		let url = getUserApiUrl() + '/user/forgot-username/' + encodeURIComponent(forgotForm.email);
		apiGet(url).then((resp) => {
            //console.debug(resp);
			setMessage(null);

			let cloned = {...securityForm};
			cloned.questionId = resp.id;
			cloned.questionText = resp.text;
			setSecurityForm(cloned);

			setWhichForm(2);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status){
                if(err.status == 404) {
					if (err.data.errorMessages && err.data.errorMessages.length && err.data.errorMessages[0].appErrorCode == 'USR-404') {
						messageObject.text = 'The email address was not found.';
						setNotFoundCount(notFoundCount + 1);
					} else {
						messageObject.text = 'Security setup is incomplete for this user.';
					}
                }
				else if (err.data.errorMessages && err.data.errorMessages.length) {
					if ('SEC-001' == err.data.errorMessages[0].appErrorCode) {
						messageObject.text = 'Forgot Username locked due to too many failed reset attempts.';
					} else {
						let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
					}
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

    let handleQuestionSubmit = function(e){
        e.preventDefault();

        let body = {
			questionId:securityForm.questionId,
			answer:securityForm.answer
        };

        setSpinner(true);

		let url = getUserApiUrl() + '/user/forgot-username/' + encodeURIComponent(forgotForm.email);
		apiPost(url, body).then((resp) => {
            //console.debug(resp);
			setMessage(null);
			setWhichForm(3);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if (err.status) {
                if(err.status == 400) {
                    messageObject.text = 'Security Question answer is invalid.';
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

    let handleCodeSubmit = function(e){
        e.preventDefault();

        setSpinner(true);

		let url = getUserApiUrl() + '/user/forgot-username/' + encodeURIComponent(forgotForm.email) + '/' + encodeURIComponent(securityForm.otpCode);
		apiGet(url).then((resp)=> {
            //console.debug(resp);
			setMessage(null);
			setUsername(resp.username);
			setWhichForm(4);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status){
                if(err.status == 404) {
                    messageObject.text = 'The email was not found.';
                }
                else if(err.status == 400){
                    messageObject.text = 'The code entered is invalid or has expired.';
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

	let showFirst = () => {
		if (whichForm == 1) {
			return (
				<div className="validate">
				<form onSubmit={handleEmailSubmit}>
					<div className="container">
						<div className="row">
							<div className="form-group col-12 col-md-7">
								<label htmlFor="email">Enter your Email</label>
								<input type="email" id="email" className="form-control" value={forgotForm.email} onChange={handleFieldChange} required maxLength="100" autoComplete="off" />
							</div>
							<div className="form-group col-12 col-md-7">
								<button className="btn btn-primary" disabled={notFoundCount > 3}>Continue</button>
								&nbsp;
								<Link to="/" className="btn btn-primary">Cancel</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
			)
		}
		return null;
	};

	let showSecond = () => {
		if (whichForm == 2) {
			return (
				<div className="validate">
				<form onSubmit={handleQuestionSubmit}>
					<div className="container">
						<p><strong>{securityForm.questionText}</strong></p>
						<div className="row">
							<div className="form-group col-12 col-md-7">
								<label htmlFor="answer">Answer</label>
								<input type="text" id="answer" className="form-control" value={securityForm.answer} onChange={handleFieldChange2} required maxLength="100" autoComplete="off" />
							</div>
							<div className="form-group col-12 col-md-7">
								<button className="btn btn-primary">Continue</button>
								&nbsp;
								<Link to="/" className="btn btn-primary">Cancel</Link>
							</div>
						</div>
					</div>
				</form>
				</div>
			)
		}
		return null;
	};

	let showThird = () => {
		if (whichForm == 3) {
			return (
				<div className="validate">
				<form onSubmit={handleCodeSubmit}>
					<div className="container">
						<p>Enter the security code you will receive shortly.</p>
						<div className="row">
							<div className="form-group col-12 col-md-7">
								<label htmlFor="otpCode">Security Code</label>
								<input type="text" id="otpCode" className="form-control" value={securityForm.otpCode} onChange={handleFieldChange2} required maxLength="10" autoComplete="off" pattern="^[a-zA-Z0-9]+$" />
							</div>
							<div className="form-group col-12 col-md-7">
								<button className="btn btn-primary">Continue</button>
								&nbsp;
								<Link to="/" className="btn btn-primary">Cancel</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
			)
		}
		return null;
	};

	let showFourth = () => {
		if (whichForm == 4) {
			let loginLink = "/login?username=" + username;
			return (
				<>
				<p>Your username is: &nbsp;<strong>{username}</strong></p>
				Return to <Link to={loginLink} >login page</Link>.
				</>
			)
		}
		return null;
	};

    return (
		<>
        <div className="validate">
            <Processing show={showSpinner}/>
			<h3>Forgot Username</h3>
            <MessageTag message={message} warning={true} />
        </div>

		{showFirst()}
		{showSecond()}
		{showThird()}
		{showFourth()}

		</>
    )
}
