import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getUserApiUrl, getAccountsApiUrl, getAdminApiUrl } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { PageSize, UserStatusArray, SelectOneBlankArray } from '../common/Constants';
import Pagination, { resetPaging } from '../common/Pagination';
import { selectOptions } from '../common/Forms';
import { decodeValues } from '../common/Formats';

export default function Users() {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();
	let [searchForm, setSearchForm] = useState({email:'',firstname:'',lastname:'',userStatusId:'',username:''});

	const history = useHistory();
	let GoTo = (uuid) => history.push('/admin/user/' + uuid);

	let [programs, setPrograms] = useState([]);
	let getPrograms = () => {
		let url = getAdminApiUrl() + '/programs';
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let url = getAccountsApiUrl() + '/agency';
		setSpinner(true);
		apiGet(url).then((resp) => {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [roles, setRoles] = useState([]);
	let getRoles = (userRoles) => {
		let url = getUserApiUrl() + '/roles';
		apiGet(url).then( (response) => {
			response.unshift({id:'',text:''});
			setRoles(response);
		}).catch( (error) => {
			console.error(error);
		});
	};

	useEffect(() => {
		getPrograms();
		getAgencies();
		getRoles();
	}, []);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let disableSearch = () => {
		for(const searchField in searchForm){
			if(searchForm[searchField])
				return false;
		}
		return true;
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);
		//console.log(searchForm);

		let url = getUserApiUrl() + '/users';
		let queryParams = [];
		for(const searchField in searchForm){
			if(searchForm[searchField])
				queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
		}
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		apiGet(url).then((resp) => {
			//console.debug(resp);
			setDataRows(resp);
			setPagingData(resetPaging());
			if(resp && resp.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No users found.',type:'warning'});
			}
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let decodeStatusValue = (id) => {
		let item = UserStatusArray.find(element => element.id == id);
		return (item ? item.text : null);
	};

	let TableRow = (props) => {
		return (
			<tr onClick={()=>GoTo(props.row.userUuid)} className="clickable">
			<td>{props.row.username}</td>
			<td>{props.row.email}</td>
			<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
			<td>{decodeStatusValue(props.row.userStatusId)}</td>
			<td>{props.row.programName}</td>
			<td style={{maxWidth:'15em'}}>{props.row.agencyName}</td>
			<td>{decodeValues(props.row.roleId, roles)}</td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={index} row={data} /> : null;
	});

	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Name</th>
							<th>Status</th>
							<th>Program</th>
							<th>Agency</th>
							<th>Role</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return null;
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Users
			<Link to="/admin/user/add" className="btn btn-primary btn-sm pull-right">New User</Link> 
		</h3>
		<MessageTag message={message} />

		<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={searchForm.programUuid} onChange={handleFieldChange} >
						{ selectOptions(programs) }
					</select>
				</div>

				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={searchForm.agencyUuid} onChange={handleFieldChange} >
						{ selectOptions(agencies) }
					</select>
				</div>

				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="email">Email</label>
					<input type="text" id="email" className="form-control" maxLength="100" value={searchForm.email} onChange={handleFieldChange} />
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="username">Username</label>
					<input type="text" id="username" className="form-control" maxLength="20" value={searchForm.username} onChange={handleFieldChange} />
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" maxLength="45" value={searchForm.firstname} onChange={handleFieldChange} />
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" maxLength="45" value={searchForm.lastname} onChange={handleFieldChange} />
				</div>

				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="roleIds">Role</label>
					<select id="roleIds" className="form-control custom-select" value={searchForm.roleIds} onChange={handleFieldChange} >
						{ selectOptions(roles) }
					</select>
				</div>

				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="userStatusId">Status</label>
					<select id="userStatusId" className="form-control custom-select" value={searchForm.userStatusId} onChange={handleFieldChange} >
					{ selectOptions(SelectOneBlankArray.concat(UserStatusArray)) }
					</select>
				</div>
			</div>
			<button className="btn btn-primary" disabled={disableSearch()} id="btnSearch">Search</button>
		</form>
		<br/>
		<Table/>

		</>
	)
}
