import React, {useState, useEffect} from 'react';
import {useHistory, Link} from "react-router-dom";
import {apiGet, getMerchantsApiUrl} from '../common/AppApi';

import Processing from '../common/Processing';
import Pagination, {resetPaging} from '../common/Pagination';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { MerchantStatusArray, PageSize, StateArray} from '../common/Constants';
import { formatPhone, cleanPhone } from '../common/Formats';
import { selectOptions } from '../common/Forms';

export default function Merchants() {
    let [showSpinner, setSpinner] = useState(false);
    let [dataRows, setDataRows] = useState([]);
    let [pagingData, setPagingData] = useState(resetPaging());
    let [message, setMessage] = useState();

	let emptyForm = {
        merchantIdentifier: '',
		merchantName: '',
		fnsNumber: '',
        email:'',
		city:'',
		stateCode:'',
		phone:''
	};

	let [searchForm, setSearchForm] = useState(emptyForm);

    const history = useHistory();
    let GoTo = (id) => history.push('/recipient/merchant/' + id);

	let AddressInfo = (props) => {
		let merchant = props.merchant;
		return (<>
			{merchant.addressOne}<br/>{merchant.addressTwo ? (<>{merchant.addressTwo}<br/></>) : null}
			{merchant.city} {merchant.stateCode} {merchant.zipcode}
			</>
		);
	};

    let TableRow = (props) => {
        return (
            <tr onClick={() => GoTo(props.row.merchantUuid)} className="clickable">
                <td>{props.row.merchantName}</td>
                <td>{props.row.fnsNumber}</td>
                <td>{props.row.email}</td>
                <td><AddressInfo merchant={props.row}/> </td>
                <td>{formatPhone(props.row.businessPhone)}</td>
                <td>{decodeStatus(props.row.merchantStatusId)}</td>
            </tr>
        );
    };

    let decodeStatus = (id) => {
        let item = MerchantStatusArray.find(element => element.id == id);
        return (item ? item.text : null);
    };

    let TableBody = dataRows.map((data, index) => {
        return ((index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize))) ?
            <TableRow key={data.merchantId} row={data}/> : null;
    });

    let Table = () => {
        if (dataRows && dataRows.length)
            return (
                <>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Merchant Name</th>
                            <th>FNS ID</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {TableBody}
                        </tbody>
                    </table>

                    <Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData}
                                pageSize={PageSize}/>

                </>
            );
        return null;
    };

	let handleSubmit = (e) => {
		e.preventDefault();
		setDataRows([]);

		let url = getMerchantsApiUrl() + '/merchants/currentuser';

		let queryParams = [];
		for(const searchField in searchForm){
			if(searchForm[searchField]){
				if (searchField == 'phone') {
					queryParams.push(searchField + '=' + encodeURIComponent( cleanPhone(searchForm[searchField])));
				} else {
					queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
				}
			}
		}
		queryParams.push('pageSize=1000'); //TODO use backend paging
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		setSpinner(true);
		apiGet(url).then((resp) => {
			console.debug(resp);
			setDataRows(resp);
			setPagingData(resetPaging());
			if (resp && resp.length) {
				if (message)
					setMessage(null);
			} else {
				setMessage({text: 'No merchant(s) found.', type: 'warning'});
			}
		}).catch((err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				console.error(err.data.errorMessages);
				if(err.data.expandedErrors && err.data.expandedErrors.length){
					let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
					messageObject.text = <>{messages}</>;
				}
				else if(err.data.errorMessages && err.data.errorMessages.length){
					let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
					messageObject.text = <>{messages}</>;
				}
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]: value});
	}

	let disableSearch = () => {
		for(const searchField in searchForm){
			if(searchForm[searchField])
				return false;
		}
		return true;
	};

    return (
        <>
            <Processing show={showSpinner}/>
            <h3>Merchants</h3>
            <MessageTag message={message}/>

            <form id="merchantNameSearchForm" onSubmit={e => handleSubmit(e)}>
			<div className="row" >
				<div className="form-group col-12 col-sm-3">
                    <label htmlFor="merchantName">Name</label>
                    <input type="text" id="merchantName" className="form-control" onChange={handleFieldChange} minLength="2" maxLength="50"/>
					<br/>
				</div>
				<div className="form-group col-12 col-sm-3">
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" className="form-control" onChange={handleFieldChange} minLength="2" maxLength="250"/>
					<br/>
				</div>
				<div className="form-group col-12 col-sm-2">
                    <label htmlFor="city">City</label>
                    <input type="text" id="city" className="form-control" onChange={handleFieldChange} minLength="2" maxLength="60"/>
					<br/>
				</div>
				<div className="form-group col-12 col-sm-2">
					<label htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control custom-select" value={searchForm.stateCode} onChange={handleFieldChange} >
						{ selectOptions( StateArray ) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-2">
                    <label htmlFor="phone">Phone</label>
                    <input type="text" id="phone" className="form-control" onChange={handleFieldChange} minLength="3"  maxLength="20"/>
					<br/>
				</div>
			</div>
			<button className="btn btn-primary" disabled={disableSearch()} title={Strings.warning.searchCritera}>Search
					</button>
            </form>
            <br/>
            <Table/>
        </>
    )
}
