import React, { useState, useEffect } from 'react';

import { apiGet, getUserApiUrl, apiPut, apiMessages } from './AppApi';
import useUserContext from './UserContext';
import { saveUser } from './AppAuth';

import Processing from './Processing';
import MessageTag from './MessageTag';
import Strings from './Strings';
import { patternMessage, invalidInputStyle } from './Forms';
import { cleanPhone, formatPhone } from './Formats';


export default function Account() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

    let {user, setUser} = useUserContext();

    let blankUser = {
        username:'',
        firstname:'',
        lastname:'',
        middlename:'',
        mobile:'',
        userUuid:'',
        email:''
    };

    let [currentUser, setCurrentUser] = useState(blankUser);
	let [formChanged, setFormChanged] = useState(false);

	let getUser = () => {
		setSpinner(true);
		let url = getUserApiUrl() + '/user/current';
		apiGet(url).then((resp) => {

            let foundUser = {
                username:resp.username,
                firstname:resp.firstname,
                lastname:resp.lastname,
                middlename:resp.middlename,
                mobile:formatPhone(resp.mobile),
                userUuid:resp.userUuid,
                email:resp.email
            };

            setCurrentUser(foundUser);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
	};

    useEffect( () => {
        getUser();
		patternMessage("#mobile", Strings.validation.phoneFormat);
    }, []);
    useEffect( () => {
        invalidInputStyle();
    });

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...currentUser, [id]:value};
		setCurrentUser(cloned);
		setFormChanged(true);
	}

    let handleSubmit = (e) => {
		e.preventDefault();

		setSpinner(true);

		let body = {... currentUser};
        body.mobile = cleanPhone(currentUser.mobile);

		let url = getUserApiUrl() + '/user/currentuser';
		apiPut(url, body).then((resp) => {
			setMessage({text:'Data updated.',type:'success'});
			setFormChanged(false);

            let updatedUser = {... user};
            updatedUser.firstname = currentUser.firstname;
            updatedUser.lastname = currentUser.lastname;
            updatedUser.middlename = currentUser.middlename;

            setUser(updatedUser); // save to context
            saveUser(updatedUser); // save to local storage

		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));

    };

    return (
    <>

    <Processing show={showSpinner}/>
    <h4>User Info</h4>
    <MessageTag message={message} />

    <form onSubmit={handleSubmit} className="dataEntry">
        <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" className="form-control" value={currentUser.username} readOnly={true} />
        </div>
        <div className="form-group required">
            <label className="asterisk-label" htmlFor="firstname">First Name</label>
            <input type="text" id="firstname" className="form-control" value={currentUser.firstname} onChange={handleFieldChange} maxLength="100" required/>
        </div>
        <div className="form-group">
            <label htmlFor="middlename">Middle Name</label>
            <input type="text" id="middlename" className="form-control" value={currentUser.middlename} onChange={handleFieldChange} maxLength="1" />
        </div>
        <div className="form-group required">
            <label className="asterisk-label" htmlFor="lastname">Last Name</label>
            <input type="text" id="lastname" className="form-control" value={currentUser.lastname} onChange={handleFieldChange} maxLength="100" required/>
        </div>
        <div className="form-group required">
            <label className="asterisk-label" htmlFor="email">Email</label>
            <input type="email" id="email" className="form-control" value={currentUser.email} onChange={handleFieldChange} required/>
        </div>
        <div className="form-group required">
            <label className="asterisk-label" htmlFor="mobile">Mobile Phone</label>
            <input type="text" id="mobile" className="form-control" value={currentUser.mobile} onChange={handleFieldChange} pattern={Strings.pattern.phone} />
        </div>
        <button className="btn btn-primary" disabled={!formChanged} >Update</button>
    </form>

    </>
    )
}
