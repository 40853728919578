import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";

import { apiPatch, apiGet, apiPost, getAccountsApiUrl, getBenefitsApiUrl, getAdminApiUrl, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { StateArray, PanStatusArray, PanTypeArray } from '../common/Constants';
import { cleanPhone, formatPhone, decodeValues } from '../common/Formats';
import Strings from  '../common/Strings';

import ConfirmDialog, { closeConfirmDialog } from '../common/ConfirmDialog';

export default function RecipientPans(props) {
	let blankRecipient = {
		accountUuid: '',
		recipientIdentifier: '',
		email: '',
		firstname: '',
		lastname: '',
		middlename: '',
		mobilePhone: '',
		cardIssuanceCode: false,
		address1: '',
		address2: '',
		city: '',
		stateCode: '',
		zipcode: ''
	};
	
	let [recipient, setRecipient] = useState(blankRecipient);
	let [pans, setPans] = useState([]);

	let { id, progId } = useParams();

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let notNull = (value) => value ? value : '';
	let notEmpty = (value, defaultValue) => value ? value : defaultValue;

	let getRecipient = (id) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/account/' + id;

		apiGet(url).then((resp)=> {
			console.debug(resp);

			let cloned = {...resp};
			cloned.middlename = notNull(resp.middlename).trim();
			cloned.address2 = notNull(resp.address2).trim();
			cloned.mobilePhone = formatPhone(resp.mobilePhone);

			setRecipient(cloned);

			getRecipientPans();
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getRecipientPans = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/pans?programId=' + progId + '&accountUuid=' + id;

		apiGet(url).then((resp)=> {
			console.debug(resp);
			setPans(resp);

			if (resp.length < 1)
				setMessage({text:'No printed pans available.',type:'warning'});

		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( ()=> {
		getRecipient(id);

	}, []);

	let confirmSettingsDefaults = {
		button:"Inactivate",type:"danger",body:"Inactivate this pan?"
	};
	let [confirmSettings, setConfirmSettings] = useState(confirmSettingsDefaults);
	let confirmStatusChange = (panId, panStatusId) => {
		let confirmFunc = () => doStatusChange(panId, panStatusId);
		if (panStatusId == 1) {
			setConfirmSettings({button:"Lost",type:"danger",body:"Status this pan as Lost?",confirm:confirmFunc});
		} else if (panStatusId == 2){
			setConfirmSettings({button:"Stolen",type:"danger",body:"Status this pan as Stolen?",confirm:confirmFunc});
		} else {
			setConfirmSettings({button:"Inactive",type:"danger",body:"Status this pan as Inactive?",confirm:confirmFunc});
		}
		window.jQuery('#confirmDialog').modal();
	};

	let doStatusChange = (panId, panStatusId) => {
		closeConfirmDialog();

		setSpinner(true);

		let body = {
			panStatusId:panStatusId
		};

		let url = getBenefitsApiUrl() + '/pans/' + panId;
		apiPatch(url, body).then((resp)=> {
			setMessage({text:'The pan has been statused.'});
			getRecipientPans();

		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let genPan = () => {
		setSpinner(true);

		let body = {
			programId:progId,
			accountUuid:id
		};

		let url = getAdminApiUrl() + '/pans';
		apiPost(url, body).then((resp)=> {
			setMessage({text:'A new Pan has been generated.'});
			getRecipientPans();

		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let DisplayPan = (props) => {
		return (
			<tr>
			<td>{props.pan.pan}</td>
			<td>{props.pan.activeDate}</td>
			<td>{props.pan.expirationDate}</td>
			<td>{decodeValues(props.pan.panStatusId, PanStatusArray)}</td>
			<td>{decodeValues(props.pan.panTypeId, PanTypeArray)}</td>
			<td>
				<button type="button" className="btn btn-danger btn-sm" onClick={() => confirmStatusChange(props.pan.panId, 4)}>Inactivate</button>
				&nbsp;<button type="button" className="btn btn-danger btn-sm" onClick={() => confirmStatusChange(props.pan.panId, 1)}>Lost</button>
				&nbsp;<button type="button" className="btn btn-danger btn-sm" onClick={() => confirmStatusChange(props.pan.panId, 2)}>Stolen</button>
			</td>
			</tr>
		);
	};
	let DisplayPans = pans.map( (data, index) => {
		return (<DisplayPan key={index} pan={data} />);
	});
	let PanTable = () => {
		if(pans && pans.length)
			return (
				<>
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Pan</th>
							<th>Active Date</th>
							<th>Expiration Date</th>
							<th>Status</th>
							<th>Type</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{DisplayPans}
					</tbody>
				</table>
				</>
		);
		return null;
	};

	const history = useHistory();
	let GoBack = () => history.goBack();
	let ViewRecipient = () => {
		return <a href='#' onClick={GoBack}>Back to Recipient</a>
	};

	return (
		<>
		<Processing show={showSpinner}/>

		<h3>Recipient Pans</h3>
		<ViewRecipient />

		<table className="table table-bordered">
			<tbody>
				<tr>
					<th>Account ID</th>
					<th>Name</th>
					<th>Email</th>
					<th>Phone</th>
				</tr>
				<tr>
					<td>{recipient.recipientIdentifier}</td>
					<td>{recipient.firstname} {recipient.middlename} {recipient.lastname}</td>
					<td>{recipient.email}</td>
					<td>{recipient.mobilePhone}</td>
				</tr>
			</tbody>
		</table>

		<hr/>
		<h4>Pan Info</h4>
		<MessageTag message={message}/>

		<PanTable />

		<button type="button" className="btn btn-primary" onClick={genPan}>Generate New PAN</button>

		<ConfirmDialog settings={confirmSettings} />

		</>
	)
}
