import React, { useState, useEffect } from 'react';
import {apiGet, apiPost, getAdminApiUrl, getMerchantsApiUrl, apiMessages, getAccountsApiUrl} from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import Strings from '../common/Strings';
import { cleanPhone } from '../common/Formats';
import {BankAccountTypeArray, StateArray} from '../common/Constants';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import useUserContext from "../common/UserContext";

export default function AddMerchant(props) {
	let {user} = useUserContext();

	let blankMerchant = {
        merchantIdentifier:'',
		programUuid:'',
		merchantName:'',
		fnsNumber:'',
		email:'',
		taxId:'',
		bankName:'',
		bankAccountNumber:'',
		bankRoutingNumber:'',
		bankAccountType:'',
		businessPhone:'',
		mobilePhone:'',
		firstName:'',
		middleName:'',
		lastName:'',
		userId:-1,
		creationChannel:'MANUAL',
		addressInfo: {
			addressOne : "",
			addressTwo : "",
			city : "",
			zipcode : "",
			stateCode : "",
			countryId: 0
		},
		mailingAddress: {
			addressOne : "",
			addressTwo : "",
			city : "",
			zipcode : "",
			stateCode : "",
			countryId: 0
		},
		physicalAddress: {
			addressOne : "",
			addressTwo : "",
			city : "",
			zipcode : "",
			stateCode : "",
			countryId: 0
		},
		taxClassId:'',
		tinTypeId:''
	};

	let [merchant, setMerchant] = useState(blankMerchant);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [programs, setPrograms] = useState([]);
	let [useRegistered, setUseRegistered] = useState({mailing:true, physical:true});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setMerchant({...merchant, [id]:value});
	}
	let handleCheckboxChange = (event) => {
		const {id, checked} = event.target;
		setUseRegistered({...useRegistered, [id]:checked});
	}

    let handleMerchantAddressInfo = (event) => {
		handleMerchantInfoFieldChangeAddr (event, 'addressInfo');
    }
    let handleMerchantMailingAddress = (event) => {
		handleMerchantInfoFieldChangeAddr (event, 'mailingAddress');
    }
    let handleMerchantPhysicalAddress = (event) => {
		handleMerchantInfoFieldChangeAddr (event, 'physicalAddress');
    }

    let handleMerchantInfoFieldChangeAddr = (event, type) => {
        const {id, value} = event.target;
		let addr = {...merchant[type], [id]:value};
        setMerchant({...merchant, [type]:addr});
    }

	let [taxClasses, setTaxClasses] = useState([]);
	let getTaxClasses = () => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/tax-classes';
		apiGet(url).then((resp) => {
			resp.unshift({taxClassId:'',descr:''});
			setTaxClasses(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [tinTypes, setTinTypes] = useState([]);
	let getTinTypes = () => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/tin-types';
		apiGet(url).then((resp) => {
			resp.unshift({tinTypeId:'',descr:''});
			setTinTypes(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getTaxClasses();
		getTinTypes();
		getPrograms();
		patternMessage("#zipcode", Strings.validation.zipFormat);
		patternMessage("#mobilePhone", Strings.validation.phoneFormat);
		patternMessage("#businessPhone", Strings.validation.phoneFormat);
		patternMessage("#email", Strings.validation.emailFormat);
		patternMessage("#taxId", Strings.validation.numbers);
	}, []);
	useEffect( () => {
		invalidInputStyle();
	});

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		if (props.admin)
			url = getAdminApiUrl() + '/programs';

		setSpinner(true);
		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getMerchantsApiUrl() + '/merchant';

		let body = { ...merchant};
		body.programId = merchant.programUuid;
		body.businessPhone = cleanPhone(merchant.businessPhone);
		body.mobilePhone = cleanPhone(merchant.mobilePhone);
		
		body.bankAccountInfo = {
			bankName : merchant.bankName,
			bankAccountNumber : merchant.bankAccountNumber,
			bankRoutingNumber : merchant.bankRoutingNumber,
			bankAccountType : merchant.bankAccountType,
			firstName : merchant.firstName,
			lastName : merchant.lastName,
			middleName : merchant.middleName
		};

		body.addressInfo = merchant.addressInfo;
		if (useRegistered.mailing) {
			body.mailingAddress = merchant.addressInfo;
		} else {
			body.mailingAddress = merchant.mailingAddress;
		}
		if (useRegistered.physical) {
			body.physicalAddress = merchant.addressInfo;
		} else {
			body.physicalAddress = merchant.physicalAddress;
		}

		apiPost(url, body).then((resp)=> {
			setMessage({text:'Merchant added.',type:'success'});
			setMerchant(blankMerchant);
			setUseRegistered({mailing:true, physical:true});
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	return (
		<>
		<Processing show={showSpinner} />

		<h2>New Merchant</h2>
        <MessageTag message={message}/>

        <h3>Merchant Info</h3>

        <form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="merchantIdentifier">Merchant ID</label>
					<input type="text" id="merchantIdentifier" className="form-control" onChange={handleFieldChange} value={merchant.merchantIdentifier} required maxLength="20" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={merchant.programUuid} onChange={handleFieldChange} required>
						{ selectOptions(programs) }
					</select>
				</div>
			</div>
			<div className="row">
					<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="merchantName">Name</label>
					<input type="text" id="merchantName" className="form-control" onChange={handleFieldChange} value={merchant.merchantName} required maxLength="50" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="fnsNumber">FNS Number</label>
					<input type="text" id="fnsNumber" className="form-control" onChange={handleFieldChange} value={merchant.fnsNumber} required minLength="7" maxLength="7" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="email">Email</label>
					<input type="email" id="email" className="form-control" onChange={handleFieldChange} value={merchant.email} required maxLength="255" pattern={Strings.pattern.email}/>
				</div>
                <div className="form-group required col-12 col-sm-3">
                    <label className="asterisk-label" htmlFor="businessPhone">Business Phone</label>
                    <input type="text" id="businessPhone" className="form-control" onChange={handleFieldChange} value={merchant.businessPhone} required maxLength="20" pattern={Strings.pattern.phone} />
                </div>
                <div className="form-group required col-12 col-sm-3">
                    <label className="asterisk-label" htmlFor="mobilePhone">Mobile Phone</label>
                    <input type="text" id="mobilePhone" className="form-control" onChange={handleFieldChange} value={merchant.mobilePhone} required maxLength="20" pattern={Strings.pattern.phone} />
                </div>
            </div>

			<div className="row">
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="taxId">Federal Tax ID</label>
					<input type="text" id="taxId" className="form-control" onChange={handleFieldChange} value={merchant.taxId} required maxLength="9" pattern="\d{9}"/>
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="tinTypeId">ID Type</label>
					<select id="tinTypeId" className="form-control" onChange={handleFieldChange} value={merchant.tinTypeId} required>
						{ selectOptions(tinTypes, 'tinTypeId', 'descr') }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="taxClassId">Tax Classification</label>
					<select id="taxClassId" className="form-control" onChange={handleFieldChange} value={merchant.taxClassId} required>
						{ selectOptions(taxClasses, 'taxClassId', 'descr') }
					</select>
				</div>
			</div>

			<div className="row">
				<div className="form-group col-12"><strong>Registered Address</strong></div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addressOne">Address One</label>
					<input type="text" id="addressOne" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.addressOne} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="addressTwo">Address Two</label>
					<input type="text" id="addressTwo" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.addressTwo} maxLength="60"/>
				</div>
				</div>
				<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.stateCode} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>

			<div className="row">
				<div className="form-group col-12 col-sm-3">
					<div className="form-check">
						<label className='clickable'>
						<input className="form-check-input" type="checkbox" id="mailing" value="true" checked={useRegistered.mailing} onChange={handleCheckboxChange} />
						 Use For Mailing Address</label>
					</div>
				</div>
				<div className="form-group col-12 col-sm-3">
					<div className="form-check">
						<label className='clickable'>
						<input className="form-check-input" type="checkbox" id="physical" value="true" checked={useRegistered.physical} onChange={handleCheckboxChange} />
						 Use For Physical Address</label>
					</div>
				</div>
			</div>

			{ useRegistered.mailing ? null : <>
			<div className="row">
				<div className="form-group col-12"><strong>Mailing Address</strong></div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addressOne">Address One</label>
					<input type="text" id="addressOne" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.addressOne} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="addressTwo">Address Two</label>
					<input type="text" id="addressTwo" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.addressTwo} maxLength="60"/>
				</div>
				</div>
				<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.stateCode} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			</> }

			{ useRegistered.physical ? null : <>
			<div className="row">
				<div className="form-group col-12"><strong>Physical Address</strong></div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addressOne">Address One</label>
					<input type="text" id="addressOne" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.addressOne} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="addressTwo">Address Two</label>
					<input type="text" id="addressTwo" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.addressTwo} maxLength="60"/>
				</div>
				</div>
				<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.stateCode} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			</> }

            <h3>Bank Info</h3>
            <div className="row">
                <div className="form-group required col-12 col-sm-5">
                    <label className="asterisk-label" htmlFor="firstName">First Name</label>
                    <input type="text" id="firstName" className="form-control" onChange={handleFieldChange} value={merchant.firstName} required maxLength="100" />
                </div>
                <div className="form-group col-12 col-sm-2">
                    <label htmlFor="middleName">Middle Initial</label>
                    <input type="text" id="middleName" className="form-control" onChange={handleFieldChange} value={merchant.middleName} maxLength="1" />
                </div>
                <div className="form-group required col-12 col-sm-5">
                    <label className="asterisk-label" htmlFor="lastName">Last Name</label>
                    <input type="text" id="lastName" className="form-control" onChange={handleFieldChange} value={merchant.lastName} required maxLength="100" />
                </div>
            </div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="bankName">Bank Name</label>
					<input type="text" id="bankName" className="form-control" onChange={handleFieldChange} value={merchant.bankName} required maxLength="45" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="bankAccountNumber">Bank Account Number</label>
					<input type="text" id="bankAccountNumber" className="form-control" onChange={handleFieldChange} value={merchant.bankAccountNumber} required maxLength="45" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="bankRoutingNumber">Routing Number</label>
					<input type="text" id="bankRoutingNumber" className="form-control" onChange={handleFieldChange} value={merchant.bankRoutingNumber} required minLength="8" maxLength="45" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="bankAccountType">Bank Account Type</label>
					<select id="bankAccountType" className="form-control" onChange={handleFieldChange} value={merchant.bankAccountType} required >
						{ selectOptions(BankAccountTypeArray) }
					</select>
				</div>
			</div>
			<button className="btn btn-primary" id="btnAdd">Add</button>
		</form>

		</>
	)
}
