import React, { useState, useEffect } from 'react';
import useUserContext from '../common/UserContext';

import { apiPost, apiGet, getAdminApiUrl, getUserApiUrl, apiMessages, getAccountsApiUrl } from '../common/AppApi';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import Strings from '../common/Strings';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import { PrivilegeCodes } from '../common/Constants';
import { cleanPhone } from '../common/Formats';
import AlertDialog from '../common/AlertDialog';

/* KEEP in sync with ndgadmin AddUser */
export default function AddUser() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let blankForm = {
		email:'',
		username:'',
		firstname:'',
		lastname:'',
		middlename:'',
		roleId:'',
		mobile:'',
		phone:'',
		programUuid:'',
		agencyUuid:''
	};
	let [userForm, setUserForm] = useState(blankForm);
	let [sendActivation, setSendActivation] = useState(false);
	let [roles, setRoles] = useState([{id:null,text:''}]);
	let [assignProgram, setAssignProgram] = useState(false);
	let [assignAgency, setAssignAgency] = useState(false);
	let [programs, setPrograms] = useState([]);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...userForm, [id]:value};
		setUserForm(cloned);
	}
	let handleCheckboxChange = (event) => {
		const {id, checked} = event.target;
		setSendActivation(checked);
	}

	let handleRoleChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...userForm, [id]:value};
		switch (cloned.roleId) {
			case '1':
				setAssignProgram(true);
				setAssignAgency(false);
				break;
			case '5':
			case '6':
				setAssignAgency(true);
				setAssignProgram(false);
				break;
			default:
				setAssignProgram(false);
				cloned.programUuid = '';
				setAssignAgency(false);
				cloned.agencyUuid = '';
		}
		setUserForm(cloned);
	}

	let alertSettings = {
		body:'Adding a recipient should be done on the Recipient Add screen.',
		type:'danger', title:'Danger'
	};

	let getPrograms = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs?programStatusId=0';

		apiGet(url).then((resp) => {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid,text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let url = getAccountsApiUrl() + '/agency';
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

    let {user} = useUserContext();
	let getRoles = () => {
		const ProgramAdminRolesArray = [
			{id:null, text:''},
			{id:1, text:'Program Admin'},
			{id:5, text:'Agency Admin'},
			{id:6, text:'Agency User'},
		];
		const AgencyAdminRolesArray = [
			{id:null, text:''},
			{id:5, text:'Agency Admin'},
			{id:6, text:'Agency User'},
		];
		const AgencyUserRolesArray = [
			{id:null, text:''},
			{id:6, text:'Agency User'},
		];

		let privileges = user.privileges;
		if (privileges.includes(PrivilegeCodes.programAccess)) {
			setRoles(ProgramAdminRolesArray);
		} else if (privileges.includes(PrivilegeCodes.agencyAdminAccess)) {
			setRoles(AgencyAdminRolesArray);
		} else if (privileges.includes(PrivilegeCodes.agencyUserAccess)) {
			setRoles(AgencyUserRolesArray);
		} else {
			setRoles([{id:null, text:''}]);
		}
	};

	useEffect( () => {
		patternMessage("#username", Strings.validation.username);
		patternMessage("#mobile", Strings.validation.phoneFormat);
		patternMessage("#phone", Strings.validation.phoneFormat);
		patternMessage("#email", Strings.validation.emailFormat);
		getRoles();
		getPrograms();
		getAgencies();
	}, []);
	useEffect( () => {
		invalidInputStyle();
	});

	let handleSubmit = (e) => {
		e.preventDefault();
		console.debug(userForm);
		setSpinner(true);

		let url = getUserApiUrl() + '/user';
		let body = {...userForm};
		body.mobile = cleanPhone(body.mobile);
		body.phone = cleanPhone(body.phone);

		apiPost(url, body).then((resp)=> {
			console.debug(resp);
			setMessage({text: 'User added.', type:'success'});
			setUserForm(blankForm);
			if (sendActivation)
				sendEmailActivation(resp.userUuid);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let sendEmailActivation = (uuid) => {
		let url = getUserApiUrl() + '/user/send-activation';
		let body = {uuid:uuid};
		apiPost(url, body).then((resp)=> {
			console.debug(resp);
		}).catch( (err)=> {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Add User</h3>
		<MessageTag message={message} />

		<form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" value={userForm.firstname} onChange={handleFieldChange} required maxLength="100"/>
				</div>
				<div className="form-group col-12 col-sm-2">
					<label htmlFor="middlename">Middle Name</label>
					<input type="text" id="middlename" className="form-control" value={userForm.middlename} onChange={handleFieldChange} maxLength="1"/>
				</div>
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" value={userForm.lastname} onChange={handleFieldChange} required maxLength="100"/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="email">Email</label>
					<input type="email" id="email" className="form-control" value={userForm.email} onChange={handleFieldChange} required maxLength="255" pattern={Strings.pattern.email}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="username">Username</label>
					<input type="text" id="username" className="form-control" value={userForm.username} onChange={handleFieldChange} required pattern="[a-zA-Z][\w]{7,19}" maxLength="20"/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="mobile">Mobile Phone</label>
					<input type="text" id="mobile" className="form-control" value={userForm.mobile} onChange={handleFieldChange} required pattern={Strings.pattern.phone} maxLength="20"/>
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="phone">Other Phone</label>
					<input type="text" id="phone" className="form-control" value={userForm.phone} onChange={handleFieldChange} pattern={Strings.pattern.phone} maxLength="20"/>
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="roleId">Role</label>
					<select id="roleId" className="form-control custom-select" value={userForm.roleId} onChange={handleRoleChange} required >
					{ selectOptions(roles) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={userForm.programUuid} onChange={handleFieldChange} disabled={!assignProgram}>
						{ selectOptions(programs) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={userForm.agencyUuid} onChange={handleFieldChange} disabled={!assignAgency}>
						{ selectOptions(agencies) }
					</select>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-12 col-sm-6">
					<div className="form-check" style={{margin:11}}>
					<label className="form-check-label">
					<input className="form-check-input" id="sendActivation" type="checkbox" checked={sendActivation} onChange={handleCheckboxChange}/>
					Send Activation Email</label>
					</div>
				</div>
			</div>
			<button className="btn btn-primary" id="btnAddUser">Add</button>
		</form>

		<AlertDialog settings={alertSettings}/>
		</>
	)
}
