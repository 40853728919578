import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import useUserContext from '../common/UserContext';

import {apiGet, apiPut, getMerchantsApiUrl, apiMessages, apiPatch} from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { cleanPhone, formatPhone } from '../common/Formats';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';

import Strings from '../common/Strings';
import {BankAccountTypeArray, StateArray, MerchantStatusArray} from '../common/Constants';
import ConfirmDialog, {closeConfirmDialog, showConfirmDialog} from "../common/ConfirmDialog";

export default function Merchant(props) {
	let {user} = useUserContext();

	let blankMerchant = {
		merchantUuid:'',
		merchantIdentifier:'',
		merchantStatusId:'',
		merchantName:'',
		fnsNumber:'',
		email:'',
		taxId:'',
        bankAccountNumber:'',
        bankRoutingNumber:'',
        bankAccountType:'',
        bankName:'',
		bankUuid:'',
		businessPhone:'',
		mobilePhone:'',
		firstName:'',
		middleName:'',
		lastName:'',
		merchantPrograms:[],
		userId:-1,
		updationChannel:'MANUAL',
		programUuid:'',
		addressInfo: {
			addressOne : "",
			addressTwo : "",
			city : "",
			zipcode : "",
			stateCode : ""
		},
		mailingAddress: {
			addressOne : "",
			addressTwo : "",
			city : "",
			zipcode : "",
			stateCode : ""
		},
		physicalAddress: {
			addressOne : "",
			addressTwo : "",
			city : "",
			zipcode : "",
			stateCode : ""
		},
		taxClassId:'',
		tinTypeId:''
	};

	let [merchant, setMerchant] = useState(blankMerchant);
	let [merchantFormChanged, setMerchantFormChanged] = useState(false);
	let [bankFormChanged, setBankFormChanged] = useState(false);
	let programs = props.programs;
    let { id } = useParams();

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [useRegistered, setUseRegistered] = useState({mailing:true, physical:true});

    let handleMerchantInfoFieldChange = (event) => {
        const {id, value} = event.target;
		setMerchant({...merchant, [id]:value});
		setMerchantFormChanged(true);
    }

    let handleMerchantAddressInfo = (event) => {
		handleMerchantInfoFieldChangeAddr (event, 'addressInfo');
    }
    let handleMerchantMailingAddress = (event) => {
		handleMerchantInfoFieldChangeAddr (event, 'mailingAddress');
    }
    let handleMerchantPhysicalAddress = (event) => {
		handleMerchantInfoFieldChangeAddr (event, 'physicalAddress');
    }

	let handleCheckboxChange = (event) => {
		const {id, checked} = event.target;
		setUseRegistered({...useRegistered, [id]:checked});
		setMerchantFormChanged(true);
	}

    let handleMerchantInfoFieldChangeAddr = (event, type) => {
        const {id, value} = event.target;
		let addr = {...merchant[type], [id]:value};
        setMerchant({...merchant, [type]:addr});
		setMerchantFormChanged(true);
    }

	let handleBankInfoFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...merchant, [id]:value};
		setMerchant(cloned);
		setBankFormChanged(true);
	}

	let handleSubmitBankInfo = (e) => {
		e.preventDefault();
		setSpinner(true);

		let body = {
			bankName : merchant.bankName,
			bankAccountNumber : merchant.bankAccountNumber,
			bankRoutingNumber : merchant.bankRoutingNumber,
			bankAccountType : merchant.bankAccountType,
			bankUuid : merchant.bankUuid,
			merchantUuid : merchant.merchantUuid,
			firstName : merchant.firstName,
			lastName : merchant.lastName,
			middleName : merchant.middleName,
		};

		let url = getMerchantsApiUrl() + '/bank-info';
		apiPut(url, body).then((resp)=> {
			setMessage({text:'Merchant Bank Info Updated.',type:'success'});
			setMerchantFormChanged(false);
			setBankFormChanged(false);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

    let handleSubmitMerchantInfo = (e) => {
        e.preventDefault();
        setSpinner(true);

        let body = {...merchant};

		body.addressInfo = merchant.addressInfo;
		if (useRegistered.mailing) {
			body.mailingAddress = merchant.addressInfo;
		} else {
			body.mailingAddress = merchant.mailingAddress;
		}
		if (useRegistered.physical) {
			body.physicalAddress = merchant.addressInfo;
		} else {
			body.physicalAddress = merchant.physicalAddress;
		}

        body.businessPhone = cleanPhone(body.businessPhone);
        body.mobilePhone = cleanPhone(body.mobilePhone);
		body.updationChannel = 'MANUAL';
		body.merchantUuid = merchant.merchantUuid;

		if (!user.privileges.includes('BANK_VIEW')) {
			body.taxId = null;
			body.taxClassId = null;
			body.tinTypeId = null;
		}

        let url = getMerchantsApiUrl() + '/merchant';
        apiPut(url, body).then((resp)=> {
            setMessage({text:'Merchant Info Updated.',type:'success'});
			setMerchantFormChanged(false);
			setBankFormChanged(false);
			getMerchant(id);
        }).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
        }).finally(() => setSpinner(false));
    };

	let getMerchant = (id) => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/merchant/' + id;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let cloned = {...resp};
			if (resp.bankAccountInfo) {
				cloned.bankAccountNumber = resp.bankAccountInfo.bankAccountNumber;
				cloned.bankRoutingNumber = resp.bankAccountInfo.bankRoutingNumber;
				cloned.bankAccountType = resp.bankAccountInfo.bankAccountType;
				cloned.bankName = resp.bankAccountInfo.bankName;
				cloned.bankUuid = resp.bankAccountInfo.bankUuid;
				cloned.firstName = resp.bankAccountInfo.firstName;
				cloned.lastName = resp.bankAccountInfo.lastName;
				cloned.middleName = resp.bankAccountInfo.middleName;
			}
			cloned.programUuid = resp.merchantPrograms[0];

			cloned.addressInfo = resp.addressInfo;
			cloned.mailingAddress = resp.mailingAddress;
			cloned.physicalAddress = resp.physicalAddress;

			let sameAddress = {mailing:false, physical:false};
			if (cloned.addressInfo.addressOne == cloned.mailingAddress.addressOne
				&& cloned.addressInfo.addressTwo == cloned.mailingAddress.addressTwo
				&& cloned.addressInfo.city == cloned.mailingAddress.city
				&& cloned.addressInfo.stateCode == cloned.mailingAddress.stateCode
				&& cloned.addressInfo.zipcode == cloned.mailingAddress.zipcode) {
					sameAddress.mailing = true;
			}
			if (cloned.addressInfo.addressOne == cloned.physicalAddress.addressOne
				&& cloned.addressInfo.addressTwo == cloned.physicalAddress.addressTwo
				&& cloned.addressInfo.city == cloned.physicalAddress.city
				&& cloned.addressInfo.stateCode == cloned.physicalAddress.stateCode
				&& cloned.addressInfo.zipcode == cloned.physicalAddress.zipcode) {
					sameAddress.physical = true;
			}

			setUseRegistered(sameAddress);

			cloned.businessPhone = formatPhone(resp.businessPhone);
			cloned.mobilePhone = formatPhone(resp.mobilePhone);

			if (!cloned.taxId)
				cloned.taxId = "";
			if (!cloned.taxClassId)
				cloned.taxClassId = "";
			if (!cloned.tinTypeId)
				cloned.tinTypeId = "";

			setMerchant(cloned);
			getMerchantPrograms();
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [merchantPrograms, setMerchantPrograms] = useState([]);
	let getMerchantPrograms = () => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/merchant/' + id + '/mapping';
		apiGet(url).then((resp)=> {
			//console.debug(resp);
			setMerchantPrograms(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let managedProgram = (uuid) => {
		for (var i=0; i<programs.length; i++){
			if(programs[i].id == uuid)
				return true;
		}
		return false;
	};

	let DisplayPrograms = (props) => {
		let ProgramRow = (p) => {
			let btnText = 'Inactivate';
			let statusId = 1;
			let btnClass = "btn btn-danger btn-sm";
			if (p.row.statusId == 1) {
				btnText = 'Activate';
				statusId = 0;
				btnClass = "btn btn-warning btn-sm";
			}
			return (
				<tr>
					<td>{p.row.programId}</td>
					<td>{p.row.programName}</td>
					<td>{p.row.shortname}</td>
					<td>{p.row.statusId == 1 ? 'Inactive' : 'Active'}</td>
					<td>
						{ managedProgram(p.row.programUuid) && hasMerchantUpdate() ? <button type="button" className={btnClass} onClick={ () => confirmMappingStatus(id, p.row.programId, statusId)}>{btnText}</button> : ''}
					</td>
				</tr>
			);
		};
		if (props.programs) {
			let rows = props.programs.map( (value, index) => <ProgramRow key={index} row={value}/>);
			return (
				<table className="table table-bordered">
					<thead>
						<tr>
						<th>Program Id</th>
						<th>Program Name</th>
						<th>Shortname</th>
						<th>Merchant Program Status</th>
						<th></th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</table>
			);
		} else {
			return null;
		}
	};

	let [taxClasses, setTaxClasses] = useState([]);
	let getTaxClasses = () => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/tax-classes';
		apiGet(url).then((resp) => {
			resp.unshift({taxClassId:'',descr:''});
			setTaxClasses(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [tinTypes, setTinTypes] = useState([]);
	let getTinTypes = () => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/tin-types';
		apiGet(url).then((resp) => {
			resp.unshift({tinTypeId:'',descr:''});
			setTinTypes(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

    useEffect( ()=> {
		getTaxClasses();
		getTinTypes();
        getMerchant(id);
        patternMessage("#zipcode", Strings.validation.zipFormat);
        patternMessage("#mobilePhone", Strings.validation.phoneFormat);
        patternMessage("#businessPhone", Strings.validation.phoneFormat);
		patternMessage("#email", Strings.validation.emailFormat);
		patternMessage("#taxId", Strings.validation.numbers);
    }, []);
    useEffect( ()=> {
        invalidInputStyle();
    });

	let confirmButton = () => {
		if (!hasMerchantUpdate()) {
			return null;
		}
		let updatableStatuses = [0,1];
		if (!updatableStatuses.includes(merchant.merchantStatusId)) {
			return null;
		}
		let btnClass = "btn btn-danger btn-sm pull-right";
		let btnText = "Inactivate";
		if (merchant.merchantStatusId != 0) {
			btnClass = "btn btn-warning btn-sm pull-right";
			btnText = "Activate";
		}
		return (
			<>
				<button type="button" className={btnClass} onClick={ () => confirmStatusChange()}>{btnText}</button>
			</>
		)
	}

	let decodeStatus = (id) => {
		let item = MerchantStatusArray.find(element => element.id == id);
		return (item ? item.text : null);
	};

	let [confirmSettings, setConfirmSettings] = useState({button:"Yes",type:"danger",confirm:()=>console.log('no action')});
	let confirmStatusChange = () => {
		let confirmSettings = {button:merchant.merchantStatusId == 1 ? 'Activate' : 'Inactivate'};
		confirmSettings.type = merchant.merchantStatusId == 1 ? 'warning' : 'danger';
		confirmSettings.confirm = () => changeMerchantStatus(merchant.merchantStatusId == 1 ? 0 : 1);
		setConfirmSettings(confirmSettings);
		showConfirmDialog();
	};

	let changeMerchantStatus = (statusId) => {
		setSpinner(true);
		closeConfirmDialog();

		let body = {
			merchantStatusId:statusId,
			updationChannel:'API'
		};

		let url = getMerchantsApiUrl() + '/merchant/' + id;
		apiPatch(url, body).then((resp)=> {
			getMerchant(id);
			setMessage({text:'Merchant Status updated.',type:'success'});

		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let confirmMappingStatus = (id, programId, statusId) => {
		let confirmSettings = {button:statusId == 1 ? 'Inactivate' : 'Activate'};
		confirmSettings.type = statusId == 1 ? 'danger' : 'warning';
		confirmSettings.confirm = () => statusProgramMapping(id, programId, statusId);
		setConfirmSettings(confirmSettings);
		showConfirmDialog();
	};

	let statusProgramMapping = (merchantUuid, programId, statusId) => {
		setSpinner(true);
		closeConfirmDialog();

		let body = {};

		let url = getMerchantsApiUrl() + '/merchant/' + merchantUuid + '/' + programId + '/' + statusId;
		apiPut(url, body).then((resp)=> {
			//console.debug(resp);
			setMessage({text:'Merchant Program Status updated.',type:'success'});
			getMerchantPrograms();
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

    let hasMerchantUpdate = () => {
        return user.privileges.includes('MERCHANT_UPDATE');
    };

	let TaxIdUpdate = () => {
		if (!user.privileges.includes('BANK_VIEW'))
			return null;

		return (
			<>
				<div className="row">
					<div className="form-group required col-12 col-sm-4">
						<label className="asterisk-label" htmlFor="taxId">Federal Tax ID</label>
						<input type="text" id="taxId" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.taxId} required maxLength="9" pattern="\d{9}"/>
					</div>
					<div className="form-group required col-12 col-sm-4">
						<label className="asterisk-label" htmlFor="tinTypeId">ID Type</label>
						<select id="tinTypeId" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.tinTypeId} required>
							{ selectOptions(tinTypes, 'tinTypeId', 'descr') }
						</select>
					</div>
					<div className="form-group required col-12 col-sm-4">
						<label className="asterisk-label" htmlFor="taxClassId">Tax Classification</label>
						<select id="taxClassId" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.taxClassId} required>
							{ selectOptions(taxClasses, 'taxClassId', 'descr') }
						</select>
					</div>
				</div>
			</>
		)
	};
	let BankUpdate = () => {
		if (!user.privileges.includes('BANK_VIEW'))
			return null;

		return (
			<>
			<br/>
				<h3>Bank Info</h3>
				<form onSubmit={handleSubmitBankInfo}>
				<div className="row">
					<div className="form-group required col-12 col-sm-5">
						<label className="asterisk-label" htmlFor="firstName">First Name</label>
						<input type="text" id="firstName" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.firstName} required maxLength="100" />
					</div>
					<div className="form-group col-12 col-sm-2">
						<label htmlFor="middleName">Middle Initial</label>
						<input type="text" id="middleName" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.middleName} maxLength="1" />
					</div>
					<div className="form-group required col-12 col-sm-5">
						<label className="asterisk-label" htmlFor="lastName">Last Name</label>
						<input type="text" id="lastName" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.lastName} required maxLength="100" />
					</div>
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="bankName">Bank Name</label>
						<input type="text" id="bankName" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.bankName} required maxLength="45" />
					</div>
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="bankAccountNumber">Bank Account</label>
						<input type="text" id="bankAccountNumber" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.bankAccountNumber} required maxLength="45" />
					</div>
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="bankRoutingNumber">Routing Number</label>
						<input type="text" id="bankRoutingNumber" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.bankRoutingNumber} required minLength="8" maxLength="45" />
					</div>
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="bankAccountType">Bank Account Type</label>
						<select id="bankAccountType" className="form-control" onChange={handleBankInfoFieldChange} value={merchant.bankAccountType} required >
							{ selectOptions(BankAccountTypeArray) }
						</select>
					</div>
				</div>
				<button className="btn btn-primary" disabled={!bankFormChanged}>Update</button>
			</form>
			</>
		)
	};

	let copyButton = {fontSize:'.7em',padding:'.1em .5em',margin:'.1em .5em'};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Merchant</h3>
        <MessageTag message={message}/>
			<table className="table table-bordered">
				<tbody>
				<tr>
					<th>Merchant ID</th>
					<th>Name</th>
					<th>Merchant Status</th>
				</tr>
				<tr>
					<td>{merchant.merchantIdentifier}</td>
					<td>{merchant.merchantName}</td>
					<td>{decodeStatus(merchant.merchantStatusId)} {confirmButton()}</td>
				</tr>
				</tbody>
			</table>
		<hr/>
		<h4>Merchant Info</h4>
        <form onSubmit={merchantInfo => handleSubmitMerchantInfo(merchantInfo)}>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="merchantIdentifier">Merchant ID</label>
					<input type="text" id="merchantIdentifier" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.merchantIdentifier} required maxLength="20" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="merchantName">Name</label>
					<input type="text" id="merchantName" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.merchantName} required maxLength="50" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="fnsNumber">FNS Number</label>
					<input type="text" id="fnsNumber" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.fnsNumber} required minLength="7" maxLength="7" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="email">Email</label>
					<input type="email" id="email" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.email} required maxLength="255" pattern={Strings.pattern.email}/>
				</div>
				</div>

				<div className="row">
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="businessPhone">Business Phone</label>
						<input type="text" id="businessPhone" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.businessPhone} required maxLength="20" pattern={Strings.pattern.phone} />
					</div>
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="mobilePhone">Mobile Phone</label>
						<input type="text" id="mobilePhone" className="form-control" onChange={handleMerchantInfoFieldChange} value={merchant.mobilePhone} required maxLength="20" pattern={Strings.pattern.phone} />
					</div>
				</div>

				{TaxIdUpdate()}

				<div className="row">
					<div className="form-group col-12"><strong>Registered Address</strong></div>
				</div>
				<div className="row">
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="addressOne">Address One</label>
						<input type="text" id="addressOne" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.addressOne} required maxLength="60" />
					</div>
					<div className="form-group col-12 col-sm-6">
						<label htmlFor="addressTwo">Address Two</label>
						<input type="text" id="addressTwo" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.addressTwo} maxLength="60"/>
					</div>
					</div>
					<div className="row">
					<div className="form-group required col-12 col-sm-6">
						<label className="asterisk-label" htmlFor="addr1">City</label>
						<input type="text" id="city" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.city} required maxLength="60" />
					</div>
					<div className="form-group required col-12 col-sm-3">
						<label className="asterisk-label" htmlFor="stateCode">State</label>
						<select id="stateCode" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.stateCode} required >
							{ selectOptions(StateArray) }
						</select>
					</div>
					<div className="form-group required col-12 col-sm-3">
						<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
						<input type="text" id="zipcode" className="form-control" onChange={handleMerchantAddressInfo} value={merchant.addressInfo.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
					</div>
				</div>

				<div className="row">
				<div className="form-group col-12 col-sm-3">
					<div className="form-check">
						<label className='clickable'>
						<input className="form-check-input" type="checkbox" id="mailing" value="true" checked={useRegistered.mailing} onChange={handleCheckboxChange} />
						 Use For Mailing Address</label>
					</div>
				</div>
				<div className="form-group col-12 col-sm-3">
					<div className="form-check">
						<label className='clickable'>
						<input className="form-check-input" type="checkbox" id="physical" value="true" checked={useRegistered.physical} onChange={handleCheckboxChange} />
						 Use For Physical Address</label>
					</div>
				</div>
				</div>

			{ useRegistered.mailing ? null : <>
			<div className="row">
				<div className="form-group col-12"><strong>Mailing Address</strong></div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addressOne">Address One</label>
					<input type="text" id="addressOne" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.addressOne} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="addressTwo">Address Two</label>
					<input type="text" id="addressTwo" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.addressTwo} maxLength="60"/>
				</div>
				</div>
				<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.stateCode} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleMerchantMailingAddress} value={merchant.mailingAddress.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			</> }

			{ useRegistered.physical ? null : <>
			<div className="row">
				<div className="form-group col-12"><strong>Physical Address</strong></div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addressOne">Address One</label>
					<input type="text" id="addressOne" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.addressOne} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="addressTwo">Address Two</label>
					<input type="text" id="addressTwo" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.addressTwo} maxLength="60"/>
				</div>
				</div>
				<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.stateCode} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleMerchantPhysicalAddress} value={merchant.physicalAddress.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			</> }

			<button className="btn btn-primary" disabled={!merchantFormChanged || !hasMerchantUpdate()}>Update</button>
		</form>

		<br/>
		<h4>Merchant Programs</h4>
		<DisplayPrograms programs={merchantPrograms}/>

		{BankUpdate()}
		<ConfirmDialog settings={confirmSettings} />
		</>
	)
}
