import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import AdminRecipient from '../program/Recipient';


export default function Recipient() {

	return (
		<>
		<AdminRecipient basePath="/admin" />
		</>
	)
}
