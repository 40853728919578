import React from 'react';

export function closePromptDialog() {
	document.getElementById('promptClose').click();
}

export function showPromptDialog() {
	window.jQuery('#promptDialog').modal();
}

export default function PromptDialog(props) {
	let handlePrompt = closePromptDialog;
	if (props.settings.handlePrompt) {
		handlePrompt = props.settings.handlePrompt;
	}

	let button = props.settings.button ? props.settings.button : 'Enter';
	let title = props.settings.title ? props.settings.title : 'Prompt';
	let defaultValue = props.settings.defaultValue ? props.settings.defaultValue : '';
	let type = props.settings.type ? props.settings.type : 'primary';

	let body = <><input type="text" id="promptInput" className="form-control" required minLength="2" maxLength="20" defaultValue={defaultValue} /></>;
	if (props.settings.body) {
		body = props.settings.body;
	}


	let btnClass = "btn ";
	switch(type) {
		case 'info':
			btnClass = btnClass + " btn-info";
			break;
		case 'warning':
			btnClass = btnClass + " btn-warning";
			break;
		case 'danger':
			btnClass = btnClass + " btn-danger";
			break;
		case 'success':
			btnClass = btnClass + " btn-success";
			break;
		default:
			btnClass = btnClass + " btn-primary";
			break;
	}

	let handleSubmit = (e) => {
		e.preventDefault();
		let value = document.getElementById('promptInput').value;
		handlePrompt(value);
		closePromptDialog();
	};

	let FormBody = () => {
		return (<>
		<form onSubmit={handleSubmit} id="promptForm">
			{body}
			<hr/>
			<button className='btn btn-primary pull-right'>{button}</button>
			<span className='pull-right'>&nbsp;</span>
			<button type="button" className="btn btn-secondary pull-right" data-dismiss="modal" id="promptClose">Cancel</button>
		</form>
		</>);
	};

	return (
		<div className="modal" tabIndex="-1" role="dialog" id="promptDialog">
		<div className="modal-dialog" role="document">
			<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title">{title}</h5>
				<button type="button" className="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body">
				<FormBody />
			</div>
			</div>
		</div>
		</div>
	)
}
