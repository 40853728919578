import React, { useState, useEffect } from 'react';


import AdminBenefits from '../program/Benefits';

export default function Benefits() {

	return <AdminBenefits admin={true}/>

}
