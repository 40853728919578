import React, { useState, useEffect } from 'react';

export default function FundingSources(props) {

	let fundingSources = props.fundingSources;
	let handleSelectChange = props.handleSelectChange;
	let value = props.value;

	let SelectOption = (props) => {
		return <option value={props.funding.fundingId} >{props.funding.name}</option>
	};
	let SelectOptions = fundingSources.map( (item, index) => {
		return <SelectOption key={index} funding={item}/>
	});

	let DisplaySelect = () => {
		if (fundingSources && fundingSources.length) {
			return <>
			<select id="fundingId" name="fundingId" value={value} className="form-control custom-select" required onChange={handleSelectChange}>
				{SelectOptions}
			</select>
			</>;
		} else {
			return null;
		}
	};

	return (
		<DisplaySelect />
	)
}
