import React, { useState, useEffect } from 'react';

import AdminRecipients from '../program/Recipients';

export default function Recipients(props) {

	let searchOnly = props && props.searchOnly ? true : false;

	return (
		<>
		<AdminRecipients admin={true} searchOnly={searchOnly}/>
		</>
	)
}
