import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet, apiPut, getUserApiUrl, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { formatPhone, stripTime } from '../common/Formats';
import Strings from '../common/Strings';
import { PrivilegeCodes } from '../common/Constants';

import UserUpdate from './UserUpdate';
import '../admin/User.css';

export default function User() {
	let userForm = {
		email:'',
		emailVerified:'',
		firstname:'',
		middlename:'',
		lastname:'',
		mobile:'',
		phone:'',
		username:'',
		userStatusId:'',
		creationDate:'',
		userId:'',
		userUuid:'',
		programs:[],
		roleId:''
	};

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let { id, progId } = useParams();

	const history = useHistory();
	let GoTo = (uuid) => history.push('/admin/program/' + uuid);

	let {user} = useUserContext();

	useEffect( () => {

		if (user) {
			if (user.privileges.includes(PrivilegeCodes.agencyAdminAccess)) {
				console.log('agency admin');
			} else if (user.privileges.includes(PrivilegeCodes.programAccess)) {
				console.log('program admin');
			}
		} else {
			console.log('no loggedInUser');
		}

	},[]);

	let ViewAccount = () => {
		if (user.accountUuid) {
			//let recipientPath = () => '/program/recipient/' + user.accountUuid + '/' + progId;
			//return <><Link to={recipientPath()}>View Recipient</Link><hr/></>
			return null;
		} else {
			return null;
		}
	};

	return (
		<>
			<Processing show={showSpinner}/>
			<h4>User Info</h4>
			<ViewAccount />

			<MessageTag message={message} />

			<UserUpdate setMessage={setMessage} />
		</>
	)
}
