import React from 'react';

export function closeConfirmDialog() {
	document.getElementById('confirmClose').click();
}

export function showConfirmDialog() {
	window.jQuery('#confirmDialog').modal();
}

export default function ConfirmDialog(props) {
	let confirm = props.confirm ? props.confirm : closeConfirmDialog;
	let title = props.title ? props.title : 'Confirm';
	let button = props.button ? props.button : 'Yes';
	let body = props.body ? props.body : 'Are you sure?';
	let type = props.type ? props.type : 'warning';

	if (props.settings) {
		if (props.settings.confirm) {
			confirm = props.settings.confirm;
		}
		if (props.settings.button) {
			button = props.settings.button;			
		}
		if (props.settings.title) {
			title = props.settings.title;
		}
		if (props.settings.body) {
			body = props.settings.body;
		}
		if (props.settings.type) {
			type = props.settings.type;
		}
	}

	let btnClass = "btn";
	let headerClass = "modal-header alert";
	switch(type) {
		case 'info':
			btnClass = btnClass + " btn-info";
			headerClass = headerClass + " alert-info";
			break;
		case 'warning':
			btnClass = btnClass + " btn-warning";
			headerClass = headerClass + " alert-warning";
			break;
		case 'danger':
			btnClass = btnClass + " btn-danger";
			headerClass = headerClass + " alert-danger";
			break;
		case 'success':
			btnClass = btnClass + " btn-success";
			headerClass = headerClass + " alert-success";
			break;
		case 'secondary':
			btnClass = btnClass + " btn-secondary";
			headerClass = headerClass + " alert-secondary";
			break;
		default:
			btnClass = btnClass + " btn-primary";
			headerClass = headerClass + " alert-primary";
			break;
	}

	return (
		<div className="modal" tabIndex="-1" role="dialog" id="confirmDialog">
		<div className="modal-dialog" role="document">
			<div className="modal-content">
			<div className={headerClass}>
				<h5 className="modal-title">{title}</h5>
				<button type="button" className="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body">
				{body}
			</div>
			<div className="modal-footer">
				<button type="button" className="btn btn-secondary" data-dismiss="modal" id="confirmClose">Cancel</button>
				<button type="button" className={btnClass} onClick={confirm} id="confirmAction">{button}</button>
			</div>
			</div>
		</div>
		</div>
	)
}
