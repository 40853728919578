import React, { useState, useEffect } from 'react';

import AdminAgency from '../program/Agency';

export default function Agency() {
	return (
		<>
		<AdminAgency />
		</>
	)
}
