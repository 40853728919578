import React, {useState, useEffect} from 'react';
import { useHistory, Link } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet, apiPost, apiMessages, getAccountsApiUrl } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { invalidInputStyle } from '../common/Forms';
import Strings from '../common/Strings';
import { PrivilegeCodes } from '../common/Constants';

import DatePicker from 'react-date-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

export default function AddAgency() {
	let [showSpinner, setSpinner] = useState(false);
    let [message, setMessage] = useState();
	let blankAgency = {agencyName:'',programUuid:'', fundingAmount:'', fundingAlert:1, fundingDate:''};
	let [agency, setAgency] = useState(blankAgency);
	let [programs, setPrograms] = useState([]);
    let {user} = useUserContext();
	let [basePath, setBasePath] = useState('/program');

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'', text:'Select...'});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getPrograms();
		if (user.privileges.includes(PrivilegeCodes.adminAccess))
			setBasePath('/admin');
	}, []);

	useEffect( () => {
		invalidInputStyle();
	});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...agency, [id]:value};
		setAgency(cloned);
	}

	let handleSelectChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...agency, [id]:value};
		setAgency(cloned);
	}

	let handleDayChange = (inputName, val) => {
		let cloned = {...agency, [inputName]:val};
		setAgency(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);
		let url = getAccountsApiUrl() + '/agency';
		apiPost(url, agency).then((resp) => {
			setMessage({text: 'Agency added.', type:'success'});
			setAgency(blankAgency);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let ProgramOption = (props) => {
		return <option value={props.program.id} >{props.program.text}</option>
	};
	let ProgramOptions = programs.map( (item, index) => {
		return <ProgramOption key={index} program={item}/>
	});
	let DisplayPrograms = () => {
		if (programs && programs.length) {
			return <>
			<select id="programUuid" name="programUuid" value={agency.programUuid} className="form-control custom-select" required onChange={handleSelectChange}>
				{ProgramOptions}
			</select>
			</>;
		} else {
			return null;
		}
	};

	const history = useHistory();
	let GoTo = () => {
		history.push(basePath + '/agencies');
	};

	return (
		<>
		<Processing show={showSpinner}/>
		<h3>Add Agency</h3>
		<MessageTag message={message}/>

		<p>
		<Link to={() => basePath + '/agencies'}>Return To Agencies</Link>
		</p>

		<h5>Add Agency</h5>
		<form onSubmit={handleSubmit}>
		<div className="row">
			<div className="form-group required col-12 col-md-5">
				<label className="asterisk-label" htmlFor="name">Name</label>
				<input type="text" id="agencyName" className="form-control" onChange={handleFieldChange} value={agency.agencyName} required maxLength="100" />
			</div>
			<div className="form-group required col-12 col-md-5">
				<label className="asterisk-label" htmlFor="programId">Program</label>
				<DisplayPrograms/>
			</div>

			<div className="form-group col-12 col-md-5">
				<label htmlFor="fundingAmount">Funding Amount</label>
				<input type="number" id="fundingAmount" className="form-control text-right" onChange={handleFieldChange} value={agency.fundingAmount} min="0.00" step="0.01" maxLength="9" max="99999999" />
			</div>
			<div className="form-group col-12 col-md-3">
				<label className="asterisk-label" htmlFor="fundingDate">Funding Date</label>
				<DatePicker value={agency.fundingDate} onChange={(val) => handleDayChange('fundingDate', val)} className={"form-control"} id="fundingDate" name="fundingDate" minDate={new Date(2023,0,1)} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
			</div>
		</div>
		<button className="btn btn-primary" id="addAgency">Add</button> <button className="btn btn-primary"
		 id="cancle" onClick={GoTo}>Cancel</button>
		</form>

		</>
	);
}
