import React from 'react';
import useUserContext from '../common/UserContext';
import { Link } from 'react-router-dom';
import { DisplayName } from '../common/UserUtils';
import { PrivilegeCodes } from '../common/Constants';
import User from './UserUpdate';

export default function Header() {
	let {user} = useUserContext();

	let UsersLink = () => {
		let showUsers = false;
		if (user.privileges) {
			let privileges = user.privileges;
			if (privileges.includes(PrivilegeCodes.programAccess)
				|| privileges.includes(PrivilegeCodes.agencyAdminAccess))
				showUsers = true;
		}
		if (showUsers) {
			return <li className="nav-item">
			<Link to="/program/users" className="nav-link">Users</Link>
			</li>
		}
		return null;
	};

    return (
	<>
		<Link to="/program" className="navbar-brand">MyMarketLink</Link>
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>

		<div className="collapse navbar-collapse" id="navbarMenu">
			<ul className="navbar-nav mr-auto">
				<li className="nav-item">
					<Link to="/program/dash" className="nav-link">Dashboard</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/recipients" className="nav-link">Recipients</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/benefits" className="nav-link">Benefits</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/transactions" className="nav-link">Transactions</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/merchants" className="nav-link">Merchants</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/merchantlocator" className="nav-link" title="Find Merchants by location">Locator</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/program" className="nav-link">Programs</Link>
				</li>
				<li className="nav-item">
					<Link to="/program/agencies" className="nav-link">Agencies</Link>
				</li>
				<UsersLink />

				<li className="nav-item dropdown">
					<a className="nav-link dropdown-toggle" id="admMenu" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Account</a>
					<div className="dropdown-menu">
						<Link to="/program/account" className="dropdown-item">Account</Link>
						<div className="dropdown-divider"></div>
						<Link to="/logout" className="dropdown-item">Logout</Link>
					</div>
				</li>

			</ul>
			<Link to="/program/account" className="nav-link btn-primary"><DisplayName user={user}/></Link>
		</div>
	</>
    )
}
