import React, {useState, useEffect} from 'react';
import { useHistory, Link, useParams } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet, apiPost, apiMessages, getAccountsApiUrl, apiPut, getUserApiUrl, apiDelete, apiPatch } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { invalidInputStyle } from '../common/Forms';
import Strings from '../common/Strings';
import { PrivilegeCodes } from '../common/Constants';

import ConfirmDialog, { closeConfirmDialog, showConfirmDialog } from '../common/ConfirmDialog';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';
import { selectOptions } from '../common/Forms';

import DatePicker from 'react-date-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

export default function Agency() {
	let [showSpinner, setSpinner] = useState(false);
    let [message, setMessage] = useState();
	let blankAgency = {agencyName:'',programUuid:'', programName:'', fundingAmount:'', fundingAlert:'', fundingDate:''};
	let [agency, setAgency] = useState(blankAgency);
	let [programs, setPrograms] = useState([]);
    let {user} = useUserContext();
	let [basePath, setBasePath] = useState('/program');
	let [users, setUsers] = useState([]);
	let [agencyUsers, setAgencyUsers] = useState([]);
	let [modifyUsers, setModifyUsers] = useState(false);
	let [modifyAgency, setModifyAgency] = useState(false);
	let [modifyAgencyFunding, setModifyAgencyFunding] = useState(false);
	let [agencies, setAgencies] = useState([]);

	let { id } = useParams();

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		setSpinner(true);
		apiGet(url).then((resp) => {
			let programs = [];
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getAgency = (agencyUuid) => {
		let url = getAccountsApiUrl() + '/agency/' + agencyUuid;
		setSpinner(true);
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let fundingDate = resp.fundingDate ? moment(resp.fundingDate).toDate() : '';
			setAgency({agencyName:resp.agencyName,
				programUuid:resp.program.programUuid,
				agencyUuid:resp.agencyUuid,
				programName:resp.program.programName,
				fundingAmount:resp.fundingAmount ? resp.fundingAmount.toFixed(2) : '',
				fundingDate:fundingDate,
				fundingAlert: resp.fundingAlert ? resp.fundingAlert : 0
			});
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getAgencyUsers = (agencyUuid) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/agency/' + agencyUuid + '/user';
		apiGet(url).then((resp) => {
			setAgencyUsers(resp);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};
	
	let getAgencies = () => {
		setSpinner(true);
		let viewable = user.privileges.includes(PrivilegeCodes.adminAccess) || user.privileges.includes(PrivilegeCodes.programAccess) ? '?viewable' : '';
		let url = getAccountsApiUrl() + '/agency' + viewable;
		apiGet(url).then((resp) => {
			console.debug(resp);
			let agens = [{id:'',text:'Select New Agency...'}];
			resp.forEach( (a) => {
				if (id != a.agencyUuid)
					agens.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agens);
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let getUpdatableAgencies = (id) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/agency';
		apiGet(url).then((resp) => {
			for (var i = 0; i < resp.length; i++) {
				if (resp[i].agencyUuid == id) {
					if (user.privileges.includes(PrivilegeCodes.agencyUpdate)) {
						setModifyAgency(true);
					}
					setModifyUsers(true);
					break;
				}
			}
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getPrograms();
		if (user.privileges.includes(PrivilegeCodes.adminAccess))
			setBasePath('/admin');

		let privileges = user.privileges;
		if (privileges.includes(PrivilegeCodes.programAccess)) {
			if (user.privileges.includes(PrivilegeCodes.agencyUpdate)) {
				setModifyAgency(true);
				setModifyAgencyFunding(true);
			}
			setModifyUsers(true);
		} else if (privileges.includes(PrivilegeCodes.adminAccess)) {
			if (user.privileges.includes(PrivilegeCodes.agencyUpdate)) {
				setModifyAgency(true);
				setModifyAgencyFunding(true);
			}
			setModifyUsers(true);
		} else if (privileges.includes(PrivilegeCodes.agencyAdminAccess)) {
			getUpdatableAgencies(id);
		}
	
		getAgency(id);
		getAgencyUsers(id);
		getAgencies();
	}, []);

	useEffect( () => {
		invalidInputStyle();
	});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...agency, [id]:value};
		setAgency(cloned);
	}

	let handleDayChange = (inputName, val) => {
		let cloned = {...agency, [inputName]:val};
		setAgency(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);
		let url = getAccountsApiUrl() + '/agency/' + agency.agencyUuid;
		apiPut(url, agency).then((resp) => {
			setMessage({text: 'Agency updated.', type:'success'});
			getAgency(agency.agencyUuid);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let ShowAgencyUsers = (props) => {
		let ShowMove = (props) => {
			if (agencies.length > 1) {
				return (
					<>
					<button type="button" className='btn btn-sm btn-primary' style={{display: modifyUsers ? 'inline' : 'none'}} onClick={ () => confirmMoveDialog(props.user) } >Move</button>
						&nbsp;
					</>
				)

			} else {
				return null;
			}
		};

		if (props.users && props.users.length) {
			let AgencyUserRow = (props) => {
				return (
					<tr>
						<td><Link to={() => basePath + '/user/' + props.row.userUuid}>{props.row.username}</Link></td>
						<td>{props.row.email}</td>
						<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
						<td>{props.row.roleDescr}</td>
						<td>
							<ShowMove user={props.row} />
							<button type="button" className='btn btn-sm btn-primary' style={{display: modifyUsers ? 'inline' : 'none'}} onClick={ () => confirmRemoveDialog(props.row.userUuid) } >Remove</button>
						</td>
					</tr>
				)
			};
			let AgencyUsersTableBody = props.users.map( (data, index) => {
				return <AgencyUserRow key={index} row={data} />;
			});
			return (
				<>
				<br/>
				<h5>Agency Admins/Users</h5>
				<table className="table" id="tblAgencyUsers">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Name</th>
							<th>Role</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ AgencyUsersTableBody }
					</tbody>
				</table>
				</>
			);
		}
		return null;
	};

	let [searchForm, setSearchForm] = useState({email:'',lastname:'',username:'',agencyUuid:'', roleIds:[5,6]});
	let searchUsers = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getUserApiUrl() + '/program-users';
		let queryParams = [];
		for(const searchField in searchForm) {
			queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
		}
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		apiGet(url).then((resp) => {
			setUsers(resp);

			if (resp && resp.length==0) {
				setMessage({text:'No users found.', type:'warning'});
			} else {
				setMessage(null);
			}

		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	let handleSearchFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...searchForm, [id]:value};
		setSearchForm(cloned);
	}

	let ShowUsers = (props) => {
		if (props.users && props.users.length) {
			let UserRow = (props) => {
				let btnLabel = null;
				if (props.row.roleId == 5){
					btnLabel = 'Add Agency Admin';
				} else if (props.row.roleId == 6) {
					btnLabel = 'Add Agency User';
				}
				return (
					<tr>
						<td><Link to={() => basePath + '/user/' + props.row.userUuid}>{props.row.username}</Link></td>
						<td>{props.row.email}</td>
						<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
						<td>
							<button type="button" className='btn btn-sm btn-primary' onClick={ () => confirmAddDialog(props.row.userUuid) }>{btnLabel}</button>
						</td>
					</tr>
				)
			};
			let UsersTableBody = props.users.map( (data, index) => {
				return <UserRow key={index} row={data} />;
			});
			return (
				<>
				<table className="table" id="tblUsers">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ UsersTableBody }
					</tbody>
				</table>
				</>
			);
		}
		return null;
	};

	let doAdd = (uuid) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/agency/' + agency.agencyUuid + '/user/' + uuid;
		apiPost(url, {}).then((resp) => {
			setMessage({text: 'User added.', type:'success'});
			setTimeout( () => getAgencyUsers(agency.agencyUuid), 1000);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let doRemove = (uuid) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/agency/' + agency.agencyUuid + '/user/' + uuid;
		apiDelete(url).then((resp) => {
			setMessage({text: 'User removed.', type:'success'});
			setTimeout( () => getAgencyUsers(agency.agencyUuid), 1000);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let doMove = () => {
		let form = document.getElementById('moveForm');
		let userValue = form['moveUserUuid'].value;
		let agencyValue = form['moveAgencyUuid'].value;
		setSpinner(true);

		let url = getAccountsApiUrl() + '/agency/' + agency.agencyUuid + '/user/' + userValue;
		let body = {agencyUuid:agencyValue};

		apiPatch(url, body).then((resp) => {
			setMessage({text: 'User moved.', type:'success'});
			setTimeout( () => getAgencyUsers(agency.agencyUuid), 1000);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if (err.privilegeError) {
				messageObject.text = <>{err.message}</>;
			} else if (err.status == 400 || err.status == 409) {
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let alreadyAssigned = (uuid) => {
		for (let agencyUser of agencyUsers){
			if (agencyUser.userUuid == uuid)
				return true;
		}
		return false;
	};

	let [confirmSettings, setConfirmSettings] = useState({});
	let confirmAddDialog = (uuid) => {
		if (alreadyAssigned(uuid)) {
			showAlertDialog();
		} else {
			let addAction = (uuid) => {
				closeConfirmDialog();
				doAdd(uuid);
			};
			setConfirmSettings({confirm:()=>addAction(uuid),body:'Add to Agency?',button:'Add'});
			showConfirmDialog();
			}
	};
	let confirmRemoveDialog = (uuid) => {
		let removeAction = (uuid) => {
			closeConfirmDialog();
			doRemove(uuid);
		};
		setConfirmSettings({confirm:()=>removeAction(uuid),body:'Remove from Agency?',button:'Remove'});
		showConfirmDialog();
	};

	let doNothing = (e) => {
		e.preventDefault();
	}
	let confirmMoveDialog = (user) => {
		let moveAction = () => {
			let form = document.getElementById('moveForm');
			if (form['moveAgencyUuid'].value) {
				closeConfirmDialog();
				doMove();
			} else {
				alert('An agency must be selected.');
			}
		};
		let moveBody = () => {
			return (
				<><form onSubmit={doNothing} id="moveForm">
					<label htmlFor="moveAgencyUuid">Agency</label>
				<select id="moveAgencyUuid" name="moveAgencyUuid" className="form-control" defaultValue={''}>
				{ selectOptions(agencies) }
				</select>
				<input type="hidden" id="moveUserUuid" name="moveUserUuid" value={user.userUuid} />
				</form>
				</>
			);
		};
		setConfirmSettings({confirm:() => moveAction(), body:moveBody(), button:'Move', type:'info', title:'Move To Agency'});
		showConfirmDialog();
	};

	const history = useHistory();
	let GoTo = () => {
		history.push(basePath + '/agencies');
	};

	let updBtnCss = {display: modifyAgency ? 'inline' : 'none'};

	return (
		<>
		<Processing show={showSpinner}/>
		<h3>Update Agency</h3>
		<MessageTag message={message}/>

		<p>
		<Link to={ () => basePath + '/agencies'}>Return To Agencies</Link>
		</p>

		<h5>Update Agency</h5>
		<form onSubmit={handleSubmit}>
		<div className="row">
			<div className="form-group required col-12 col-md-5">
				<label className="asterisk-label" htmlFor="name">Name</label>
				<input type="text" id="agencyName" className="form-control" onChange={handleFieldChange} value={agency.agencyName} required maxLength="100" disabled={!modifyAgency}/>
			</div>
			<div className="form-group col-12 col-md-5">
				<label className="asterisk-label" htmlFor="programId">Program</label>
				<input type="text" id="programName" name="programName" className="form-control" value={agency.programName} readOnly={true}/>
			</div>
			<div className="form-group col-12 col-md-5">
				<label htmlFor="fundingAmount">Funding Amount</label>
				<input type="number" id="fundingAmount" className="form-control text-right" onChange={handleFieldChange} value={agency.fundingAmount} min="0.00" step="0.01" maxLength="9" max="99999999" disabled={!modifyAgencyFunding}/>
			</div>
			<div className="form-group col-12 col-md-3">
				<label className="asterisk-label" htmlFor="fundingDate">Funding Date</label>
				<DatePicker value={agency.fundingDate} onChange={(val) => handleDayChange('fundingDate', val)} className={"form-control"} id="fundingDate" name="fundingDate" minDate={new Date(2023,0,1)} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD" disabled={!modifyAgencyFunding}/>
			</div>
		</div>
		<button className="btn btn-primary" id="updtAgency" style={updBtnCss} disabled={!modifyAgency}>Update</button> <button className="btn btn-primary"
		 id="cancle" onClick={GoTo}>Cancel</button>
		</form>

		<ShowAgencyUsers users={agencyUsers}/>

		<div style={{display: modifyUsers ? 'block' : 'none'}}>
		<h5>Add Admin/User</h5>
        <form onSubmit={searchUsers}>
		<div className="row">
			<div className="form-group col-12 col-md-6 col-lg-2">
				<label htmlFor="username">Username</label>
				<input type="text" id="username" className="form-control" maxLength="20" value={searchForm.username} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-3">
				<label htmlFor="email">Email</label>
				<input type="text" id="email" className="form-control" maxLength="100" value={searchForm.email} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-3">
				<label htmlFor="lastname">Last Name</label>
				<input type="text" id="lastname" className="form-control" maxLength="45" value={searchForm.lastname} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-2"><br/>
				<button className="btn btn-primary" id="userSearch">Search</button>
			</div>
		</div>
		</form>
		</div>

		<ShowUsers users={users}/>

		<AlertDialog settings={{body:'This user is already assigned to the Agency.', type:'info', title:'Notice'}} />
		<ConfirmDialog settings={confirmSettings} />
		</>
	);
}
