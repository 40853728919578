import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import useUserContext from './UserContext';

export default function ProtectedRoute({ children, ...rest }) {
    let {user, setUser} = useUserContext();
    
    let userPrivileges = [];
    if(user && user.privileges)
        userPrivileges = user.privileges;

    let foundPriv = false;
    if (rest.privileges) {
        for (const item of rest.privileges) {
            if (userPrivileges.find( p => p === item)) {
                foundPriv = true;
                break;
            }
        }
    } else {
        foundPriv = userPrivileges.find(userPriv => userPriv === rest.privilege || userPriv === rest.privilege2);
    }

    if(!foundPriv)
        return <Redirect to="/login" />

    return (
        <Route
            {...rest}
            render={({ location }) => ( children ) }
        />
    );
}
