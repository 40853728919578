const Strings = {
	validation : {
		fundingSource : "At least one funding source must be entered.",
		fundingSourceName : "Funding source name must be 2 through 30 characters.",
		fundingSourceDupe : "Funding Source Name already exists.",
		zipFormat : "Zipcode format should be '99999' or '99999-9999'.",
		phoneFormat : "Invalid phone number format. Example: '999-999-9999' or '999 999-9999' or '9999999999'.",
		emailFormat : "Invalid email format. Example: 'john@mail.com'.",
		username : "Username must be 8-20 alphanumeric characters, starting with an alpha character.",
		number : "The value must be a number.",
		numbers : "The value must be numeric digits. Example: '999999999'",
		password : "Password must contain one uppercase, one lowercase, one numeric, and one special character.",
		confirmPassword : "Confirm password must match new password.",
		cardNumberInName: "Avoid putting part of the card number in the card name.",
		cardNameFormat: "Card Name must be 2 or more characters.",
		cardTypeRequired: "A card type must be selected.",
		authNoFormat: "Authorization Number cannot contain spaces.",
		panFormat: "PAN must be 16 to 19 numeric digits.",
		expirationDays: "Days must be 1 to 4 numberic digits."
	},
	help : {
		expirationDays: "Optional: A number of days for populating the expiration date.",
		expirationDaysIncentive: "Optional: A number of days for populating the incentive expiration date.",
		benefitSetAmount: "Optional: Use a preset amount for benefit entry.",
		lastIssuance: "The last day a benefit can be issued or earned. Remove date to allow issuance.",
		benefitNote: "Optional: Enable a note field for benefit issuances.",
		benefitOption: "Optional: Select the custom dropdown type to enable for benefit issuances."
	},
	error : {
		general : "An unexpected error occurred.",
		unavailable : "The api for this request is currently unavailable.",
		privilege : "You do not have the necessary privilege for the requested action.",
		logon : "Invalid username and password combination.",
		loginUnavailable : "Unable to login at this time.",
		portalUnavailable : "The web portal is not available for this user.",
		externalPanDupe : "The PAN entered already exists in the system."
	},
	warning : {
		searchCritera : "At least one field must be entered.",
		noIncentives : "Your are not enrolled in any incentives.",
		noEBTCards : "There are no EBT cards attached to your account."
	},
	site : {
		title: "MyMarketLink"
	},
	confirm : {
		addToProgram: "Add this user as an admin for this program?",
		removeFromProgram: "Remove this user as an admin for this program?",
		deleteEntry: "Are you sure you want to delete this entry?",
		deletePAN: "Are you sure you want to delete this PAN?"
	},
	notifications : {
		notFound: "Notification not found.",
		noneFound: "No notifications found.",
		preferenceInfo: "Select which events you would like to receive a notification for."
	},
	success: {
		activation: 'Activation successful. You can now proceed to the oniine portal or login via the MyMarketLink app.'
	},
	pattern: {
		email: "[A-Za-z0-9._~#$+^*\\-]+@[A-Za-z0-9.\\-]+.[A-Za-z]{2,4}",
		phone: "\\d{3}([\\-. ])?\\d{3}([\\-. ])?\\d{4}"
	}
};

export default Strings;
