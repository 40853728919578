import React, { useState, useEffect } from 'react';

import AdminAddAgency from '../program/AddAgency';

export default function AddAgency() {
	return (
		<>
		<AdminAddAgency />
		</>
	)
}
