import React, { Component } from 'react'
import {Route, Switch} from 'react-router-dom'

import Home from './Home';
import Account from './Account';
import Recipients from './Recipients';
import Recipient from './Recipient';
import AddRecipient from './AddRecipient';
import Program from './Program';
import Benefits from './Benefits';
import Benefit from './Benefit';
import AddBenefit from './AddBenefit';
import BenefitFile from './BenefitFile';
import Transactions from './Transactions';
import Transaction from '../recipient/Transaction';
import Merchants from './Merchants';
import Merchant from './Merchant';
import AddMerchant from './AddMerchant';
import AccountFile from './AccountFile';
import Users from './Users';
import User from './User';
import AddUser from './AddUser';
import MerchantFile from './MerchantFile';
import RecipientPans from './RecipientPans';
import RecipientIncentives from './RecipientIncentives';
import EBTCards from './EBTCards';
import Agencies from './Agencies';
import AddAgency from './AddAgency';
import Agency from './Agency';
import Dashboard from './Dashboard';
import MerchantLocator from './MerchantLocator';

export default class Content extends Component {
    render() {
        return (
            <Route path="/program">
                <Switch>
                    <Route path="/program/home" component={Home} />
                    <Route path="/program/account" component={Account} />
                    <Route path="/program/agencies" component={Agencies} />
                    <Route path="/program/agency/add" component={AddAgency} />
                    <Route path="/program/agency/:id" component={Agency} />
                    <Route path="/program/benefits/:recipientId" component={Benefits} />
                    <Route path="/program/benefits" component={Benefits} />
                    <Route path="/program/benefit/add" component={AddBenefit} />
                    <Route path="/program/benefit/file" component={BenefitFile} />
                    <Route path="/program/benefit/:id" component={Benefit} />
                    <Route path="/program/dash" component={Dashboard} />
                    <Route path="/program/recipients" component={Recipients} />
                    <Route path="/program/recipient/ebtcards/:id" component={EBTCards} />
                    <Route path="/program/recipient/incentives/:id/:progId" component={RecipientIncentives} />
                    <Route path="/program/recipient/add" component={AddRecipient} />
                    <Route path="/program/recipient/file" component={AccountFile} />
                    <Route path="/program/recipient/:id/:progId" component={Recipient} />
                    <Route path="/program/pans/:id/:progId" component={RecipientPans} />
                    <Route path="/program/program" component={Program} />
                    <Route path="/program/transactions" component={Transactions} />
                    <Route path="/program/transaction/:id" component={Transaction} />
                    <Route path="/program/merchantlocator" component={MerchantLocator} />
                    <Route path="/program/merchants" component={Merchants} />
                    <Route path="/program/merchant/add" component={AddMerchant} />
                    <Route path="/program/merchant/file" component={MerchantFile} />
                    <Route path="/program/merchant/:id" component={Merchant} />
                    <Route path="/program/user/add" component={AddUser} />
                    <Route path="/program/user/:id" component={User} />
                    <Route path="/program/user/:id/:progId" component={User} />
                    <Route path="/program/users" component={Users} />
                    <Route path="/program" component={Home} />
                </Switch>
            </Route>
        )
    }
}
