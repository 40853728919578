import React, {useState, useEffect} from 'react';
import useUserContext from '../common/UserContext';

import {useHistory, Link} from "react-router-dom";
import {apiGet, getMerchantsApiUrl, getAdminApiUrl, apiMessages} from '../common/AppApi';

import Processing from '../common/Processing';
import Pagination, {resetPaging} from '../common/Pagination';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { MerchantStatusArray, PageSize } from '../common/Constants';
import { selectOptions } from '../common/Forms';

export default function MerchantSearch(props) {
    let [showSpinner, setSpinner] = useState(false);
    let [dataRows, setDataRows] = useState([]);
    let [pagingData, setPagingData] = useState(resetPaging());
    let [message, setMessage] = useState();
	let {user} = useUserContext();
	let [downloadUrl, setDownloadUrl] = useState('');

    let emptyForm = {
        merchantIdentifier: '',
		merchantName: '',
		fnsNumber: '',
		email: '',
		programId:'',
        taxId:''
    };

	let [searchForm, setSearchForm] = useState(emptyForm);
	let programs = props.programs;
    let basePath = props.basePath ? props.basePath : "/program";

    const history = useHistory();
    let GoTo = (id) => history.push(basePath + '/merchant/' + id);

    let TableRow = (props) => {
        return (
            <tr onClick={() => GoTo(props.row.merchantUuid)} className="clickable">
                <td>{props.row.merchantIdentifier}</td>
                <td>{props.row.merchantName}</td>
                <td>{props.row.fnsNumber}</td>
                <td>{props.row.email}</td>
                <td>{decodeStatus(props.row.merchantStatusId)}</td>
            </tr>
        );
    };

    let decodeStatus = (id) => {
        let item = MerchantStatusArray.find(element => element.id == id);
        return (item ? item.text : null);
    };

    let TableBody = dataRows.map((data, index) => {
        return ((index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize))) ?
            <TableRow key={data.merchantId} row={data}/> : null;
    });

    let sortByProperty = (a, b, p, d) => {
        let lessThan = -1 * d;
        let greaterThan = 1 * d;
        return a[p] < b[p] ? lessThan : (a[p] > b[p] ? greaterThan : 0);
    };

    let sortData = (p, d) => {
        let data = dataRows.filter( item => item != null); // to create a copy
        data.sort( (a,b) => sortByProperty(a, b, p, d) );
        setDataRows(data);
        setPagingData(resetPaging());
    };

	let [sortColumn, setSortColumn] = useState({column:'merchantIdentifier', firstLoad:true});
	useEffect(() => {
        if (sortColumn.firstLoad)
            return;
        let direction = sortColumn.sort === -1 ? -1 : 1;
        sortData(sortColumn.column, direction);
	}, [sortColumn]);

    let DownArrow = (props) => {
        if (props.show) {
            if (sortColumn.sort && sortColumn.sort === -1) {
                return (<><span onClick={onclick} className="text-info">&darr;</span></>)
            } else {
                return (<><span onClick={onclick} className="text-info">&uarr;</span></>)
            }
        } else {
            return (<><span onClick={onclick} style={{color:'#ddd'}}>&uarr;</span></>)
        }
    };

    let setSort = (col) => {
        let direction = 1;
        if (col === sortColumn.column) {
            let currentSort = sortColumn.sort ? sortColumn.sort : 1;
            direction = currentSort * -1;
        }
        setSortColumn({column:col, sort:direction});
    };

    let Table = () => {
        if (dataRows && dataRows.length) {
            let myStyle = {cursor:'pointer'};
            let hdTitle = 'Click to sort';
            return (
                <>
                    <table className="table table-hover">
                        <thead>
                        <tr>
							<th style={myStyle} onClick={() => setSort('merchantIdentifier')} title={hdTitle}>Merchant ID <DownArrow show={sortColumn.column==='merchantIdentifier'}/></th>
                            <th style={myStyle} onClick={() => setSort('merchantName')} title={hdTitle}>Merchant Name <DownArrow show={sortColumn.column==='merchantName'}/></th>
                            <th style={myStyle} onClick={() => setSort('fnsNumber')} title={hdTitle}>FNS ID <DownArrow show={sortColumn.column==='fnsNumber'}/></th>
                            <th style={myStyle} onClick={() => setSort('email')} title={hdTitle}>Email <DownArrow show={sortColumn.column==='email'}/></th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {TableBody}
                        </tbody>

                        <tfoot><tr><td className='text-right' colSpan={5}>
						Export:&nbsp;
						<a href={downloadUrl + '&exportType=xlsx'} className='btn btn-primary btn-sm' download="merchants.xlsx" title="Export as excel">Excel</a>
						&nbsp;
						<a href={downloadUrl} className='btn btn-primary btn-sm' download="merchants.csv" title="Export as csv">CSV</a>
    					</td></tr></tfoot>

                    </table>

                    <Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData}
                                pageSize={PageSize}/>

                </>
            );
        }
        return null;
    };

    let handleSubmitSearch = (e) => {
		setDataRows([]);
        e.preventDefault();

        setSpinner(true);
        let url = getMerchantsApiUrl() + '/merchants';

		let queryParams = [];
		for(const searchField in searchForm){
			if(searchForm[searchField]){
				queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
			}
		}
		queryParams.push('pageSize=1000'); //TODO use backend paging
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

        let fileDownload = window.location.protocol + '//' + window.location.host + getMerchantsApiUrl() + '/merchants/export?' + queryParams.join('&');
        setDownloadUrl(fileDownload);
        if ('production' != process.env.NODE_ENV) {
            console.debug('downloadUrl port will be incorrect in dev mode ' + fileDownload);
        }

        apiGet(url).then((resp) => {
			//console.debug(resp);
            setSortColumn({column:'merchantIdentifier', firstLoad:true});
            setDataRows(resp);
            setPagingData(resetPaging());
            if (resp && resp.length) {
                if (message)
                    setMessage(null);
            } else {
                setMessage({text: 'No merchant(s) found.', type: 'warning'});
            }
        }).catch((err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
        }).finally(() => setSpinner(false));
    };

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let disableSearch = () => {
		for(const searchField in searchForm){
			if(searchForm[searchField])
				return false;
		}
		return true;
	};

    let hasMerchantAdd = () => {
        if(!user.privileges.includes('MERCHANT_ADD')){
            return null;
        }

        return (
            <>
            <Link to={basePath + "/merchant/file"} className="btn btn-primary btn-sm pull-right">Import Merchants</Link>
            <Link to={basePath + "/merchant/add"} className="btn btn-primary btn-sm pull-right">New Merchant</Link>
            </>
        )
    };

    return (
        <>
            <Processing show={showSpinner}/>
            <h3>Merchants
                {hasMerchantAdd()}
            </h3>
            <MessageTag message={message}/>

            <form id="merchantIdSearchForm" onSubmit={e => handleSubmitSearch(e)}>
			<div className="row" >
				<div className="form-group col-12 col-sm-2">
                        <label htmlFor="merchantId">Merchant ID</label>
                        <input type="text" id="merchantId" className="form-control" onChange={handleFieldChange} maxLength={50} />
                               <br/>
				</div>
				<div className="form-group col-12 col-sm-2">
                        <label htmlFor="merchantName">Merchant Name</label>
                        <input type="text" id="merchantName" className="form-control" onChange={handleFieldChange} maxLength={50} />
					<br/>
				</div>
				<div className="form-group col-12 col-sm-2">
                        <label htmlFor="fnsNumber">FNS Number</label>
                        <input type="text" id="fnsNumber" className="form-control" onChange={handleFieldChange} maxLength={20} />
					<br/>
				</div>
				<div className="form-group col-12 col-sm-3">
                        <label htmlFor="email">Merchant Email</label>
                        <input type="text" id="email" className="form-control" onChange={handleFieldChange} maxLength={250} />
					<br/>
				</div>

				<div className="form-group col-12 col-sm-2">
					<label htmlFor="programId">Program</label>
					<select id="programId" className="form-control custom-select" value={searchForm.programId} onChange={handleFieldChange} >
						{ selectOptions(programs) }
					</select>
				</div>

			</div>
			<button className="btn btn-primary" disabled={disableSearch()} title={Strings.warning.searchCritera} id="btnSearch">Search
                </button>
            </form>
            <br/>
            <Table/>
        </>
    )
}
