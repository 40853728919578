import React, {useState} from 'react';

import {apiPostFile, getMerchantsApiUrl} from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import useUserContext from '../common/UserContext';

export default function MerchantFile() {
    let {user} = useUserContext();

	let blankForm = {
		merchantFile:''
	}
	let [uploadForm, setUploadForm] = useState(blankForm);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let handleFileChange = (event) => {
		let cloned = {...uploadForm};
		cloned.merchantFile = event.target.files[0];
		setUploadForm(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();

		console.debug('file size ' + uploadForm.merchantFile.size);
		const maxSize = 1024 * 1024;
		if (uploadForm.merchantFile.size > maxSize) {
			setMessage({text:'Merchant file size cannot be greater than 1MB.',type:'warning'});
			return false;
		}
		setSpinner(true);

		let url = getMerchantsApiUrl() + '/merchant/file';
		let formData = new FormData();
		formData.append('merchantFile', uploadForm.merchantFile);
        console.log(formData);

		apiPostFile(url, formData).then((resp)=> {
			console.debug(resp);
			//setUploadForm(blankForm);
			let messageObject = {text:'Merchant file processed.',type:'success'};
			let messages = [];
			if(resp && resp.processed)
				messages.push(resp.processed + ' merchant rows processed.');
			if(resp && resp.added)
				messages.push(resp.added + ' merchant(s) added.');
			if(resp && resp.updated)
				messages.push(resp.updated + ' merchant(s) updated.');

			let messageText = messages.map( (value, index) => <div key={index}>{value}</div>);
			messageObject.text = <>{messageText}</>;

			setMessage(messageObject);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				console.error(err.data.errorMessages);
				if(err.data.expandedErrors && err.data.expandedErrors.length){
					let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
					messageObject.text = <>{messages}</>;
				}
				else if(err.data.errorMessages && err.data.errorMessages.length){
					let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
					messageObject.text = <>{messages}</>;
				}
			} else if (err.status == 422){
				messageObject = {text:'Invalid file content.',type:'danger'};
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	return (
		<>
		<Processing show={showSpinner} />

		<h3>Upload Merchant File</h3>
        <MessageTag message={message} />
		
		<label style={{fontStyle: 'italic', fontSize: 13}}>Merchant Files need to be CSV or Excel files in a specific format. <br /> Example files are available for download below for assistance. </label>
		<div>	
			<a href="/ExampleMerchantFiles/MerchantFileExample.csv" download="MerchantFileExample.csv">Download Example CSV File</a>
			<br />
			<a href="/ExampleMerchantFiles/MerchantFileExample.xlsx" download="MerchantFileExample.xlsx">Download Example Excel File</a>
		</div>

		<br />

        <form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group col-12 col-md-6">
					<label htmlFor="merchantFile" className="btn btn-info">Select Merchant File <input type="file" id="merchantFile" name="merchantFile" className="form-control-file d-none" onChange={handleFileChange} required accept=".csv,.xls,.xlsx"/></label>
					<br/><h4>&nbsp;{uploadForm.merchantFile.name}</h4>
				</div>
			</div>
			<button className="btn btn-primary">Upload File</button>
		</form>
		</>
	)
}
