import React, { useState, useEffect } from 'react';

import AccountPassword from '../common/AccountPassword';
import AccountSecurityQuestion from '../common/AccountSecurityQuestion';
import CurrentUserUpdate from '../common/CurrentUserUpdate';

export default function Account() {
    let [resetPassword, setResetPassword] = useState(0); //TODO revisit this

    return (
    <>
    <CurrentUserUpdate/>
    <hr/>
    <AccountPassword key={resetPassword}/>
    <hr/>
    <AccountSecurityQuestion />

    </>
    )
}
