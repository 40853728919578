import React from 'react';
import './Pagination.css';

export function resetPaging(){
	return {currentPage:1, beginIndex:0};	
}

export default function Pagination(props) {
	let pageSize = props.pageSize || 5;
	let recordCount = props.recordCount || 16; // for testing
	let pageCount = props.pageCount || 0;
	let setPagingData = props.setPagingData || function(){console.debug('setPagingData not defined')};
	let pagingData = props.pagingData || {currentPage:1, beginIndex:0};

	let nextPage = ()=> {
		let newPagingData = {currentPage:1, beginIndex:0};
		newPagingData.currentPage = pagingData.currentPage + 1;
		newPagingData.beginIndex = (newPagingData.currentPage - 1) * pageSize;
		setPagingData(newPagingData);
	};
	if(props.nextPage)
		nextPage = props.nextPage;

	let prevPage = ()=> {
		let newPagingData = {currentPage:1, beginIndex:0};
		newPagingData.currentPage = pagingData.currentPage - 1;
		newPagingData.beginIndex = (newPagingData.currentPage - 1) * pageSize;
		setPagingData(newPagingData);
	};
	if(props.prevPage)
		prevPage = props.prevPage;

	let gotoPage = (pageNo)=> {
		let newPagingData = {currentPage:pageNo, beginIndex:0};
		newPagingData.beginIndex = (newPagingData.currentPage - 1) * pageSize;
		setPagingData(newPagingData);
	};
	if(props.gotoPage)
		gotoPage = props.gotoPage;

	let PageItem = (props) => {
		let className = props.current ? 'page-item active' : 'page-item';
		return (
		<li className={className}>
			<div className="page-link" onClick={() => gotoPage(props.no)}>{props.no}</div>
		</li>
		)
	};

	if(pageCount == 0 && recordCount > pageSize)
		pageCount = Math.ceil( recordCount / pageSize );

	if(pageCount < 2)
		return null;
	
	let pageItems = [];
	for(var i=1; i <= pageCount; i++){
		pageItems.push(<PageItem key={i} no={i} current={(i==pagingData.currentPage)} />);
	}

	let prevClass = pagingData.currentPage > 1 ? 'page-item' : 'page-item disabled';
	let nextClass = pagingData.currentPage < pageCount ? 'page-item' : 'page-item disabled';

    return (
		<ul className="pagination">
			<li className={prevClass}>
			<div className="page-link" onClick={prevPage}>&lt;</div>
			</li>
			{ pageItems }
			<li className={nextClass}>
			<div className="page-link" onClick={nextPage}>&gt;</div>
			</li>
		</ul>
	);
}
