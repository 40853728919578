import React, { useState, useEffect  } from 'react';
import { useHistory, Link, useParams } from "react-router-dom";

import { apiGet, getAdminApiUrl, apiPut, apiMessages } from '../common/AppApi';
import Strings from '../common/Strings';
import { stripTime } from '../common/Formats';

import { selectOptions } from '../common/Forms';
import { StateArray, PageSize, ProgramStatusArray, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { HelpTooltip } from '../common/AppIcons';

export default function Role() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [role, setRole] = useState({roleId:'',descr:''});
	let [formChanged, setFormChanged] = useState(false);

	let [privileges, setPrivileges] = useState([]);

	let { roleId } = useParams();

	let loadRole = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/roles/' + roleId;

		apiGet(url).then((resp)=> {
			setRole(resp);
			loadPrivileges(resp.privileges);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let loadPrivileges = (rolePrivileges) => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/privileges';

		apiGet(url).then((resp) => {
			let newPrivileges = resp.map( (priv, index) => {
				priv.checked = false;
				for (var i=0; i<rolePrivileges.length; i++) {
					if (rolePrivileges[i].privilegeId == priv.privilegeId) {
						priv.checked = true;
						break;
					}
				}
				return priv;
			});
			setPrivileges(newPrivileges);
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		loadRole();
	}, []);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...role, [id]:value};
		setFormChanged(true);
		setRole(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getAdminApiUrl() + '/roles';

		let body = {roleId:role.roleId,descr:role.descr,privilegeIds:[]};
		for (var i=0; i<privileges.length; i++) {
			if (privileges[i].checked)
				body.privilegeIds.push(privileges[i].privilegeId);
		}
		//console.debug(body);

		apiPut(url, body).then((resp) => {
			setMessage({text: 'Role saved.', type:'success'});
			setRole(resp);
			setFormChanged(false);
			setConfirmed(false);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let checkStyle = {padding:".1em 1.5em", cursor:'pointer'};

	let handleCheckbox = (event) => {
		const { value, checked } = event.target;
		let clonedPrivileges = [];
		privileges.forEach( (item) => {
			let cloned = {...item};
			if (cloned.privilegeId == value)
				cloned.checked = checked;
			clonedPrivileges.push(cloned);
		});
		setPrivileges(clonedPrivileges);
		setFormChanged(true);
	};

	let [confirmed, setConfirmed] = useState(false);
	let handleConfirmCheckbox = (event) => {
		const { value, checked } = event.target;
		setConfirmed(checked);
	};

	let PrivilegesDisplay = () => {
		if (role.privileges && privileges && privileges.length) {
			let PrivilegeRow = (props) => {
				return <tr>
					<td>{props.priv.privilegeId}</td>
					<td>{props.priv.code}</td>
					<td>{props.priv.descr}</td>
					<td>
						<label style={checkStyle}><input type="checkbox" value={props.priv.privilegeId} checked={props.priv.checked} onChange={handleCheckbox}/></label>
					</td>
				</tr>;
			};
			
			let PrivilegeBody = privileges.map( (priv, index) => {
				return <PrivilegeRow key={priv.privilegeId} priv={priv}/>;
			});

			return (
				<>
				<br/>
				<table className='table table-bordered'>
				<thead>
					<tr>
						<th>Privilege Id</th>
						<th>Code</th>
						<th>Descr</th>
						<th>Assigned</th>
					</tr>
				</thead>
				<tbody>
					{PrivilegeBody}
				</tbody>
				</table>
				</>
			);
		} else {
			return null;
		}
	};

	let noSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Role</h3>
		<MessageTag message={message} />

        <form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group col-12 col-md-2">
					<label className="asterisk-label" htmlFor="roleId">Role ID</label>
					<input type="text" id="roleId" className="form-control" onChange={handleFieldChange} value={role.roleId} readOnly={true} maxLength="10" />
				</div>
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="descr">Descr</label>
					<input type="text" id="descr" className="form-control" onChange={handleFieldChange} value={role.descr} required minLength="2" maxLength="20" />
				</div>
				<div className="form-group col-12 col-md-1">
					<label className="asterisk-label">&nbsp;</label>
					<button className="btn btn-primary" disabled={!formChanged}>Update</button>
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-md-5">
				<label style={checkStyle}>
					<input type="checkbox" value={1} checked={confirmed} onChange={handleConfirmCheckbox} required/>
					<span style={{marginLeft:10}}>I understand the consequences of this change.</span>
				</label>
				</div>

			</div>
		</form>

        <form onSubmit={noSubmit}>
		<PrivilegesDisplay />
		</form>


		</>
	)
}
