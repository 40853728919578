import React, { useState, useEffect } from 'react';

import { getAccountsApiUrl, apiGet } from '../common/AppApi';

export default function IncentivePrograms(props) {

	let [programs, setPrograms] = useState([]);
	let programId = props.programId;

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/incentive/programs';
		apiGet(url).then((resp) => {
			let progs = [{programId:'',programName:'Program...',programUuid:'',shortName:''}];
			if (resp)
				progs = progs.concat(resp);
			setPrograms(progs);
		}).catch( (err)=> {
			console.error(err);
		});
	};

	useEffect( () => {
		getPrograms();
	}, []);

	let handleSelectChange = props.handleSelectChange;

	let ProgramOption = (props) => {
		return <option value={props.program.programId} >{props.program.programName}</option>
	};
	let ProgramOptions = programs.map( (data, index) => {
		return <ProgramOption key={data.programId} program={data}/>
	});

	let DisplayPrograms = () => {
		if (programs && programs.length) {
			return <>
			<select id="programId" name="programId" value={programId} className="form-control custom-select" required onChange={handleSelectChange}>
				{ProgramOptions}
			</select>
			</>;
		} else {
			return null;
		}
	};

	return (
		<DisplayPrograms />
	)
}
