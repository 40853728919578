import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import { apiGet, apiPut, apiPatch, getBenefitsApiUrl, getAccountsApiUrl, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { formatPhone } from '../common/Formats';
import { selectOptions, patternMessage } from '../common/Forms';
import { SelectOneArray } from '../common/Constants';

import DatePicker from 'react-date-picker';

import 'react-day-picker/lib/style.css';
import moment from 'moment';

import ConfirmDialog, { closeConfirmDialog, showConfirmDialog } from '../common/ConfirmDialog';

import AddBenefitForm from './AddBenefitForm';

export default function Benefit(props) {

	let blankUpdateForm = {
		benefitAuthorizationNumber:'',
		benefitAvailableDate:'',
		benefitExpirationDate:'',
		benefitUuid:'',
		benefitAmount:0,
		benefitStatus:'',
		programUuid:'',
		programName:'',
		programId:null,
		accountId:'',
		fundingId:'',
		notifiedRecipient:false,
		benefitTypeId:1,
		benefitTypeDescr:'',
		incentiveUuid:'',
		updationChannel:'API',
		lastUpdatedDate:'',
		agencyName:'',
		maxBenefitAmount:9999.99,
		note:'',
		enableBenefitNote:false,
		benefitOptionId:'',
		benefitOptionTypeId:''
	};

	let maxDate = new Date();
	maxDate.setDate(new Date().getDate() + 183);
	
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let { id } = useParams();

	let [benefitToUpdate, setBenefitToUpdate] = useState(blankUpdateForm);
	let [benefitID, setBenefitID] = useState();
	let [recipient, setRecipient] = useState({
		firstname:'',
		middlename:'',
		lastname:'',
		recipientIdentifier:'',
		email:'',
		mobilePhone:'',
		accountUuid:''
	});

	let [program, setProgram] = useState({programUuid:'',programName:''});
	let [benefitOptionType, setBenefitOptionType] = useState({name:'',options:[]});

	let handleUpdateFieldChange = (event) => {
		const {id, value} = event.target;
		setBenefitToUpdate({...benefitToUpdate, [id]:value});
	}
	let handleCheckboxChange = (event) => {
		const {id, value, checked} = event.target;
		setBenefitToUpdate({...benefitToUpdate, [id]:checked});
	};

	let handleUpdateDayChange = (inputName) => {
		return function onChange(newDate){
			setBenefitToUpdate({...benefitToUpdate, [inputName]:newDate});
		}
	}

	let getBenefit = (id) => {
		setSpinner(true);

		let url = getBenefitsApiUrl() + '/benefit/' + id;

		apiGet(url).then((resp) => {
			console.debug(resp);

			setBenefitID(resp.benefitUuid);

			let cloned = {...blankUpdateForm};
			cloned.programUuid = resp.programUuid;

			let tzo = new Date().getTimezoneOffset() / 60;
			if (resp.benefitAvailableDate) {
				cloned.benefitAvailableDate = moment(resp.benefitAvailableDate).add(tzo, 'h').toDate();
			}
			if (resp.benefitExpirationDate) {
				cloned.benefitExpirationDate = moment(resp.benefitExpirationDate).add(tzo, 'h').toDate();
			}

			cloned.benefitAmount = Number(resp.benefitBalance).toFixed(2);
			cloned.benefitStatus = resp.benefitStatus;
			cloned.benefitAuthorizationNumber = resp.benefitAuthorizationNumber;
			cloned.accountId = resp.recipientAccountUuid;
			cloned.updationChannel = resp.updationChannel;
			cloned.benefitUuid = resp.benefitUuid;
			cloned.recipientIdentifier = resp.recipientIdentifier;
			cloned.programName = resp.programName;
			cloned.programId = resp.programId;
			cloned.notifiedRecipient = resp.notifiedRecipient;
			cloned.fundingId = resp.fundingId;
			cloned.benefitTypeId = resp.benefitTypeId;
			cloned.benefitTypeDescr = cloned.benefitTypeId == 2 ? 'Incentive' : 'Regular';
			cloned.incentiveUuid = resp.incentiveUuid ? resp.incentiveUuid : '';
			if (resp.lastUpdatedDate)
				cloned.lastUpdatedDate = resp.lastUpdatedDate;
			cloned.agencyName = resp.agencyName ? resp.agencyName : '';
			if (resp.maxBenefitAmount && isNaN(resp.maxBenefitAmount) == false)
				cloned.maxBenefitAmount = resp.maxBenefitAmount.toFixed(2);
	
			setBenefitToUpdate(cloned);
			setProgram({programUuid:cloned.programUuid,programName:cloned.programName});

			getFundingSources(cloned.programUuid);
			getIncentives(cloned.programUuid);

			if (resp.benefitOptionId)
				cloned.benefitOptionId = resp.benefitOptionId;
			cloned.note = resp.note;
			cloned.enableBenefitNote = resp.enableBenefitNote;
			cloned.benefitOptionTypeId = resp.benefitOptionTypeId;

			if (resp.benefitOptionTypeId) {
				getBenefitOptionType(resp.benefitOptionTypeId);
			} else {
				setBenefitOptionType({name:'',options:[]});
			}

			getRecipient(resp.recipientAccountUuid);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	let getBenefitOptionType = (id) => {
		let url = getBenefitsApiUrl() + '/benefit-option-type/' + id;
		//console.debug(url);
		setSpinner(true);
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let b = {name:resp.benefitOptionType.name,options:[{optionId:'',descr:''}]};
			resp.options.forEach(item => {
				b.options.push(item);
			});
			setBenefitOptionType(b);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getRecipient = (id) => {
		setSpinner(true);

		let url = getAccountsApiUrl() + '/account/' + id;
		apiGet(url).then((resp)=> {
			let recip = {
				firstname:resp.firstname,
				middlename:resp.middlename,
				lastname:resp.lastname,
				recipientIdentifier:resp.recipientIdentifier,
				email:resp.email,
				mobilePhone: formatPhone(resp.mobilePhone),
				accountUuid: resp.accountUuid
			};
			setRecipient(recip);
		}).catch( (err)=> {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	useEffect( ()=> {
		if(!benefitID)
			getBenefit(id);
	}, [benefitID]);

	useEffect( () => {
		patternMessage("#benefitAuthorizationNumber", Strings.validation.authNoFormat);
	}, []);

	let handleUpdate = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getBenefitsApiUrl() + '/benefit';

		let body = {
			benefitUuid:benefitToUpdate.benefitUuid,
			benefitBalance:Number(benefitToUpdate.benefitAmount),
			benefitAvailableDate: benefitToUpdate.benefitAvailableDate,
			benefitExpirationDate: benefitToUpdate.benefitExpirationDate,
			benefitAuthorizationNumber:benefitToUpdate.benefitAuthorizationNumber,
			notifiedRecipient:benefitToUpdate.notifiedRecipient,
			fundingId:benefitToUpdate.fundingId,
			benefitTypeId: benefitToUpdate.benefitTypeId,
			incentiveUuid: benefitToUpdate.incentiveUuid,
			benefitOptionId: benefitToUpdate.benefitOptionId,
			note: benefitToUpdate.note,
			updationChannel: 'API'
		};
		if (benefitToUpdate.lastUpdatedDate)
			body.lastUpdatedDate = benefitToUpdate.lastUpdatedDate;

		if (body == null) {
			setSpinner(false);
			return;
		}
		apiPut(url, body).then((resp) => {
			setMessage('Benefit updated.');
			getBenefit(benefitID);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
		
	};

	let doVoid = () => {
		handleVoid();
		closeConfirmDialog();
	};

	let handleVoid = () => {
		setSpinner(true);

		let url = getBenefitsApiUrl() + '/benefit/' + benefitToUpdate.benefitUuid;

		let body = {
			benefitStatus: 'VOIDED',
			updationChannel: 'API'
		};

		apiPatch(url, body).then((resp)=> {
			setMessage('Benefit voided.');
			let cloned = {...benefitToUpdate};

			cloned.benefitAuthorizationNumber = resp.benefitAuthorizationNumber;
			cloned.benefitAvailableDate = moment(resp.benefitAvailableDate).toDate();
			cloned.benefitExpirationDate = moment(resp.benefitExpirationDate).toDate();
			cloned.benefitAmount = Number(resp.benefitBalance).toFixed(2);
			cloned.benefitStatus = 'VOID';
			cloned.fundingId = resp.fundingId;
			cloned.notifiedRecipient = resp.notifiedRecipient;
			cloned.benefitTypeId = resp.benefitTypeId;
			cloned.incentiveUuid = resp.incentiveUuid;
			if (resp.lastUpdatedDate)
				cloned.lastUpdatedDate = resp.lastUpdatedDate;

			setBenefitToUpdate(cloned);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let showVoidButton = () => {
		if (benefitToUpdate.benefitStatus == 'ISSUED') {
			return (
				<>
				<button className="btn btn-warning" type="button" onClick={() => showConfirmDialog()}>Void Benefit</button>
				</>
			)
		} else {
			return null;
		}
	};

	let disableNotifiedCheckbox = () => {
		return benefitToUpdate.benefitStatus == 'ISSUED' ? false : true;
	};
	let disabledCheckboxCursor = () => {
		return disableNotifiedCheckbox() ? 'form-check-label disabled' : 'form-check-label';
	}

	let basePath = () => props.basePath ? props.basePath : '/program';
	let ViewBenefitsLink = () => {
		let viewBenefits = () => basePath() + '/benefits/' + encodeURIComponent(benefitToUpdate.recipientIdentifier);
		return <Link to={viewBenefits()}>Benefits</Link>
	};

	let ViewRecipientLink = () => {
		let viewRecipient = () => basePath() + '/recipient/' + encodeURIComponent(recipient.accountUuid) + '/' + benefitToUpdate.programId;
		return <Link to={viewRecipient()}>Recipient</Link>
	};

	let ViewTransactions = () => {
		let transactionsPath = () => basePath() + '/transactions';
		return <Link to={transactionsPath()}>Transactions</Link>
	};

	let [fundingSources, setFundingSources] = useState([]);
	let getFundingSources = (uuid) => {
		setSpinner(true);
		let url = getBenefitsApiUrl() + '/funding-sources/' + uuid;
		apiGet(url).then((resp) => {
			if (resp && resp.length) {
				let newResp = resp.map( (item) => {
					item.id = item.fundingId;
					item.text = item.name;
					return item;
				});
				setFundingSources(SelectOneArray.concat(newResp));
			}
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if (err.status == 404) {
				setFundingSources([]);
				messageObject.text = 'No funding sources defined for the program.';
			} else if(err.privilegeError) {
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [incentives, setIncentives] = useState([]);
	let getIncentives = (programUuid) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive?programUuid=' + programUuid;
		apiGet(url).then((resp) => {
			console.debug(resp);
			if(resp && resp.length) {
				let newResp = resp.map( (item) => {
					item.id = item.incentiveUuid;
					item.text = item.name;
					return item;
				});
				setIncentives(SelectOneArray.concat(newResp));
			}
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	return (
		<>
		<Processing show={showSpinner}/>

		<h3>Benefit</h3>

		<table className="table table-bordered">
			<tbody>
				<tr>
					<th>Account ID</th>
					<th>Recipient Name</th>
					<th>Email</th>
					<th>Phone</th>
				</tr>
				<tr>
					<td>{recipient.recipientIdentifier}</td>
					<td>{recipient.firstname} {recipient.middlename} {recipient.lastname}</td>
					<td>{recipient.email}</td>
					<td>{recipient.mobilePhone}</td>
				</tr>
			</tbody>
		</table>

		<ViewRecipientLink/> - <ViewBenefitsLink/> - <ViewTransactions />

		<MessageTag message={message}/>
		<h4>Benefit Info</h4>
		<form onSubmit={e => handleUpdate(e)} id="test">
			<div className="row">
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="programName">Program</label>
					<input type="text" id="programName" name="programName" className="form-control" value={benefitToUpdate.programName} readOnly={true}/>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="benefitStatus">Status</label>
					<input type="text" id="benefitStatus" name="benefitStatus" className="form-control" value={benefitToUpdate.benefitStatus} readOnly={true}/>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="fundingId">Funding</label>
					<select id="fundingId" className="form-control custom-select" value={benefitToUpdate.fundingId} onChange={handleUpdateFieldChange} required>
						{ selectOptions(fundingSources) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="agencyName">Agency</label>
					<input type="text" id="agencyName" name="agencyName" className="form-control" value={benefitToUpdate.agencyName} readOnly={true}/>
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="benefitAuthorizationNumber">Authorization Number</label>
					<input type="text" id="benefitAuthorizationNumber" name="benefitAuthorizationNumber" className="form-control" onChange={handleUpdateFieldChange} value={benefitToUpdate.benefitAuthorizationNumber} required maxLength={20} readOnly={benefitToUpdate.benefitTypeId == 2} pattern="\S*"/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="benefitAmount">Balance Amount</label>
					<input type="number" id="benefitAmount" name="benefitAmount" className="form-control text-right" onChange={handleUpdateFieldChange} value={benefitToUpdate.benefitAmount} min="0.00" step="0.01" required maxLength={12} max={benefitToUpdate.maxBenefitAmount}/>
				</div>

				<div className="form-group col-12 col-sm-6">
					<label htmlFor="benefitTypeDescr">Benefit Type</label>
					<input type="text" id="benefitTypeDescr" name="benefitTypeDescr" className="form-control" defaultValue={benefitToUpdate.benefitTypeDescr} readOnly={true}/>
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="incentiveUuid">Incentive</label>
					<select id="incentiveUuid" className="form-control custom-select" value={benefitToUpdate.incentiveUuid} onChange={handleUpdateFieldChange} required={benefitToUpdate.benefitTypeId == 2}>
						{ selectOptions(incentives) }
					</select>
				</div>

				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="benefitAvailableDate">Available Date</label>
					<DatePicker onChange={handleUpdateDayChange("benefitAvailableDate")} value={benefitToUpdate.benefitAvailableDate} className="form-control" minDate={new Date(2022,1,1)} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="benefitExpirationDate">Expiration Date</label>
					<DatePicker onChange={handleUpdateDayChange("benefitExpirationDate")} value={benefitToUpdate.benefitExpirationDate} className="form-control" minDate={new Date(2022,1,1)} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="notifiedRecipient">Recipient Notified</label>
					<div className="form-check">
						<label className={disabledCheckboxCursor()} htmlFor="notifiedRecipient">
						<input className="form-check-input" type="checkbox" value="" id="notifiedRecipient" checked={benefitToUpdate.notifiedRecipient} onChange={handleCheckboxChange} disabled={disableNotifiedCheckbox()}/>
						 Notified</label>
					</div>
					<small className="form-text text-muted">Note: Unchecking will trigger resending a notification.</small>
				</div>
			</div>

			<div className="row">
			{ benefitToUpdate.enableBenefitNote ? 
				<div className="form-group col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="note">Note</label>
					<input type="text" id="note" className="form-control" onChange={handleUpdateFieldChange} value={benefitToUpdate.note} maxLength={100} />
				</div>
				 : null}

			{ benefitToUpdate.benefitOptionTypeId ? 
				<div className="form-group required col-12 col-sm-4">
				<label className="asterisk-label" htmlFor="benefitOptionId">{benefitOptionType.name}</label>
				<select id="benefitOptionId" className="form-control custom-select" value={benefitToUpdate.benefitOptionId} onChange={handleUpdateFieldChange} required>
					{ selectOptions(benefitOptionType.options,'optionId','descr') }
				</select>
				</div>
				 : null}
			</div>

			<button className="btn btn-primary">Update</button> {showVoidButton()}
		</form>

		<ConfirmDialog settings={{confirm:doVoid,body:'Are you sure you want to void this benefit?',button:'Void Benefit'}} />

		<hr/>
		<h4>Issue Benefit</h4>
		<AddBenefitForm admin={props.admin} recipientIdentifier={recipient.recipientIdentifier} programUuid={benefitToUpdate.programUuid}/>

		</>
	)
}
