import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getAdminApiUrl } from '../common/AppApi';
import Strings from '../common/Strings';
import { stripTime } from '../common/Formats';

import { selectOptions } from '../common/Forms';
import { StateArray, PageSize, ProgramStatusArray, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { HelpTooltip } from '../common/AppIcons';

export default function Roles() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [roles, setRoles] = useState([]);
	let [selectedRole, setSelectedRole] = useState(-1);

	const history = useHistory();
	let GoTo = (id) => history.push('/admin/roles/' + id);

	let loadRoles = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/roles';

		apiGet(url).then((resp)=> {
			setRoles(resp);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		loadRoles();
	}, []);

	let TableRow = (props) => {
		return <tr>
			<td>{props.role.roleId}</td>
			<td>{props.role.descr}</td>
			<td>
				<button type="button" className='btn btn-primary btn-sm' onClick={() => GoTo(props.role.roleId)}>Edit</button>
				&nbsp;
				<button type="button" className='btn btn-primary btn-sm' onClick={() => setSelectedRole(props.role.roleId)}>Show Privileges</button>
			</td>
			</tr>;
	};

	let TableBody = roles.map( (role, index) => {
		return <TableRow key={role.roleId} role={role}/>;
	});

	let TableDisplay = () => {
		if (roles && roles.length) {
			return (
			<table className='table table-bordered' id="tblRole">
			<thead>
				<tr>
					<th>Role Id</th>
					<th>Descr</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{TableBody}
			</tbody>
			</table>
			);
		} else {
			return null;
		}
	};

	let PrivilegesDisplay = () => {
		if (roles && roles.length && (selectedRole >= 0)) {
			let PrivilegeRow = (props) => {
				return <tr>
					<td>{props.priv.privilegeId}</td>
					<td>{props.priv.code}</td>
					<td>{props.priv.descr}</td>
				</tr>;
			};
			
			let PrivilegeBody = <tr><td colSpan={3}>No privileges assigned.</td></tr>;
			if (roles[selectedRole].privileges && roles[selectedRole].privileges.length) {
				PrivilegeBody = roles[selectedRole].privileges.map( (priv, index) => {
					return <PrivilegeRow key={priv.privilegeId} priv={priv}/>;
				});
			}

			return (
				<>
				<br/>
				<h5>{roles[selectedRole].descr} Privileges</h5>
				<table className='table table-bordered' id="tblPrivilege">
				<thead>
					<tr>
						<th>Privilege Id</th>
						<th>Code</th>
						<th>Descr</th>
					</tr>
				</thead>
				<tbody>
					{PrivilegeBody}
				</tbody>
				</table>
				</>
			);
		} else {
			return null;
		}
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<Link to="/admin/role/add" className="btn btn-primary btn-sm pull-right">Add Role</Link>
		<h3>Roles</h3>
		<MessageTag message={message} />

		<TableDisplay />

		<PrivilegesDisplay />

		</>
	)
}
