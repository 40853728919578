import React, { useState, useEffect } from 'react';

import AdminRecipientIncentives from '../program/RecipientIncentives';

export default function RecipientIncentives() {

	return (
		<AdminRecipientIncentives admin={true} />
	)
}
