import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';

import { apiGet,  getUserApiUrl, apiPost } from './common/AppApi';

import { patternMessage, invalidInputStyle, notEqualMessage, selectOptions } from './common/Forms';

import Strings from './common/Strings';
import PasswordChecklist from './common/PasswordChecklist';
import { Link } from 'react-router-dom';

import Processing from './common/Processing';
import MessageTag from './common/MessageTag';
import './Validate.css';

export default function Validate(props) {
	let blankForm = {
        userUuid:'',
		username:'',
		password:'',
		confirmPassword:'',
        otpPreference:'email',
        questionId:'',
        answer:''
    };
    
	let [activationForm, setActivationForm] = useState(blankForm);
    //let { hash } = useParams();

	let [showSpinner, setSpinner] = useState(true);
	let [message, setMessage] = useState();
	let [questions, setQuestions] = useState([{id:null,text:''}]);
	let [allGood, setAllGood] = useState(false);
    
    const history = useHistory();

	let getSecurityQuestions = () => {
		setSpinner(true);
		let url = getUserApiUrl() + '/security-questions';
		apiGet(url).then((resp) => {
			//console.debug(resp);
			resp.unshift({id:null,text:''});
			setQuestions(resp);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
		});
    };
    
    let getInfo = () => {
        setSpinner(true);

        let hhash = props.location.search.split('=')[1];
        console.debug(hhash);

		let url = getUserApiUrl() + '/validate/' + hhash;
		apiGet(url).then((resp) => {
			//console.debug(resp);
            let cloned = {...activationForm, username:resp.username, userUuid:resp.userUuid};
            setActivationForm(cloned);

		}).catch( (err) => {
            console.error(err);
            if(err.status == 404) {
                setMessage({text: 'The activation link is no longer valid.', type:'danger'});
            } else {
                setMessage({text: Strings.error.general, type:'danger'});
            }
		}).finally(() => {
			//setSpinner(false);
		});
    };
    
	useEffect( () => {
        getInfo();
		getSecurityQuestions();
		patternMessage("#password", Strings.validation.password);
        notEqualMessage("#confirmPassword", Strings.validation.confirmPassword, "#password");
    },[]);
	useEffect( () => {
        invalidInputStyle();
    });

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...activationForm, [id]:value};
		setActivationForm(cloned);
	}
	let radioChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...activationForm, otpPreference:value};
		setActivationForm(cloned);
	}
    
    let handleSubmit = function(e){
        e.preventDefault();

        let hhash = props.location.search.split('=')[1];
        let body = {
            password:activationForm.password,
            confirmPassword:activationForm.confirmPassword,
            questionId:activationForm.questionId,
            answer:activationForm.answer,
            otpPreference:activationForm.otpPreference,
            hash:hhash
        };

        setSpinner(true);

		let url = getUserApiUrl() + '/activate-user';
		apiPost(url, body).then((resp) => {
			setMessage({text:Strings.success.activation, type:'success'});
            setAllGood(true);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status){
                if(err.status == 404) {
                    messageObject.text = 'The activation link is invalid.';
                }
                else if(err.status == 400) {
					console.error(err.data.errorMessages);
					if(err.data.expandedErrors && err.data.expandedErrors.length) {
						let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
					}
					else if(err.data.errorMessages && err.data.errorMessages.length) {
						let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
					}
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

    let loginLink = "/login?username=" + activationForm.username;

    return (
        <div className="validate">
            <Processing show={showSpinner}/>
            <h2 className="title">Complete the following to create your account.</h2>
            <MessageTag message={message} warning={true} />
            { allGood ? <>
                <p><br/><Link to={loginLink} className="btn btn-success" id="loginLink">Login To Web Portal</Link><br/><br/></p>
            </> : <>
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="form-group col-12 col-md-7">
                                <label htmlFor="username">Username</label>
                                <input type="text" id="username" className="form-control" value={activationForm.username} readOnly />
                            </div>
                            <div className="form-group required col-12 col-md-7">
                                <label className="asterisk-label" htmlFor="passwd">Password</label>
                                <input type="password" id="password" className="form-control" autoComplete="off" value={activationForm.password} onChange={handleFieldChange} required maxLength="30" pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!\x22#\\\$%&'()*+,-./:;<>=?@\[\]\^_`|{}~]).{8,30}" />
                                <div><PasswordChecklist password={activationForm.password}/></div>
                            </div>
                            <div className="form-group required col-12 col-md-7">
                                <label className="asterisk-label" htmlFor="passwd">Confirm Password</label>
                                <input type="password" id="confirmPassword" className="form-control" autoComplete="off" value={activationForm.confirmPassword} onChange={handleFieldChange} required maxLength="40" />
                            </div>

                            <div className="form-group required col-12 col-md-7">
                                <label className="asterisk-label" htmlFor="questionId">Security Question</label>
                                <select id="questionId" className="form-control custom-select" value={activationForm.questionId} onChange={handleFieldChange} required >
                                { selectOptions(questions) }
                                </select>
                            </div>
                            <div className="form-group required col-12 col-md-7">
                                <label className="asterisk-label" htmlFor="answer">Security Answer</label>
                                <input type="text" id="answer" className="form-control" autoComplete="off" value={activationForm.answer} onChange={handleFieldChange} required minLength="2" maxLength="100" />
                            </div>
                            <div className="form-group required col-12 col-md-7">
                            <label className="asterisk-label" htmlFor="otpPreference">Verification Code Preference</label>
                            <div>
                            <label className='form-check-label'>
                                Email <input className="radioBtn" type="radio" id="otpPreference" name="otpPreference" value="email" onChange={radioChange} checked={activationForm.otpPreference=='email'} required />
                            </label>
                            <label className='form-check-label'>
                                SMS <input className="radioBtn" type="radio" name="otpPreference" value="sms" onChange={radioChange} checked={activationForm.otpPreference=='sms'} />
                            </label>
                            </div>
                            </div>
                            <div className="form-group col-12 col-md-7">
                                <button className="btn btn-primary" id="btnComplete" disabled={allGood}>Complete Setup</button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
            }
        </div>
    )
}
