import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet, apiPatch, apiPost, getUserApiUrl, apiMessages, apiPut, apiDelete } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { PrivilegeCodes } from '../common/Constants';

import { formatPhone, stripTime, cleanPhone } from '../common/Formats';
import { UserStatusArray, UserStatus } from '../common/Constants';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import Strings from '../common/Strings';
import ConfirmDialog, { closeConfirmDialog, showConfirmDialog } from '../common/ConfirmDialog';

import '../admin/User.css';

export default function User(props) {
	let userForm = {
		email:'',
		emailVerified:'',
		firstname:'',
		middlename:'',
		lastname:'',
		mobile:'',
		phone:'',
		username:'',
		userStatusId:'',
		creationDate:'',
		failedLogins:0,
		failedResets:0,
		userId:'',
		userUuid:'',
		otpPreference:'',
		programs:[],
		roleId:''
	};

	let [showSpinner, setSpinner] = useState(false);
	let [user, setUser] = useState(userForm);
	let setMessage = props.setMessage;

	let { id } = useParams();
	const history = useHistory();

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...user, [id]:value};
		setUser(cloned);
	}

	let getUser = (id) => {
		setSpinner(true);

		let url = getUserApiUrl() + '/recipient-users/' + id;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let user = {...resp};
			user.phone = formatPhone(user.phone);
			user.mobile = formatPhone(user.mobile);
			user.creationDate = stripTime(user.creationDate);
			if(!user.programs)
				user.programs = [];
			setUser(user);

			if (props.pushUp)
				props.pushUp(user);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
		});
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getUserApiUrl() + '/recipient-users/' + id;
		let body = {
			email:user.email,
			username:user.username,
			firstname:user.firstname,
			middlename:user.middlename,
			lastname:user.lastname,
			mobile:cleanPhone(user.mobile)
		};
		delete body.otpPreference;

		apiPatch(url, body).then((resp) => {
			//console.debug(resp);
			setMessage({text: 'User updated.', type:'success'});
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		patternMessage("#username", Strings.validation.username);
		patternMessage("#mobile", Strings.validation.phoneFormat);
		patternMessage("#email", Strings.validation.emailFormat);
		getUser(id);
	},[]);
	useEffect( () => {
		invalidInputStyle();
	});

	let sendEmailActivation = (uuid, force) => {
		setSpinner(true);
		let url = getUserApiUrl() + '/recipient-users/send-activation';
		let body = {uuid:uuid};
		if (force) {
			body.resetHash = true;
			body.resetEmailVerified = true;
		}
		apiPost(url, body).then((resp) => {
			setMessage({text: 'Activation Email request sent.', type:'success'});
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let resendActivationEmail = () => {
		if (user.userStatusId == UserStatus.Deleted) return null;
		if (user && user.emailVerified && user.emailVerified.toUpperCase() == 'Y') {
			return (<>
				<br/><br/>
				<button type="button" className="btn btn-primary" id="btnForce" onClick={ () => sendEmailActivation(user.userUuid, true)} >Force Re-Activation</button>
			</>);
		} else {
			return (<>
				<br/><br/>
				<button type="button" className="btn btn-primary" id="btnResend" onClick={ () => sendEmailActivation(user.userUuid)} >Resend Activation Email</button>
			</>);
		}
	}

	let UserPrograms = () => {
		if (user && user.programs && user.programs.length) {
			let names = [];
			for (let p of user.programs) {
				names.push(p.programName);
			}
			let progranNames = names.join(', ');
			return<>
				<label className="asterisk-label" htmlFor="programs">Programs</label>
				<input type="text" id="programs" className="form-control" defaultValue={progranNames} readOnly={true}/>
			</>;
		}
		return null;
	};

	let UserAgencies = () => {
		if (user && user.agencies && user.agencies.length) {
			let ShowAgencyRow = (props) => {
				return <Link to={ () => '/program/agency/' + props.row.agencyUuid}>{props.row.agencyName}</Link>
			};
			let ShowAgencyRows = user.agencies.map( (data, index) => {
				return <ShowAgencyRow key={index} row={data} />;
			});
			return<>
				<label className="asterisk-label" htmlFor="agencies">Agencies</label>
				<br/>
				{ShowAgencyRows}
			</>;
		}
		return null;
	};

	let showDisableButton = () => {
		if (user && user.userStatusId != UserStatus.Disabled && user.userStatusId != UserStatus.Deleted) {
			return <>
				&nbsp;<button className="btn btn-warning" type="button" onClick={disableUser} title='Disable User'>Disable</button>
			</>;
		} else {
			return null;
		}
	}
	let showDeleteButton = () => {
		if (user && user.userStatusId != UserStatus.Deleted) {
			return <>
				&nbsp;<button className="btn btn-danger" type="button" onClick={deleteUser} title='Delete User'>Delete</button>
			</>;
		} else {
			return null;
		}
	}

	let [confirmConfig, setConfirmConfig] = useState({});
	let deleteUser = () => {
		let xbody = <>Are you sure you want to delete this user?<br/> This cannot be undone.</>
		let config = {type:'danger', button:"Delete", body:xbody, confirm: statusDeleted };
		setConfirmConfig(config);
		showConfirmDialog();
	};

	let disableUser = () => {
		let config = {type:'warning', button:"Disable", body:'Are you sure you want to disable this user?', confirm: statusUser };
		setConfirmConfig(config);
	};

	useEffect( () => {
		if (confirmConfig.type) {
			showConfirmDialog();
		}
	}, [confirmConfig]);

	let statusUser = () => {
		closeConfirmDialog();
		setSpinner(true);
		let url = getUserApiUrl() + '/user/' + id + '/status';
		let body = {
			userStatusId: 3
		};

		apiPut(url, body).then((resp) => {
			setMessage({text: 'User status updated.', type:'success'});
			let cloned = {...user, userStatusId:3};
			setUser(cloned);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let statusDeleted = () => {
		closeConfirmDialog();
		setSpinner(true);
		let url = getUserApiUrl() + '/user/' + id;

		apiDelete(url).then((resp) => {
			setMessage({text: 'User has been deleted.', type:'success'});
			let cloned = {...userForm, userStatusId:4};
			setUser(cloned);
			setTimeout( () => history.push('/program/users'), 2500);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 422){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [sendCode, setSendCode] = useState(false);
	let handleCheckbox = (event) => {
		const {id, value, checked} = event.target;
		setSendCode(checked);
	};

	let sendResetOtpRequest = (uuid) => {
		setSpinner(true);
		let url = getUserApiUrl() + '/user/reset-otp/' + uuid;
		if (sendCode)
			url = url.concat('/send');
		let body = {};

		apiPost(url, body).then((resp) => {
			setMessage({text: 'OTP request sent.', type:'success'});
			setSendCode(false);
		}).catch( (err) => {
			console.error(err);
			setMessage({text: 'OTP request failed.', type:'warning'});
		}).finally(() => setSpinner(false));
	};

	let userContext = useUserContext();

	let resetOtpToken = () => {
		if (user.userStatusId == UserStatus.Deleted) return null;

		let privileges = userContext.user.privileges;
		if (user && user.otpPreference && privileges.includes(PrivilegeCodes.otpReset)) {
			return (<>
				<br/><br/><button type="button" className="btn btn-primary" onClick={ () => sendResetOtpRequest(user.userUuid)} >Reset OTP Token</button>
				&nbsp;&nbsp;<label className='clickable'
				 title="Send security code after resetting the otp token"><input type="checkbox" id="sendCode" checked={sendCode} onChange={handleCheckbox}/> Send Security Code</label>
			</>);
		}
		return null;
	}

	return (
		<>
			<Processing show={showSpinner}/>

			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="form-group col-12 col-sm-5">
						<label htmlFor="userId">User ID</label>
						<input type="text" id="userId" className="form-control" value={user.userId} disabled={true} />
					</div>
					<div className="form-group col-12 col-sm-5">
						<label htmlFor="userUuid">User Uuid</label>
						<input type="text" id="userUuid" className="form-control" value={user.userUuid} disabled={true} />
					</div>
				</div>

				<div className="row">
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" value={user.firstname} onChange={handleFieldChange} required maxLength="100"/>
				</div>
				<div className="form-group col-12 col-sm-2">
					<label htmlFor="middlename">Middle Name</label>
					<input type="text" id="middlename" className="form-control" value={user.middlename} onChange={handleFieldChange} maxLength="1"/>
				</div>
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" value={user.lastname} onChange={handleFieldChange} required maxLength="100"/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="email">Email</label>
					<input type="email" id="email" className="form-control" value={user.email} onChange={handleFieldChange} required maxLength="255" pattern={Strings.pattern.email}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="username">Username</label>
					<input type="text" id="username" className="form-control" value={user.username} onChange={handleFieldChange} required pattern="[a-zA-Z][\w]{7,19}" maxLength="20"/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="mobile">Mobile Phone</label>
					<input type="text" id="mobile" className="form-control" value={user.mobile} onChange={handleFieldChange} required pattern={Strings.pattern.phone} maxLength="20"/>
				</div>
				</div>

				<div className="row">
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="userStatusId">Status</label>
					<select id="userStatusId" className="form-control custom-select" value={user.userStatusId} onChange={handleFieldChange} required disabled={true}>
					{ selectOptions( UserStatusArray ) }
					</select>
				</div>

				<div className="form-group col-12 col-sm-6">
					<label htmlFor="creationDate">Creation Date</label>
					<input type="text" id="creationDate" className="form-control" value={user.creationDate} disabled={true} />
				</div>
				</div>

				<div className="row">
				<div className="form-group col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="roleDescr">Role</label>
					<input type="text" id="roleDescr" className="form-control" defaultValue={user.roleDescr} readOnly={true}/>
				</div>

				<div className="form-group col-12 col-sm-6">
					<UserAgencies />
					<UserPrograms />
				</div>
				</div>

				<div className="row">
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="failedLogins">Failed Logins</label>
					<input type="text" id="failedLogins" className="form-control" value={user.failedLogins} disabled={true} />
				</div>

				<div className="form-group col-12 col-sm-3">
						<label htmlFor="failedResets">Failed Resets</label>
						<input type="text" id="failedResets" className="form-control" value={user.failedResets} disabled={true} />
				</div>

				<div className="form-group col-12 col-sm-3">
					<label htmlFor="otpPreference">OTP Preference</label>
					<input type="text" id="otpPreference" className="form-control" value={user.otpPreference} disabled={true} />
				</div>
				</div>

				<button className="btn btn-primary" disabled={user.userStatusId == UserStatus.Deleted} title="Save Updates">Update</button>
				<span style={{width:'2em',display:'inline-block'}}>&nbsp;</span>
				{ showDisableButton() }
				{ showDeleteButton() }
				{ resendActivationEmail() }
				{ resetOtpToken() }
			</form>

			<ConfirmDialog settings={confirmConfig}/>
		</>
	)
}
