import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getUserApiUrl, apiPost } from './common/AppApi';

import { patternMessage, invalidInputStyle, notEqualMessage } from './common/Forms';

import Strings from './common/Strings';

import Processing from './common/Processing';
import MessageTag from './common/MessageTag';

export default function ForgotPassword(props) {
	let blankForm = {
		username:''
    };
	let blankSecurityForm = {
		questionId:'',
		questionText:'',
		answer:'',
		otpCode:''
    };
    
	let [forgotForm, setForgotForm] = useState(blankForm);
	let [securityForm, setSecurityForm] = useState(blankSecurityForm);

	let [whichForm, setWhichForm] = useState(1);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [notFoundCount, setNotFoundCount] = useState(0);
	let [tooMany, setTooMany] = useState(false);
    
    const history = useHistory();

	useEffect( () => {
		patternMessage("#username", "Special characters not allowed.");
		patternMessage("#otpCode", "Special characters not allowed.");
    },[]);
	useEffect( () => {
        invalidInputStyle();
    });
	useEffect( () => {
		if (notFoundCount > 3)
			setTooMany(true);
    },[notFoundCount]);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...forgotForm, [id]:value};
		setForgotForm(cloned);
	}
    
	let handleFieldChange2 = (event) => {
		const {id, value} = event.target;
		let cloned = {...securityForm, [id]:value};
		setSecurityForm(cloned);
	}
    
    let handleSubmit = function(e){
        e.preventDefault();

        let body = {
        };

        setSpinner(true);

		let url = getUserApiUrl() + '/security-question/' + encodeURIComponent(forgotForm.username);
		apiGet(url, body).then((resp) => {
            console.debug(resp);
			setMessage(null);

			let cloned = {...securityForm};
			cloned.questionId = resp.id;
			cloned.questionText = resp.text;
			setSecurityForm(cloned);

			setWhichForm(2);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status) {
                if(err.status == 404) {
					if (err.data.errorMessages && err.data.errorMessages.length && err.data.errorMessages[0].appErrorCode == 'USR-404') {
						messageObject.text = 'The username was not found.';
						setNotFoundCount(notFoundCount + 1);
					} else {
						messageObject.text = 'Security setup is incomplete for this user.';
					}
                }
                else if(err.status == 400) {
					console.error(err.data.errorMessages);
					if (err.data.expandedErrors && err.data.expandedErrors.length) {
						let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
					}
					else if (err.data.errorMessages && err.data.errorMessages.length) {
						let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
						if ('SEC-001' == err.data.errorMessages[0].appErrorCode)
							setTooMany(true);
					}
					else if (err.data.appErrorMessage) {
						messageObject.text = <>{err.data.appErrorMessage}</>;
					}
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

    let handleSubmit2 = function(e){
        e.preventDefault();

        let body = {
			questionId:securityForm.questionId,
			answer:securityForm.answer
        };

        setSpinner(true);

		let url = getUserApiUrl() + '/forgot-password/' + encodeURIComponent(forgotForm.username);
		apiPost(url, body).then((resp) => {
            console.debug(resp);
			setMessage(null);
			setWhichForm(3);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status) {
                if(err.status == 400) {
					if (err.data.errorMessages && err.data.errorMessages.length) {
						let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
						if ('SEC-001' == err.data.errorMessages[0].appErrorCode)
							setTooMany(true);
					} else {
						messageObject.text = 'Security Question answer is invalid.';
					}
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

    let handleCodeSubmit = function(e){
        e.preventDefault();

        setSpinner(true);

		let url = getUserApiUrl() + '/forgot-password/' + encodeURIComponent(forgotForm.username) + '/' + encodeURIComponent(securityForm.otpCode);
		apiGet(url).then((resp) => {
            console.debug(resp);
			setMessage({text:'A reset email will be sent shortly.',type:'success'});
			setWhichForm(4);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status){
                if(err.status == 404) {
                    messageObject.text = 'The username was not found.';
                }
                else if(err.status == 400){
                    messageObject.text = 'The code entered is invalid or has expired.';
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

	let showFirst = () => {
		if (whichForm==1) {
			return (
				<div className="validate">
				<form onSubmit={handleSubmit}>
					<div className="container">
						<div className="row">
							<div className="form-group col-12 col-md-7">
								<label htmlFor="username">Enter your Username</label>
								<input type="text" id="username" className="form-control" value={forgotForm.username} onChange={handleFieldChange} required maxLength="50" autoComplete="off" pattern="^[a-zA-Z0-9]+$" />
							</div>
							<div className="form-group col-12 col-md-7">
								<button className="btn btn-primary" disabled={tooMany}>Continue</button>
								&nbsp;
								<Link to="/" className="btn btn-primary">Cancel</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
			)
		}
		return null;
	};

	let showSecond = () => {
		if (whichForm==2) {
			return (
				<div className="validate">
				<form onSubmit={handleSubmit2}>
					<div className="container">
						<p><strong>{securityForm.questionText}</strong></p>
						<div className="row">
							<div className="form-group col-12 col-md-7">
								<label htmlFor="answer">Answer</label>
								<input type="text" id="answer" className="form-control" value={securityForm.answer} onChange={handleFieldChange2} required maxLength="100" autoComplete="off" />
							</div>
							<div className="form-group col-12 col-md-7">
								<button className="btn btn-primary" disabled={tooMany}>Continue</button>
								&nbsp;
								<Link to="/" className="btn btn-primary">Cancel</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
			)
		}
		return null;
	};

	let showThird = () => {
		if (whichForm == 3) {
			return (
				<div className="validate">
				<form onSubmit={e => handleCodeSubmit(e)}>
					<div className="container">
						<p>Enter the security code you will receive shortly.</p>
						<div className="row">
							<div className="form-group col-12 col-md-7">
								<label htmlFor="otpCode">Security Code</label>
								<input type="text" id="otpCode" className="form-control" value={securityForm.otpCode} onChange={handleFieldChange2} required maxLength="10" autoComplete="off" pattern="^[a-zA-Z0-9]+$" />
							</div>
							<div className="form-group col-12 col-md-7">
								<button className="btn btn-primary">Continue</button>
								&nbsp;
								<Link to="/" className="btn btn-primary">Cancel</Link>
							</div>
						</div>
					</div>
				</form>
			</div>
			)
		}
		return null;
	};

	let showFourth = () => {
		if (whichForm==4) {
			return (
				<>
				Return to <Link to="/login" >login page</Link>.
				</>
			)
		}
		return null;
	};

    return (
		<>
        <div className="validate">
            <Processing show={showSpinner}/>
			<h3>Forgot Password</h3>
            <MessageTag message={message} warning={true} />
        </div>

		{showFirst()}
		{showSecond()}
		{showThird()}
		{showFourth()}

		</>
    )
}
