import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUser } from './AppAuth';

const sessionIntervalKey = 'SESSION_INTERVAL_KEY';

export function cancelSessionInterval() {
    let intervalID = localStorage.getItem(sessionIntervalKey);
    if(intervalID)
        clearInterval(intervalID);
}

export default function SessionExpiration() {
	const history = useHistory();

	let sessionInterval = parseInt(process.env.REACT_APP_SESSION_EXPIRATION);
	let sessionExpirationTime = new Date().getTime() + sessionInterval;

	let expirationFunction = () => {
		if (sessionExpirationTime < new Date().getTime()){
			cancelSessionInterval();
			history.push("/logout");
		}
	};

	useEffect(() => {
		cancelSessionInterval();
		if(getUser())
			localStorage.setItem(sessionIntervalKey, setInterval(expirationFunction, 1000));
	}, []);

	let sessionEvents = ['mousemove','mousedown','scroll','keypress'];
	sessionEvents.forEach(e => window.addEventListener(e, () => sessionExpirationTime = new Date().getTime() + sessionInterval));

	return (null)
}
