import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getAccountsApiUrl } from '../common/AppApi';
import Strings from '../common/Strings';

import { PageSize, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import moment from 'moment';

export default function Incentives() {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();

	const history = useHistory();
	let GoTo = (id) => history.push('/admin/incentive/' + id);

	let getIncentives = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive';

		apiGet(url).then((resp)=> {
			setDataRows(resp);
			setPagingData(resetPaging());
			if(resp && resp.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No incentives found.', type:'light'});
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let TableRow = (props) => {
		let formattedDt = moment(props.row.updated).utc().format("YYYY-MM-DD HH:mm:ss");
		let programNames = [];
		if (props.row.programs && props.row.programs.length) {
			for(var i=0; i<props.row.programs.length; i++) {
				programNames.push(props.row.programs[i].programName);
			}
		}

		let ProgramName = (props) => {
			return <>{props.name}<br/></>
		};
		let ProgramNames = (props) => {
			if (props.names && props.names.length) {
				return props.names.map((data, index) => {return <ProgramName key={index} name={data} />});
			} else {
				return null;
			}
		};
	
		return (
			<tr onClick={()=>GoTo(props.row.incentiveUuid)} className="clickable">
			<td>{props.row.name}</td>
			<td>{props.row.startDate}</td>
			<td>{props.row.endDate}</td>
			<td>{props.row.program.programName}</td>
			<td>{props.row.funding.name}</td>
			<td>{formattedDt}</td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={data.incentiveUuid} row={data} /> : null;
	});
	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table table-hover" id="incentives">
					<thead>
						<tr>
							<th>Name</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Program</th>
							<th>Funding</th>
							<th>Updated</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return null;
	};

	useEffect( () => {
		getIncentives();
	}, []);

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Incentives 
			<Link to="/admin/incentive/add" className="btn btn-primary btn-sm pull-right" id="addNew">New Incentive</Link>
		</h3>
		<MessageTag message={message} />

		<br/>
		<Table />

		</>
	)
}
