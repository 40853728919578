import React from 'react';

export function patternMessage(selector, message) {
	const fieldInput = document.querySelector(selector);
	if(fieldInput){
		fieldInput.addEventListener('input', (event) => {
			if (fieldInput.validity.patternMismatch) {
				fieldInput.setCustomValidity(message);
			} else {
				fieldInput.setCustomValidity('');
			}
		});
	}
};

export function notEqualMessage(selector, message, source) {
	const fieldInput = document.querySelector(selector);
	const sourceInput = document.querySelector(source);
	if(fieldInput){
		fieldInput.addEventListener('input', (event) => {
			if (fieldInput.value != sourceInput.value) {
				fieldInput.setCustomValidity(message);
			} else {
				fieldInput.setCustomValidity('');
			}
		});
	}
};

export function invalidInputStyle(){
	const invalidClass = "is-invalid";
	const fieldInputs = document.querySelectorAll("input, select, textarea");
	fieldInputs.forEach(input => {
		if(input.validity.valid)
			input.classList.remove(invalidClass);
	});
	fieldInputs.forEach(input => {
		input.addEventListener("invalid", event => {
			input.classList.add(invalidClass);
		}, false);
	});
}

export function resetInvalidInputStyle(formSelector){
	const form = document.querySelector(formSelector);
	const fieldInputs = form.querySelectorAll("input, select, textarea");
	const invalidClass = "is-invalid";
	fieldInputs.forEach(input => {
		input.classList.remove(invalidClass);
	});
}

export function selectOptions(optionsArray, idName, valueName) {
	let idN = idName || 'id';
	let valueN = valueName || 'text';

	let options = optionsArray.map( option =>
		<option key={option[idN]} value={option[idN]}>{option[valueN]}</option>
	);
	return options;
};
