import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getAdminApiUrl, apiPost, apiMessages } from '../common/AppApi';
import Strings from '../common/Strings';
import { stripTime } from '../common/Formats';

import { selectOptions } from '../common/Forms';
import { StateArray, PageSize, ProgramStatusArray, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { HelpTooltip } from '../common/AppIcons';

export default function AddRole() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [roles, setRoles] = useState([]);
	let [selectedRole, setSelectedRole] = useState(-1);

	let [addForm, setAddForm] = useState({roleId:'',descr:''});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...addForm, [id]:value};
		setAddForm(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getAdminApiUrl() + '/roles';

		apiPost(url, addForm).then((resp)=> {
			setMessage({text: 'Role added.', type:'success'});
			setAddForm({roleId:'',descr:''});
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 409){
				messageObject.text = <>Role Id already exists.</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
		setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Add Role</h3>
		<MessageTag message={message} />

		<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group required col-12 col-md-3">
					<label className="asterisk-label" htmlFor="roleId">Role ID</label>
					<input type="text" id="roleId" className="form-control" onChange={handleFieldChange} value={addForm.roleId} required maxLength="10" />
				</div>
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="descr">Descr</label>
					<input type="text" id="descr" className="form-control" onChange={handleFieldChange} value={addForm.descr} required minLength="2" maxLength="20" />
				</div>
				<div className="form-group required col-12 col-md-3">
					<br/>
					<button className="btn btn-primary">Add</button>
				</div>
			</div>
		</form>

		</>
	)
}
