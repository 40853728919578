import { cancelSessionInterval } from './SessionExpiration';

const userKey = 'USER_KEY';
const tokenKey = 'TOKEN_KEY';
const refreshKey = 'REFRESH_KEY';

export function saveUser(value){
    localStorage.setItem(userKey, JSON.stringify(value));
}
export function getUser(){
    let value = localStorage.getItem(userKey);
    return value ? JSON.parse(value) : null;
}
export function deleteUser(){
    localStorage.removeItem(userKey);
}

export function saveToken(value){
    localStorage.setItem(tokenKey, value);
}
export function getToken(){
    return localStorage.getItem(tokenKey);
}
export function deleteToken(){
    localStorage.removeItem(tokenKey);
}

export function saveRefreshToken(value){
    localStorage.setItem(refreshKey, value);
}
export function getRefreshToken(){
    localStorage.getItem(refreshKey);
}
export function deleteRefreshToken(){
    localStorage.removeItem(refreshKey);
}

export function clearSession() {
    cancelSessionInterval();
    localStorage.clear();
}

export function getAuthHeader(){
	let token = getToken();
	return token ? 'Bearer ' + token : null;
}
