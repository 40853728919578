import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import { apiGet, getTransactionApiUrl, apiMessages } from '../common/AppApi';

import { StateArray, PanStatusArray, PanTypeArray } from '../common/Constants';
import { decodeValues, decodeValues2, formatPhone, formatCurrency } from '../common/Formats';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';

import './Transaction.css';
import moment from 'moment';

let map = null;
let currentMarker = null;

/*
*  Do not make a copy of this page.
*  This page should be reused for all transaction detail pages.
*/
export default function Transaction() {
    let { id } = useParams();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [transaction, setTransaction] = useState({});

	let getTransaction = (id) => {
		setSpinner(true);

		let url = getTransactionApiUrl() + '/transactions/' + id;

		apiGet(url).then((resp) => {
			//console.debug(resp);
			setTransaction(resp);
			if (resp.trxLocLat && resp.trxLocLon) {
				const trxLoc = {
					coords : { latitude:resp.trxLocLat, longitude:resp.trxLocLon}
				};
				locationSuccess(trxLoc);
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [transactionTypes, setTransactionTypes] = useState([]);
	let getTransactionTypes = () => {
		let url = getTransactionApiUrl() + '/transaction-types';
		apiGet(url).then((resp) => {
			let types = resp.map( (item) => {
				return {id:item.transactionTypeId,text:item.descr};
			});
			types.unshift({id:null,text:''});
			setTransactionTypes(types);
		}).catch( (err) => {
			console.error(err);
		});
	};

	let googleMapScript = null;
	const ZOOM_LEVEL = 16;

	let locationSuccess = (position) => {
		setSpinner(false);

		const latitude  = position.coords.latitude;
		const longitude = position.coords.longitude;

		if(!googleMapScript){
			googleMapScript = document.createElement('script');
			googleMapScript.src = 
				`https://maps.googleapis.com/maps/api/js?key=AIzaSyBPfWxj52gQGVnHnldP8y3H2QLaljHe-NI&v=quarterly`;
			googleMapScript.async = true;
			window.document.body.appendChild(googleMapScript);
			googleMapScript.addEventListener('load', () => {
				map = new window.google.maps.Map(document.getElementById("map"), {
					center: { lat: latitude, lng: longitude},
					zoom: ZOOM_LEVEL,
				});
				if (currentMarker) currentMarker.setMap(null);
				currentMarker = new window.google.maps.Marker({
					position: { lat: latitude, lng: longitude},
					map: map,
				});
			});
		}
	};
	
    useEffect( () => {
		getTransactionTypes();
		getTransaction(id);
    }, []);

	let BenefitInfo = (props) => {
		let benefit = props.benefit;
		let benefits = props.benefits;
		let trxBenefits = props.trxBenefits;

		if (benefits && trxBenefits) {
			let benefitRows = [];
			for(var i=0; i<benefits.length; i++) {
				benefitRows.push({
					benefitUuid:benefits[i].benefitUuid,
					benefitAuthNumber:benefits[i].benefitAuthNumber,
					amount:trxBenefits[i].amount,
					fundingName:benefits[i].fundingName,
					incentiveName:benefits[i].incentiveName
				});
			}

			let TableRow = (props) => {		
				return (
					<tr>
					<td>{props.row.benefitAuthNumber}</td>
					<td>{formatCurrency(props.row.amount)}</td>
					<td>{props.row.fundingName}</td>
					<td>{props.row.incentiveName}</td>
					</tr>
				);
			};
		
			let TableBody = benefitRows.map( (data, index) => {
				return <TableRow key={data.benefitUuid} row={data} />
			});
		
			return (<>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>Benefit Authorization Affected</th>
							<th>Amount Affected</th>
							<th>Funding</th>
							<th>Incentive</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
			</>);
		} else if (benefit) {
			return (<>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>Benefit Authorization</th>
							<th>Available Date</th>
							<th>Expiration Date</th>
							<th>Status</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
					<tr>
					<td>{benefit.benefitAuthNumber}</td>
					<td>{benefit.benefitAvailableDate}</td>
					<td>{benefit.benefitExpiryDate}</td>
					<td>{benefit.benefitStatus}</td>
					<td>{formatCurrency(benefit.benefitAmount)}</td>
					</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let BalanceInfo = (props) => {
		let transaction = props.trx;
		if (transaction.endBalance || transaction.startBalance) {
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Program</th>
							<th>Start Balance</th>
							<th>End Balance</th>
						</tr>
						<tr>
							<td>{transaction.programName}</td>
							<td>{formatCurrency(transaction.startBalance)}</td>
							<td>{formatCurrency(transaction.endBalance)}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let OriginalTrxInfo = (props) => {
		let transaction = props.trx;
		if (transaction.reversalTraceNbr) {
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Original Trance Number</th>
							<th>Original Transaction Date</th>
							<th>Reason</th>
						</tr>
						<tr>
							<td>{transaction.reversalTraceNbr}</td>
							<td>{transaction.originalTrxDatetime}</td>
							<td>{transaction.reversalReason}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let PanInfo = (props) => {
		if (props.pan) {
			let pan = props.pan;
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>PAN</th>
							<th>Type</th>
							<th>Status</th>
							<th>Active Date</th>
							<th>Expiration Date</th>
						</tr>
						<tr>
							<td>{pan.pan}</td>
							<td>{decodeValues(pan.panTypeId, PanTypeArray)}</td>
							<td>{decodeValues(pan.panStatusId, PanStatusArray)}</td>
							<td>{pan.activeDate}</td>
							<td>{pan.expirationDate}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let RecipientInfo = (props) => {
		if (props.recipient) {
			let recipientAccount = props.recipient;
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Recipient</th>
							<th>Recipient Identifier</th>
						</tr>
						<tr>
							<td>{recipientAccount.firstname} {recipientAccount.middlename} {recipientAccount.lastname}</td>
							<td>{recipientAccount.recipientIdentifier}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let MerchantInfo = (props) => {
		let trx = props.trx;
		let merchant = props.trx.merchant;
		let merchantId = '';
		if (merchant && merchant.merchantIdentifier) {
			merchantId = merchant.merchantIdentifier;
		}
		if (trx.merchantName || merchantId) {
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Merchant Name</th>
							<th>Merchant ID</th>
						</tr>
						<tr>
							<td>{trx.merchantName}</td>
							<td>{merchantId}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let LocationInfo = (props) => {
		let trx = props.trx;
		let AddressTwo = (props) => {
			if (props.address2) {
				return(<> {props.address2},</>);
			} else {
				return null;
			}
		}
		if (trx.locationAddress1) {
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Location</th>
						</tr>
						<tr>
							<td>{trx.locationAddress1},<AddressTwo address2={trx.locationAddress2}/> {trx.locationCity}, {trx.locationState} {trx.locationZipcode}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let DeliveryInfo = (props) => {
		let trx = props.trx;
		let AddressTwo = (props) => {
			if (props.address2) {
				return(<> {props.address2},</>);
			} else {
				return null;
			}
		}
		if (trx.deliveryAddress1) {
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Delivery Location</th>
						</tr>
						<tr>
							<td>{trx.deliveryAddress1},<AddressTwo address2={trx.deliveryAddress2}/> {trx.deliveryCity}, {trx.deliveryState} {trx.deliveryZipcode}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let AddlResponseText = (props) => {
		if (props.trx.addlResponseText) {
			return (<>
				<table className="table table-bordered">
					<tbody>
						<tr>
							<th>Additional Response Text</th>
						</tr>
						<tr>
							<td>{props.trx.addlResponseText}</td>
						</tr>
					</tbody>
				</table>
			</>);
		} else {
			return null;
		}
	};

	let MapInfo = (props) => {
		if (props.trx && props.trx.trxLocLat && props.trx.trxLocLon) {
			return (<>
				<div className="detail map" id="map">
				</div>
			</>);
		} else {
			return null;
		}
	};

	let TerminalInfo = (props) => {
		let transaction = props.trx;
		if (transaction && (transaction.terminalId || transaction.panEntryMode || transaction.posConditionCode || transaction.aiCode)) {
			return (<>
					<tr>
						<th>Terminal Id</th>
						<th>Pan Entry Mode</th>
						<th>Pos Condition Code</th>
						<th>AI Code</th>
					</tr>
					<tr>
						<td>{transaction.terminalId}&nbsp;</td>
						<td>{transaction.panEntryMode}</td>
						<td>{transaction.posConditionCode}</td>
						<td>{transaction.aiCode}</td>
					</tr>
			</>);
		} else {
			return null;
		}
	};

    return (
        <>

		<div className="card border-primary">
				<div className="card-header">Transaction Detail</div>
				<div className="card-body">

				<Processing show={showSpinner}/>
				<MessageTag message={message} />

				<table className="table table-bordered" id="trxDetail">
					<tbody>
						<tr>
							<th>Transaction Date</th>
							<th>Trace Number</th>
							<th>Transaction Id</th>
							<th>Api Version</th>
						</tr>
						<tr>
							<td>{transaction.trxDatetime}</td>
							<td>{transaction.traceNbr}</td>
							<td className="text-uppercase">{transaction.trxUuid}</td>
							<td>{transaction.trxApiVersion}</td>
						</tr>
						<tr>
							<th>Transaction Type</th>
							<th>Request Amount</th>
							<th colSpan="2">Response</th>
						</tr>
						<tr>
							<td id="trxType">{decodeValues(transaction.trxTypeId, transactionTypes)}</td>
							<td>{formatCurrency(transaction.reqAmt)}</td>
							<td colSpan="2">{transaction.respText}</td>
						</tr>
						<tr>
							<th>Settlement Date</th>
							<th>Settlement Amount</th>
							<th>Auth Number</th>
							<th>MCC</th>
						</tr>
						<tr>
							<td>{transaction.settlementDate}</td>
							<td>{formatCurrency(transaction.settleAmt)}</td>
							<td>{transaction.authNbr}</td>
							<td>{transaction.mcc}</td>
						</tr>
						<TerminalInfo trx={transaction}/>
					</tbody>
				</table>

				<PanInfo pan={transaction.pan}/>

				<RecipientInfo recipient={transaction.recipientAccount}/>

				<BalanceInfo trx={transaction}/>

				<BenefitInfo benefit={transaction.benefit} benefits={transaction.benefits} trxBenefits={transaction.trxBenefits}/>

				<OriginalTrxInfo trx={transaction} />

				<MerchantInfo trx={transaction} />

				<DeliveryInfo trx={transaction} />

				<AddlResponseText trx={transaction} />

				<LocationInfo trx={transaction} />
			
				</div>
			</div>

			<MapInfo trx={transaction}/>
        </>
    )
}
