import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import AdminBenefit from '../program/Benefit';


export default function Benefit() {

	return (
		<>
		<AdminBenefit basePath="/admin" admin={true}/>
		</>
	)
}
