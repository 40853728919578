import React, {useState, useEffect, useRef} from 'react';
import useUserContext from '../common/UserContext';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';

import { selectOptions } from '../common/Forms';
import { SelectOneArray, SelectOneBlankArray } from '../common/Constants';

import { apiGet, getAccountsApiUrl } from '../common/AppApi';
import './Dashboard.css';

import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default function Dashboard() {
	let {user} = useUserContext();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [groups, setGroups] = useState([]);
	let [programs, setPrograms] = useState([]);
	let [agencies, setAgencies] = useState([{programUuid:'', agencyUuid:'', agencyName:''}]);
	let [fundingSources, setFundingSources] = useState([{programUuid:'', fundingId:'', fundingName:''}]);
	
	let [displayData, setDisplayData] = useState(null);
	let [programFundingData, setProgramFundingData] = useState(null);
	let [programRedemptionData, setProgramRedemptionData] = useState(null);
	let [programRedemptionCountData, setProgramRedemptionCountData] = useState(null);
	let [programIssuanceData, setProgramIssuanceData] = useState(null);
	let [programIssuanceCountData, setProgramIssuanceCountData] = useState(null);
	let [programRecipientRedemptionCountData, setProgramRecipientRedemptionCountData] = useState(null);
	let [merchantData, setMerchantData] = useState(null);
	let [topMerchantRedemptionsData, setTopMerchantRedemptionsData] = useState(null);
	let [programIncentiveData, setProgramIncentiveData] = useState(null);

	let [dateRanges, setDateRanges] = useState({
		dateRange1:'30', dateRange2:'30', dateRange3:'30', dateRange4:'30', dateRange5:'30',
		dateRange6:'30', dateRange7:'30', dateRange8:'30'
	});

	useEffect(() => {
			let url = getAccountsApiUrl() + '/dash';
			apiGet(url).then((resp) => {
				//console.debug(resp);
				setGroups(resp);

				let previousProgram = null;
				let progs = [{programUuid:'', programName:'Select...'}];
				resp.forEach( (item, index) => {
					if (item.programUuid != previousProgram) {
						previousProgram = item.programUuid;
						progs.push({
							programUuid:item.programUuid,
							programName:item.programName
						});
					}
				});
				setPrograms(progs);
			}).catch( (err) => {
				console.error(err);
				let messageObject = {text:Strings.error.general,type:'warning'};
				if(err.privilegeError){
					messageObject.text = <>{err.message}</>;
					messageObject.type = 'danger';
				}
				setMessage(messageObject);
			});
	}, []);

	let [searchForm, setSearchForm] = useState({programUuid:'', agencyUuid:'', fundingId:'', dateRange:'YTD'});
	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let handleDateRangeChange = (event) => {
		const {id, value} = event.target;
		setDateRanges({...dateRanges, [id]:value});
	}

	let getProgramName = (id) => {
		for (var i=0; i<programs.length; i++) {
			if (programs[i].programUuid == id)
				return programs[i].programName;
		}
		return null;
	};
	let getAgencyName = (id) => {
		for (var i=0; i<agencies.length; i++) {
			if (agencies[i].agencyUuid == id)
				return agencies[i].agencyName;
		}
		return null;
	};
	let getFundingName = (id) => {
		let theItem = groups.find( (item) => {
			return item.fundingId == id;
		});
		return (theItem && theItem.fundingName) ? theItem.fundingName : '';
	};

	let programName = useRef(null);
	let currentProgramName = () => {
		return programName.current ? programName.current : 'Program';
	};
	let agencyName = useRef(null);
	let currentAgencyName = () => {
		return agencyName.current ? agencyName.current : 'Agency';
	};
	let fundingName = useRef(null);
	let currentFundingName = () => {
		return fundingName.current ? fundingName.current : null;
	};

	useEffect(() => {
		let agenciesClone = [{programUuid:'', agencyUuid:'', agencyName:''}];
		let fundingClone = [{programUuid:'', fundingId:'', fundingName:''}];

		if (searchForm.programUuid) {
			let prevAgencyUuid = null, prevFundingId = null;

			for (var i=0; i<groups.length; i++) {
				let item = groups[i];
				if (item.programUuid == searchForm.programUuid) {
					if (item.agencyUuid && item.agencyUuid != prevAgencyUuid) {
						prevAgencyUuid = item.agencyUuid;
						agenciesClone.push({
							agencyName:item.agencyName,
							agencyUuid:item.agencyUuid,
							programUuid:item.programUuid
						});
					}
					if (item.fundingId && item.fundingId != prevFundingId) {
						prevFundingId = item.fundingId;
						fundingClone.push({
							fundingId:item.fundingId,
							fundingName:item.fundingName,
							programUuid:item.programUuid
						});
					}
				}
			}
		}

		setAgencies(agenciesClone);
		setFundingSources(fundingClone);
		setSearchForm({...searchForm, agencyUuid:'', fundingId:''});

		if (searchForm.programUuid) {
			programName.current = getProgramName(searchForm.programUuid);
		} else {
			programName.current = null;
		}

	}, [searchForm.programUuid]);

	useEffect(() => {
		if (searchForm.agencyUuid) {
			agencyName.current = getAgencyName(searchForm.agencyUuid);
		} else {
			agencyName.current = null;
		}
	}, [searchForm.agencyUuid]);

	useEffect(() => {
		//console.debug('fundingId ' + searchForm.fundingId);
		if (searchForm.fundingId) {
			fundingName.current = getFundingName(searchForm.fundingId);
		} else {
			fundingName.current = null;
		}
	}, [searchForm.fundingId]);

	let agencyFunding = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/dash/agency-funding/' + searchForm.agencyUuid + '?dateRange=' + searchForm.dateRange;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			setProgramFundingData(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'warning'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
				messageObject.type = 'danger';
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false));
	};

	let programFunding = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/dash/program-funding/' + searchForm.programUuid + '?dateRange=' + searchForm.dateRange;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			setProgramFundingData(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'warning'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
				messageObject.type = 'danger';
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false));
	};

	let buildQueryParams = (dataType) => {
		let queryParams = [];

		if (searchForm.agencyUuid)
			queryParams.push('agencyUuid=' + searchForm.agencyUuid);
		if (searchForm.fundingId)
			queryParams.push('fundingId=' + searchForm.fundingId);

		if(queryParams.length == 0) {
			if (searchForm.programUuid)
				queryParams.push('programUuid=' + searchForm.programUuid);
		}

		if (dataType)
			queryParams.push('dataType=' + dataType);

		return queryParams.join('&');
	};

	let getMerchantData = (dateType, saveState) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/dash/merchant-data/' + searchForm.programUuid;
		if (dateType)
			url += '?dateRange=' + dateType;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			saveState(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'warning'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
				messageObject.type = 'danger';
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false));
	};

	let getTopMerchantsRedemptionData = (dateType, saveState) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/dash/top-merchant-redemptions' + '?' + buildQueryParams();
		if (dateType)
			url += '&dateRange=' + dateType;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			saveState(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'warning'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
				messageObject.type = 'danger';
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false));
	};

	let getChartData = (dataType, dateType, saveState) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/dash/chart-data?' + buildQueryParams(dataType);
		if (dateType)
			url += '&dateRange=' + dateType;
		apiGet(url).then((resp) => {
			//if (dataType == 6) console.debug(resp);
			saveState(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'warning'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
				messageObject.type = 'danger';
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false));
	};

	let programRedemptions = () => {
		getChartData(2, dateRanges.dateRange1, setProgramRedemptionData);
	};
	let programRedemptionsCount = () => {
		getChartData(1, dateRanges.dateRange2, setProgramRedemptionCountData);
	};
	let programIssuances = () => {
		getChartData(3, dateRanges.dateRange3, setProgramIssuanceData);
	};
	let programRecipientRedemptionsCount = () => {
		getChartData(4, dateRanges.dateRange4, setProgramRecipientRedemptionCountData);
	};

	// data changes
	useEffect(() => {
		if (programFundingData) {
			doProgramFundingChart();
		}
	}, [programFundingData]);

	useEffect(() => {
		if (programRedemptionData) {
			doRedemptionsChart();
		}
	}, [programRedemptionData]);
	useEffect(() => {
		if (programRedemptionCountData) {
			doRedemptionsCountChart();
		}
	}, [programRedemptionCountData]);
	useEffect(() => {
		if (programIssuanceData) {
			doIssuancesChart();
		}
	}, [programIssuanceData]);
	useEffect(() => {
		if (programRecipientRedemptionCountData) {
			doRecipientRedemptionsCountChart();
		}
	}, [programRecipientRedemptionCountData]);
	useEffect(() => {
		if (merchantData) {
			doMerchantParticipationChart();
		}
	}, [merchantData]);
	useEffect(() => {
		if (topMerchantRedemptionsData) {
			doTopMerchantRedemptionsChart();
		}
	}, [topMerchantRedemptionsData]);
	useEffect(() => {
		if (programIssuanceCountData) {
			doIssuanceCountChart();
		}
	}, [programIssuanceCountData]);
	useEffect(() => {
		if (programIncentiveData) {
			doIncentivesChart();
		}
	}, [programIncentiveData]);

	// date changes
	useEffect(() => {
		if (programRedemptionData) {
			if (searchForm.agencyUuid || searchForm.programUuid || searchForm.fundingId) {
				programRedemptions();
			}
		}
	}, [dateRanges.dateRange1]);
	useEffect(() => {
		if (programRedemptionCountData) {
			if (searchForm.agencyUuid || searchForm.programUuid || searchForm.fundingId) {
				programRedemptionsCount()
			}
		}
	}, [dateRanges.dateRange2]);
	useEffect(() => {
		if (programIssuanceData) {
			if (searchForm.agencyUuid || searchForm.programUuid || searchForm.fundingId) {
				programIssuances();
			}
		}
	}, [dateRanges.dateRange3]);
	useEffect(() => {
		if (programRecipientRedemptionCountData) {
			if (searchForm.agencyUuid || searchForm.programUuid || searchForm.fundingId) {
				programRecipientRedemptionsCount();
			}
		}
	}, [dateRanges.dateRange4]);
	useEffect(() => {
		if (merchantData) {
			if (searchForm.programUuid) {
				getMerchantData(dateRanges.dateRange5, setMerchantData);
			}
		}
	}, [dateRanges.dateRange5]);
	useEffect(() => {
		if (topMerchantRedemptionsData) {
			if (searchForm.programUuid) {
				getTopMerchantsRedemptionData(dateRanges.dateRange6, setTopMerchantRedemptionsData)
			}
		}
	}, [dateRanges.dateRange6]);
	useEffect(() => {
		if (programIssuanceCountData) {
			if (searchForm.programUuid) {
				getChartData(5, dateRanges.dateRange7, setProgramIssuanceCountData);
			}
		}
	}, [dateRanges.dateRange7]);
	useEffect(() => {
		if (programIncentiveData) {
			if (searchForm.programUuid) {
				getChartData(6, dateRanges.dateRange8, setProgramIncentiveData);
			}
		}
	}, [dateRanges.dateRange8]);

	useEffect(() => {
		//console.debug(dateRanges);
		if (dateRanges.initial) {
			programRedemptions();
			programRedemptionsCount()
			programIssuances();
			programRecipientRedemptionsCount();
			getMerchantData(dateRanges.dateRange5, setMerchantData);
			getTopMerchantsRedemptionData(dateRanges.dateRange6, setTopMerchantRedemptionsData)
			getChartData(5, dateRanges.dateRange7, setProgramIssuanceCountData);
			getChartData(6, dateRanges.dateRange8, setProgramIncentiveData);
			setDateRanges({...dateRanges, initial:false});
		}
	}, [dateRanges]);

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);
		setMessage(null);

		let queryParams = [];

		if (searchForm.agencyUuid)
			queryParams.push('agencyUuid=' + searchForm.agencyUuid);
		if (searchForm.fundingId)
			queryParams.push('fundingId=' + searchForm.fundingId);
		if (searchForm.programUuid)
			queryParams.push('programUuid=' + searchForm.programUuid);
		queryParams.push('dateRange=' + searchForm.dateRange);

		let url = getAccountsApiUrl() + '/dash/snapshot?' + queryParams.join('&');

		apiGet(url).then((resp) => {
			//console.debug(resp);
			if (resp.funding || resp.issued || resp.redeemed ) {
				setDisplayData(resp);
				if (searchForm.agencyUuid) {
					agencyFunding();
				} else if (searchForm.programUuid) {
					programFunding();
				}

				setProgramRedemptionData(null);
				setProgramRedemptionCountData(null);
				setProgramIssuanceData(null);
				setProgramRecipientRedemptionCountData(null);
				setMerchantData(null);
				setTopMerchantRedemptionsData(null);
				setProgramIssuanceCountData(null);
				setProgramIncentiveData(null);

				setDateRanges({
					dateRange1:searchForm.dateRange, dateRange2:searchForm.dateRange, dateRange3:searchForm.dateRange,
					dateRange4:searchForm.dateRange, dateRange5:searchForm.dateRange,
					dateRange6:searchForm.dateRange, dateRange7:searchForm.dateRange,
					dateRange8:searchForm.dateRange, initial:true
				});

			} else {
				setMessage({text:'No benefit data available.', type:'warning'});

				const charts = [
					fundingChart,
					redemptionChart,
					programFundingChart,
					redemptionsChart,
					issuancesChart,
					redemptionsCountChart,
					recipientRedemptionsCountChart,
					merchantParticipationChart,
					topMerchantRedemptionsChart,
					issuanceCountChart,
					programIncentiveData,
				];
				charts.forEach( (item,index) => {
					if (item && item.current)
						item.current.destroy();
				});

				const dataStores = [
					setDisplayData,
					setProgramFundingData,
					setProgramRedemptionData,
					setProgramIssuanceData,
					setProgramRedemptionCountData,
					setProgramRecipientRedemptionCountData,
					setMerchantData,
					setTopMerchantRedemptionsData,
					setProgramIssuanceCountData,
					setProgramIncentiveData,
				];
				dataStores.forEach( (item,index) => {
					item(null);
				});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'warning'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
				messageObject.type = 'danger';
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false));
	};

	let DisplayIssuance = () => {
		if (displayData) {
			return <><br/>
			<table className='table table-sm micro'>
				<tbody>
					<tr>
						<th>Funding</th><td>$ {displayData.funding.toFixed(2)}</td><td></td>
					</tr>
					<tr>
						<th>Issued</th><td>$ {displayData.issued.toFixed(2)}</td><td>{displayData.issuedPct.toFixed(1)}%</td>
					</tr>
					<tr>
						<th>Not Issued</th><td>$ {displayData.notIssued.toFixed(2)}</td><td>{displayData.notIssuedPct.toFixed(1)}%</td>
					</tr>
				</tbody>
			</table>
			</>;
		} else {
			return null;
		}
	};
	let DisplayRedemption = () => {
		if (displayData) {
			return <><br/>
			<table className='table table-sm micro'>
				<tbody>
					<tr>
						<th>Issued</th><td>$ {displayData.issued.toFixed(2)}</td><td></td>
					</tr>
					<tr>
						<th>Redeemed</th><td>$ {displayData.redeemed.toFixed(2)}</td><td>{displayData.redemptionPct.toFixed(1)}%</td>
					</tr>
					<tr>
						<th>Not Redeemed</th><td>$ {displayData.notRedeemed.toFixed(2)}</td><td>{displayData.notRedeemedPct.toFixed(1)}%</td>
					</tr>
				</tbody>
			</table>
			</>;
		} else {
			return null;
		}
	};
	let DisplayAllocation = () => {
		if (programFundingData && programFundingData.length) {
			let rows = programFundingData.map( (item, index) => {
				return <tr key={index}><th>{getFundingName(item.dataId)}</th><td>${item.amount.toFixed(2)}</td><td>{item.percentage.toFixed(1)}%</td></tr>
			});
			return <><br/>
			<table className='table table-sm micro'>
				<tbody>
					{rows}
				</tbody>
			</table>
			</>;
		} else {
			return null;
		}
	};
	let DisplayMerchantParticipation = () => {
		if (merchantData && merchantData.merchantCount) {
			return <><br/>
			<table className='table table-sm micro'>
				<tbody>
					<tr>
						<th>Enrolled Merchants</th><td>{merchantData.merchantCount.count}</td><td></td>
					</tr>
					<tr>
						<th>Merchants with a Redemption</th><td>{merchantData.merchantRedemptionCount.count}</td><td>{merchantData.merchantRedemptionCount.percentage.toFixed(1)}%</td>
					</tr>
					<tr>
						<th>Merchants without a Redemption</th><td>{merchantData.merchantNoRedemptionCount.count}</td><td>{merchantData.merchantNoRedemptionCount.percentage.toFixed(1)}%</td>
					</tr>
				</tbody>
			</table>
			</>;
		} else {
			return null;
		}
	};

	const namedColors = {
		red:'rgb(255, 99, 132, .9)',
		blue:'rgb(54, 162, 235, .9)',
		green:'rgb(75, 192, 192, .9)',
		purple:'rgb(145, 85, 225, .9)',
		orange:'rgb(255, 150, 64, .9)',
		yellow:'rgb(245, 205, 86, .9)',
		grey:'rgb(185, 185, 195, .9)',
	};
	const backgroundColors = [
		namedColors.blue,
		namedColors.red,
		namedColors.green,
		namedColors.orange,
		namedColors.purple,
		namedColors.yellow,
		namedColors.grey,
	];

	const animationConfig = {duration: 600};
	const tooltipCallbacks = {label: (context) => {
		return context.label + ': ' + context.parsed.toFixed(2);
	}};
	const pieOptionsConfig = {borderWidth:1};

	let fundingChart = useRef(null);
	let doProgramIssuanceChart = () => {
		let chartTitle = ['Issuance',(searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		const pieConfig = {
			type: 'pie',
			data: {
				labels: ['Issued ' + displayData.issuedPct + '%', 'Not Issued ' + displayData.notIssuedPct + '%'],
				datasets: [{
				  data: [displayData.issued, displayData.notIssued],
				  backgroundColor: backgroundColors,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: tooltipCallbacks
					},
					title: {display:true, text:chartTitle}
				},
				datasets: {pie: pieOptionsConfig}
			}
		};

		if (fundingChart.current)
			fundingChart.current.destroy();

		const ctx = document.getElementById('fundingChart');
		fundingChart.current = new Chart(ctx, pieConfig);
	};

	let redemptionChart = useRef(null);
	let doRedemptionChart = () => {
		let chartTitle = ['Redemption',(searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		const pieConfig = {
			type: 'pie',
			data: {
				labels: ['Redeemed ' + displayData.redemptionPct + '%', 'Not Redeemed ' + displayData.notRedeemedPct + '%'],
				datasets: [{
				  data: [displayData.redeemed, displayData.notRedeemed],
				  backgroundColor: backgroundColors,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: tooltipCallbacks
					},
					title: {display:true, text:chartTitle}
				},
				datasets: {pie: pieOptionsConfig}
			}
		};

		if (redemptionChart.current)
			redemptionChart.current.destroy();

		const ctx = document.getElementById('redemptionChart');
		redemptionChart.current = new Chart(ctx, pieConfig);
	};

	let programFundingChart = useRef(null);
	let doProgramFundingChart = () => {
		let data = programFundingData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(getFundingName(item.fundingId) + ' ' + item.percentage + '%');
			theData.push(item.amount);
		});
		const chartTitle = ['Issuance Funding Source', (searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		const pieConfig = {
			type: 'pie',
			data: {
				labels: theLabels,
				datasets: [{
				  data: theData,
				  backgroundColor: backgroundColors,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: tooltipCallbacks
					},
					title: {display:true,text:chartTitle}
				},
				datasets: {pie: pieOptionsConfig}
			}
		};

		if (programFundingChart.current)
			programFundingChart.current.destroy();

		const ctx = document.getElementById('programFundingChart');
		programFundingChart.current = new Chart(ctx, pieConfig);
	};

	const barTooltipCallbacks = {label: (context) => {
		return context.dataset.label + ' ' + context.parsed.y.toFixed(2);
	}};

	let redemptionsChart = useRef(null);
	let doRedemptionsChart = () => {
		let data = programRedemptionData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(item.trxDate);
			theData.push(item.amount);
		});

		let chartTitle = ['Redemption Amounts', (searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange1));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: [{
					label:'Redemption Amount',
					data: theData,
					backgroundColor: namedColors.green,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: barTooltipCallbacks
					},
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Dollars'},
						suggestedMax: 10
					}
				}
			}
		};

		if (redemptionsChart.current)
			redemptionsChart.current.destroy();
		
		const ctx = document.getElementById('redemptionsChart');
		redemptionsChart.current = new Chart(ctx, barConfig);
	};

	let redemptionsCountChart = useRef(null);
	let doRedemptionsCountChart = () => {
		let data = programRedemptionCountData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(item.trxDate);
			theData.push(item.count);
		});

		let chartTitle = ['Redemption Counts',(searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange2));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: [{
					label:'Redemption Count',
					data: theData,
					backgroundColor: namedColors.purple,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Count'},
						ticks: {
							stepSize: 1
						},
						suggestedMax: 5
					}
				}
			}
		};

		if (redemptionsCountChart.current)
			redemptionsCountChart.current.destroy();
		
		const ctx = document.getElementById('redemptionsCountChart');
		redemptionsCountChart.current = new Chart(ctx, barConfig);
	};

	let issuancesChart = useRef(null);
	let doIssuancesChart = () => {
		let data = programIssuanceData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(item.trxDate);
			theData.push(item.amount);
		});

		let chartTitle = ['Issuance Amounts',(searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange3));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: [{
					label:'Issuance Amount',
					data: theData,
					backgroundColor: namedColors.orange,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: barTooltipCallbacks
					},
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Dollars'},
						suggestedMax: 10
					}
				}
			}
		};

		if (issuancesChart.current)
			issuancesChart.current.destroy();
		
		const ctx = document.getElementById('issuancesChart');
		issuancesChart.current = new Chart(ctx, barConfig);
	};

	let issuanceCountChart = useRef(null);
	let doIssuanceCountChart = () => {
		let data = programIssuanceCountData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(item.trxDate);
			theData.push(item.count);
		});

		let chartTitle = ['Issuance Counts',(searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange7));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: [{
					label:'Issuance Count',
					data: theData,
					backgroundColor: namedColors.red,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Count'},
						ticks: {
							stepSize: 1
						},
						suggestedMax: 5
					}
				}
			}
		};

		if (issuanceCountChart.current)
			issuanceCountChart.current.destroy();
		
		const ctx = document.getElementById('issuanceCountChart');
		issuanceCountChart.current = new Chart(ctx, barConfig);
	};

	let recipientRedemptionsCountChart = useRef(null);
	let doRecipientRedemptionsCountChart = () => {
		let data = programRecipientRedemptionCountData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(item.trxDate);
			theData.push(item.count);
		});

		let chartTitle = ['Recipient Redemption Counts',(searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange4));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: [{
					label:'Recipient Count',
					data: theData,
					backgroundColor: namedColors.blue,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Count'},
						ticks: {
							stepSize: 1
						},
						suggestedMax: 5
					}
				}
			}
		};

		if (recipientRedemptionsCountChart.current)
		recipientRedemptionsCountChart.current.destroy();
		
		const ctx = document.getElementById('recipientRedemptionsCountChart');
		recipientRedemptionsCountChart.current = new Chart(ctx, barConfig);
	};

	const tooltipCallbacksInt = {label: (context) => {
		return context.label + ': ' + context.parsed;
	}};

	let merchantParticipationChart = useRef(null);
	let doMerchantParticipationChart = (data) => {
		let theLabels = ['Redemption ' + merchantData.merchantRedemptionCount.percentage.toFixed(1) + '%',
			'No Redemption ' + merchantData.merchantNoRedemptionCount.percentage.toFixed(1) + '%'];
		let theData = [merchantData.merchantRedemptionCount.count, merchantData.merchantNoRedemptionCount.count];

		const chartTitle = ['Merchant Participation', currentProgramName(), getDateOptionText(dateRanges.dateRange5)];
		const pieConfig = {
			type: 'pie',
			data: {
				labels: theLabels,
				datasets: [{
				  data: theData,
				  backgroundColor: backgroundColors,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: tooltipCallbacksInt
					},
					title: {display:true,text:chartTitle}
				},
				datasets: {pie: pieOptionsConfig}
			}
		};

		if (merchantParticipationChart.current)
			merchantParticipationChart.current.destroy();

		const ctx = document.getElementById('merchantParticipationChart');
		merchantParticipationChart.current = new Chart(ctx, pieConfig);
	};

	let topMerchantRedemptionsChart = useRef(null);
	let doTopMerchantRedemptionsChart = () => {
		let data = topMerchantRedemptionsData;
		let theLabels = [''];
		let theData = [];
		data.forEach( (item, index) => {
			//theLabels.push(item.dataName);
			theData.push({
				label:item.dataName,
				data:[item.amount],
				backgroundColor:backgroundColors[index]
			});
		});

		let chartTitle = ['Top Redemption Merchants', (searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange6));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: theData
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: barTooltipCallbacks
					},
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Dollars'},
						suggestedMax: 10
					}
				}
			}
		};

		if (topMerchantRedemptionsChart.current)
			topMerchantRedemptionsChart.current.destroy();
		
		const ctx = document.getElementById('topMerchantRedemptionsChart');
		topMerchantRedemptionsChart.current = new Chart(ctx, barConfig);
	};

	let incentivesChart = useRef(null);
	let doIncentivesChart = () => {
		let data = programIncentiveData;
		let theLabels = [];
		let theData = [];
		data.forEach( (item, index) => {
			theLabels.push(item.trxDate);
			theData.push(item.amount);
		});

		let chartTitle = ['Incentives Earned', (searchForm.agencyUuid ? currentAgencyName() : currentProgramName())];
		if (fundingName.current)
			chartTitle.push(fundingName.current);
		chartTitle.push(getDateOptionText(dateRanges.dateRange8));

		const barConfig = {
			type: 'bar',
			data: {
				labels: theLabels,
				datasets: [{
					label:'Incentive Amount',
					data: theData,
					backgroundColor: namedColors.yellow,
				}]
			},
			options: {
				animation:animationConfig,
				plugins: {
					tooltip: {
						callbacks: barTooltipCallbacks
					},
					title: {display:true,text:chartTitle}
				},
				scales: {
					y:{
						beginAtZero: true,
						title: {display:true,text:'Dollars'},
						suggestedMax: 10
					}
				}
			}
		};

		if (incentivesChart.current)
			incentivesChart.current.destroy();
		
		const ctx = document.getElementById('incentivesChart');
		incentivesChart.current = new Chart(ctx, barConfig);
	};

	let canvasSize = {maxHeight:280,maxWidth:280,margin:'auto'};
	let canvasSize2 = {height:300,maxHeight:300,width:520,margin:'auto'};

	useEffect(() => {
		if (displayData) {
			doProgramIssuanceChart();
			doRedemptionChart();
		}
	}, [displayData]);

	 const DateOptions = [
		{id:"30", text:'Last 30 Days'},
		{id:"MTD", text:'Month To Date'},
		{id:"YTD", text:'Year To Date'},
		{id:"", text:'To Date'},
	];
	let getDateOptionText = (id) => {
		let text = DateOptions.find( item => {
			return item.id == id;
		});
		return text ? text.text : '';
	};
	
	return (
		<>
			<h3>Dashboard</h3>
			<MessageTag message={message} />
			<Processing show={showSpinner}/>

			<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group col-12 col-md-3">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control" value={searchForm.programUuid} onChange={handleFieldChange} >
						{ selectOptions(programs, 'programUuid', 'programName') }
					</select>
				</div>
				<div className="form-group col-12 col-md-4">
					<label htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control" value={searchForm.agencyUuid} onChange={handleFieldChange} disabled={agencies.length < 2}>
						{ selectOptions(agencies, 'agencyUuid', 'agencyName') }
					</select>
				</div>
				<div className="form-group col-12 col-md-3">
					<label htmlFor="fundingId">Funding Source</label>
					<select id="fundingId" className="form-control" value={searchForm.fundingId} onChange={handleFieldChange} >
						{ selectOptions(fundingSources, 'fundingId', 'fundingName') }
					</select>
				</div>
				<div className="form-group col-12 col-md-2">
					<label htmlFor="dateRange">Date Range</label>
					<select id="dateRange" className="form-control" onChange={handleFieldChange} defaultValue={"YTD"}>
					{ selectOptions(DateOptions) }
					</select>
				</div>
				<div className="form-group col-12 col-md-3">
					<br/>
					<button className="btn btn-primary" id="btnSearch" disabled={searchForm.programUuid ? false : true}>Retrieve Data</button>
				</div>
			</div>
			</form>

			<div className="row">
				<div className="col-12 col-lg-4 outline" style={{display:displayData ? 'block' : 'none'}}>
					<div><canvas id="programFundingChart" style={canvasSize}></canvas></div>
					<DisplayAllocation />
				</div>

				<div className="col-12 col-lg-4 outline" style={{display:displayData ? 'block' : 'none'}}>
					<div><canvas id="fundingChart" style={canvasSize}></canvas></div>
					<DisplayIssuance />
				</div>

				<div className="col-12 col-lg-4 outline" style={{display:displayData ? 'block' : 'none'}}>
					<div><canvas id="redemptionChart" style={canvasSize}></canvas></div>
					<DisplayRedemption />
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:programRedemptionData ? 'block' : 'none'}}>
					<select id="dateRange1" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange1}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="redemptionsChart" style={canvasSize2}></canvas></div>
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:programRedemptionCountData ? 'block' : 'none'}}>
					<select id="dateRange2" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange2}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="redemptionsCountChart" style={canvasSize2}></canvas></div>
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:programRecipientRedemptionCountData ? 'block' : 'none'}}>
					<select id="dateRange4" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange3}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="recipientRedemptionsCountChart" style={canvasSize2}></canvas></div>
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:programIssuanceData ? 'block' : 'none'}}>
					<select id="dateRange3" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange3}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="issuancesChart" style={canvasSize2}></canvas></div>
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:programIssuanceCountData ? 'block' : 'none'}}>
					<select id="dateRange7" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange7}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="issuanceCountChart" style={canvasSize2}></canvas></div>
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:programIncentiveData ? 'block' : 'none'}}>
					<select id="dateRange8" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange8}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="incentivesChart" style={canvasSize2}></canvas></div>
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:merchantData && merchantData.merchantCount && merchantData.merchantCount.count ? 'block' : 'none'}}>
					<select id="dateRange5" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange5}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="merchantParticipationChart" style={canvasSize2}></canvas></div>
					<DisplayMerchantParticipation />
				</div>

				<div className="col-12 col-lg-6 outline" style={{display:topMerchantRedemptionsData ? 'block' : 'none'}}>
					<select id="dateRange6" className="form-control pull-right micro" onChange={handleDateRangeChange} value={dateRanges.dateRange6}>
					{ selectOptions(DateOptions) }
					</select>
					<div><canvas id="topMerchantRedemptionsChart" style={canvasSize2}></canvas></div>
				</div>

			</div>
		</>
	)
}
