import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { apiGet, apiPut, getAccountsApiUrl } from '../common/AppApi';
import useUserContext from '../common/UserContext';
import {saveUser} from '../common/AppAuth';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import moment from 'moment';
import { decodeValues } from '../common/Formats';

import Pagination, { resetPaging } from '../common/Pagination';
import { NotificationStatus } from '../common/Constants';

import NotificationPreferences from './NotificationPreferences';


export default function Notifications() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

    let {user, setUser} = useUserContext();

	let [notifications, setNotifications] = useState([]);
	let [uuidsRead, setUuidsRead] = useState({});
	let [pagingData, setPagingData] = useState(resetPaging());

	let [events, setEvents] = useState([]);
	let [statuses, setStatuses] = useState([]);

	const history = useHistory();

	const PageSize = 5;

	let setNotificationCount = (count) => {
		let cloned = {... user};
		cloned.notifications = count;
		saveUser(cloned); // save to local storage
		setUser(cloned); // save to context
	};

	let updateNotification = (uuids, statusId, callback) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/notifications';
		let body = {notificationStatusId:statusId, notificationUuids:uuids};
		apiPut(url, body).then((resp)=> {
			console.debug(resp);
			if (callback) {
				callback();
			} else {
				deleteFromLocal(uuids);
			}
		}).catch( (err)=> {
			console.error(err);
			if (err.status == 404) {
				setMessage({text:Strings.notifications.notFound,type:'danger'});
			} else if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	// remove from local state
	let deleteFromLocal = (uuids) => {
		let notifs = [];
		let unread = 0;
		notifications.forEach( (item) => {
			if (!uuids.includes(item.notificationUuid)) {
				let cloned = {...item};
				if (cloned.notificationStatusId == NotificationStatus.Unread)
					unread++;
				notifs.push(cloned);
			}
		});
		setNotifications(notifs);
		setNotificationCount(unread);
	};

	// mark read in local state
	let markReadLocal = (uuids) => {
		let notifs = [];
		let unread = 0;
		notifications.forEach( (item) => {
			let cloned = {...item};
			if (uuids.includes(item.notificationUuid)) {
				if (cloned.notificationStatusId == NotificationStatus.Unread)
					cloned.notificationStatusId = NotificationStatus.Read;
			} else {
				if (cloned.notificationStatusId == NotificationStatus.Unread)
					unread++;
			}
			notifs.push(cloned);
		});
		setNotifications(notifs);
		setNotificationCount(unread);
	};

	let getNotifications = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/notifications';
		apiGet(url).then((resp)=> {
			console.debug(resp);

			if (resp && resp.length) {
				setNotifications(resp);
				setPagingData(resetPaging());
				let unread = 0;
				resp.forEach( (item) => {
					if(item.notificationStatusId == NotificationStatus.Unread)
						unread++;
				});
				setNotificationCount(unread);
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	}

	let getEvents = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/notifications/events';
		apiGet(url).then((resp)=> {
			console.debug(resp);
			setEvents(resp);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	}

	let getStatuses = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/notifications/statuses';
		apiGet(url).then((resp)=> {
			console.debug(resp);
			setStatuses(resp);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	}

	useEffect( () => {
		getEvents();
		getStatuses();
		getNotifications();
	}, [] ); // empty array, run once

	// check for read messages, update after a delay
	let setRead = () => {
		let uuids = [];
		notifications.forEach( (item, index) => {
			if ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) {
				if (item.notificationStatusId == NotificationStatus.Unread)
					uuids.push(item.notificationUuid);
			}
		});

		if (uuids && uuids.length) {
			setTimeout( function(){
				updateNotification(uuids, NotificationStatus.Read, () => markReadLocal(uuids));
			}, 4000);
		}
	};

	// if rows were dismissed, reset paging if necessary
	useEffect( () => {
		if ((notifications.length >= PageSize) && (notifications.length % PageSize == 0))
			setPagingData(resetPaging());
	}, [notifications] );

	// check for read messages after paging
	useEffect( () => {
		setRead();
	}, [pagingData] );

	let NotificationRow = (props) => {
		let item = props.item;
		let notifiedDate = moment(new Date(item.notifiedDate)).format("YYYY-MM-DD hh:mm:ss");
		return (<>
			<tr>
				<td>
				{notifiedDate}
				</td>
				<td>
				{decodeValues(item.notificationEventId,events)}
				</td>
				<td>
				{item.notificationText}
				</td>
				<td>
				{decodeValues(item.notificationStatusId,statuses)}
				</td>
				<td>
				<button className="btn btn-sm btn-primary" type="button" title="Dismiss" onClick={() => updateNotification([item.notificationUuid],NotificationStatus.Dismissed)}><i className="fa fa-trash" aria-hidden="true"></i></button>
				</td>
			</tr>
		</>)
	};
	let NotificationBody = notifications.map( (item, index) => {
		if ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) {
			return (<NotificationRow key={item.notificationUuid} item={item} />);
		} else {
			return null;
		}
	});

	let NotificationTable = () => {
		if (notifications && notifications.length) {
			return (
				<>
				<table className="table">
				<thead>
					<tr>
					<th>Date</th>
					<th>Event</th>
					<th></th>
					<th>Status</th>
					<th></th>
					</tr>
				</thead>
				<tbody>
				{NotificationBody}
				</tbody>
				</table>
				<Pagination recordCount={notifications.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
		);
		} else {
			return (<>{Strings.notifications.noneFound}</>);
		}
	};

    return (
        <>
        <Processing show={showSpinner}/>
		<MessageTag message={message} />

		<h3>Notifications</h3>

		<NotificationTable />

		<NotificationPreferences />
        </>
    )
}
