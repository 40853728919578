import React, { useState, useEffect } from 'react';

import { apiGet, getMerchantsApiUrl } from '../common/AppApi';
import { StateArray } from '../common/Constants';
import { decodeValues2, formatPhone } from '../common/Formats';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { PageSize } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';

import './MerchantLocator.css';


let map = null;
let currentMarker = null;
let markers = [];

export default function MerchantLocator() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [pagingData, setPagingData] = useState(resetPaging());

	let clearMarkers = () => {
		// clear from map
		for(var i=0; i<markers.length; i++){
			markers[i].setMap(null);
		}
		// reset markers array
		markers = [];
	};

	let [merchants, setMerchants] = useState([]);
	let getMerchants = (latitude,longitude) => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/merchant/locator?latitude=' + latitude
		  + '&longitude=' + longitude;
		apiGet(url).then( (response) => {
			console.debug(response);
			setMerchants(response);

			let locationpin = '/locationpin.png';
			let formatContent = (merchant) => {
				let parts = [];
				parts.push(merchant.merchantName);
				parts.push(merchant.addressOne);
				if (merchant.addressTwo) parts.push(merchant.addressTwo);
				parts.push(merchant.city + ' ' + decodeValues2(merchant.stateId,StateArray) + ' ' + merchant.zipCode);
				parts.push(formatPhone(merchant.mobilePhone));
				parts.push(merchant.email);
				parts.push(merchant.distance + ' mile(s)');
				return parts.join('<br/>');
			};

			clearMarkers();
			// Create an info window to share between markers.
			const infoWindow = new window.google.maps.InfoWindow();
			// Create the markers.
			response.forEach( (item, index) => {
				const marker = new window.google.maps.Marker({
					position:{lat:item.latitude,lng:item.longitude},
					map,
					title: formatContent(item),
					label: `${index + 1}`,
					optimized: false,
					icon: locationpin
				});
				// Add a click listener for each marker, and set up the info window.
				marker.addListener("click", () => {
					infoWindow.close();
					infoWindow.setContent(marker.getTitle());
					infoWindow.open(marker.getMap(), marker);
				});
				markers.push(marker);
			});

			setPagingData(resetPaging());

		}).catch( (error) => {
			console.error(error);
			setMessage({text:'Unable to retrieve merchants.',type:'danger'});
		}).finally(() => setSpinner(false));
	};

	let googleMapScript = null;
	const ZOOM_LEVEL = 10;
	const DEFAULT_LOCATION = {
		coords : { latitude:30.26529193483543, longitude:-97.73461398870387}
	};

	let locationSuccess = (position) => {
		setSpinner(false);

		const latitude  = position.coords.latitude;
		const longitude = position.coords.longitude;
		console.debug(latitude + " " + longitude);

		if(!googleMapScript){
			googleMapScript = document.createElement('script');
			let jssource =`https://maps.googleapis.com/maps/api/js?key=AIzaSyBPfWxj52gQGVnHnldP8y3H2QLaljHe-NI&v=quarterly`;
			googleMapScript.src = jssource;
			googleMapScript.async = true;
			window.document.body.appendChild(googleMapScript);
			googleMapScript.addEventListener('load', () => {
				map = new window.google.maps.Map(document.getElementById("map"), {
					center: { lat: latitude, lng: longitude},
					zoom: ZOOM_LEVEL,
				});
				if (currentMarker) currentMarker.setMap(null);
				currentMarker = new window.google.maps.Marker({
					position: { lat: latitude, lng: longitude},
					map: map,
				});

				const centerControlDiv = document.createElement("div");
				CenterControl(centerControlDiv, map);
				map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
				  centerControlDiv
				);

				getMerchants(latitude,longitude);
			});
		} else {
			map.setCenter({ lat: latitude, lng: longitude});
			map.setZoom(ZOOM_LEVEL);

			currentMarker.setMap(null);
			currentMarker = new window.google.maps.Marker({
				position: { lat: latitude, lng: longitude},
				map: map,
			});

			getMerchants(latitude,longitude);
		}
	};
	
	let locationError = () => {
		setMessage({text:'Unable to determine current location.',type:'warning'});
		setSpinner(false);
		locationSuccess(DEFAULT_LOCATION);
	};

	let codeAddress = (e) => {
		e.preventDefault();
		
		var address = document.getElementById('locationSearchInput').value;
		let geocoder = new window.google.maps.Geocoder();
		geocoder.geocode( { 'address': address}, function(results, status) {
			if (status == 'OK') {
				map.setCenter(results[0].geometry.location);
				map.setZoom(ZOOM_LEVEL);

				currentMarker.setMap(null);
				currentMarker = new window.google.maps.Marker({
					map: map,
					position: results[0].geometry.location
	 			});

				getMerchants(results[0].geometry.location.lat(),results[0].geometry.location.lng());	  
				setMessage(null);

			} else {
				console.error(status);
				setMessage({text:'Location not found.',type:'warning'});
			}
		});
	};

	useEffect( () => {
		resetPosition();
	}, [] );

	let displayAddress2 = (address2) => {
		if (address2) {
			return (<>{address2}<br/></>);
		} else {
			return null;
		}

	};

	let TableRow = (props) => {
		let row = props.row;
		return (
			<>
			<a className="list-group-item list-group-item-action flex-column align-items-start">
			<div className="d-flex w-100 justify-content-between">
				<h5>{row.merchantName}</h5>
				<small>{row.distance} Mile(s)</small>
			</div>
			{row.addressOne}<br/>
			{displayAddress2(row.addressTwo)}
			{row.city} {decodeValues2(row.stateId,StateArray)} {row.zipCode}<br/>
			{formatPhone(row.mobilePhone)}<br/>
			{row.email}<br/>
			</a>
			</>
		);
	};

	let TableRows = merchants.map( (item, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={item.merchantUuid} row={item} /> : null;
	});

	let Table = () => {
		if (merchants && merchants.length) {
		return (<>

		<div className="list-group">
			{TableRows}
		</div>

		<br/>

		<Pagination recordCount={merchants.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />

		</>);
		}
		else {
			return null;
		}
	}

	let resetPosition = () => {
		if(navigator.geolocation) {
			setSpinner(true);
			navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
		} else {
			setMessage({text:'Unable to determine current location.',type:'warning'});
			locationSuccess(DEFAULT_LOCATION);
		}
	};

	let CenterControl = (controlDiv, map) => {
        // Set CSS for the control border.
        const controlUI = document.createElement("div");
        controlUI.style.backgroundColor = "#fff";
        controlUI.style.border = "2px solid #fff";
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.cursor = "pointer";
        controlUI.style.margin = "1em";
        //controlUI.style.marginBottom = "22px";
        controlUI.style.textAlign = "center";
        controlUI.title = "Click to center on your location";
        controlDiv.appendChild(controlUI);
        // Set CSS for the control interior.
        const controlText = document.createElement("div");
        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "Arial,sans-serif";
        controlText.style.fontSize = "2.5em";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = "&#8982;";
        controlUI.appendChild(controlText);
        // Setup the click event listeners: simply set the map to Chicago.
        controlUI.addEventListener("click", () => {
		  resetPosition();
		  //map.setCenter(results[0].geometry.location);
		  //map.setZoom(ZOOM_LEVEL);
        });
    }

    return (
        <>
        <Processing show={showSpinner}/>
		<MessageTag message={message} />

		<form onSubmit={e => codeAddress(e)}>

			<label className="sr-only" htmlFor="locationSearchInput">Merchant Location Search</label>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
				<button className="btn btn-outline-primary" id="button-addon1">Search</button>
				</div>
				<input type="text" id="locationSearchInput" className="form-control" placeholder="Location Search" aria-label="Location Search" aria-describedby="button-addon1"/>
			</div>

		</form>

		<div className="map" id="map">
		<h1>Map</h1>
		</div>

		<Table/>

        </>
    )
}
