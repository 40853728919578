import React, {useState, useEffect} from 'react';
import useUserContext from '../common/UserContext';
import Recipients from './Recipients';

import AddBenefitForm from './AddBenefitForm';

export default function AddBenefit() {
	
	let [identifier, setIdentifier] = useState();
	let [addParms, setAddParms] = useState({});

	let copyId = (id) => {
		setIdentifier(id);
	};
	let getId = () => identifier;

	return (
		<>
		<h3>Issue Benefit</h3>

		<AddBenefitForm clearId={() => setIdentifier(null)} getId={getId} programUuid={addParms.programUuid} agencyUuid={addParms.agencyUuid}/>
		<br/>
		<hr/>
		<Recipients searchOnly={true} copyId={copyId} setAddParms={setAddParms}/>
		</>
	)
}
