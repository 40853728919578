import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";

import { apiGet, getTransactionApiUrl } from '../common/AppApi';

import { formatCurrency } from '../common/Formats';
import { decodeValues } from '../common/Formats';

export default function ProgramTransactions(props) {
	let transactions = props.transactions;
	let [responseCodes, setResponseCodes] = useState([]);

	useEffect( () => {
			getResponseCodes();
			getTransactionTypes();
		}, []
	);

	let getResponseCodes = () => {
		let url = getTransactionApiUrl() + '/response-codes';
		apiGet(url).then((resp)=> {
			//console.debug(resp);
			setResponseCodes(resp);
		}).catch( (err)=> {
			console.error(err);
		});
	};

	let [transactionTypes, setTransactionTypes] = useState([]);
	let getTransactionTypes = () => {
		let url = getTransactionApiUrl() + '/transaction-types';
		apiGet(url).then((resp)=> {
			//console.debug(resp);
			let types = resp.map( (item) => {
				return {id:item.transactionTypeId,text:item.descr};
			});
			types.unshift({id:null,text:''});
			setTransactionTypes(types);
		}).catch( (err)=> {
			console.error(err);
		});
	};

	let decodeResponseCode = (id, values) => {
		return decodeValues(id, values, 'responseCodeId' , 'responseCode');
	};
	let decodeResponseDescr = (id, values) => {
		return decodeValues(id, values, 'responseCodeId' , 'descr');
	};

	if (transactions && transactions.length) {
			
		let TableRow = (props) => {
			return (
				<tr>
					<td>{props.row.trxDatetime}</td>
					<td>{decodeValues(props.row.trxTypeId, transactionTypes)}</td>
					<td>{formatCurrency(props.row.reqAmt)}</td>
					<td>{decodeResponseCode(props.row.respCodeId, responseCodes)} {decodeResponseDescr(props.row.respCodeId, responseCodes)}</td>
					<td>Food Place, 101 Main St</td>
				</tr>
			);
		};
		let TableBody = transactions.map( (item, index) => {
			return (<TableRow key={index} row={item} />);
		});

		return (
			<>
			<div className="card border-primary">
				<div className="card-header">{props.title}</div>
				<div className="card-body">
					<table className="table">
						<thead>
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Amount</th>
								<th>Response</th>
								<th>Location</th>
							</tr>
						</thead>
						<tbody>
							{TableBody}
						</tbody>
					</table>
				</div>
			</div>
			</>		  
			);
	}
	else {
		return (
			<>
			<div className="card border-primary">
				<div className="card-header">{props.title}</div>
				<div className="card-body">
						No recent transactions.
				</div>
			</div>
			</>
		);
	}
}
