import React from 'react';
import useUserContext from '../common/UserContext';
import { Link } from 'react-router-dom';
import { DisplayName } from '../common/UserUtils';

export default function Header() {
	let {user} = useUserContext();

	let NotificationsLink = () => {
		if (user.notifications) {
			return (
				<Link to="/recipient/notifications" className="nav-link">Notifications<span className="badge badge-secondary badge-pill">{user.notifications}</span></Link>
			);
		} else {
			return (
				<Link to="/recipient/notifications" className="nav-link">Notifications</Link>
			);
		}
	};

    return (
	<>
		<Link to="/recipient" className="navbar-brand">MyMarketLink</Link>
		<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon"></span>
		</button>

		<div className="collapse navbar-collapse" id="navbarMenu">
			<ul className="navbar-nav mr-auto">
				<li className="nav-item">
					<Link to="/recipient/benefits" className="nav-link">Benefits</Link>
				</li>
				<li className="nav-item">
					<Link to="/recipient/transactions" className="nav-link">Transactions</Link>
				</li>
				<li className="nav-item">
					<Link to="/recipient/merchantlocator" className="nav-link" title="Find Merchants by location">Merchant Locator</Link>
				</li>
				<li className="nav-item">
					<Link to="/recipient/merchants" className="nav-link" title="Search Merchants">Merchants</Link>
				</li>
				<li className="nav-item">
					<NotificationsLink />
				</li>
				<li className="nav-item">
					<Link to="/recipient/incentives" className="nav-link">Incentives</Link>
				</li>
				<li className="nav-item">
					<Link to="/recipient/ebtcards" className="nav-link">EBT Cards</Link>
				</li>

				<li className="nav-item dropdown">
					<a className="nav-link dropdown-toggle" id="accountMenu" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Account</a>
					<div className="dropdown-menu">
						<Link to="/recipient/account" className="dropdown-item">Account</Link>
						<div className="dropdown-divider"></div>
						<Link to="/logout" className="dropdown-item">Logout</Link>
					</div>
				</li>
			</ul>
			<Link to="/recipient/account" className="nav-link btn-primary"><DisplayName user={user}/></Link>
		</div>
	</>
    )
}
