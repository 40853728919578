import React, { useState, useEffect } from 'react';
import Strings from '../common/Strings';
import { showPromptDialog } from './PromptDialog';
import { patternMessage } from '../common/Forms';
import {showAlertDialog} from './AlertDialog';
import { showConfirmDialog, closeConfirmDialog } from './ConfirmDialog';

export default function FundingSource(props) {

    let [selectKey, setSelectKey] = useState(-1);

	let removeItem = () => {
		let selectBox = document.getElementById('fundingSources');
		if (selectBox.selectedIndex > -1) {
			let myConfirm = () => {
				let cloned = JSON.parse(JSON.stringify(props.fundingSources));
				cloned.splice(selectBox.selectedIndex, 1);
				props.parentCallback(cloned);
				closeConfirmDialog();
			};
			props.setPromptSettings({
				body:'Remove funding source ' + props.fundingSources[selectBox.selectedIndex].name + '?',
				type:'danger',
				confirm:myConfirm
			});
			showConfirmDialog();
		}
	};

	let sortFunction = (a, b) => {
		if(a.name.toUpperCase() < b.name.toUpperCase()) {
			return -1;
		} else if(a.name.toUpperCase() > b.name.toUpperCase()){
			return 1;
		} else {
			return 0;
		}
	}

	let nameExists = (n) => {
		for(var i=0; i<props.fundingSources.length; i++) {
			if (props.fundingSources[i].name.toUpperCase() == n.toUpperCase())
				return true;
		}
		return false;
	};

	let addItem = () => {
		props.setPromptSettings({button:'Add',title:'Add Funding Source',handlePrompt:(v)=>{
			if (nameExists(v)) {
				props.setPromptSettings({body:Strings.validation.fundingSourceDupe});
				showAlertDialog();
			} else {
				let cloned = JSON.parse(JSON.stringify(props.fundingSources));
				let newKey = selectKey - 1;
				cloned.push({fundingId:newKey,name:v.trim()});
				setSelectKey(newKey);
				cloned.sort(sortFunction);
				props.parentCallback(cloned);
			}
		}});
		showPromptDialog();
	};

	let editItem = () => {
		let selectBox = document.getElementById('fundingSources');
		if (selectBox.selectedIndex > -1) {
			props.setPromptSettings({button:'Edit',title:'Edit Funding Source',
			defaultValue:props.fundingSources[selectBox.selectedIndex].name,
			handlePrompt:(v)=>{
				let cloned = JSON.parse(JSON.stringify(props.fundingSources));
				cloned[selectBox.selectedIndex].name = v.trim();
				cloned.sort(sortFunction);
				props.parentCallback(cloned);
			}});
			showPromptDialog();
		}
	};

	let selectOptions = (optionsArray) => {
		let options = optionsArray.map( option =>
			<option key={option.fundingId} value={option.fundingId}>{option.name}</option>
		);
		return options;
	};

	let myStyle = {marginRight:'2px'};

    return (
    <>

	<div className="row">
		<div className="form-group required col-12 col-md-6">
            <label htmlFor="fundingSources">Funding Sources</label>
			<select id="fundingSources" className="form-control custom-select" size={4}>
				{ selectOptions( props.fundingSources ) }
			</select>
		</div>

		<div className="form-group col-12 col-md-6">
            <label >Modify Funding Sources</label><br/>
			<button type='button' className='btn btn-primary btn-sm' style={myStyle} onClick={addItem}>Add New</button>
			<button type='button' className='btn btn-primary btn-sm' style={myStyle} onClick={editItem}>Edit Selected</button>
			<button type='button' className='btn btn-primary btn-sm' style={myStyle} onClick={removeItem}>Remove Selected</button>
			<small className="form-text text-muted">Note: Funding changes are not saved until the Program is saved.</small>
		</div>

	</div>

    </>
    )
}
