import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <h2>
             Page not found.   
            </h2>
        )
    }
}
