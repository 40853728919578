import React, { useState, useEffect } from 'react';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import { StateArray, ProgramStatusArray } from '../common/Constants';
import Strings from '../common/Strings';
import { apiPost, apiGet, getAdminApiUrl, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import FundingSource from '../common/FundingSource';
import { cleanPhone } from '../common/Formats';

import ConfirmDialog from '../common/ConfirmDialog';
import PromptDialog from '../common/PromptDialog';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';


import DatePicker from 'react-date-picker';

import 'react-day-picker/lib/style.css';
import moment from 'moment';

export default function AddProgram() {

	let defaultConfig = '{"iin":"641997","acctNbrLen":9,"issuerProgramKey":"","tweak":"0000000000000000","distrib":3000573,"offset":59845460,"cvvKeyA":"","cvvKeyB":"","expirationDate":"2037-12-31 23:59:59","svcCodeVirtual":"721","svcCodePrint":"701","svcCodeManual":"000"}'.replaceAll(',',',\n');

	let newProgram = {
		programStatusId:0,
		programName:'',
		shortName:'',
		contactPhone:'',
		contactEmail:'',
		startDate:'',
		endDate:'',
		contactStateCode:'',
		contactAddress1:'',
		contactAddress2:'',
		contactCity:'',
		contactZip:'',
		configuration:defaultConfig,
		fundingSources:[],
		benefitExpiration:'',
		incentiveExpiration:'',
		fundingAmount:'',
		fundingDate:'',
		lastIssuanceDate:'',
		enableBenefitNote:false,
		benefitOptionTypeId:''
	};

	let startAndEndDates = {
		startDate: '',
		endDate: ''
	}

	let [program, setProgram] = useState(newProgram);
	let [dates, setDates] = useState(startAndEndDates);
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [defaultFundingSources, setDefaultFundingSources] = useState([]);
	let [confirmSettings, setConfirmSettings] = useState({});
	let [benefitOptionTypes, setBenefitOptionTypes] = useState([]);

	let handleSubmit = (e) => {
		e.preventDefault();
		if (program.fundingSources.length < 1) {
			setConfirmSettings({body:Strings.validation.fundingSource});
			showAlertDialog();
			return;
		}

		let url = getAdminApiUrl() + '/programs';
		let body = {...program};
		body.contactPhone = cleanPhone(program.contactPhone);
		body.fundingSources = program.fundingSources.map( x => {
			return {functionId:x.fundingId > -1 ? x.fundingId : null, name:x.name}
		});

		setSpinner(true);
		apiPost(url, body).then((resp) => {
			//console.debug(resp);
			setMessage({text: 'Program added.', type:'success'});
			newProgram.fundingSources = defaultFundingSources;
			setProgram(newProgram);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));

	};

	let getDefaultFundingSources = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/default-funding';

		apiGet(url).then((resp) => {
			let key = -1;
			program.fundingSources = resp.map( x => {return {fundingId:key--, name:x.name}});
			setProgram(program);
			setDefaultFundingSources(program.fundingSources);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getBenefitOptionTypes = () => {
		let url = getAdminApiUrl() + '/benefit-option-types';
		apiGet(url).then( (response) => {
			console.debug(response);
			let types = [];
			types.push({id:'',text:''});
			response.forEach( (item) => {
				types.push({id:item.optionTypeId, text: item.name + ' - ' + item.descr});
			});
			setBenefitOptionTypes(types);
		}).catch( (error) => {
			console.error(error);
		});
	};

	useEffect( () => {
		patternMessage("#contactZip", Strings.validation.zipFormat);
		patternMessage("#contactPhone", Strings.validation.phoneFormat);
		patternMessage("#contactEmail", Strings.validation.emailFormat);
		patternMessage("#benefitExpiration", Strings.validation.expirationDays);
		patternMessage("#incentiveExpiration", Strings.validation.expirationDays);
		getDefaultFundingSources();
		getBenefitOptionTypes();
	}, []);
	useEffect( () => {
		invalidInputStyle();
	});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...program, [id]:value};
		setProgram(cloned);
	}
	let handleCheckboxChange = (event) => {
		const {id, checked} = event.target;
		let cloned = {...program, [id]:checked};
		setProgram(cloned);
	}

	let handleDayChange = (inputName) => {
		return function onChange(newDate){
			let formattedDate = moment(newDate).format("YYYY-MM-DD")
			setProgram({...program, [inputName]:formattedDate});
			setDates({...dates, [inputName]:newDate});
		}
	}
	let handleDayChange2 = (inputName, val) => {
		let cloned = {...program, [inputName]:val};
		setProgram(cloned);
	}

	let returnFundingSources = (values) => {
		let cloned = {...program, fundingSources:values};
		setProgram(cloned);
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>New Program</h3>
		<MessageTag message={message} />

        <form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="programName">Name</label>
					<input type="text" id="programName" className="form-control" onChange={handleFieldChange} value={program.programName} maxLength="45" required/>
				</div>
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="shortName">Short Name</label>
					<input type="text" id="shortName" className="form-control" onChange={handleFieldChange} value={program.shortName} maxLength="20" required/>
				</div>
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="programStatusId">Status</label>
					<select id="programStatusId" className="form-control custom-select" value={program.programStatusId} onChange={handleFieldChange} required >
						{ selectOptions( ProgramStatusArray ) }
					</select>
				</div>
				<div className="form-group required col-12 col-md-6">
					<label className="asterisk-label" htmlFor="contactEmail">Contact Email</label>
					<input type="email" id="contactEmail" className="form-control" value={program.contactEmail} onChange={handleFieldChange} maxLength="200" required pattern={Strings.pattern.email}/>
				</div>
				<div className="form-group required col-12 col-md-6">
					<label className="asterisk-label" htmlFor="contactPhone">Contact Phone</label>
					<input type="text" id="contactPhone" className="form-control" value={program.contactPhone} onChange={handleFieldChange} maxLength="20" required pattern={Strings.pattern.phone}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="contactAddress1">Contact Address</label>
					<input type="text" id="contactAddress1" className="form-control" value={program.contactAddress1} onChange={handleFieldChange} maxLength="60" required />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="contactAddress2">Contact Address 2</label>
					<input type="text" id="contactAddress2" className="form-control" value={program.contactAddress2} onChange={handleFieldChange} maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="contactCity">Contact City</label>
					<input type="text" id="contactCity" className="form-control" value={program.contactCity} onChange={handleFieldChange} maxLength="60" required />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="contactStateCode">Contact State</label>
					<select id="contactStateCode" className="form-control custom-select" value={program.contactStateCode} onChange={handleFieldChange} required >
						{ selectOptions( StateArray ) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="contactZip">Contact Zip Code</label>
					<input type="text" id="contactZip" className="form-control" value={program.contactZip} onChange={handleFieldChange} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-md-3">
					<label className="asterisk-label" htmlFor="startDate">Start Date</label>
					<DatePicker value={dates.startDate} onChange={handleDayChange("startDate")} className={"form-control"} minDate={new Date()} required={true} id="startDate" name="startDate" format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group required col-12 col-md-3">
					<label className="asterisk-label" htmlFor="endDate">End Date</label>
					<DatePicker value={dates.endDate} onChange={handleDayChange("endDate")} className={"form-control"} minDate={new Date()} required={true} id="endDate" name="endDate" format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</div>

			<FundingSource fundingSources={program.fundingSources} parentCallback={returnFundingSources} setPromptSettings={setConfirmSettings} />

			<div className="row">
				<div className="form-group col-12 col-md-4">
					<label htmlFor="benefitExpiration">Benefit Expiration Days</label>
					<input type="number" id="benefitExpiration" className="form-control text-right" onChange={handleFieldChange} value={program.benefitExpiration} min="0" step="1" maxLength="4" pattern="[0-9]{0,4}"/>
					<small className="form-text text-muted">{Strings.help.expirationDays}</small>
				</div>
				<div className="form-group col-12 col-md-4">
					<label htmlFor="incentiveExpiration">Incentive Benefit Expiration Days</label>
					<input type="number" id="incentiveExpiration" className="form-control text-right" onChange={handleFieldChange} value={program.incentiveExpiration} min="0" step="1" maxLength="4" pattern="[0-9]{0,4}"/>
					<small className="form-text text-muted">{Strings.help.expirationDaysIncentive}</small>
				</div>
				<div className="form-group col-12 col-md-4">
					<label htmlFor="benefitAmount">Benefit Amount</label>
					<input type="number" id="benefitAmount" className="form-control text-right" onChange={handleFieldChange} value={program.benefitAmount} min="0.00" step="0.01" maxLength="6" />
					<small className="form-text text-muted">{Strings.help.benefitSetAmount}</small>
				</div>

				<div className="form-group col-12 col-md-4">
					<label htmlFor="fundingAmount">Funding Amount</label>
					<input type="number" id="fundingAmount" className="form-control text-right" onChange={handleFieldChange} value={program.fundingAmount} min="0.00" step="1.00" maxLength="8" max="99999999" pattern="[0-9\.]{0,8}"/>
				</div>
				<div className="form-group col-12 col-md-4">
					<label className="asterisk-label" htmlFor="fundingDate">Funding Date</label>
					<DatePicker value={program.fundingDate} onChange={(val) => handleDayChange2('fundingDate', val)} className={"form-control"} id="fundingDate" name="fundingDate" minDate={new Date(2023,0,1)} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>

				<div className="form-group col-12 col-md-4">
					<label className="asterisk-label" htmlFor="lastIssuanceDate">Last Issuance Date</label>
					<DatePicker value={program.lastIssuanceDate} onChange={(val) => handleDayChange2('lastIssuanceDate', val)} className={"form-control"} id="lastIssuanceDate" name="lastIssuanceDate" minDate={new Date(2023,0,1)} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
					<small className="form-text text-muted">{Strings.help.lastIssuance}</small>
				</div>
			</div>

			<div className="row">
				<div className="form-group col-12 col-md-5">
					<label htmlFor="benefitOptionTypeId">Enable Benefit Dropdown Option</label>
					<select id="benefitOptionTypeId" className="form-control custom-select" value={program.benefitOptionTypeId} onChange={handleFieldChange} >
						{ selectOptions( benefitOptionTypes ) }
					</select>
					<small className="form-text text-muted">{Strings.help.benefitOption}</small>
				</div>
				<div className="form-group col-12 col-sm-4">
					<label>Enable Benefit Note</label>
					<div className="form-check">
						<label htmlFor="enableBenefitNote" className='clickable'>
						<input className="form-check-input" type="checkbox" value="true" id="enableBenefitNote" checked={program.enableBenefitNote} onChange={handleCheckboxChange} />
						 Enabled</label>
					</div>
					<small className="form-text text-muted">{Strings.help.benefitNote}</small>
				</div>
			</div>

			<div className="row">
				<div className="form-group required col-12 col-md-8">
					<label htmlFor="configuration">Configuration</label>
					<small className="form-text text-muted">Leave items blank to accepts defaults.</small>
					<textarea id="configuration" className="form-control" onChange={handleFieldChange} value={program.configuration} maxLength="1000" rows={5} />
				</div>
			</div>

			<button className="btn btn-primary" id="btnAdd">Add</button>
		</form>

		<ConfirmDialog settings={confirmSettings} />

		<PromptDialog settings={confirmSettings} />

		<AlertDialog settings={confirmSettings} />

		</>
	)
}
