import React, { useState, useEffect } from 'react';

import { apiGet, getBenefitsApiUrl } from '../common/AppApi';

import Processing from '../common/Processing';
import Pagination, { resetPaging } from '../common/Pagination';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { PageSize, BenefitTypeArray } from '../common/Constants';
import { stripTime, formatCurrency, decodeValues } from '../common/Formats';

import { ExpiringIcon, ExpiredIcon } from '../common/AppIcons';

import DatePicker from 'react-date-picker';

import './Benefits.css';
import moment from 'moment';

export default function Benefits() {
	let emptyForm = {
		startDate:'',
		endDate:'',
		authNo:''
	};

	let maxDate = new Date();
	maxDate.setDate(new Date().getDate() + 183);

	let [showSpinner, setSpinner] = useState(false);
	let [searchForm, setSearchForm] = useState(emptyForm);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();

	let [balance, setBalance] = useState(0);

	let getBalances = () => {
		let url = getBenefitsApiUrl() + '/benefit/balance';
		apiGet(url).then( (response) => {
			console.debug(response);
			setBalance(response.totalAmount);
		}).catch( (error) => {
			console.error(error);
		});
	};

	useEffect( () => {
			getBalances();
		}, []
	);

	let handleSubmit = (e) => {
		e.preventDefault();
		console.info(searchForm);

		setSpinner(true);
		let url = getBenefitsApiUrl() + '/benefit';

		let queryParams = [];

		for(const searchField in searchForm){
			if(searchForm[searchField]){
				if(searchField == "startDate"){
					queryParams.push(searchField + '=' + encodeURIComponent(moment(searchForm.startDate).format("YYYY-MM-DD")));
				}
				else if(searchField == "endDate"){
					queryParams.push(searchField + '=' + encodeURIComponent(moment(searchForm.endDate).format("YYYY-MM-DD")));
				}
				else{
					queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
				}
			}
		}
		queryParams.push('pageSize=1000'); //TODO use backend paging
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		apiGet(url).then((resp)=> {
			console.debug(resp);
			setDataRows(resp.data);
			setPagingData(resetPaging());
			if(resp && resp.data && resp.data.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No benefits found.',type:'warning'});
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let expiryAlert = (expDate, days) => {
		let today = new Date();
		let expiringDate = new Date();
		expiringDate.setDate(today.getDate() + days);
		let expiredIcon = (today > expDate) ? (<ExpiredIcon />) : null;
		let expiringIcon = (!expiredIcon && expiringDate > expDate) ? (<ExpiringIcon />) : null;
		return (expiredIcon ? expiredIcon : (expiringIcon ? expiringIcon : null));
	};

	let decodeBenefitType = () => {};

	let TableRow = (props) => {
		let alerts = expiryAlert(new Date(stripTime(props.row.benefitExpiryDate)), 14);

		return (
			<tr>
			<td>{stripTime(props.row.benefitAvailableDate)}</td>
 			<td>{stripTime(props.row.benefitExpiryDate)} {alerts}</td>
			<td>{props.row.programName}</td>
			<td>{decodeValues(props.row.benefitTypeId, BenefitTypeArray)}</td>
			<td>{formatCurrency(props.row.benefitAmount)}</td>
			<td>{formatCurrency(props.row.benefitBalance)}</td>
			<td>{props.row.benefitAuthNumber}</td>
			</tr>
		);
	};
	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={data.benefitId} row={data} /> : null;
	});
	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<div className="pull-right">
					<ExpiringIcon /> = Expiring soon, <ExpiredIcon /> = Expired
				</div>
				<table className="table" id="tblResults">
					<thead>
						<tr>
							<th>Available Date</th>
							<th>Expiration Date</th>
							<th>Program</th>
							<th>Type</th>
							<th>Issued Amt</th>
							<th>Balance</th>
							<th>Authorization Number</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return null;
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	
	let handleDayChange = (inputName) => {
		return function onChange(newDate){
			setSearchForm({...searchForm, [inputName]:newDate});
		}
	}

    return (
        <>
        <Processing show={showSpinner}/>
		<MessageTag message={message} />

		<h3>Benefits</h3>
		<div className="row">
		<div className="col-12 col-sm-2">Balance: {formatCurrency(balance)}</div>
			{/*<div className="col-12 col-sm-3"><span className="text-warning"><ExpiringIcon /> Expiring soon: $00.00</span></div>*/}
		</div>
		<br/>
        <form onSubmit={e => handleSubmit(e)}>
			<div className="row">
			<div className="form-group col-12 col-sm-3">
					<label htmlFor="startDate">Start Date</label>
					<DatePicker value={searchForm.startDate} onChange={handleDayChange("startDate")} className={"form-control"} minDate={new Date(2020,7,1)} maxDate={maxDate} />
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="endDate">End Date</label>
					<DatePicker value={searchForm.endDate} onChange={handleDayChange("endDate")} className={"form-control"} minDate={new Date(2020,7,1)} maxDate={maxDate} />
				</div>
				{/*<div className="form-group col-12 col-sm-3">
					<label htmlFor="program">Program</label>
					<select id="program" className="form-control custom-select">
						<option>SFMNP</option>
					</select>
				</div>*/}
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="authNo">Auth No</label>
					<input type="text" id="authNo" className="form-control" onChange={handleFieldChange} value={searchForm.authNo} maxLength="20" />
				</div>
			</div>
			<button className="btn btn-primary" onClick={handleSubmit} id="btnSearch">Search</button>
		</form>

		<Table />

        </>
    )
}
