import React, { useState, useEffect } from 'react';

import AdminEBTCards from '../program/EBTCards';

export default function EBTCards() {

	return (
		<AdminEBTCards admin={true} />
	)
}
