import React, { useState, useEffect } from 'react';

import AdminAddMerchant from "../program/AddMerchant";

export default function AddMerchant() {

	return (
		<>
		<AdminAddMerchant admin={true} />
		</>
	)
}
