import React, {useState, useEffect} from 'react';
import useUserContext from '../common/UserContext';
import { apiGet, getAccountsApiUrl } from '../common/AppApi';

export default function Home() {
	let {user} = useUserContext();
	let [program, setProgram] = useState(null);

	useEffect(() => {
			let url = getAccountsApiUrl() + '/account/programs';
			apiGet(url).then((resp)=> {
				if (resp && resp.length && resp.length > 0)
					setProgram(resp[0]);
			}).catch( (err) => {
				console.error(err);
			});
	}, []);
	
	return (
		<>
			<p>
			Welcome to the TIMS Adminstrative Portal for {program ? program.programName : 'your program'}.
			</p>
			<p>
			This portal enables the management of electronic benefits issued by the program's Administrative Agency and provides access to and reporting on current program data.
			</p>

			<br/>
			<br/>
			<br/>

			<p>
			Any questions about the portal should be directed to Novo Dia Group at <a href="mailto:support@novodiagroup.com">support@novodiagroup.com</a>.
			</p>
		</>
	)
}
