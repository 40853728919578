import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getAdminApiUrl } from '../common/AppApi';
import Strings from '../common/Strings';
import { stripTime } from '../common/Formats';

import { selectOptions } from '../common/Forms';
import { StateArray, PageSize, ProgramStatusArray, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { HelpTooltip } from '../common/AppIcons';

export default function Programs() {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();

	let emptyForm = {
		programName:'',
		shortName:'',
		programId:'',
		contactEmail:'',
		contactStateCode:'',
		programStatusId:''
	};
	let [searchForm, setSearchForm] = useState(emptyForm);

	const history = useHistory();
	let GoTo = (id) => history.push('/admin/program/' + id);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let handleSubmit = (e) => {
		e.preventDefault();
		//console.debug(searchForm);

		setSpinner(true);
		let url = getAdminApiUrl() + '/programs';

		let queryParams = [];
		for(const searchField in searchForm) {
			if (searchForm[searchField])
				queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
		}

		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		apiGet(url).then((resp)=> {
			//console.debug(resp);
			setDataRows(resp);
			setPagingData(resetPaging());
			if(resp && resp.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No programs found.',type:'warning'});
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let decodeStatusValue = (id) => {
		let item = ProgramStatusArray.find(element => element.id == id);
		return (item ? item.text : null);
	};

	let TableRow = (props) => {
		return (
			<tr onClick={()=>GoTo(props.row.programUuid)} className="clickable">
			<td>{props.row.programId}</td>
			<td>{props.row.programName}</td>
			<td>{props.row.shortName}</td>
			<td>{props.row.contactEmail}</td>
			<td>{decodeStatusValue(props.row.programStatusId)}</td>
			<td>{stripTime(props.row.startDate)}</td>
			<td>{stripTime(props.row.endDate)}</td>
			<td>{props.row.contactStateCode}</td>
			</tr>
		);
	};
	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={data.programId} row={data} /> : null;
	});
	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Program ID</th>
							<th>Program Name</th>
							<th>Short Name</th>
							<th>Contact Email</th>
							<th>Status</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>State</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return null;
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Programs 
			<Link to="/admin/program/add" className="btn btn-primary btn-sm pull-right">New Program</Link>
		</h3>
		<MessageTag message={message} />

		<form onSubmit={e => handleSubmit(e)}>
			<div className="row">
			<div className="form-group col-12 col-sm-6 col-lg-2">
				<label htmlFor="programId">Program ID <HelpTooltip title="Exact database ID"/></label>
				<input type="text" id="programId" className="form-control" onChange={handleFieldChange} value={searchForm.programId} maxLength="12" />
			</div>
			<div className="form-group col-12 col-sm-6 col-lg-2">
					<label htmlFor="programName">Program Name</label>
					<input type="text" id="programName" className="form-control" onChange={handleFieldChange} value={searchForm.programName} maxLength="45" />
				</div>
				<div className="form-group col-12 col-sm-6 col-lg-2">
					<label htmlFor="shortName">Short Name</label>
					<input type="text" id="shortName" className="form-control" onChange={handleFieldChange} value={searchForm.shortName} maxLength="20" />
				</div>
				<div className="form-group col-12 col-sm-6 col-lg-2">
					<label htmlFor="contactEmail">Contact Email</label>
					<input type="text" id="contactEmail" className="form-control" onChange={handleFieldChange} value={searchForm.contactEmail} maxLength="200" />
				</div>
				<div className="form-group col-12 col-sm-6 col-lg-2">
					<label htmlFor="contactStateCode">Contact State</label>
					<select id="contactStateCode" className="form-control custom-select" value={searchForm.contactStateCode} onChange={handleFieldChange} >
						{ selectOptions( StateArray ) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-6 col-lg-2">
					<label htmlFor="programStatusId">Status</label>
					<select id="programStatusId" className="form-control custom-select" value={searchForm.programStatusId} onChange={handleFieldChange} >
						{ selectOptions( SelectOneArray.concat(ProgramStatusArray) ) }
					</select>
				</div>
			</div>
			<button className="btn btn-primary" id="btnSearch">Search</button>
		</form>

		<br/>
		<Table />

		</>
	)
}
