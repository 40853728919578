import React, { useState, useEffect } from 'react';

import { apiPost, apiGet, getAccountsApiUrl, getAdminApiUrl, apiMessages } from '../common/AppApi';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { StateArray, PrivilegeCodes } from '../common/Constants';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import { cleanPhone } from '../common/Formats';
import Strings from  '../common/Strings';
import useUserContext from '../common/UserContext';

export default function AddRecipient(props) {
    let {user} = useUserContext();

	let blankRecipient = {
		recipientAccountIdentifier:'',
		firstname:'',
		lastname:'',
		middlename:'',
		address1:'',
		address2:'',
		city:'',
		stateCode:'',
		zipcode:'',
		email:'',
		mobilePhone:'',
		cardIssuanceCode:false,
		businessPhone:'',
		faxNumber:'',
		creationChannel:'API',
		programUuid:'',
		agencyUuid:''
	};
	
	let [recipient, setRecipient] = useState(blankRecipient);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [programs, setPrograms] = useState([]);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...recipient, [id]:value};
		setRecipient(cloned);
	}
	let handleCheckboxChange = (event) => {
		const {id, value, checked} = event.target;
		setRecipient({...recipient, [id]:checked});
	};

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		if (props.admin) {
			url = getAdminApiUrl() + '/programs?programStatusId=0';
		}

		setSpinner(true);
		apiGet(url).then((resp) => {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid,text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let url = getAccountsApiUrl() + '/agency';
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let agencyRequired = () => {
		return user.privileges.includes(PrivilegeCodes.agencyAdminAccess) || user.privileges.includes(PrivilegeCodes.agencyUserAccess);
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getAccountsApiUrl() + '/account';

		let body = {...recipient};
		body.recipientAccountIdentifier = ''; // will be auto generated
		setRecipient(body);

		// remove non numeric characters
		body.mobilePhone = cleanPhone(body.mobilePhone);
		body.recipientProgramIdentifier = recipient.programUuid;

		apiPost(url, body).then((resp) => {
			//console.debug(resp);
			setMessage({text:'Recipient added with Account Id 0.'.replace('0',resp.recipientIdentifier),type:'success'});
			blankRecipient.recipientAccountIdentifier = resp.recipientIdentifier;
			setRecipient(blankRecipient);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400) {
				messageObject.text = apiMessages(err);
			} else if(err.status == 409) {
				if(err.data.errorMessages && err.data.errorMessages.length && err.data.errorMessages[0].dataField == 'email') {
					messageObject.text = 'Email already exists.';
				} else {
					messageObject.text = apiMessages(err);
				}
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		patternMessage("#zipcode", Strings.validation.zipFormat);
		patternMessage("#mobilePhone", Strings.validation.phoneFormat);
		patternMessage("#email", Strings.validation.emailFormat);
		getPrograms();
		getAgencies();
	}, []);
	useEffect( () => {
		invalidInputStyle();
	});

	return (
		<>
		<Processing show={showSpinner} />

		<h3>Add Recipient</h3>
        <MessageTag message={message} />
		
        <form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={recipient.programUuid} onChange={handleFieldChange} required>
						{ selectOptions(programs) }
					</select>
				</div>
				<div className={(agencyRequired() ? "required " : "") + "form-group col-12 col-sm-6"}>
					<label className="asterisk-label" htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={recipient.agencyUuid} onChange={handleFieldChange} required={agencyRequired()}>
						{ selectOptions(agencies) }
					</select>
				</div>
					<input type="hidden" id="recipientAccountIdentifier" onChange={handleFieldChange} value={recipient.recipientAccountIdentifier} />
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" onChange={handleFieldChange} value={recipient.firstname} required maxLength="100" />
				</div>
				<div className="form-group col-12 col-sm-2">
					<label htmlFor="middlename">Middle Initial</label>
					<input type="text" id="middlename" className="form-control" onChange={handleFieldChange} value={recipient.middlename} maxLength="1" />
				</div>
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" onChange={handleFieldChange} value={recipient.lastname} required maxLength="100" />
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="address1">Address One</label>
					<input type="text" id="address1" className="form-control" onChange={handleFieldChange} value={recipient.address1} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="address2">Address Two</label>
					<input type="text" id="address2" className="form-control" onChange={handleFieldChange} value={recipient.address2} maxLength="60"/>
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleFieldChange} value={recipient.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control custom-select" value={recipient.stateCode} onChange={handleFieldChange} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleFieldChange} value={recipient.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="email">Email</label>
					<input type="email" id="email" className="form-control" onChange={handleFieldChange} value={recipient.email} required maxLength="255" pattern={Strings.pattern.email}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="mobilePhone">Mobile Phone</label>
					<input type="text" id="mobilePhone" className="form-control" onChange={handleFieldChange} value={recipient.mobilePhone} required maxLength="20" pattern={Strings.pattern.phone} />
				</div>
			</div>
			<div className="row">
			<div className="form-group col-12 col-sm-6">
				<label htmlFor="cardIssuanceCode">Card Issuance</label>
				<div className="form-check">
					<label className='form-check-label' htmlFor="cardIssuanceCode">
					<input className="form-check-input" type="checkbox" value="true" id="cardIssuanceCode" checked={recipient.cardIssuanceCode} onChange={handleCheckboxChange} />
						Yes
					</label>
				</div>
			</div>
			</div>
			<button className="btn btn-primary" id="btnAdd">Add</button>
		</form>

		</>
	)
}
