import React from 'react';

import MerchantLocatorCommon from '../recipient/MerchantLocator';

export default function MerchantLocator() {
	return (
		<>
		<MerchantLocatorCommon />
		</>
	)
}