import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import { apiPut, apiMessages, getAccountsApiUrl, apiGet } from '../common/AppApi';
import Strings from '../common/Strings';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { invalidInputStyle } from '../common/Forms';
import DatePicker from 'react-date-picker';
import moment from 'moment';

import IncentivePrograms from './IncentivePrograms';
import FundingSources from './FundingSources';

export default function Incentive() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let blankForm = {
		name:'',
		startDate:'',
		endDate:null,
		matchRate:'0.00',
		accrualMaxDay:'0.00',
		accrualMaxMonth:'0.00',
		accrualMaxTrx:'0.00',
		accrualFeeRate:'0.00',
		redemptionFeeRate:'0.00',
		programId:'',
		fundingId:''
	};
	let [incentive, setIncentive] = useState(blankForm);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...incentive, [id]:value};
		setIncentive(cloned);
	}

	let handleUpdateDayChange = (inputName) => {
		return function onChange(newDate){
			setIncentive({...incentive, [inputName]:newDate});
		}
	}

	let handleOnBlur = (event) => {
		const {id, value} = event.target;
		let cloned = {...incentive, [id]:Number.parseFloat(value).toFixed(2)};
		if (!value) {
			cloned[id] = '0.00';
		}
		setIncentive(cloned);
	};

	let handleSelectChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...incentive, [id]:value};
		getFundingSources(value);
		setIncentive(cloned);
	}

	let defaultSources = [{fundingId:'',name:'Funding...'}];
	let [fundingSources, setFundingSources] = useState(defaultSources);
	let getFundingSources = (id) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive/programs/' + id + '/funding';

		apiGet(url).then((resp)=> {
			let sources = defaultSources.concat(resp);
			console.debug(sources);
			setFundingSources(sources);
		}).catch( (err)=> {
			setFundingSources(defaultSources);
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if (err.status == 404) {
				messageObject.text = 'No funding sources defined for the program.';
			} else if(err.privilegeError) {
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let { id } = useParams();

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let payload = {...incentive};
		payload.startDate = moment(incentive.startDate).format('YYYY-MM-DD');
		if (payload.endDate)
			payload.endDate = moment(incentive.endDate).format('YYYY-MM-DD');;

		let url = getAccountsApiUrl() + '/incentive/' + id;
		apiPut(url, payload).then((resp) => {
			setMessage({text: 'Incentive updated.', type:'success'});
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let loadIncentive = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive/' + id;
		apiGet(url).then((resp) => {

			let cloned = {...incentive};
			cloned.name = resp.name;
			cloned.startDate = moment(resp.startDate).toDate();
			if (resp.endDate)
				cloned.endDate = moment(resp.endDate).toDate();
			cloned.matchRate = Number.parseFloat(resp.matchRate).toFixed(2);
			cloned.accrualMaxDay = Number.parseFloat(resp.accrualMaxDay).toFixed(2);
			cloned.accrualMaxMonth = Number.parseFloat(resp.accrualMaxMonth).toFixed(2);
			cloned.accrualMaxTrx = Number.parseFloat(resp.accrualMaxTrx).toFixed(2);
			cloned.accrualFeeRate = Number.parseFloat(resp.accrualFeeRate).toFixed(2);
			cloned.redemptionFeeRate = Number.parseFloat(resp.redemptionFeeRate).toFixed(2);
			cloned.programId = resp.program.programId;
			cloned.fundingId = resp.funding.fundingId;
			setIncentive(cloned);

			getFundingSources(cloned.programId);

		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		loadIncentive();
	}, []);
	useEffect( () => {
		invalidInputStyle();
	});

	return (
		<>
			<Processing show={showSpinner}/>
			<h3>Incentive</h3>
			<MessageTag message={message} />

			<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group required col-12 col-md-5">
					<label className="asterisk-label" htmlFor="name">Name</label>
					<input type="text" id="name" className="form-control" onChange={handleFieldChange} value={incentive.name} required maxLength="100" />
				</div>
				<div className="form-group required col-12 col-md-3">
					<label className="asterisk-label" htmlFor="startDate">Start Date</label>
					<DatePicker id="startDate" name="startDate" onChange={handleUpdateDayChange("startDate")} value={incentive.startDate} className="form-control" minDate={new Date(2023,1,1)} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="endDate">End Date</label>
					<DatePicker id="endDate" name="endDate" onChange={handleUpdateDayChange("endDate")} value={incentive.endDate} className="form-control" minDate={new Date()} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="matchRate">Match Rate %</label>
					<input type="number" id="matchRate" className="form-control" onChange={handleFieldChange} onBlur={handleOnBlur} value={incentive.matchRate} maxLength="8" step='.1' min="0.0"/>
					<small className="form-text text-muted">Example: 5.25% = 5.25</small>
				</div>
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="accrualMaxDay">$ Accrual Max / Day</label>
					<input type="number" id="accrualMaxDay" className="form-control" onChange={handleFieldChange} onBlur={handleOnBlur} value={incentive.accrualMaxDay} maxLength="8" step='.1' min="0.00"/>
				</div>
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="accrualMaxMonth">$ Accrual Max / Month</label>
					<input type="number" id="accrualMaxMonth" className="form-control" onChange={handleFieldChange} onBlur={handleOnBlur} value={incentive.accrualMaxMonth} maxLength="8" step='.1' min="0.00"/>
				</div>
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="accrualMaxTrx">$ Accrual Max / Trx</label>
					<input type="number" id="accrualMaxTrx" className="form-control" onChange={handleFieldChange} onBlur={handleOnBlur} value={incentive.accrualMaxTrx} maxLength="8" step='.1' min="0.00"/>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="accrualFeeRate">Accrual Fee Rate %</label>
					<input type="number" id="accrualFeeRate" className="form-control" onChange={handleFieldChange} onBlur={handleOnBlur} value={incentive.accrualFeeRate} maxLength="8" step='.1' min="0.0"/>
				</div>
				<div className="form-group col-12 col-md-3">
					<label className="asterisk-label" htmlFor="redemptionFeeRate">Redemption Fee Rate %</label>
					<input type="number" id="redemptionFeeRate" className="form-control" onChange={handleFieldChange} onBlur={handleOnBlur} value={incentive.redemptionFeeRate} maxLength="8" step='.1' min="0.0"/>
				</div>
				<div className="form-group col-12 col-md-8">
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-md-6">
					<label className="asterisk-label" htmlFor="programId">Attach to Program</label>
					<IncentivePrograms programId={incentive.programId} handleSelectChange={handleSelectChange} />
				</div>
				<div className="form-group required col-12 col-md-6">
					<label className="asterisk-label" htmlFor="fundingId">Funding Source</label>
					<FundingSources value={incentive.fundingId} handleSelectChange={handleFieldChange} fundingSources={fundingSources} />
				</div>
			</div>
			<button className="btn btn-primary" id="updIncentive">Update</button>
		</form>

		</>
	)
}
