import React, {useState, useEffect} from 'react';

import {apiGet, getAdminApiUrl} from '../common/AppApi';

import Strings from '../common/Strings';

import MerchantSearch from '../program/MerchantSearch';

export default function Merchants() {
    let [showSpinner, setSpinner] = useState(false);
    let [message, setMessage] = useState();
	let [programs, setPrograms] = useState([]);

	let getPrograms = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs';

		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programId,text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getPrograms();
	}, []);

    return (
        <>
        <MerchantSearch programs={programs} basePath="/admin"/>
        </>
    )
}
