import React from 'react';

import {Route, Switch} from 'react-router-dom';

import ProgramHeader from './program/Header';
import RecipientHeader from './recipient/Header';
import AdminHeader from './admin/Header';

import Strings from './common/Strings';


function DefaultHeader(){
    return (
    <a className="navbar-brand" href="/">{ Strings.site.title }</a>
    );
}

export default function Header() {
  return (
    <header className="container">
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <Switch>
        <Route path="/recipient" component={RecipientHeader} />
        <Route path="/program" component={ProgramHeader} />
        <Route path="/admin" component={AdminHeader} />
        <Route path="/" component={DefaultHeader} />
      </Switch>
    </nav>
    </header>
  );
}
