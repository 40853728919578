import React, { useState, useEffect } from 'react';

import { apiGet, getAccountsApiUrl } from '../common/AppApi';
import Strings from '../common/Strings';

import { PageSize } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import moment from 'moment';

export default function RecipientIncentives(props) {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();

	let getRecipientIncentives = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive/recipient-incentive/currentrecipient';

		apiGet(url).then((resp)=> {
			setDataRows(resp);
			setPagingData(resetPaging());
			if(resp && resp.length){
				// no message
			}
			else{
				setMessage({text:Strings.warning.noIncentives, type:'light'});
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let TableRow = (props) => {
		let formattedDt = moment(props.row.updated).utc().format("YYYY-MM-DD HH:mm:ss");
		return (
			<tr>
			<td>{props.row.incentiveName}</td>
			<td>{props.row.startDate}</td>
			<td>{props.row.endDate}</td>
			<td>{formattedDt}</td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={index} row={data} /> : null;
	});
	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table table-hover" id="tblIncentives">
					<thead>
						<tr>
							<th>Name</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Updated</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return null;
	};

	useEffect( () => {
		getRecipientIncentives();
	}, []);


	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Incentives </h3>
		<MessageTag message={message} />

		<br/>
		<Table />

		</>
	)
}
