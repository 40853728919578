import React, { useState, useEffect } from 'react';
import useUserContext from '../common/UserContext';

import { apiPut, apiGet, getAccountsApiUrl, apiMessages } from '../common/AppApi';

import { cleanPhone, formatPhone } from '../common/Formats';
import Strings from  '../common/Strings';

import AccountPassword from '../common/AccountPassword';
import AccountSecurityQuestion from '../common/AccountSecurityQuestion';

export default function Account() {
    let {user, setUser} = useUserContext();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let blankRecipient = {
		accountUuid: '',
		recipientIdentifier: '',
		email: '',
		firstname: '',
		lastname: '',
		middlename: '',
		mobilePhone: '',
		cardIssuanceCode: false,
		address1: '',
		address2: '',
		city: '',
		stateCode: '',
		zipcode: ''
	};
	
	let [recipient, setRecipient] = useState(blankRecipient);

	let notNull = (value) => value ? value : '';

	let getRecipient = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/account/currentuser';

		apiGet(url).then((resp)=> {
			console.debug(resp);

			let cloned = {...resp};
			cloned.middlename = notNull(resp.middlename).trim();
			cloned.address2 = notNull(resp.address2).trim();
			cloned.mobilePhone = formatPhone(resp.mobilePhone);

			setRecipient(cloned);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( ()=> {
		getRecipient();
	}, []);

    return (
        <>

        <h4>Account Info</h4>

		<table className="table table-bordered">
			<tbody>
				<tr>
					<th>Account Identifier</th>
					<th>Name</th>
					<th>Email</th>
				</tr>
				<tr>
					<td>{recipient.recipientIdentifier}</td>
					<td>{recipient.firstname} {recipient.middlename} {recipient.lastname}</td>
					<td>{recipient.email}</td>
				</tr>
				<tr>
                    <th>Phone</th>
					<th>Card Issuance</th>
					<th>Username</th>
				</tr>
				<tr>
                    <td>{recipient.mobilePhone}</td>
					<td>{recipient.cardIssuanceCode ? 'Yes' : 'No'}</td>
					<td>{user.username}</td>
				</tr>
				<tr>
                    <th colSpan="3">Address</th>
				</tr>
				<tr>
                    <td colSpan="3">{recipient.address1}<br/>{recipient.address2}<br/>
                    {recipient.city} {recipient.stateCode} {recipient.zipcode}</td>
				</tr>
			</tbody>
		</table>

        <hr/>
        <AccountPassword />
        <hr/>
        <AccountSecurityQuestion />

        </>
        )
}
