import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";

import { apiGet, apiPut, getUserApiUrl, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { formatPhone, stripTime } from '../common/Formats';
import Strings from '../common/Strings';

import { UserStatus } from '../common/Constants';

import UserUpdate from './UserUpdate';
import './User.css';

export default function User() {
	let userForm = {
		email:'',
		emailVerified:'',
		firstname:'',
		middlename:'',
		lastname:'',
		mobile:'',
		phone:'',
		userStatusId:'',
		username:'',
		programs:[],
		agencies:[]
	};

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [user, setUser] = useState(userForm);

	let { id } = useParams();

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...user, [id]:value};
		setUser(cloned);
	}

	let getUser = (id) => {
		setSpinner(true);

		let url = getUserApiUrl() + '/user/' + id;
		apiGet(url).then((resp)=> {
			//console.debug(resp);
			let user = {...resp};
			user.phone = formatPhone(user.phone);
			user.mobile = formatPhone(user.mobile);
			user.creationDate = stripTime(user.creationDate);
			if(!user.programs)
				user.programs = [];
			if (!resp.agencies)
				user.agencies = resp.agencies;
			setUser(user);

			getUserRoles(id);
		}).catch( (err)=> {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
		});
	};

	let getUserRoles = (id) => {
		setSpinner(true);
		let url = getUserApiUrl() + '/user/' + id + '/roles';
		apiGet(url).then((resp) => {
			//console.debug(resp);
			getRoles(resp);
		}).catch( (err)=> {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
		});
	};

	let [roles, setRoles] = useState([]);
	let getRoles = (userRoles) => {
		let url = getUserApiUrl() + '/roles';
		apiGet(url).then( (response) => {
			//console.debug(response);
			response.map( (r) => {
				r.checked = false;
				userRoles.forEach( (ur) => {
					if (r.id == ur)
						r.checked = true;
				});
				return r;
			});
			//console.debug(response);
			setRoles(response);
		}).catch( (error) => {
			console.error(error);
		});
	};

	const history = useHistory();
	let GoTo = (uuid) => history.push('/admin/program/' + uuid);
	let UserProgram = (props) => <><Link to="/admin/users" onClick={(e) => {e.preventDefault();GoTo(props.row.programUuid);}} >{props.row.programName}</Link><br/></>;
	let UserPrograms = user.programs.map( (data, index) => {
		return <UserProgram key={data.programUuid} row={data} />;
	});

	useEffect( () => {
		console.debug(id);
		getUser(id);
	},[]);

	let handleCheckbox = (event) => {
		const { id, value, checked } = event.target;
		let newRoles = [];
		roles.forEach( (item) => {
			let cloned = {...item};
			newRoles.push(cloned);
		});
		newRoles.map( (r) => {
			if (r.id == id) {
				r.checked = checked;
			} else {
				r.checked = null;
			}
			return r;
		});
		setRoles(newRoles);
	};

	let UserRoles = roles.map( (data, index) => {
		return <div className="roleName" key={index}><label className='form-check-label'><input type="radio" name="roleRadio" id={data.id} value={data.id} checked={data.checked} onChange={handleCheckbox}/> {data.text}</label></div>;
	});

	let updateUserRoles = () => {
		setSpinner(true);

		let url = getUserApiUrl() + "/user/" + id + "/roles";
		let updatedRoleIds = [];
		roles.forEach( (item) => {
			if(item.checked)
				updatedRoleIds.push(item.id);
		});
		
		let body = {roleIds:updatedRoleIds};
		apiPut(url, body).then((resp)=> {
			console.debug(resp);
			setMessage('User roles updated.');
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
	};

	let UserAgencies = () => {
		if (user && user.agencies && user.agencies.length) {
			let ShowAgencyRow = (props) => {
				return <Link to={ () => '/admin/agency/' + props.row.agencyUuid}>{props.row.agencyName}</Link>
			};
			let ShowAgencyRows = user.agencies.map( (data, index) => {
				return <ShowAgencyRow key={index} row={data} />;
			});
			return<>
				{ShowAgencyRows}
			</>;
		}
		return null;
	};

	return (
		<>
			<Processing show={showSpinner}/>
			<h4>User</h4>

			<MessageTag message={message} />

			<UserUpdate setMessage={setMessage}/>
			
			<br/>
			<table className="user">
				<tbody>
				<tr>
					<th>Roles</th>
					<td>
						<div className="row">
							<div className="col">
							{ UserRoles }
							</div>
							<div className="col">
							<button type="button" onClick={updateUserRoles} className="btn btn-primary" id="btnUpdateRoles" disabled={user.userStatusId == UserStatus.Deleted}>Update Role</button>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<th>Programs</th>
					<td>{ UserPrograms } </td>
				</tr>
				<tr>
					<th>Agencies</th>
					<td>< UserAgencies /> </td>
				</tr>
				</tbody>
			</table>
		</>
	)
}
