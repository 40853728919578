import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { apiGet, getBenefitsApiUrl, getAccountsApiUrl } from '../common/AppApi';

import useUserContext from '../common/UserContext';
import {saveUser} from '../common/AppAuth';

import Processing from '../common/Processing';

import { formatCurrency } from '../common/Formats';
import { NotificationStatus } from '../common/Constants';

export default function Home() {
	let [showSpinner, setSpinner] = useState(true);
	let [programs, setPrograms] = useState([{programId:-1,programName:'No Balances Found',totalAmount:0,programUuid:''}]);

	const history = useHistory();

    let {user, setUser} = useUserContext();

	useEffect( () => {
		getNotifications();
		loadPrograms();
	}, [] ); // empty array, run once

	let updateUserObject = (count) => {
		let cloned = {... user};
		cloned.notifications = count;
		saveUser(cloned); // save to local storage
		setUser(cloned); // save to context
	};

	let getBalances = (progs) => {
		let url = getBenefitsApiUrl() + '/benefit/balances';
		apiGet(url).then( (response) => {
			console.debug(response);
			// loop through programs to set balance
			progs.map( (program) => {
				response.forEach( (balance) => {
					if(balance.programId == program.programId){
						program.totalAmount = balance.totalAmount;
						return program;
					}
				});
				return program;
			});
			// set updated programs
			if(progs && progs.length)
				setPrograms(progs);
		}).catch( (error) => {
			console.error(error);
		}).finally(() => setSpinner(false));
	};

	let loadPrograms = () => {
		let url = getAccountsApiUrl() + '/account/currentuser/programs';
		apiGet(url).then( (response) => {
			console.debug(response);
			// initialize amount
			response.forEach( (item) => item.totalAmount = 0);
			// pass to get balances function
			getBalances(response);
		}).catch( (error) => {
			console.error(error);
		});
	};

	let getNotifications = () => {
		let url = getAccountsApiUrl() + '/notifications';
		apiGet(url).then((resp)=> {
			console.debug(resp);
			if (resp && resp.length) {
				let unread = 0;
				resp.forEach( (item) => {
					if(item.notificationStatusId == NotificationStatus.Unread)
						unread++;
				});
				if(unread > 0) {
					updateUserObject(unread);
				} else if (user.notifications && unread == 0){
					updateUserObject(0);
				}
			}
		}).catch( (err)=> {
			console.error(err);
		});
	}

	let goTo = (id) => history.push('/recipient/program/' + id);

	let DisplayBlock = (props) => {
		let blockId = 'blockId' + props.programId;
		let totalAmountFormatted = formatCurrency(props.totalAmount);
		return (
			<div className="col-12 col-md-6 col-lg-4 clickable dblock" onClick={()=>goTo(props.programUuid)}>
				<div className="card border-primary" title="Click for details" id={blockId}>
					<div className="card-header">{props.programName} <small className='text-info float-right'>Details</small></div>
					<div className="card-body">
					<h4 className="card-title">Balance: {totalAmountFormatted}</h4>
					</div>
				</div>
			</div>
		);
	};

	let DisplayBlocks = programs.map(
		(data, index) => {
			if (data.programUuid) {
				return (
					<DisplayBlock key={data.programId} programId={data.programId} programName={data.programName} totalAmount={data.totalAmount} programUuid={data.programUuid}/>
				);
			}
			else {
				return (
					<DisplayBlock key={data.programId} programId={data.programId} programName={data.programName} totalAmount={data.totalAmount} />
				);
			}
		}
	);

    return (

		<div className="row">
			<Processing show={showSpinner}/>
			{DisplayBlocks}
		</div>
	  
    )
}
