import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Home';
import Account from './Account';
import Benefits from './Benefits';
import Transactions from './Transactions';
import Transaction from './Transaction';
import Merchant from './Merchant';
import Merchants from './Merchants';
import MerchantLocator from './MerchantLocator';
import Notifications from './Notifications';
import Program from './Program';
import EBTCards from './EBTCards';
import EBTCardsAdd from './EBTCardsAdd';
import EBTCardsEdit from './EBTCardsEdit';
import RecipientIncentives from './RecipientIncentives';

export default function Content() {
	return (
		<Route path="/recipient">
			<Switch>
				<Route path="/recipient/home" component={Home} />
				<Route path="/recipient/account" component={Account} />
				<Route path="/recipient/benefits" component={Benefits} />
				<Route path="/recipient/ebtcards/add" component={EBTCardsAdd} />
				<Route path="/recipient/ebtcard/:id" component={EBTCardsEdit} />
				<Route path="/recipient/ebtcards" component={EBTCards} />
				<Route path="/recipient/incentives" component={RecipientIncentives} />
				<Route path="/recipient/transaction/:id" component={Transaction} />
				<Route path="/recipient/transactions" component={Transactions} />
				<Route path="/recipient/merchants" component={Merchants} />
				<Route path="/recipient/merchantlocator" component={MerchantLocator} />
				<Route path="/recipient/merchant/:id" component={Merchant} />
				<Route path="/recipient/notifications" component={Notifications} />
				<Route path="/recipient/program/:id" component={Program} />
				<Route path="/recipient" component={Home} />
			</Switch>
		</Route>
	)
}
