import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import useUserContext from './common/UserContext';

import { clearSession, saveUser, saveToken, getToken } from './common/AppAuth';
import { apiLogin, apiGet, getApiUrl, getUserApiUrl } from './common/AppApi';

import { invalidInputStyle } from './common/Forms';

import Strings from './common/Strings';
import { PrivilegeCodes } from './common/Constants';

import Processing from './common/Processing';
import MessageTag from './common/MessageTag';
import './Login.css';

export default function Login() {
	let [username, setUsername] = useState('');
	let [password, setPassword] = useState('');
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
    let [redirectTo, setRedirectTo] = useState(false);
    let {user, setUser} = useUserContext();
    
    const history = useHistory();
    
    useEffect( () => {
        if(redirectTo)
            history.push(redirectTo);
    },[redirectTo]);

    useEffect( () => {
		const flashUsername = new URLSearchParams(window.location.search).get("username");
        if (flashUsername)
            setUsername(flashUsername);
    },[]);

	useEffect( () => {
        invalidInputStyle();
	});

    let handleLogin = function(e){
        e.preventDefault();

        if(username && password){
            setSpinner(true);
            clearSession();

			let parms = {username:username, password:password};

            let url = getApiUrl() + "/authenticate";
            apiLogin(url, parms).then( (response) => {
                //console.debug(response);

                if(response && response.token){
					saveToken("LOGGED_IN");

                    let userInfoUrl = getUserApiUrl() + "/user/current";
                    apiGet(userInfoUrl).then((resp) => {
                        //console.debug(resp);
    
                        let user = {
                            userId: resp.userId,
                            userUuid: resp.userUuid,
                            username: resp.username,
                            email: resp.email,
                            firstname: resp.firstname,
                            lastname: resp.lastname,
                            middlename: resp.middlename,
                            privileges: resp.privileges,
                            programs: resp.programs
                        };
    
                        saveUser(user); // save to local storage
                        setUser(user); // save to context
    
                        if(user.privileges.includes(PrivilegeCodes.recipientAccess)){
                            //console.debug('recipient');
                            setRedirectTo('/recipient');
                        }
                        else if(user.privileges.includes(PrivilegeCodes.programAccess)
                            || user.privileges.includes(PrivilegeCodes.agencyAdminAccess)
                            || user.privileges.includes(PrivilegeCodes.agencyUserAccess)) {
                            //console.debug('program');
                            setRedirectTo('/program');
                        }
                        else if(user.privileges.includes(PrivilegeCodes.adminAccess)){
                            //console.debug('admin');
                            setRedirectTo('/admin');
                        }
                        else{
                            setMessage({text:Strings.error.portalUnavailable,type:'danger'});
                        }
    
                    }).catch( (err)=> {
                        setMessage({text:Strings.error.loginUnavailable,type:'danger'});
                    }).finally(() => setSpinner(false));

                } else{
                    setMessage('Login failed.');
				}

            }).catch((error) => {
                if(error.status && error.status == 401){
                    setMessage({text:Strings.error.logon,type:'danger'});
                }
                else{
                    setMessage({text:Strings.error.loginUnavailable,type:'danger'});
                }
            }).finally(() => setSpinner(false));    
        }
        else if(!username){
            setMessage({text:'Username is required.',type:'danger'});
        }
        else if(!password){
            setMessage({text:'Password is required.',type:'danger'});
		}

		return false;
    }

    return (
        <div className="login">
        <Processing show={showSpinner}/>
        <h2>Login</h2>
        <MessageTag message={message} warning={true} />
        <form onSubmit={handleLogin}>
        <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" className="form-control" onChange={e => setUsername(e.target.value)} value={username} required minLength="6" maxLength="200" autoComplete="off"/>
        </div>
        <div className="form-group">
            <label htmlFor="passwd">Password</label>
            <input type="password" id="passwd" className="form-control" onChange={e => setPassword(e.target.value)} required minLength="6" maxLength="50" autoComplete="off"/>
        </div>
        <button id="btnLogin" className="btn btn-primary">Login</button>
        </form>
        <Link to="/forgotpass" >Forgot Password</Link> &nbsp; <Link to="/forgotusername" >Forgot Username</Link>
        </div>
    )
}
