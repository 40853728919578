import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useQuery } from "react-router-dom";
import useUserContext from './common/UserContext';

import { clearSession, saveUser, saveToken, getToken } from './common/AppAuth';
import { apiGet, getApiUrl, getUserApiUrl, apiPost } from './common/AppApi';

import { patternMessage, invalidInputStyle, notEqualMessage, selectOptions } from './common/Forms';

import Strings from './common/Strings';
import PasswordChecklist from './common/PasswordChecklist';

import Processing from './common/Processing';
import MessageTag from './common/MessageTag';
import './Validate.css';

export default function ResetPassword(props) {
	let blankForm = {
        userUuid:'',
		username:'',
		password:'',
		confirmPassword:'',
        otpType:'',
        questionId:'',
        answer:''
    };
    
	let [passwordForm, setPasswordForm] = useState(blankForm);
    //let { hash } = useParams();

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [questions, setQuestions] = useState([{id:null,text:''}]);
    
    const history = useHistory();
    
    let getInfo = () => {
        setSpinner(true);

        let hhash = props.location.search.split('=')[1];
        console.debug(hhash);

		let url = getUserApiUrl() + '/validate-pass/' + hhash;
		apiGet(url).then((resp)=> {
			console.debug(resp);

            let cloned = {...passwordForm, username:resp.username, userUuid:resp.userUuid};
            setPasswordForm(cloned);

		}).catch( (err)=> {
            console.error(err);
            if(err.status == 404) {
                setMessage({text: 'The reset link is no longer valid.', type:'danger'});
            } else {
                setMessage({text: Strings.error.general, type:'danger'});
            }
		}).finally(() => {
			setSpinner(false);
		});
    };
    
	useEffect( () => {
        getInfo();
		patternMessage("#password", Strings.validation.password);
        notEqualMessage("#confirmPassword", Strings.validation.confirmPassword, "#password");
    },[]);
	useEffect( () => {
        invalidInputStyle();
    });

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...passwordForm, [id]:value};
		setPasswordForm(cloned);
	}
    
    let handleSubmit = function(e){
        e.preventDefault();

        let hhash = props.location.search.split('=')[1];
        let body = {
            newPassword:passwordForm.password,
            currentPassword:'skip'
        };
        console.debug(body);

        setSpinner(true);

		let url = getUserApiUrl() + '/reset-pass/' + hhash;
		apiPost(url, body).then((resp)=> {
            console.debug(resp);
            window.location.href = "/logout";
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.status){
                if(err.status == 404) {
                    messageObject.text = 'The activation link is invalid.';
                }
                else if(err.status == 400){
					console.error(err.data.errorMessages);
					if(err.data.expandedErrors && err.data.expandedErrors.length){
						let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
					}
					else if(err.data.errorMessages && err.data.errorMessages.length){
						let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
						messageObject.text = <>{messages}</>;
					}
				}
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
		});
    }

    return (
        <div className="validate">
            <Processing show={showSpinner}/>
            <h2 className="title">Reset your password.</h2>
            <MessageTag message={message} warning={true} />
            <form onSubmit={e => handleSubmit(e)}>
                <div className="container">
                    <div className="row">
                        <div className="form-group col-12 col-md-7">
                            <label htmlFor="username">Username</label>
                            <input type="text" id="username" className="form-control" value={passwordForm.username} readOnly />
                        </div>
                        <div className="form-group required col-12 col-md-7">
                            <label className="asterisk-label" htmlFor="passwd">Password</label>
                            <input type="password" id="password" className="form-control" autoComplete="off" value={passwordForm.password} onChange={handleFieldChange} required maxLength="30" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!\x22#\\\$%&'()*+,-./:;<>=?@\[\]\^_`|{}~]).{8,30}$" />
                            <div><PasswordChecklist password={passwordForm.password}/></div>
                        </div>
                        <div className="form-group required col-12 col-md-7">
                            <label className="asterisk-label" htmlFor="passwd">Confirm Password</label>
                            <input type="password" id="confirmPassword" className="form-control" autoComplete="off" value={passwordForm.confirmPassword} onChange={handleFieldChange} required maxLength="40" />
                        </div>

                        <div className="form-group col-12 col-md-7">
                            <button className="btn btn-primary">Set Password</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
