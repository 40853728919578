import React from 'react';

export function ExpiringIcon() {
	return (<i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>)
}

export function ExpiredIcon() {
	return (<i className="fa fa-exclamation-circle text-danger" aria-hidden="true"></i>)
}

export function HelpTooltip(props) {
	return (<i className="fa fa-question-circle-o" aria-hidden="true" title={props.title} data-toggle="tooltip"></i>)
}

export function EyeIcon(props) {
	if (props.title) {
		return (<i className="fa fa-eye text-info" title={props.title}></i>)
	} else {
		return (<i className="fa fa-eye text-info"></i>)
	}
}
