import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import { apiGet, apiPut, apiPost, getAdminApiUrl, getUserApiUrl, apiDelete, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { StateArray, ProgramStatusArray } from '../common/Constants';
import { stripTime, formatPhone, cleanPhone } from '../common/Formats';

import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import Strings from '../common/Strings';

import ConfirmDialog, { closeConfirmDialog, showConfirmDialog } from '../common/ConfirmDialog';
import PromptDialog, { showPromptDialog } from '../common/PromptDialog';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';

import FundingSource from '../common/FundingSource';

import DatePicker from 'react-date-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';
import moment from 'moment';

import { HelpTooltip } from '../common/AppIcons';

export default function Program() {
	let defaultForm = {
		contactEmail:'',
		contactPhone:'',
		endDate:'',
		programId:'',
		programName:'',
		shortName:'',
		contactStateCode:'',
		programStatusId:'',
		programUuid:'',
		startDate:'',
		configuration:'',
		contactAddress1:'',
		contactAddress2:'',
		contactCity:'',
		contactZip:'',
		fundingSources:[],
		benefitExpiration:'',
		incentiveExpiration:'',
		benefitAmount:'',
		fundingAmount:'',
		fundingDate:'',
		lastIssuanceDate:'',
		enableBenefitNote:false,
		benefitOptionTypeId:''
	};

	let startAndEndDates = {
		startDate: '',
		endDate: '',
	}

	let [program, setProgram] = useState(defaultForm);
	let [dates, setDates] = useState(startAndEndDates);
	let { id } = useParams();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [programUsers, setProgramUsers] = useState([]);
	let [searchForm, setSearchForm] = useState({email:'',lastname:'',userId:'',username:'', roleIds:[1,2]});
	let [users, setUsers] = useState([]);
	let [benefitOptionTypes, setBenefitOptionTypes] = useState([]);

	let getProgram = (id) => {
		setSpinner(true);

		let url = getAdminApiUrl() + '/programs/' + id;

		apiGet(url).then((resp) => {
			console.debug(resp);

			let newStartDate = moment(stripTime(resp.startDate));
			let newEndDate = moment(stripTime(resp.endDate));
			
			let program = {};
			program.contactEmail = resp.contactEmail;
			program.contactPhone = formatPhone(resp.contactPhone);
			program.programId = resp.programId;
			program.programName = resp.programName;
			program.shortName = resp.shortName;
			program.contactStateCode = resp.contactStateCode;
			program.programStatusId = resp.programStatusId;
			program.programUuid = resp.programUuid;
			program.startDate = newStartDate.format("YYYY-MM-DD");
			program.endDate = newEndDate.format("YYYY-MM-DD");
			program.contactAddress1 = resp.contactAddress1;
			program.contactAddress2 = resp.contactAddress2 ? resp.contactAddress2 : '';
			program.contactCity = resp.contactCity;
			program.contactZip = resp.contactZip;
			program.configuration = resp.configuration;
			if (resp.configuration) {
				program.configuration = resp.configuration.replaceAll(',',',\n');
			}
			if (resp.fundingSources) {
				program.fundingSources = resp.fundingSources;
			}
			dates.startDate = newStartDate.toDate();
			dates.endDate = newEndDate.toDate();

			program.benefitExpiration = resp.benefitExpiration ? resp.benefitExpiration : '';
			program.incentiveExpiration = resp.incentiveExpiration ? resp.incentiveExpiration : '';
			program.benefitAmount = resp.benefitAmount ? Number(resp.benefitAmount).toFixed(2) : '';
			program.fundingAmount = resp.fundingAmount ? Number(resp.fundingAmount).toFixed(2) : '';

			program.fundingDate = resp.fundingDate ? moment(resp.fundingDate).toDate() : '';
			program.lastIssuanceDate = resp.lastIssuanceDate ? moment(resp.lastIssuanceDate).toDate() : '';

			program.enableBenefitNote = resp.enableBenefitNote ? true : false;
			if (resp.benefitOptionTypeId)
				program.benefitOptionTypeId = resp.benefitOptionTypeId;

			setProgram(program);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
			getProgramUsers(id);
		});
	};

	let getBenefitOptionTypes = () => {
		let url = getAdminApiUrl() + '/benefit-option-types';
		apiGet(url).then( (response) => {
			console.debug(response);
			let types = [];
			types.push({id:'',text:''});
			response.forEach( (item) => {
				types.push({id:item.optionTypeId, text: item.name + ' - ' + item.descr});
			});
			setBenefitOptionTypes(types);
		}).catch( (error) => {
			console.error(error);
		});
	};

	let getProgramUsers = (id) => {
		setSpinner(true);

		let url = getAdminApiUrl() + '/programs/' + id + '/users';

		apiGet(url).then((resp)=> {
			//console.debug(resp); 
			setProgramUsers(resp);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	let searchUsers = (e) => {
		e.preventDefault();
		setSpinner(true);
		//console.debug(searchForm);

		let url = getUserApiUrl() + '/users';

		let queryParams = [];
		for(const searchField in searchForm) {
			if (searchForm[searchField])
				queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
		}
		if(queryParams.length && queryParams.length > 1) {
			url = url + '?' + queryParams.join('&');
		} else {
			setSpinner(false);
			setConfirmSettings({body:'Please enter at least one user search criteria.'});
			showAlertDialog();
			return false;
		}

		apiGet(url).then((resp)=> {
			//console.debug(resp);
			setUsers(resp);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		patternMessage("#contactPhone", Strings.validation.phoneFormat);
		patternMessage("#contactEmail", Strings.validation.emailFormat);
		patternMessage("#userId", Strings.validation.number);
		patternMessage("#benefitExpiration", Strings.validation.expirationDays);
		patternMessage("#incentiveExpiration", Strings.validation.expirationDays);
		getBenefitOptionTypes();
		getProgram(id);
	}, []);
	useEffect( () => {
		invalidInputStyle();
	});

	let handleSubmit = (e) => {
		e.preventDefault();
		if (program.fundingSources.length < 1) {
			setConfirmSettings({body:Strings.validation.fundingSource});
			showAlertDialog();
			return;
		}
		
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/' + id;
		let body = {...program};
		body.contactPhone = cleanPhone(program.contactPhone);
		delete body.programId;

		console.debug(program);

		body.fundingSources = program.fundingSources.map( x => {
			return {fundingId:x.fundingId > -1 ? x.fundingId : null, name:x.name}
		});

		apiPut(url, body).then((resp)=> {
			setMessage({text: 'Program updated.', type:'success'});
			let cloned = {...program};
			if (resp.fundingSources) {
				cloned.fundingSources = resp.fundingSources;
			}

			setProgram(cloned);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...program, [id]:value};
		setProgram(cloned);
	}
	let handleCheckboxChange = (event) => {
		const {id, checked} = event.target;
		let cloned = {...program, [id]:checked};
		setProgram(cloned);
	}

	let handleDayChange = (inputName) => {
		return function onChange(newDate){
			let formattedDate = moment(newDate).format("YYYY-MM-DD");
			setProgram({...program, [inputName]:formattedDate});
			if (inputName == 'startDate' || inputName == 'endDate')
				setDates({...dates, [inputName]:newDate});
		}
	}
	let handleDayChange2 = (inputName, val) => {
		let cloned = {...program, [inputName]:val};
		setProgram(cloned);
	}

	const history = useHistory();
	let TableRow = (props) => {
		let GoTo = (uuid) => history.push('/admin/user/' + uuid);

		return (
			<tr>
				<td onClick={() => GoTo(props.row.userUuid) } className="clickable">{props.row.username}</td>
				<td onClick={() => GoTo(props.row.userUuid) } className="clickable">{props.row.email}</td>
				<td onClick={() => GoTo(props.row.userUuid) } className="clickable">{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
				<td>
				<button type="button" className="btn btn-primary btn-sm" onClick={ () => confirmDeleteDialog(props.row.userUuid) } id="btnRemoveFromProgram">Remove From Program</button>
				</td>
			</tr>
		);
	};

	let TableBody = programUsers.map( (data, index) => {
		return <TableRow key={data.userUuid} row={data} />;
	});

	let ProgramAdminsTable = () => {
		if (programUsers.length){
			return (
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ TableBody }
					</tbody>
				</table>
			);
		}
		else {
			return null;
		}
	};

	let handleSearchFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...searchForm, [id]:value};
		setSearchForm(cloned);
	}

	let UserRow = (props) => {
		return (
			<tr>
				<td>{props.row.userId}</td>
				<td>{props.row.email}</td>
				<td>{props.row.username}</td>
				<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
				<td>
				<button type="button" className="btn btn-primary btn-sm" onClick={ () => confirmAddDialog(props.row.userUuid) } id="btnAddToProgram">Add To Program</button>
				</td>
			</tr>
		)
	};

	let UserTableBody = users.map( (data, index) => {
		return <UserRow key={data.userId} row={data} />;
	});

	let UsersTable = () => {
		if (users.length){
			return (
				<table className="table">
					<thead>
						<tr>
							<th>User ID</th>
							<th>Email</th>
							<th>Username</th>
							<th>Name</th>
							<th>Select</th>
						</tr>
					</thead>
					<tbody>
						{ UserTableBody }
					</tbody>
				</table>
			);
		}
		else {
			return null;
		}
	};

	let deleteAdminUser = (uuid) => {
		if(!uuid) return;
		setSpinner(true);

		let url = getAdminApiUrl() + '/programs/' + id + '/users';

		apiDelete(url, {userUuid:uuid}).then((resp)=> {
			//console.debug(resp);
			setMessage({text: 'User removed from Program.', type:'success'});
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
			getProgramUsers(id);
		});
	};

	let addAdminUser = (uuid) => {
		if(!uuid) return;
		setSpinner(true);

		let url = getAdminApiUrl() + '/programs/' + id + '/users';

		apiPost(url, {userUuid:uuid}).then((resp)=> {
			//console.debug(resp);
			setMessage({text: 'User added to Program.', type:'success'});
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => {
			setSpinner(false);
			getProgramUsers(id);
		});
	};

	let [confirmSettings, setConfirmSettings] = useState({});

	let [adminUser, setAdminUser] = useState({});

	let confirmAddDialog = (uuid) => {
		let addAction = (uuid) => {
			closeConfirmDialog();
			addAdminUser(uuid);
		};
		setAdminUser({uuid:uuid, action:'ADD'});
		setConfirmSettings({confirm:()=>addAction(uuid),body:Strings.confirm.addToProgram,button:'Add'});
		showConfirmDialog();
	};
	let confirmDeleteDialog = (uuid) => {
		let deleteAction = (uuid) => {
			closeConfirmDialog();
			deleteAdminUser(uuid);
		};
		setAdminUser({uuid:uuid, action:'DELETE'});
		setConfirmSettings({confirm:()=>deleteAction(uuid),body:Strings.confirm.removeFromProgram,button:'Remove'});
		showConfirmDialog();
	};

	let returnFundingSources = (values) => {
		let cloned = {...program, fundingSources:values};
		setProgram(cloned);
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h4>Program ID: {program.programId}</h4>
		<MessageTag message={message} />

        <form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="programName">Name</label>
					<input type="text" id="programName" className="form-control" onChange={handleFieldChange} value={program.programName} maxLength="45" required/>
				</div>
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="shortName">Short Name</label>
					<input type="text" id="shortName" className="form-control" onChange={handleFieldChange} value={program.shortName} maxLength="20" required/>
				</div>
				<div className="form-group required col-12 col-md-4">
					<label className="asterisk-label" htmlFor="programStatusId">Status</label>
					<select id="programStatusId" className="form-control custom-select" value={program.programStatusId} onChange={handleFieldChange} required >
						{ selectOptions( ProgramStatusArray ) }
					</select>
				</div>
				<div className="form-group required col-12 col-md-6">
					<label className="asterisk-label" htmlFor="contactEmail">Contact Email</label>
					<input type="email" id="contactEmail" className="form-control" value={program.contactEmail} onChange={handleFieldChange} maxLength="200" required pattern={Strings.pattern.email}/>
				</div>
				<div className="form-group required col-12 col-md-6">
					<label className="asterisk-label" htmlFor="contactPhone">Contact Phone</label>
					<input type="text" id="contactPhone" className="form-control" value={program.contactPhone} onChange={handleFieldChange} maxLength="20" required pattern={Strings.pattern.phone}/>
				</div>

				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="contactAddress1">Contact Address</label>
					<input type="text" id="contactAddress1" className="form-control" value={program.contactAddress1} onChange={handleFieldChange} maxLength="60" required />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="contactAddress2">Contact Address 2</label>
					<input type="text" id="contactAddress2" className="form-control" value={program.contactAddress2} onChange={handleFieldChange} maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="contactCity">Contact City</label>
					<input type="text" id="contactCity" className="form-control" value={program.contactCity} onChange={handleFieldChange} maxLength="60" required />
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="contactStateCode">Contact State</label>
					<select id="contactStateCode" className="form-control custom-select" value={program.contactStateCode} onChange={handleFieldChange} required >
						{ selectOptions( StateArray ) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="contactZip">Contact Zip Code</label>
					<input type="text" id="contactZip" className="form-control" value={program.contactZip} onChange={handleFieldChange} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>

			<div className="row">
				<div className="form-group required col-12 col-md-3">
					<label className="asterisk-label" htmlFor="startDate">Start Date</label>
					<DatePicker onChange={handleDayChange("startDate")} value={dates.startDate} className={"form-control"} minDate={new Date(2021,1,1)} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group required col-12 col-md-3">
					<label className="asterisk-label" htmlFor="endDate">End Date</label>
					<DatePicker onChange={handleDayChange("endDate")} value={dates.endDate} className={"form-control"} minDate={new Date(2021,1,1)} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</div>

			<FundingSource fundingSources={program.fundingSources} parentCallback={returnFundingSources} setPromptSettings={setConfirmSettings} />

			<div className="row">
				<div className="form-group col-12 col-md-4">
					<label htmlFor="benefitExpiration">Benefit Expiration Days</label>
					<input type="number" id="benefitExpiration" className="form-control text-right" onChange={handleFieldChange} value={program.benefitExpiration} min="0" step="1" maxLength="4" pattern="[0-9]{0,4}"/>
					<small className="form-text text-muted">{Strings.help.expirationDays}</small>
				</div>
				<div className="form-group col-12 col-md-4">
					<label htmlFor="incentiveExpiration">Incentive Benefit Expiration Days</label>
					<input type="number" id="incentiveExpiration" className="form-control text-right" onChange={handleFieldChange} value={program.incentiveExpiration} min="0" step="1" maxLength="4" pattern="[0-9]{0,4}"/>
					<small className="form-text text-muted">{Strings.help.expirationDaysIncentive}</small>
				</div>
				<div className="form-group col-12 col-md-4">
					<label htmlFor="benefitAmount">Benefit Amount</label>
					<input type="number" id="benefitAmount" className="form-control text-right" onChange={handleFieldChange} value={program.benefitAmount} min="0.00" step="0.01" maxLength="6" max="9999" pattern="[0-9\.]{0,6}"/>
					<small className="form-text text-muted">{Strings.help.benefitSetAmount}</small>
				</div>

				<div className="form-group col-12 col-md-4">
					<label htmlFor="fundingAmount">Funding Amount</label>
					<input type="number" id="fundingAmount" className="form-control text-right" onChange={handleFieldChange} value={program.fundingAmount} min="0.00" step="1.00" maxLength="8" max="99999999" pattern="[0-9\.]{0,8}"/>
				</div>
				<div className="form-group col-12 col-md-4">
					<label className="asterisk-label" htmlFor="fundingDate">Funding Date</label>
					<DatePicker value={program.fundingDate} onChange={(val) => handleDayChange2('fundingDate', val)} className={"form-control"} id="fundingDate" name="fundingDate" minDate={new Date(2023,0,1)} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group col-12 col-md-4">
					<label className="asterisk-label" htmlFor="lastIssuanceDate">Last Issuance Date</label>
					<DatePicker value={program.lastIssuanceDate} onChange={(val) => handleDayChange2('lastIssuanceDate', val)} className={"form-control"} id="lastIssuanceDate" name="lastIssuanceDate" minDate={new Date(2023,0,1)} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
					<small className="form-text text-muted">{Strings.help.lastIssuance}</small>
				</div>
			</div>

			<div className="row">
				<div className="form-group col-12 col-md-5">
					<label htmlFor="benefitOptionTypeId">Enable Benefit Dropdown Option</label>
					<select id="benefitOptionTypeId" className="form-control custom-select" value={program.benefitOptionTypeId} onChange={handleFieldChange} >
						{ selectOptions( benefitOptionTypes ) }
					</select>
					<small className="form-text text-muted">{Strings.help.benefitOption}</small>
				</div>
				<div className="form-group col-12 col-sm-4">
					<label>Enable Benefit Note</label>
					<div className="form-check">
						<label htmlFor="enableBenefitNote" className='clickable'>
						<input className="form-check-input" type="checkbox" value="true" id="enableBenefitNote" checked={program.enableBenefitNote} onChange={handleCheckboxChange} />
						 Enabled</label>
					</div>
					<small className="form-text text-muted">{Strings.help.benefitNote}</small>
				</div>
			</div>

			<div className="row">
				<div className="form-group required col-12 col-md-8">
					<label htmlFor="configuration">Configuration</label>
					<textarea id="configuration" className="form-control" onChange={handleFieldChange} value={program.configuration} maxLength="1000" rows={5} />
				</div>
			</div>

			<button className="btn btn-primary">Update</button>
		</form>

		<hr/>
		<h4>Program Admins</h4>
		<ProgramAdminsTable />

		<h5>Add Admin</h5>
        <form onSubmit={searchUsers}>
		<div className="row">
			<div className="form-group col-12 col-md-6 col-lg-2">
				<label htmlFor="username">Username</label>
				<input type="text" id="username" className="form-control" maxLength="20" value={searchForm.username} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-3">
				<label htmlFor="email">Email</label>
				<input type="text" id="email" className="form-control" maxLength="100" value={searchForm.email} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-3">
				<label htmlFor="lastname">Last Name</label>
				<input type="text" id="lastname" className="form-control" maxLength="45" value={searchForm.lastname} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-2">
				<label htmlFor="userId">User ID <HelpTooltip title="Exact database ID"/></label>
				<input type="text" id="userId" className="form-control" maxLength="20" value={searchForm.userId} onChange={handleSearchFieldChange} pattern="\d+"/>
			</div>
			<div className="form-group col-12 col-md-6 col-lg-2"><br/>
				<button className="btn btn-primary" id="adminSearch">Search</button>
			</div>
		</div>
		</form>

		<UsersTable />

		<ConfirmDialog settings={confirmSettings} />

		<PromptDialog settings={confirmSettings} />

		<AlertDialog settings={confirmSettings} />

		</>
	)
}
