import React, { Component } from 'react'
import {Route, Switch} from 'react-router-dom'

import Home from './Home';
import Account from './Account';
import Recipients from './Recipients';
import Recipient from './Recipient';
import AddRecipient from './AddRecipient';
import Programs from './Programs';
import Program from './Program';
import AddProgram from './AddProgram';
import Benefits from './Benefits';
import Benefit from './Benefit';
import AddBenefit from './AddBenefit';
import Transactions from './Transactions';
import Transaction from '../recipient/Transaction';
import Merchants from './Merchants';
import Merchant from './Merchant';
import AddMerchant from './AddMerchant';
import MerchantFile from './MerchantFile';
import Users from './Users';
import User from './User';
import AddUser from './AddUser';
import RecipientPans from './RecipientPans';
import Roles from './Roles';
import Role from './Role';
import AddRole from './AddRole';
import Privileges from './Privileges';
import Configurations from './Configurations';
import Configuration from './Configuration';
import AddConfiguration from './AddConfiguration';
import Incentives from './Incentives';
import AddIncentive from './AddIncentive';
import Incentive from './Incentive';
import RecipientIncentives from './RecipientIncentives';
import EBTCards from './EBTCards';
import Agencies from './Agencies';
import AddAgency from './AddAgency';
import Agency from './Agency';
import Dashboard from './Dashboard';
import MerchantLocator from './MerchantLocator';

export default class Content extends Component {
    render() {
        return (
            <Route path="/admin">
                <Switch>
                    <Route path="/admin/home" component={Home} />
                    <Route path="/admin/account" component={Account} />
                    <Route path="/admin/agencies" component={Agencies} />
                    <Route path="/admin/agency/add" component={AddAgency} />
                    <Route path="/admin/agency/:id" component={Agency} />
                    <Route path="/admin/benefits/:recipientId" component={Benefits} />
                    <Route path="/admin/benefits" component={Benefits} />
                    <Route path="/admin/benefit/add" component={AddBenefit} />
                    <Route path="/admin/benefit/:id" component={Benefit} />
                    <Route path="/admin/dash" component={Dashboard} />
                    <Route path="/admin/recipients" component={Recipients} />
                    <Route path="/admin/recipient/ebtcards/:id" component={EBTCards} />
                    <Route path="/admin/recipient/incentives/:id/:progId" component={RecipientIncentives} />
                    <Route path="/admin/recipient/add" component={AddRecipient} />
                    <Route path="/admin/recipient/:id/:progId" component={Recipient} />
                    <Route path="/admin/pans/:id/:progId" component={RecipientPans} />
                    <Route path="/admin/programs" component={Programs} />
                    <Route path="/admin/program/add" component={AddProgram} />
                    <Route path="/admin/program/:id" component={Program} />
                    <Route path="/admin/transactions" component={Transactions} />
                    <Route path="/admin/account" component={Account} />
                    <Route path="/admin/incentives" component={Incentives} />
                    <Route path="/admin/incentive/add" component={AddIncentive} />
                    <Route path="/admin/incentive/:id" component={Incentive} />
                    <Route path="/admin/transaction/:id" component={Transaction} />
                    <Route path="/admin/merchantlocator" component={MerchantLocator} />
                    <Route path="/admin/merchants" component={Merchants} />
                    <Route path="/admin/merchant/add" component={AddMerchant} />
                    <Route path="/admin/merchant/file" component={MerchantFile} />
                    <Route path="/admin/merchant/:id" component={Merchant} />
                    <Route path="/admin/privileges" component={Privileges} />
                    <Route path="/admin/role/add" component={AddRole} />
                    <Route path="/admin/roles/:roleId" component={Role} />
                    <Route path="/admin/roles" component={Roles} />
                    <Route path="/admin/users" component={Users} />
                    <Route path="/admin/user/add" component={AddUser} />
                    <Route path="/admin/user/:id" component={User} />
                    <Route path="/admin/configuration/add" component={AddConfiguration} />
                    <Route path="/admin/configuration/:id" component={Configuration} />
                    <Route path="/admin/configurations" component={Configurations} />
                    <Route path="/admin" component={Home} />
                </Switch>
            </Route>
        )
    }
}
