import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from "react-router-dom";

import { apiGet, getAdminApiUrl, apiPost, apiMessages, apiPut, apiDelete } from '../common/AppApi';
import Strings from '../common/Strings';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { invalidInputStyle } from '../common/Forms';

import moment from 'moment';

export default function Configuration() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [configs, setConfigs] = useState([]);
	let blankForm = {configKey:'',configValue:'',effectiveDate:''};
	let [config, setConfig] = useState(blankForm);
	let [updating, setUpdating] = useState(false);

	let { id } = useParams();

	let loadConfigs = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/config/' + id;

		apiGet(url).then((resp)=> {
			setConfigs(resp);
			console.debug(resp);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		loadConfigs();
	}, []);

	let UpdateButton = (props) => {
		if (props.updateable) {
			return <><button className='btn btn-primary btn-sm' onClick={ () => setUpdateMode(props.config)}>Update</button> <button className='btn btn-primary btn-sm' onClick={ () => deleteConfig(props.config.configKey, props.config.effectiveDate)}>Delete</button></>;
		} else {
			return <><button className='btn btn-primary btn-sm' onClick={ () => setConfig(props.config) } >Copy</button></>;
		}
	};

	let setUpdateMode = (config) => {
		setUpdating(true);
		setConfig({
			configKey:config.configKey,
			configValue:config.configValue,
			originalDate:config.effectiveDate,
			effectiveDate:config.effectiveDate
		});
	};
	let setUpdateModeOff = () => {
		setUpdating(false);
		setConfig(blankForm);
	};

	let copyCurrent = (config) => {
		setConfig(config);
	};

	let TableRow = (props) => {
		let myStyle = {display:'inline-block',width:'20em',overflow:'hidden',textOverflow:'ellipsis',whitespace:'nowrap'};
		let updated = moment(props.config.updated).utc().format("YYYY-MM-DD HH:mm:ss");
		let updateable = moment().isBefore(moment(props.config.effectiveDate)) ? 1 : 0;
		return <tr>
			<td>{props.config.configKey}</td>
			<td><div style={myStyle}>{props.config.configValue}</div></td>
			<td>{props.config.effectiveDate}</td>
			<td>{updated}</td>
			<td><UpdateButton updateable={updateable} config={props.config}/></td>
			</tr>;
	};

	let TableBody = configs.map( (config, index) => {
		return <TableRow key={index} config={config}/>;
	});

	let TableDisplay = () => {
		return (
		<table className='table table-bordered'>
		<thead>
			<tr>
				<th>Key</th>
				<th>Value</th>
				<th>Effective Date</th>
				<th>Updated</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			{TableBody}
		</tbody>
		</table>
		);
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...config, [id]:value};
		setConfig(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();		
		setSpinner(true);

		if (updating) {
			let body = {configKey:id,
				configValue:config.configValue,
				effectiveDate:config.originalDate,
				newEffectiveDate:config.effectiveDate
			};
	
			let url = getAdminApiUrl() + '/config';
			apiPut(url, body).then((resp) => {
				setMessage({text: 'Configuration value updated.', type:'success'});
				setUpdating(false);
				setConfig(blankForm);
				setTimeout(loadConfigs, 300);
			}).catch( (err)=> {
				console.error(err);
				let messageObject = {text:Strings.error.general,type:'danger'};
				if(err.privilegeError){
					messageObject.text = <>{err.message}</>;
				} else if(err.status == 400 || err.status == 404 || err.status == 409){
					messageObject.text = apiMessages(err);
				}
				setMessage(messageObject);
			}).finally(() => setSpinner(false));

		} else {
			let body = {configKey:id,
				configValue:config.configValue,
				effectiveDate:config.effectiveDate
			};
	
			let url = getAdminApiUrl() + '/config';
			apiPost(url, body).then((resp) => {
				setMessage({text: 'New configuration value added.', type:'success'});
				setConfig(blankForm);
				setTimeout(loadConfigs, 300);
			}).catch( (err)=> {
				console.error(err);
				let messageObject = {text:Strings.error.general,type:'danger'};
				if(err.privilegeError){
					messageObject.text = <>{err.message}</>;
				} else if(err.status == 400 || err.status == 404 || err.status == 409){
					messageObject.text = apiMessages(err);
				}
				setMessage(messageObject);
			}).finally(() => setSpinner(false));
		}
	};

	let deleteConfig = (configKey, effectiveDate) => {
		let allgood = window.confirm("OK to delete this value?");
		if (!allgood)
			return;
		setSpinner(true);

		let body = {
			configKey:configKey,
			effectiveDate:effectiveDate,
		};

		let url = getAdminApiUrl() + '/config';
		apiDelete(url, body).then((resp) => {
			setMessage({text: 'Configuration value deleted.', type:'success'});
			setUpdating(false);
			setConfig(blankForm);
			setTimeout(loadConfigs, 300);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let DisplayOptions = () => {
		if (updating) {
			return <>
				<div className="form-group col-12 col-md-8">
					<button className="btn btn-primary">Update Value</button>
					&nbsp; <button className="btn btn-primary" onClick={ setUpdateModeOff }>Cancel</button>
				</div>
			</>
		} else {
			return <>
				<div className="form-group col-12 col-md-8">
					<button className="btn btn-primary">Add New Value</button>
				</div>
			</>
		}
	};

	useEffect( () => {
		invalidInputStyle();
	});

	return (
		<>
			<Processing show={showSpinner}/>
			<h3>Configuration</h3>
			<MessageTag message={message} />

			<TableDisplay />

			<hr/>
			<form onSubmit={handleSubmit}>
			<h4>New Value</h4>
			<div className="row">
				<div className="form-group required col-12 col-md-8">
					<label className="asterisk-label" htmlFor="configValue">Value</label>
					<input type="text" id="configValue" className="form-control" onChange={handleFieldChange} value={config.configValue} required maxLength="255" />
				</div>
				<div className="form-group required col-12 col-md-3 col-xl-2">
					<label className="asterisk-label" htmlFor="effectiveDate">Effective Date</label>
					<input type="text" id="effectiveDate" className="form-control" onChange={handleFieldChange} value={config.effectiveDate} required maxLength="10" placeholder='YYYY-MM-DD' pattern='[\d]{4}-[\d]{2}-[\d]{2}'/>
				</div>
				<DisplayOptions />
			</div>
		</form>

		</>
	)
}
