import React, { useState, useEffect } from 'react';

import AdminRecipientPans from '../program/RecipientPans';

export default function RecipientPans(props) {

	return (
		<AdminRecipientPans />
	)
}
