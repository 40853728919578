import React, { useState, useEffect } from 'react';

import { getAdminApiUrl, apiPost, apiMessages } from '../common/AppApi';
import Strings from '../common/Strings';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { invalidInputStyle } from '../common/Forms';

export default function AddConfiguration() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let blankForm = {configKey:'',configValue:'',effectiveDate:''};
	let [config, setConfig] = useState(blankForm);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...config, [id]:value};
		setConfig(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();		
		setSpinner(true);

		let url = getAdminApiUrl() + '/config';
		apiPost(url, config).then((resp) => {
			setMessage({text: 'New configuration key and value added.', type:'success'});
			setConfig(blankForm);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		invalidInputStyle();
	});

	return (
		<>
			<Processing show={showSpinner}/>
			<h3>Add New Configuration Key</h3>
			<MessageTag message={message} />

			<hr/>
			<form onSubmit={handleSubmit}>
			<h4>New Key and Value</h4>
			<div className="row">
				<div className="form-group required col-12 col-md-8">
					<label className="asterisk-label" htmlFor="configKey">Key</label>
					<input type="text" id="configKey" className="form-control" onChange={handleFieldChange} value={config.configKey} required maxLength="255" />
				</div>
				<div className="form-group required col-12 col-md-8">
					<label className="asterisk-label" htmlFor="configValue">Value</label>
					<input type="text" id="configValue" className="form-control" onChange={handleFieldChange} value={config.configValue} required maxLength="255" />
				</div>
				<div className="form-group required col-12 col-md-3 col-xl-2">
					<label className="asterisk-label" htmlFor="effectiveDate">Effective Date</label>
					<input type="text" id="effectiveDate" className="form-control" onChange={handleFieldChange} value={config.effectiveDate} required maxLength="10" placeholder='YYYY-MM-DD' pattern='[\d]{4}-[\d]{2}-[\d]{2}'/>
				</div>
				<div className="form-group col-12 col-md-8">
					<button className="btn btn-primary">Add New Value</button>
				</div>
			</div>
		</form>

		</>
	)
}
