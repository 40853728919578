import React, {useState, useEffect} from 'react';
import { useHistory, Link } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet, getAccountsApiUrl } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Pagination, { resetPaging } from '../common/Pagination';

import { PrivilegeCodes, PageSize } from '../common/Constants';

import Strings from '../common/Strings';


export default function Agencies() {
	let [showSpinner, setSpinner] = useState(false);
    let [message, setMessage] = useState();
    let {user} = useUserContext();
	let [hasAdd, setHasAdd] = useState(false);
	let [hasUpdate, setHasUpdate] = useState(false);
	let [basePath, setBasePath] = useState('/program');
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());

	let getAgencies = () => {
		setSpinner(true);

		let viewable = user.privileges.includes(PrivilegeCodes.agencyAdminAccess) || user.privileges.includes(PrivilegeCodes.agencyUserAccess) ? '?viewable' : '';
		let url = getAccountsApiUrl() + '/agency' + viewable;

		apiGet(url).then((resp) => {
			setDataRows(resp);
		}).catch( (err) => {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getAgencies();
		setHasAdd(user.privileges.includes(PrivilegeCodes.agencyAdd));
		setHasUpdate(user.privileges.includes(PrivilegeCodes.agencyView));
		if (user.privileges.includes(PrivilegeCodes.adminAccess))
			setBasePath('/admin');
	}, []);

	let PageTitle = () => {
		if (dataRows && dataRows.length && dataRows.length > 1) {
			return <>Agencies</>;
		} else {
			return <>Agency</>;
		}
	};

	const history = useHistory();
	let GoTo = (uuid) => {
		if (hasUpdate) {
			history.push(basePath + '/agency/' + uuid);
		}
	};

	let TableRow = (props) => {	
		let clazz = hasUpdate ? 'clickable' : '';
		let fundingAmount = props.row.fundingAmount ? props.row.fundingAmount.toFixed(2) : '';
		let fundingDate = props.row.fundingDate ? props.row.fundingDate : '';
		return (
			<tr className={clazz} onClick={ () => GoTo(props.row.agencyUuid)}>
			<td>{props.row.agencyName}</td>
			<td>{props.row.program.programName}</td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={index} row={data} /> : null;
	});

	let DisplayTable = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table table-hover" id="agencies">
					<thead>
						<tr>
							<th>Name</th>
							<th>Program</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return <p>No agencies found.</p>;
	};

	let AddLink = () => {
		let newLink = basePath + '/agency/add';
		if (hasAdd) {
			return <Link to={newLink} className="btn btn-primary btn-sm pull-right" id="addNewAgency">New Agency</Link>;
		} else {
			return null;
		}
	};

	return (
		<>
		<Processing show={showSpinner}/>
		<h3><PageTitle/> <AddLink/></h3>
		<MessageTag message={message}/>
		<DisplayTable/>
		</>
	);
}
