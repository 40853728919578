export const PageSize = 10;
export const ExpirationDays = 14;

// Notified and Unread are the same
export const NotificationStatus = {Notified:0, Unread:0, Read:1, Dismissed:2};

export const StateArray = [
	{id:'', id2: '', text:'Select One'},
	{id:'AL', id2:0, text:'Alabama'},
	{id:'AK', id2:1, text:'Alaska'},
	{id:'AZ', id2:3, text:'Arizona'},
	{id:'AR', id2:4, text:'Arkansas'},
	{id:'CA', id2:8, text:'California'},
	{id:'CO', id2:9, text:'Colorado'},
	{id:'CT', id2:10, text:'Connecticut'},
	{id:'DE', id2:11, text:'Delaware'},
	{id:'DC', id2:12, text:'District of Columbia'},
	{id:'FL', id2:14, text:'Florida'},
	{id:'GA', id2:15, text:'Georgia'},
	{id:'HI', id2:17, text:'Hawaii'},
	{id:'ID', id2:18, text:'Idaho'},
	{id:'IL', id2:19, text:'Illinois'},
	{id:'IN', id2:20, text:'Indiana'},
	{id:'IA', id2:21, text:'Iowa'},
	{id:'KS', id2:22, text:'Kansas'},
	{id:'KY', id2:23, text:'Kentucky'},
	{id:'LA', id2:24, text:'Louisiana'},
	{id:'ME', id2:25, text:'Maine'},
	{id:'MD', id2:26, text:'Maryland'},
	{id:'MA', id2:28, text:'Massachusetts'},
	{id:'MI', id2:29, text:'Michigan'},
	{id:'MN', id2:30, text:'Minnesota'},
	{id:'MS', id2:31, text:'Mississippi'},
	{id:'MO', id2:32, text:'Missouri'},
	{id:'MT', id2:33, text:'Montana'},
	{id:'NE', id2:34, text:'Nebraska'},
	{id:'NV', id2:35, text:'Nevada'},
	{id:'NH', id2:36, text:'New Hampshire'},
	{id:'NJ', id2:37, text:'New Jersey'},
	{id:'NM', id2:38, text:'New Mexico'},
	{id:'NY', id2:39, text:'New York'},
	{id:'NC', id2:40, text:'North Carolina'},
	{id:'ND', id2:41, text:'North Dakota'},
	{id:'OH', id2:43, text:'Ohio'},
	{id:'OK', id2:44, text:'Oklahoma'},
	{id:'OR', id2:45, text:'Oregon'},
	{id:'PA', id2:47, text:'Pennsylvania'},
	{id:'RI', id2:49, text:'Rhode Island'},
	{id:'SC', id2:50, text:'South Carolina'},
	{id:'SD', id2:51, text:'South Dakota'},
	{id:'TN', id2:52, text:'Tennessee'},
	{id:'TX', id2:53, text:'Texas'},
	{id:'UT', id2:54, text:'Utah'},
	{id:'VT', id2:55, text:'Vermont'},
	{id:'VA', id2:56, text:'Virginia'},
	{id:'WA', id2:58, text:'Washington'},
	{id:'WV', id2:59, text:'West Virginia'},
	{id:'WI', id2:60, text:'Wisconsin'},
	{id:'WY', id2:61, text:'Wyoming'}
];

export const SelectOneArray = [
	{id:'', text:'Select...'}
];
export const SelectOneBlankArray = [
	{id:'', text:''}
];

export const ProgramStatusArray = [
	{id:0, text:'Enabled'},
	{id:1, text:'Disabled'},
];

export const BenefitStatusArray = [
	{id:0, text:'Issued'},
	{id:1, text:'Expired'},
	{id:2, text:'Expunged'},
	{id:3, text:'Voided'},
];

export const BankAccountTypeArray = [
	{id:'', text:'Select One'},
	{id:0, text:'Checking'},
	{id:1, text:'Savings'},
];

export const MerchantStatusArray = [
	{id:0, text:'Active'},
	{id:1, text:'Inactive'},
	{id:2, text:'Deleted'},
];

export const UserStatus = {Disabled:3, Deleted:4};
export const UserStatusArray = [
	{id:0, text:'Pending'},
	{id:1, text:'Registered'},
	{id:2, text:'Enabled'},
	{id:3, text:'Disabled'},
	{id:4, text:'Deleted'},
];

export const PanStatusArray = [
	{id:0, text:'Active'},
	{id:1, text:'Lost'},
	{id:2, text:'Stolen'},
	{id:3, text:'Used'},
	{id:4, text:'Inactive'},
];

export const PanTypeArray = [
	{id:0, text:'Virtual'},
	{id:1, text:'Printed'},
	{id:2, text:'Manual'},
];

export const BenefitTypeArray = [
	{id:1, text:'Regular'},
	{id:2, text:'Incentive'},
];

export const PrivilegeCodes = {
	recipientAccess:'RECIPIENT_ACCESS',
	programAccess:'PROGRAMADMIN_ACCESS',
	adminAccess:'NDGADMIN_ACCESS',
	agencyAdminAccess:'AGENCYADMIN_ACCESS',
	agencyUserAccess:'AGENCYUSER_ACCESS',
	userView:'USER_VIEW',
	userAdd:'USER_ADD',
	userUpdate:'USER_UPDATE',
	userDelete:'USER_DELETE',
	recipientView:'RECIPIENT_VIEW',
	recipientAdd:'RECIPIENT_ADD',
	recipientUpdate:'RECIPIENT_UPDATE',
	recipientDelete:'RECIPIENT_DELETE',
	merchantView:'MERCHANT_VIEW',
	merchantAdd:'MERCHANT_ADD',
	merchantUpdate:'MERCHANT_UPDATE',
	merchantDelete:'MERCHANT_DELETE',
	bankView:'BANK_VIEW',
	bankAdd:'BANK_ADD',
	bankUpdate:'BANK_UPDATE',
	benefitView:'BENEFIT_VIEW',
	benefitAdd:'BENEFIT_ADD',
	benefitUpdate:'BENEFIT_UPDATE',
	benefitDelete:'BENEFIT_DELETE',
	programView:'PROGRAM_VIEW',
	programAdd:'PROGRAM_ADD',
	programUpdate:'PROGRAM_UPDATE',
	programDelete:'PROGRAM_DELETE',
	trxView:'TRANSACTION_VIEW',
	trxAdd:'TRANSACTION_ADD',
	trxUpdate:'TRANSACTION_UPDATE',
	notifView:'NOTIFICATION_VIEW',
	notifAdd:'NOTIFICATION_ADD',
	notifUpdate:'NOTIFICATION_UPDATE',
	notifPrefView:'NOTIFICATION_PREF_VIEW',
	notifPrefUpdate:'NOTIFICATION_PREF_UPDATE',
	panView:'PAN_VIEW',
	panAdd:'PAN_ADD',
	panUpdate:'PAN_UPDATE',
	extPanView:'EXTERNAL_PAN_VIEW',
	extPanViewFull:'EXTERNAL_PAN_VIEW_FULL',
	extPanAdd:'EXTERNAL_PAN_ADD',
	extPanUpdate:'EXTERNAL_PAN_UPDATE',
	extPanDelete:'EXTERNAL_PAN_DELETE',
	roleAdd:'ROLE_ADD',
	roleView:'ROLE_VIEW',
	roleUpdate:'ROLE_UPDATE',
	privilegeView:'PRIVILEGE_VIEW',
	incentiveView:'INCENTIVE_VIEW',
	incentiveAdd:'INCENTIVE_ADD',
	incentiveUpdate:'INCENTIVE_UPDATE',
	recipIncentiveAdd:'RECIPIENT_INCENTIVE_ADD',
	recipIncentiveUpdate:'RECIPIENT_INCENTIVE_UPDATE',
	recipIncentiveDelete:'RECIPIENT_INCENTIVE_DELETE',
	agencyAdd:'AGENCY_ADD',
	agencyUpdate:'AGENCY_UPDATE',
	agencyView:'AGENCY_VIEW',
	userProgramAdd:'PROGRAM_USER_ADD',
	userProgramDelete:'PROGRAM_USER_DELETE',
	userProgramView:'PROGRAM_USER_VIEW',
	otpReset:'OTP_RESET',
};
