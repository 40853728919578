import React from 'react';

import Strings from './Strings';

export function getApiUrl(){
    return process.env.REACT_APP_API_URL;
}
export function getAccountsApiUrl(){
    return process.env.REACT_APP_ACCOUNT_API_PATH;
}
export function getMerchantsApiUrl(){
    return process.env.REACT_APP_MERCHANT_API_PATH;
}
export function getBenefitsApiUrl(){
    return process.env.REACT_APP_BENEFIT_API_PATH;
}
export function getUserApiUrl(){
    return process.env.REACT_APP_USER_API_PATH;
}
export function getAdminApiUrl(){
    return process.env.REACT_APP_ADMIN_API_PATH;
}
export function getTransactionApiUrl(){
    return process.env.REACT_APP_TRX_API_PATH;
}

/* Pass in object to return as mocked response */
export function mockApiCall(response){
    return new Promise( (resolve) => { setTimeout( () => resolve(response) , 400); } );
}

export function apiGet(url) {
    let options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
	};
	
    return fetchCall(url, options);
}

export function apiLogin(url, body) {
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
	};
	
    if(body)
        options.body = JSON.stringify(body);

    return fetch(url, options).then(async response => {
        const data = await response.json();
        //console.debug('response.status: ' + response.status);

        if (response.ok) {
            return data;
        }
        else {
            console.error(response.status + ': ' + response.statusText);
            if (response.status == 401) {
                let errorResponse = {
                    status: response.status,
                    message: Strings.error.logon,
                    loginError: true
                };
                return Promise.reject(errorResponse);
            }
            else {
                return Promise.reject({status:response.status,data:data});
            }
        }

    });
}

export function apiPost(url, body) {
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
	};
	
    if(body)
        options.body = JSON.stringify(body);

	return fetchCall(url, options);
}

export function apiPostFile(url, file) {
    let options = {
        method: 'POST',
        mode: "cors",
        body:file
	};
	
	return fetchCall(url, options);
}

export function apiPut(url, body) {
    let options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
	};
	
    if(body)
        options.body = JSON.stringify(body);

	return fetchCall(url, options);
}

export function apiPatch(url, body) {
    let options = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
	};
	
    if(body)
        options.body = JSON.stringify(body);

	return fetchCall(url, options);
}

export function apiDelete(url, body) {
    let options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
	};
	
    if(body)
        options.body = JSON.stringify(body);

	return fetchCall(url, options);
}

function fetchCall(url, options){
    //console.debug(url);
    //console.debug(options);

    return fetch(url, options).then(async response => {
        const textResponse = await response.text();
        let data = {};
        if (textResponse && response.status != 204) {
            try {
                data = JSON.parse(textResponse);
            } catch (e) {
                console.debug(e);
            }
        }
        //console.debug('response.status: ' + response.status);

        if (response.ok) {
            return data;
        }
        else {
            console.error(response.status + ': ' + response.statusText);
            if (response.status == 401) {
                window.location.href = "/logout";
            }
            else if (response.status == 403) {
                let errorResponse = {
                    status: response.status,
                    message: Strings.error.privilege,
                    privilegeError: true
                };
                return Promise.reject(errorResponse);
            }
            else {
                return Promise.reject({status:response.status,data:data});
            }
        }
    });
}

export function apiMessages(err) {
    console.error(err.data);
    let messagesText = null;
    if(err.data.expandedErrors && err.data.expandedErrors.length){
        let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
        messagesText = <>{messages}</>;
    }
    else if(err.data.errorMessages && err.data.errorMessages.length){
        let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
        messagesText = <>{messages}</>;
    }
    else if(err.data.appErrorMessage){
        messagesText = err.data.appErrorMessage;
    }
    return messagesText;
};
