import React, { useState, useEffect } from 'react';

import { apiGet, apiPut, getAccountsApiUrl } from '../common/AppApi';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';


export default function NotificationPreferences(props) {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [preferences, setPreferences] = useState([]);
	
	let getPreferences = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/notifications/preferences';
		apiGet(url).then((resp)=> {
			//console.debug(resp);
			let prefs = [];
			if (resp && resp.length) {
				resp.forEach( item => {
					prefs.push({
						eventId:item.eventId,
						methodId:item.methodId,
						descr:item.descr,
						selected:item.selected
					});
				});
				setPreferences(prefs);
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	}

	let CheckBox = (props) => {
		return (<>
			<div className="form-row">
			<div className="form-check col-6 col-md-4">
				<label className="form-check-label">
				<input className="form-check-input" type="checkbox" name="eventId" value={props.item.eventId} defaultChecked={props.item.selected} />
				{props.item.descr}</label>
			</div>
			<div className="col-3 col-md-2">
				<label>
				<input className="radioBtn" type="radio" name={'methodId' + props.item.eventId} value="1" defaultChecked={props.item.methodId=='1'}/>
				 &nbsp;Email
				</label>
			</div>
			<div className="col-3 col-md-2">
				<label>
				<input className="radioBtn" type="radio" name={'methodId' + props.item.eventId} value="2" defaultChecked={props.item.methodId=='2'}/>
				 &nbsp;SMS
				</label>
			</div>
			</div>
		</>)
	};

	let CheckBoxes = preferences.map( (item, index) => {
		return <CheckBox key={item.eventId} item={item} />;
	});

	useEffect( () => {
		getPreferences();
	}, [] ); // empty array, run once

	let onSubmit = () => {
		let body = {eventIds:[], methodIds:[]};
		let myForm = document.getElementById('preferences');
		for(var i=0; i<myForm.elements.length; i++){
			var myElement = myForm.elements[i];
			if (myElement.type == 'checkbox' && myElement.checked) {
				let eventId = myElement.value;
				let inputName = 'methodId' + myElement.value;
				let methodInput = myForm.elements[inputName];
				body.eventIds.push(eventId);
				body.methodIds.push(methodInput.value);
			}
		}
		console.debug(body);

		// update state
		let clone = [];
		preferences.forEach( item => {
			let c = {
				eventId:item.eventId,
				methodId:1,
				descr:item.descr,
				selected:false
			};

			item.selected = false;
			for(var i=0; i<body.eventIds.length; i++){
				if(body.eventIds[i] == item.eventId){
					c.selected = true;
					c.methodId = body.methodIds[i];
					break;
				}
			}

			clone.push(c);
		});
		setPreferences(clone);

		setSpinner(true);
		let url = getAccountsApiUrl() + '/notifications/preferences';

		apiPut(url, body).then((resp)=> {
			console.debug(resp);
			setMessage({text:"Preferences updated.",type:'success'});
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

    return (
        <>
        <Processing show={showSpinner}/>
		<MessageTag message={message} />

		<h4>Preferences</h4>
		<p>{Strings.notifications.preferenceInfo}</p>
		<form id="preferences" style={{padding:".5em 2em"}} onSubmit={ () => false}>
			{CheckBoxes}
		</form>
		<button type="button" className="btn btn-primary" onClick={onSubmit}>Save Preferences</button>

        </>
    )
}
