import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getAdminApiUrl } from '../common/AppApi';
import Strings from '../common/Strings';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import moment from 'moment';

export default function Configurations() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [configs, setConfigs] = useState([]);

	const history = useHistory();
	let GoTo = (id) => history.push('/admin/configuration/' + id);

	let loadConfigs = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/config';

		apiGet(url).then((resp)=> {
			setConfigs(resp);
			console.debug(resp);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		loadConfigs();
	}, []);

	let TableRow = (props) => {
		let myStyle = {display:'inline-block',width:'24em',overflow:'hidden',textOverflow:'ellipsis',whitespace:'nowrap'};
		let updated = moment(props.config.updated).utc().format("YYYY-MM-DD HH:mm:ss");
		return <tr onClick={() => GoTo(props.config.configKey)} className='clickable'>
			<td>{props.config.configKey}</td>
			<td><div style={myStyle}>{props.config.configValue}</div></td>
			<td>{props.config.effectiveDate} </td>
			<td>{updated}</td>
			</tr>;
	};

	let TableBody = configs.map( (config, index) => {
		return <TableRow key={index} config={config}/>;
	});

	let TableDisplay = () => {
		return (
		<table className='table table-bordered table-hover'>
		<thead>
			<tr>
				<th>Key</th>
				<th>Value</th>
				<th>Effective Date</th>
				<th>Updated</th>
			</tr>
		</thead>
		<tbody>
			{TableBody}
		</tbody>
		</table>
		);
	};

	return (
		<>
			<Processing show={showSpinner}/>
			<Link to="/admin/configuration/add" className="btn btn-primary btn-sm pull-right">New Config Key</Link>
			<h3>Configuration</h3>
			<MessageTag message={message} />

			<TableDisplay />
		</>
	)
}
