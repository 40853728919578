import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";

import { apiGet, getAdminApiUrl } from '../common/AppApi';
import Strings from '../common/Strings';
import { stripTime } from '../common/Formats';

import { selectOptions } from '../common/Forms';
import { StateArray, PageSize, ProgramStatusArray, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { HelpTooltip } from '../common/AppIcons';

export default function Privileges() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let [privileges, setPrivileges] = useState([]);
	let [selectedRole, setSelectedRole] = useState(-1);

	const history = useHistory();
	let GoTo = (id) => history.push('/admin/roles/' + id);

	let loadPrivileges = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/privileges';

		apiGet(url).then((resp)=> {
			setPrivileges(resp);
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		loadPrivileges();
	}, []);

	let TableRow = (props) => {
		return <tr>
			<td>{props.privilege.privilegeId}</td>
			<td>{props.privilege.code}
			</td>
			<td>{props.privilege.descr}</td>
			</tr>;
	};

	let TableBody = privileges.map( (priv, index) => {
		return <TableRow key={priv.privilegeId} privilege={priv}/>;
	});

	let TableDisplay = () => {
		if (privileges && privileges.length) {
			return (
			<table className='table table-bordered'>
			<thead>
				<tr>
					<th>Privilege Id</th>
					<th>Code</th>
					<th>Descr</th>
				</tr>
			</thead>
			<tbody>
				{TableBody}
			</tbody>
			</table>
			);
		} else {
			return null;
		}
	};


	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Privileges</h3>
		<MessageTag message={message} />

		<TableDisplay />

		</>
	)
}
