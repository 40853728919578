import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useUserContext from '../common/UserContext';

import { apiGet, getTransactionApiUrl, apiMessages, getAccountsApiUrl } from '../common/AppApi';

import Processing from '../common/Processing';
import Pagination, { resetPaging } from '../common/Pagination';

import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { selectOptions } from '../common/Forms';
import { decodeValues, decodeValues2, formatCurrency } from '../common/Formats';

import { PageSize, PrivilegeCodes } from '../common/Constants';

import DateTimePicker from 'react-datetime-picker';

import { HelpTooltip } from '../common/AppIcons';

import moment from 'moment';

import { useQuery } from '../common/CustomHooks';

export default function TransactionSearch(props) {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();
	let [downloadUrl, setDownloadUrl] = useState('');

	let maxDate = new Date();
	maxDate.setDate(new Date().getDate() + 183);

	let {user} = useUserContext();

	let basePath = props.basePath;
	const history = useHistory();
	let GoTo = (id) => history.push(basePath + '/transaction/' + id);

	let query = useQuery();
	let rid = query.get('rid');

	let emptyForm = {
		startDate:'',
		endDate:'',
		trxTypeId:'',
		respCodeId:'',
		programUuid:'',
		merchantNbr:'',
		agencyUuid:'',
		recipientIdentifier:'',
		accountUuid:''
	};
	let [searchForm, setSearchForm] = useState(emptyForm);

	let [responseCodes, setResponseCodes] = useState([]);
	let getResponseCodes = () => {
		let url = getTransactionApiUrl() + '/response-codes';
		apiGet(url).then((resp)=> {
			let codes = resp.map( (item) => {
				return {id:item.responseCodeId,text:item.responseCode + ' ' + item.descr};
			});
			codes.unshift({id:null,text:''});
			setResponseCodes(codes);
		}).catch( (err)=> {
			console.error(err);
		});
	};

	let [transactionTypes, setTransactionTypes] = useState([]);
	let getTransactionTypes = () => {
		let url = getTransactionApiUrl() + '/transaction-types';
		apiGet(url).then((resp)=> {
			let types = resp.map( (item) => {
				return {id:item.transactionTypeId,text:item.descr};
			});
			types.unshift({id:null,text:''});
			setTransactionTypes(types);
		}).catch( (err)=> {
			console.error(err);
		});
	};

	let programs = props.programs;

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let viewable = user.privileges.includes(PrivilegeCodes.agencyAdminAccess) || user.privileges.includes(PrivilegeCodes.agencyUserAccess) ? '?viewable' : '';
		let url = getAccountsApiUrl() + '/agency' + viewable;
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getResponseCodes();
		getTransactionTypes();
		getAgencies();
		if (rid) {
			console.log('rid ' + rid);
			setSearchForm({...searchForm, recipientIdentifier:rid});
		}
	}, []);

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let handleDayChange = (inputName) => {
		return function onChange(newDate){
			setSearchForm({...searchForm, [inputName]:newDate});
		}
	}

	let disableSearch = () => {
		for(const searchField in searchForm){
			if(searchForm[searchField])
				return false;
		}
		return true;
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		handleSubmit2(0);
	};

	let handleSubmit2 = (currentPage) => {
		//console.debug(searchForm);
		setSpinner(true);

		let url = getTransactionApiUrl() + '/transactions';

		let queryParams = [];
		for(const searchField in searchForm){
			if(searchForm[searchField]){
				if (searchField == 'startDate') {
					//construct time string to search with selected time.
					//seconds default to 0 for start time.
					let date = new Date(searchForm[searchField]);
					let timeString = "T" + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":00";
					
					queryParams.push(searchField + '=' + encodeURIComponent(moment(searchForm[searchField]).format("YYYY-MM-DD") + timeString));
				} else if (searchField == 'endDate'){
					//seconds default to 59 for end time.
					let date = new Date(searchForm[searchField]);
					let timeString = "T" + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":59";
					
					queryParams.push(searchField + '=' + encodeURIComponent(moment(searchForm[searchField]).format("YYYY-MM-DD") + timeString));
				} else {
					queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
				}
			}
		}

		// these two are overridden by the backend for file exports
		queryParams.push("pageSize=" + PageSize);
		queryParams.push("page=" + currentPage);
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		// create file download url with query parameters
		let fileDownload = window.location.protocol + '//' + window.location.host + getTransactionApiUrl() + '/transactions/export?' + queryParams.join('&');
		setDownloadUrl(fileDownload);

		if ('production' != process.env.NODE_ENV) {
			console.debug('downloadUrl port will be incorrect in dev mode ' + fileDownload);
		}

		apiGet(url).then((resp) => {
			//console.debug(resp);
			setDataRows(resp.data);
			let pagingData = resetPaging();
			pagingData.currentPage = resp.page + 1;
			pagingData.recordCount = resp.totalCount;
			pagingData.pageCount = resp.totalPages;
			setPagingData(pagingData);
			if(resp && resp.data && resp.data.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No transactions found.',type:'warning'});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let nextPage = ()=> {
		let nextData = {...pagingData};
		nextData.currentPage = pagingData.currentPage + 1;
		setPagingData(nextData);
		handleSubmit2(nextData.currentPage - 1);
	};

	let prevPage = ()=> {
		let nextData = {...pagingData};
		nextData.currentPage = pagingData.currentPage - 1;
		setPagingData(nextData);
		handleSubmit2(nextData.currentPage - 1);
	};

	let gotoPage = (pageNo)=> {
		let nextData = {...pagingData};
		nextData.currentPage = pageNo;
		setPagingData(nextData);
		handleSubmit2(nextData.currentPage - 1);
	};

	let TableRow = (props) => {
		let adjDate = moment(props.row.trxDatetime).format('YYYY-MM-DD HH:mm:ss');
		return (
			<tr onClick={ () => GoTo(props.row.trxUuid)} className="clickable">
			<td>{adjDate}</td>
			<td>{props.row.programName}</td>
			<td>{decodeValues(props.row.trxTypeId, transactionTypes)}</td>
			<td>{formatCurrency(props.row.reqAmt)}</td>
			<td>{formatCurrency(props.row.settleAmt)}</td>
			<td>{decodeValues(props.row.respCodeId, responseCodes)}</td>
			<td></td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return <TableRow key={index} row={data} />
	});

	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table" id="tblResults">
					<thead>
						<tr>
							<th>Transaction Date</th>
							<th>Program</th>
							<th>Type</th>
							<th>Request Amount</th>
							<th>Settled Amount</th>
							<th>Response</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
					<tfoot><tr><td className='text-right' colSpan={6}>
						Export:&nbsp;
						<a href={downloadUrl + '&exportType=xlsx'} className='btn btn-primary btn-sm' download="transactions.xlsx" title="Export as excel">Excel</a>
						&nbsp;
						<a href={downloadUrl} className='btn btn-primary btn-sm' download="transactions.csv" title="Export as csv">CSV</a>
					</td></tr></tfoot>
				</table>
				<Pagination recordCount={pagingData.recordCount} pageCount={pagingData.pageCount} pagingData={pagingData} setPagingData={() => {}} pageSize={PageSize}
				 nextPage={nextPage} prevPage={prevPage} gotoPage={gotoPage}/>
				</>
			);
		return null;
	};

    return (
        <>
        <Processing show={showSpinner}/>
		<h3>Transactions</h3>
		<MessageTag message={message} />

		<form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={searchForm.programUuid} onChange={handleFieldChange} >
						{ selectOptions(programs) }
					</select>
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={searchForm.agencyUuid} onChange={handleFieldChange} >
						{ selectOptions(agencies) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="startDate">Start Date</label>
					<br />
					<DateTimePicker value={searchForm.startDate} onChange={handleDayChange("startDate")} className={"form-control"} minDate={new Date(2022,1,1)} maxDate={maxDate} format="yyyy-MM-dd HH:mm:ss" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="endDate">End Date</label>
					<br />
					<DateTimePicker value={searchForm.endDate} onChange={handleDayChange("endDate")} className={"form-control"} minDate={new Date(2022,7,1)} maxDate={maxDate} format="yyyy-MM-dd HH:mm:ss" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="trxTypeId">Transaction Type</label>
					<select id="trxTypeId" className="form-control custom-select" value={searchForm.trxTypeId} onChange={handleFieldChange} >
						{ selectOptions(transactionTypes) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="respCodeId">Response</label>
					<select id="respCodeId" className="form-control custom-select" value={searchForm.respCodeId} onChange={handleFieldChange} >
						{ selectOptions(responseCodes) }
					</select>
				</div>

				<div className="form-group col-12 col-sm-3">
					<label htmlFor="recipientIdentifier">Recipient Account ID <HelpTooltip title="Full Recipient Account ID"/></label>
					<input type="text" id="recipientIdentifier" className="form-control" value={searchForm.recipientIdentifier} onChange={handleFieldChange} maxLength={20}/>
				</div>

				<div className="form-group col-12 col-sm-2">
					<label htmlFor="merchantNbr">Merchant ID</label>
					<input type="text" id="merchantNbr" className="form-control" value={searchForm.merchantNbr} onChange={handleFieldChange} maxLength={20}/>
				</div>

			</div>
			<button className="btn btn-primary" disabled={disableSearch()} id="btnSearch">Search</button>
		</form>

		<Table />

        </>
    )
}
