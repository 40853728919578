import React, { useState, useEffect } from 'react';
import useUserContext from '../common/UserContext';
import { useHistory, useParams, Link } from "react-router-dom";

import { apiGet, getAccountsApiUrl, apiMessages } from '../common/AppApi';

import Strings from  '../common/Strings';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { EyeIcon, HelpTooltip } from '../common/AppIcons';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';

export default function EBTCards() {
    let {user, setUser} = useUserContext();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [ebtCards, setEbtCards] = useState([]);
	let [alertSettings, setAlertSettings] = useState({title:'Full PAN',type:'info',body:'pan here',size:'small',button:'Close'});
	let [returnTo, setReturnTo] = useState(null);
	const history = useHistory();

    let { id } = useParams();

	let getEBTCards = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/external-pan/all/' + id;
		apiGet(url).then((resp)=> {
			if (resp && resp.length > 0) {
				setEbtCards(resp);
			} else {
				setEbtCards([]);
				setMessage({text:'There are no EBT cards attached to this account.', type:'light'});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		getEBTCards();
		if (!returnTo && history.location.state && history.location.state.from) {
			setReturnTo(history.location.state.from);
		}
	}, []);

	let showPanDailog = (pan) => {
		let settings = {...alertSettings};
		settings.body = pan;
		setAlertSettings(settings);
		showAlertDialog();
	};

	let ShowPan = (props) => {
		if (props.pan && props.pan.length > 6) {
			return <><a href='#' onClick={() => showPanDailog(props.pan)}><EyeIcon title="View full PAN" /></a></>
		} else {
			return null;
		}
	};

	let DisplayRow = (props) => {
		let pan = props.card.pan.length > 6 ? '*' + props.card.pan.substring(props.card.pan.length - 4) : props.card.pan;
		return <tr>
			<td>{props.card.name}</td>
			<td>{pan} <ShowPan pan={props.card.pan}/></td>
			<td>{props.card.externalPanType.descr}</td>
			<td>{props.card.externalPanStatus.descr}</td>
			<td>{props.card.programName}</td>
			</tr>
	};

	let TableBody = ebtCards.map( (item, index) => {
		return <DisplayRow key={index} card={item} />;
	});

	let GoBack = () => {
		if (returnTo) {
			history.push(returnTo)
		} else {
			history.goBack();
		}
	};

	let DisplayCards = () => {
		if (ebtCards && ebtCards.length) {
			return <>
				<table className='table table-bordered'>
					<thead>
						<tr>
							<th>Name</th>
							<th>Pan</th>
							<th>Type</th>
							<th>Status</th>
							<th>Program</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
			</>
		} else {
			return null;
		}
	};

    return (
        <>
		<Processing show={showSpinner}/>
		<h3>EBT Cards</h3>
		<MessageTag message={message} />
		<a href='#' onClick={GoBack}>Back to Recipient</a>

		<DisplayCards />
		<AlertDialog settings={alertSettings}/>
        </>
        )
}
