import React, {useEffect, useState} from 'react';

import {apiPostFile, getBenefitsApiUrl} from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import useUserContext from '../common/UserContext';

export default function BenefitFile() {
    let {user} = useUserContext();

	let blankForm = {
		programUuid:'',
		benefitFile:''
	}
	let [uploadForm, setUploadForm] = useState(blankForm);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let handleFileChange = (event) => {
		let cloned = {...uploadForm};
		cloned.benefitFile = event.target.files[0];
		setUploadForm(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();

		console.debug('file size ' + uploadForm.benefitFile.size);
		const maxSize = 1024 * 1024;
		if (uploadForm.benefitFile.size > maxSize) {
			setMessage({text:'Benefit file size cannot be greater than 1MB.',type:'warning'});
			return false;
		}
		setSpinner(true);

		let url = getBenefitsApiUrl() + '/benefit/file';
		let formData = new FormData();
		formData.append('benefitFile', uploadForm.benefitFile);

		apiPostFile(url, formData).then((resp)=> {
			console.info(resp);
			//setUploadForm(blankForm);
			let messageObject = {text:'Benefit file processed.',type:'success'};
			let messages = [];
			if(resp && resp.processed)
				messages.push(resp.processed + ' benefit rows processed.');
			if(resp && resp.added)
				messages.push(resp.added + ' benefit(s) added.');
			if(resp && resp.updated)
				messages.push(resp.updated + ' benefit(s) updated.');

			let messageText = messages.map( (value, index) => <div key={index}>{value}</div>);
			messageObject.text = <>{messageText}</>;

			setMessage(messageObject);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				console.error(err.data.errorMessages);
				if(err.data.expandedErrors && err.data.expandedErrors.length){
					let messages = err.data.expandedErrors.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
					messageObject.text = <>{messages}</>;
				}
				else if(err.data.errorMessages && err.data.errorMessages.length){
					let messages = err.data.errorMessages.map( (value, index) => <div key={index}>{value.appErrorMessage}</div>);
					messageObject.text = <>{messages}</>;
				}
			} else if (err.status == 422){
				messageObject = {text:'Invalid file content.',type:'danger'};
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	return (
		<>
		<Processing show={showSpinner} />

		<h3>Upload Benefit File</h3>
        <MessageTag message={message} />
		
		<label style={{fontStyle: 'italic', fontSize: 13}}>Benefit Files need to be CSV or Excel files in a specific format. <br /> Example files are available for download below for assistance. </label>
		<div>	
			<a href="/ExampleBenefitFiles/BenefitFileExample.csv" download="BenefitFileExample.csv">Download Example CSV File</a>
			<br />
			<a href="/ExampleBenefitFiles/BenefitFileExample.xlsx" download="BenefitFileExample.xlsx">Download Example Excel File</a>
		</div>

		<br />

        <form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group col-12 col-md-6">
					<label htmlFor="benefitFile" className="btn btn-info">Select Benefit File <input type="file" id="benefitFile" name="benefitFile" className="form-control-file d-none" onChange={handleFileChange} required accept=".csv,.xls,.xlsx"/></label>
					<br/><h4>&nbsp;{uploadForm.benefitFile.name}</h4>
				</div>
			</div>
			<button className="btn btn-primary">Upload File</button>
		</form>

		</>
	)
}
