import React, { useState, useEffect } from 'react';

import AdminAgencies from '../program/Agencies';

export default function Agencies() {
	return (
		<>
		<AdminAgencies />
		</>
	)
}
