import React from 'react';

export default function MessageTag(props) {
	if(!props.message)
		return (<div className="alert">&nbsp;</div>);

	const message = props.message.text ? props.message.text : props.message;
	const msgId = props.id ? props.id : 'msgId' ;

	let cssClass = "alert alert-dismissible ";
	switch(props.message.type) {
		case 'info':
			cssClass = cssClass + "alert-info";
			break;
		case 'warning':
			cssClass = cssClass + "alert-warning";
			break;
		case 'danger':
			cssClass = cssClass + "alert-danger";
			break;
		case 'success':
			cssClass = cssClass + "alert-success";
			break;
		case 'light':
			cssClass = cssClass + "alert-light";
			break;
		default:
			cssClass = cssClass + "alert-primary";
	}

	return (
		<div className={cssClass} id={msgId}>
		{message}
		</div>
	);
}
