import React,{useState, useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';

import { UserContext } from './common/UserContext';
import {getUser} from './common/AppAuth';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

export default function App() {
  let savedUser = getUser();
  let [user, setUser] = useState( savedUser ? savedUser : {} );
  let contextValue = {user, setUser};

  return (
    <BrowserRouter>
      <UserContext.Provider value={contextValue}>
      <Header />
      <Content />
      </UserContext.Provider>
      <Footer />
    </BrowserRouter>
  );
}
