import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";

import { formatCurrency, formatPhone, stripTime } from '../common/Formats';
import { ExpiringIcon, ExpiredIcon } from '../common/AppIcons';
import { ExpirationDays } from '../common/Constants';

export default function ProgramBenefits(props) {
	let benefits = props.benefits;

	if (benefits && benefits.length) {
		let expiryAlert = (expDate, days) => {
			let today = new Date();
			let expiringDate = new Date();
			expiringDate.setDate(today.getDate() + days);
			let expiredIcon = (today > expDate) ? (<ExpiredIcon />) : null;
			let expiringIcon = (!expiredIcon && expiringDate > expDate) ? (<ExpiringIcon />) : null;
			return (expiredIcon ? expiredIcon : (expiringIcon ? expiringIcon : null));
		};
	
		let TableRow = (props) => {
			let alerts = expiryAlert(new Date(stripTime(props.row.benefitExpiryDate)), ExpirationDays);
			return (
				<tr>
				<td>{stripTime(props.row.benefitAvailableDate)}</td>
				 <td>{stripTime(props.row.benefitExpiryDate)} {alerts}</td>
				<td>{formatCurrency(props.row.benefitAmount)}</td>
				<td>{props.row.benefitAuthNumber}</td>
				</tr>
			);
		};
		let TableBody = benefits.map( (item, index) => {
			return (<TableRow key={item.benefitId} row={item} />);
		});
	
		return (
			<>
			<div className="card border-primary">
				<div className="card-header">{props.title}</div>
				<div className="card-body">
					<table className="table">
						<thead>
							<tr>
								<th>Available Date</th>
								<th>Expiration Date</th>
								<th>Amount</th>
								<th>Authorization Number</th>
							</tr>
						</thead>
						<tbody>
							{TableBody}
						</tbody>
					</table>
				</div>
			</div>
			</>		  
			);
	}
	else {
		return null;
	}

}
