import React from 'react';

export function closeAlertDialog() {
	document.getElementById('alertClose').click();
}

export function showAlertDialog() {
	window.jQuery('#alertDialog').modal();
}

export default function AlertDialog(props) {
	let title = props.settings.title ? props.settings.title : 'Alert';
	let body = props.settings.body ? props.settings.body : '';
	let type = props.settings.type ? props.settings.type : 'warning';
	let button = props.settings.button ? props.settings.button : 'OK';

	let modalSize = 'modal-dialog';
	if (props.settings.size == 'small') {
		modalSize += ' modal-sm';
	} else if (props.settings.size == 'large') {
		modalSize += ' modal-lg';
	}

	let btnClass = "btn";
	let headerClass = "modal-header alert";
	switch(type) {
		case 'info':
			btnClass = btnClass + " btn-info";
			headerClass = headerClass + " alert-info";
			break;
		case 'warning':
			btnClass = btnClass + " btn-warning";
			headerClass = headerClass + " alert-warning";
			break;
		case 'danger':
			btnClass = btnClass + " btn-danger";
			headerClass = headerClass + " alert-danger";
			break;
		case 'success':
			btnClass = btnClass + " btn-success";
			headerClass = headerClass + " alert-success";
			break;
		case 'secondary':
			btnClass = btnClass + " btn-secondary";
			headerClass = headerClass + " alert-secondary";
			break;
		default:
			btnClass = btnClass + " btn-primary";
			headerClass = headerClass + " alert-primary";
			break;
	}

	return (
		<div className="modal" tabIndex="-1" role="dialog" id="alertDialog">
		<div className={modalSize} role="document">
			<div className="modal-content">
			<div className={headerClass}>
				<h5 className="modal-title">{title}</h5>
				<button type="button" className="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className="modal-body">
				{body}
			</div>
			<div className="modal-footer">
				<button type="button" className={btnClass} data-dismiss="modal" id="alertClose">{button}</button>
			</div>
			</div>
		</div>
		</div>
	)
}
