import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import { apiGet, getBenefitsApiUrl, getTransactionApiUrl } from '../common/AppApi';

import { formatCurrency, formatPhone, decodeValues } from '../common/Formats';
import Processing from '../common/Processing';
import { ProgramStatusArray } from '../common/Constants';

import ProgramBenefits from './ProgramBenefits';
import ProgramTransactions from './ProgramTransactions';

import moment from 'moment';

export default function Program() {
	let [showSpinner, setSpinner] = useState(true);
	let [balance, setBalance] = useState({});
	let [program, setProgram] = useState({});

	let { id } = useParams();

	useEffect( () => {
		getBalance(id);
		getProgram(id);
	}, []);

	let getBalance = (id) => {
		let url = getBenefitsApiUrl() + '/benefit/program/' + id + '/balance';
		apiGet(url).then( (response) => {
			//console.debug(response);
			setBalance(response);
		}).catch( (error) => {
			console.error(error);
			if (error.status && error.status == 404) {
				console.error("No balance found for program " + id);
			}
		});
	};

	let getProgram = (id) => {
		setSpinner(true);
		let url = getBenefitsApiUrl() + '/benefit/program/' + id;
		apiGet(url).then( (response) => {
			//console.debug(response);
			setProgram(response);
			getBenefits(response.programId);
			getTransactions();
		}).catch( (error) => {
			console.error(error);
		}).finally(() => setSpinner(false));
	};

	let [benefits, setBenefits] = useState([]);
	let getBenefits = (programId) => {
		setSpinner(true);
		let today = moment().format('YYYY-MM-DD');
		let url = getBenefitsApiUrl() + '/benefit?startDate='+ encodeURIComponent(today)
		+ '&programId=' + programId;

		apiGet(url).then((resp) => {
			//console.debug(resp);
			if (resp && resp.data && resp.data.length)
				setBenefits(resp.data);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	let [transactions, setTransactions] = useState([]);
	let getTransactions = (programId) => {
		setSpinner(true);
		let url = getTransactionApiUrl() + '/transactions/currentuser/' + id;

		apiGet(url).then((resp) => {
			//console.debug(resp);
			setTransactions(resp.data);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

    return (
		<>
		<Processing show={showSpinner}/>

		<div className="card border-primary" id="programInfo">
			<div className="card-header"><h3>{program.programName}</h3></div>
			<div className="card-body">
				<div className="row">
					<table className="table table-bordered">
						<tbody>
							<tr>
								<th>Contact Email</th>
								<th>Contact Phone</th>
								<th>State</th>
								<th>Program Status</th>
							</tr>
							<tr>
								<td>{program.contactEmail}</td>
								<td>{formatPhone(program.contactPhone)}</td>
								<td>{program.contactStateCode}</td>
								<td>{decodeValues(program.programStatusId, ProgramStatusArray)}</td>
							</tr>
						</tbody>
					</table>
				</div>
				
				<div className="row">
					<div className="col-12 col-sm-2">Balance: {formatCurrency(balance.totalAmount)}</div>
				</div>
			</div>
		</div>
	
		<ProgramBenefits benefits={benefits} title="Benefits"/>
	
		<ProgramTransactions transactions={transactions} title="Recent Transactions"/>

		</>		  
        );
}
