import React, {useState, useEffect} from 'react';
import useUserContext from '../common/UserContext';

import { apiGet, getAdminApiUrl, apiMessages, getAccountsApiUrl, getUserApiUrl, apiDelete, apiPost } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { stripTime, decodeValues, formatPhone } from '../common/Formats';
import { selectOptions } from '../common/Forms';
import { ProgramStatusArray, PrivilegeCodes } from '../common/Constants';
import Strings from '../common/Strings';

import ConfirmDialog, { closeConfirmDialog, showConfirmDialog } from '../common/ConfirmDialog';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';

export default function Program() {
	let {user} = useUserContext();
	let [showSpinner, setSpinner] = useState(false);
    let [message, setMessage] = useState();
	let [programs, setPrograms] = useState([]);
	let [program, setProgram] = useState({});
	let [incentives, setIncentives] = useState([]);
	let [programUsers, setProgramUsers] = useState([]);
	let [users, setUsers] = useState([]);
	let [viewUsers, setViewUsers] = useState(false);
	let [modifyUsers, setModifyUsers] = useState(false);

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let programs = [];
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getPrograms();

		let privileges = user.privileges;
		if (privileges.includes(PrivilegeCodes.userProgramAdd)
			|| privileges.includes(PrivilegeCodes.userProgramDelete)) {
			setModifyUsers(true);
		}
		if (privileges.includes(PrivilegeCodes.userProgramView)) {
			setViewUsers(true);
		}

	}, []);

	useEffect(() => {
		if (programs && programs.length)
			getProgram(programs[0].id);
	}, [programs]);

	let getProgram = (id) => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/' + id;
		apiGet(url).then((resp) => {
			//console.debug(resp);
			let program = {...resp};
			program.startDate = stripTime(resp.startDate);
			program.endDate = stripTime(resp.endDate);
			setProgram(program);
			setMessage(null);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally( () => setSpinner(false) );
	};
	
	let AddressTwo = (props) => {
		if (props.address2) {
			return (<>{props.address2}<br/></>);
		} else {
			return null;
		}
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		getProgram(value);
	}

	let ProgramDropdown = () => {
		if (programs && programs.length > 1) {
			return (<>
				<form onSubmit={ e => console.debug('no action')}>
				<div className="form-group col-12 col-sm-10 col-md-6 col-lg-4">
					<label htmlFor="programUuid"><strong>Program</strong></label>
					<select id="programUuid" className="form-control custom-select" value={program.programUuid} onChange={handleFieldChange} >
						{ selectOptions( programs ) }
					</select>
				</div>
				</form>
			</>);
		} else {
			return null;
		}
	}

	let getIncentives = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive?programUuid=' + program.programUuid;
		apiGet(url).then((resp)=> {
			//console.log(resp);
			if(resp && resp.length){
				setIncentives(resp);
			} else {
				setIncentives([]);
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let getProgramUsers = (id) => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/' + id + '/users';
		apiGet(url).then((resp) => {
			//console.debug(resp);
			setProgramUsers(resp);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		if (program && program.programUuid)
			getIncentives();
		if (program && program.programUuid)
			getProgramUsers(program.programUuid);
	}, [program]);

	let IncentiveInfo = (props) => {
		let incentive = props.incentive;
		return ( 
			<tr>
				<td>{incentive.name}</td>
				<td>{incentive.startDate}</td>
				<td>{incentive.endDate}</td>
				<td>{incentive.funding.name}</td>
				<td>{incentive.matchRate.toFixed(2)}</td>
				<td>{incentive.accrualMaxDay.toFixed(2)}</td>
				<td>{incentive.accrualMaxMonth.toFixed(2)}</td>
				<td>{incentive.accrualMaxTrx.toFixed(2)}</td>
				<td>{incentive.accrualFeeRate.toFixed(2)}</td>
				<td>{incentive.redemptionFeeRate.toFixed(2)}</td>
			</tr>
		)
	};

	let incentivesRows = incentives.map( (data, index) => {
		return <IncentiveInfo key={index} incentive={data} />;
	});

	let DisplayIncentives = () => {
		if (incentives && incentives.length > 0) {
			let centerCss = {textAlign:'center'};
			return <>
				<h4>Incentives</h4>
				<table className='table table-bordered table-sm'>
					<thead>
						<tr>
							<th colSpan={5}></th>
							<th colSpan={3} style={centerCss}>Accrual Max $</th>
							<th colSpan={2} style={centerCss}>Fee Rates %</th>
						</tr>
						<tr>
							<th>Name</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Funding</th>
							<th>Match Rate %</th>
							<th>Day</th>
							<th>Month</th>
							<th>Trx</th>
							<th style={centerCss}>Accrual</th>
							<th style={centerCss}>Redemption</th>
						</tr>
					</thead>
					<tbody>
						{incentivesRows}
					</tbody>
				</table>
			</>;
		} else {
			return null;
		}
	};

	let PageTitle = () => {
		if (programs && programs.length) {
			return <>Programs</>;
		} else {
			return <>Program</>;
		}
	};

	let ShowProgramUsers = (props) => {
		if (props.users && props.users.length) {
			let ProgramUserRow = (props) => {
				return (
					<tr>
						<td>{props.row.username}</td>
						<td>{props.row.email}</td>
						<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
						<td><button type="button" className='btn btn-sm btn-primary' style={{display: modifyUsers ? 'inline' : 'none'}} onClick={ () => confirmRemoveDialog(props.row.userUuid) } >Remove</button>
						</td>
					</tr>
				)
			};
			let ProgramUsersTableBody = props.users.map( (data, index) => {
				return <ProgramUserRow key={index} row={data} />;
			});
			return (
				<>
				<h5>Program Admins</h5>
				<table className="table">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ ProgramUsersTableBody }
					</tbody>
				</table>
				<br/>
				</>
			);
		}
		return null;
	};

	let [searchForm, setSearchForm] = useState({email:'',lastname:'',username:'',agencyUuid:'', roleIds:[1]});
	let searchUsers = (e) => {
		e.preventDefault();
		setSpinner(true);

		let url = getUserApiUrl() + '/program-users';
		let queryParams = [];
		for(const searchField in searchForm) {
			queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
		}
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		apiGet(url).then((resp) => {
			//console.debug(resp);
			setUsers(resp);
			if (resp && resp.length==0) {
				setMessage({text:'No users found.', type:'warning'});
			} else {
				setMessage(null);
			}
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	let handleSearchFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...searchForm, [id]:value};
		setSearchForm(cloned);
	}

	let ShowUsers = (props) => {
		if (props.users && props.users.length) {
			let UserRow = (props) => {
				return (
					<tr>
						<td>{props.row.username}</td>
						<td>{props.row.email}</td>
						<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
						<td>
							<button type="button" className='btn btn-sm btn-primary' onClick={ () => confirmAddDialog(props.row.userUuid) }>Add Program Admin</button>
						</td>
					</tr>
				)
			};
			let UsersTableBody = props.users.map( (data, index) => {
				return <UserRow key={index} row={data} />;
			});
			return (
				<>
				<table className="table">
					<thead>
						<tr>
							<th>Username</th>
							<th>Email</th>
							<th>Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ UsersTableBody }
					</tbody>
				</table>
				<br/>
				</>
			);
		}
		return null;
	};

	let doAdd = (userUuid) => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/' + program.programUuid + '/users';
		apiPost(url, {userUuid:userUuid}).then((resp) => {
			setMessage({text: 'User added to Program.', type:'success'});
			setTimeout( () => getProgramUsers(program.programUuid), 1000);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false) );
	};

	let doRemove = (userUuid) => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/' + program.programUuid + '/users';
		apiDelete(url, {userUuid:userUuid}).then((resp) => {
			setMessage({text: 'User removed from Program.', type:'success'});
			setTimeout( () => getProgramUsers(program.programUuid), 1000);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false) );
	};

	let alreadyAssigned = (uuid) => {
		for (let programUser of programUsers){
			if (programUser.userUuid == uuid)
				return true;
		}
		return false;
	};

	let [confirmSettings, setConfirmSettings] = useState({});
	let confirmAddDialog = (uuid) => {
		if (alreadyAssigned(uuid)) {
			showAlertDialog();
		} else {
			let addAction = (uuid) => {
				closeConfirmDialog();
				doAdd(uuid);
			};
			setConfirmSettings({confirm:() => addAction(uuid),body:'Add to Program?',button:'Add'});
			showConfirmDialog();
		}
	};
	let confirmRemoveDialog = (uuid) => {
		let removeAction = (uuid) => {
			closeConfirmDialog();
			doRemove(uuid);
		};
		setConfirmSettings({confirm:() => removeAction(uuid),body:'Remove from Program?',button:'Remove'});
		showConfirmDialog();
	};

	return (
		<>
		<Processing show={showSpinner}/>
		<h3><PageTitle/></h3>
		<MessageTag message={message}/>
		<ProgramDropdown />
		<table className="table table-bordered">
			<tbody>
				<tr>
					<th>Program Name</th>
					<th>Shortname</th>
					<th>Uuid</th>
				</tr>
				<tr>
					<td>{program.programName}</td>
					<td>{program.shortName}</td>
					<td>{program.programUuid}</td>
				</tr>
				<tr>
					<th>Status</th>
					<th>Start Date</th>
					<th>End Date</th>
				</tr>
				<tr>
					<td>{decodeValues(program.programStatusId, ProgramStatusArray)}</td>
					<td>{program.startDate}</td>
					<td>{program.endDate}</td>
				</tr>
				<tr>
					<th>Contact Phone</th>
					<th>Contact Email</th>
					<th>Contact Address</th>
				</tr>
				<tr>
					<td>{formatPhone(program.contactPhone)}</td>
					<td>{program.contactEmail}</td>
					<td>
						{program.contactAddress1}<br/>
						<AddressTwo address2={program.contactAddress2} />
						{program.contactCity} {program.contactStateCode} {program.contactZip}
					</td>
				</tr>
				<tr>
					<th>Benefit Amount</th>
					<th>Benefit Expiration</th>
					<th>Incentive Expiration</th>
				</tr>
				<tr>
					<td>{program.benefitAmount ? program.benefitAmount.toFixed(2) : ''}</td>
					<td>{program.benefitExpiration}</td>
					<td>{program.incentiveExpiration}</td>
				</tr>
				<tr>
					<th>Funding Amount</th>
					<th>Funding Date</th>
					<th>Last Issue Date</th>
				</tr>
				<tr>
					<td>{program.fundingAmount ? program.fundingAmount.toFixed(2) : ''}</td>
					<td>{program.fundingDate ? program.fundingDate : program.startDate}</td>
					<td>{program.lastIssuanceDate}</td>
				</tr>
			</tbody>
		</table>

		<ShowProgramUsers users={programUsers}/>

		<div style={{display: modifyUsers ? 'block' : 'none'}}>
		<h5>Add Program Admin</h5>
        <form onSubmit={searchUsers}>
		<div className="row">
			<div className="form-group col-12 col-md-6 col-lg-2">
				<label htmlFor="username">Username</label>
				<input type="text" id="username" className="form-control" maxLength="20" value={searchForm.username} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-3">
				<label htmlFor="email">Email</label>
				<input type="text" id="email" className="form-control" maxLength="100" value={searchForm.email} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-3">
				<label htmlFor="lastname">Last Name</label>
				<input type="text" id="lastname" className="form-control" maxLength="45" value={searchForm.lastname} onChange={handleSearchFieldChange} />
			</div>
			<div className="form-group col-12 col-md-6 col-lg-2"><br/>
				<button className="btn btn-primary" id="userSearch">Search</button>
			</div>
		</div>
		</form>
		</div>

		<ShowUsers users={users}/>
		<br/>

		<DisplayIncentives />

		<AlertDialog settings={{body:'This user is already assigned to the Program.', type:'info', title:'Notice'}} />
		<ConfirmDialog settings={confirmSettings} />
		</>
	);
}
