import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";

import { apiPut, apiGet, getAccountsApiUrl, apiMessages } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { StateArray } from '../common/Constants';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';
import { cleanPhone, formatPhone } from '../common/Formats';
import Strings from  '../common/Strings';

import AddBenefitForm from './AddBenefitForm';

import ConfirmDialog, { closeConfirmDialog, showConfirmDialog } from '../common/ConfirmDialog';

export default function Recipient(props) {
	let blankRecipient = {
		accountUuid: '',
		recipientIdentifier: '',
		accountStatusId: '',
		email: '',
		firstname: '',
		lastname: '',
		middlename: '',
		mobilePhone: '',
		cardIssuanceCode: false,
		address1: '',
		address2: '',
		city: '',
		stateCode: '',
		zipcode: ''
	};
	
	let [recipient, setRecipient] = useState(blankRecipient);
	let [formChanged, setFormChanged] = useState(false);
	let [statuses, setStatuses] = useState([]);

	let { id, progId } = useParams();

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	const history = useHistory();

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...recipient, [id]:value};
		setRecipient(cloned);
		setFormChanged(true);
	}
	let handleCheckboxChange = (event) => {
		const {id, value, checked} = event.target;
		setRecipient({...recipient, [id]:checked});
		setFormChanged(true);
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let body = {...recipient};
		// not used but required by backend
		body.businessPhone = '';
		body.faxNumber = '';

		body.mobilePhone = cleanPhone(body.mobilePhone);

		let url = getAccountsApiUrl() + '/account';
		apiPut(url, body).then((resp) => {
			setMessage({text:'Recipient updated.',type:'success'});
			setFormChanged(false);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			} else if(err.status == 409) {
				if(err.data.errorMessages && err.data.errorMessages.length && err.data.errorMessages[0].dataField == 'email') {
					messageObject.text = 'Email already exists.';
				} else {
					messageObject.text = apiMessages(err);
				}
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let notNull = (value) => value ? value : '';
	let notEmpty = (value, defaultValue) => value ? value : defaultValue;

	let getRecipient = (id) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/account/' + id;

		apiGet(url).then((resp)=> {
			let cloned = {...resp};
			cloned.middlename = notNull(resp.middlename).trim();
			cloned.address2 = notNull(resp.address2).trim();
			cloned.mobilePhone = formatPhone(resp.mobilePhone);

			setRecipient(cloned);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getStatuses = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/statuses';

		apiGet(url).then((resp)=> {
			setStatuses(resp);
		}).catch( (err)=> {
			console.error(err);
		}).finally(() => setSpinner(false));
	};
	let decodeStatus = (id) => {
		let item = statuses.find(element => element.id == id);
		return (item ? item.text : null);
	};

	useEffect( ()=> {
		getRecipient(id);
		getStatuses();

		patternMessage("#zipcode", Strings.validation.zipFormat);
		patternMessage("#mobilePhone", Strings.validation.phoneFormat);
		patternMessage("#email", Strings.validation.emailFormat);
	}, []);
	useEffect( ()=> {
		invalidInputStyle();
	});

	let basePath = () => props.basePath ? props.basePath : '/program';
	let ViewBenefits = () => {
		let benefitsPath = () => basePath() + '/benefits/' + encodeURIComponent(recipient.recipientIdentifier);
		return <Link to={benefitsPath()}>Benefits</Link>
	};

	let ViewTransactions = () => {
		let transactionsPath = () => basePath() + '/transactions?rid=' + encodeURIComponent(recipient.recipientIdentifier);
		return <Link to={transactionsPath()}>Transactions</Link>
	};

	let ViewPans = () => {
		let pansPath = () => basePath() + '/pans/' + id + '/' + progId;
		return <Link to={pansPath()}>Pans</Link>
	};

	let ViewUser = () => {
		if (recipient.userUuid) {
			let pansPath = () => basePath() + '/user/' + recipient.userUuid + '/' + progId;
			return <Link to={pansPath()}>User Info</Link>
		} else {
			return null;
		}
	};

	let ViewIncentives = () => {
		let incentivesPath = () => basePath() + '/recipient/incentives/' + id + '/' + progId;
		return <Link to={incentivesPath()}>Incentives</Link>
	};

	let ViewEBTCards = () => {
		let ebtCardsPath = () => basePath() + '/recipient/ebtcards/' + id;
		return <Link to={{pathname:ebtCardsPath(),state:{from:history.location.pathname}}}>EBT Cards</Link>
	};

	let [confirmForm, setConfirmForm] = useState({button:"Inactivate",type:"danger"});
	let confirmStatusChange = () => {
		if (recipient.accountStatusId == 0) {
			setConfirmForm({button:"Inactivate",type:"danger", confirm: () => doStatusChange(1)});
		} else {
			setConfirmForm({button:"Activate",type:"warning", confirm:() => doStatusChange(0)});
		}
		showConfirmDialog();
	};

	let doStatusChange = (statusId) => {
		closeConfirmDialog();

		setSpinner(true);

		let body = {
			accountUuid:recipient.accountUuid,
			accountStatusId:statusId
		};

		let url = getAccountsApiUrl() + '/account';
		apiPut(url, body).then((resp) => {
			getRecipient(id);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};


	let confirmButton = () => {
		let btnClass = "btn btn-danger btn-sm pull-right";
		let btnText = "Inactivate";
		if (recipient.accountStatusId != 0) {
			btnClass = "btn btn-warning btn-sm pull-right";
			btnText = "Activate";
		}

		return (
			<>
				<button type="button" className={btnClass} onClick={ () => confirmStatusChange()}>{btnText}</button>
			</>
		)
	}

	return (
		<>
		<Processing show={showSpinner}/>

		<h3>Recipient</h3>

		<table className="table table-bordered" id="recipientInfoTbl">
			<tbody>
				<tr>
					<th>Account ID</th>
					<th>Name</th>
					<th>Email</th>
					<th>Username</th>
					<th>Phone</th>
				</tr>
				<tr>
					<td>{recipient.recipientIdentifier}</td>
					<td>{recipient.firstname} {recipient.middlename} {recipient.lastname}</td>
					<td>{recipient.email}</td>
					<td>{recipient.username}</td>
					<td>{recipient.mobilePhone}</td>
				</tr>
				<tr>
					<th>Program</th>
					<th>Agency</th>
					<th>Account Status</th>
					<th></th>
					<th></th>
				</tr>
				<tr>
					<td>{recipient.programName}</td>
					<td>{recipient.agencyName}</td>
					<td>{decodeStatus(recipient.accountStatusId)} {confirmButton()}</td>
					<td></td>
					<td></td>
				</tr>
			</tbody>
		</table>
		<ViewBenefits/> - <ViewTransactions /> - <ViewPans /> - <ViewUser /> - <ViewIncentives /> - <ViewEBTCards />

		<hr/>
		<h4>Recipient Info</h4>
		<MessageTag message={message}/>

        <form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" onChange={handleFieldChange} value={recipient.firstname} required maxLength="100" />
				</div>
				<div className="form-group col-12 col-sm-2">
					<label htmlFor="middlename">Middle Initial</label>
					<input type="text" id="middlename" className="form-control" onChange={handleFieldChange} value={recipient.middlename} maxLength="1" />
				</div>
				<div className="form-group required col-12 col-sm-5">
					<label className="asterisk-label" htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" onChange={handleFieldChange} value={recipient.lastname} required maxLength="100" />
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="address1">Address One</label>
					<input type="text" id="address1" className="form-control" onChange={handleFieldChange} value={recipient.address1} required maxLength="60" />
				</div>
				<div className="form-group col-12 col-sm-6">
					<label htmlFor="address2">Address Two</label>
					<input type="text" id="address2" className="form-control" onChange={handleFieldChange} value={recipient.address2} maxLength="60"/>
				</div>
			</div>
			<div className="row">
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="addr1">City</label>
					<input type="text" id="city" className="form-control" onChange={handleFieldChange} value={recipient.city} required maxLength="60" />
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="stateCode">State</label>
					<select id="stateCode" className="form-control custom-select" value={recipient.stateCode} onChange={handleFieldChange} required >
						{ selectOptions(StateArray) }
					</select>
				</div>
				<div className="form-group required col-12 col-sm-4">
					<label className="asterisk-label" htmlFor="zipcode">Zip Code</label>
					<input type="text" id="zipcode" className="form-control" onChange={handleFieldChange} value={recipient.zipcode} required maxLength="10" pattern="(\d{5}([\-]\d{4})?)" />
				</div>
			</div>
			<div className="row">
					<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="email">Email</label>
					<input type="email" id="email" className="form-control" onChange={handleFieldChange} value={recipient.email} required maxLength="255" pattern={Strings.pattern.email}/>
				</div>
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="mobilePhone">Mobile Phone</label>
					<input type="text" id="mobilePhone" className="form-control" onChange={handleFieldChange} value={recipient.mobilePhone} required maxLength="20" pattern={Strings.pattern.phone} />
				</div>
			</div>
			<div className="row">
			<div className="form-group col-12 col-sm-6">
				<label htmlFor="cardIssuanceCode">Card Issuance</label>
				<div className="form-check">
					<label className='form-check-label' htmlFor="cardIssuanceCode">
					<input className="form-check-input" type="checkbox" value="true" id="cardIssuanceCode" checked={recipient.cardIssuanceCode} onChange={handleCheckboxChange} />
						Yes
					</label>
				</div>
			</div>
			</div>

			<button className="btn btn-primary" disabled={!formChanged}>Update</button>
		</form>

		<ConfirmDialog settings={confirmForm}/>

		<hr/>
		<h4>Issue Benefit</h4>
		<AddBenefitForm recipientIdentifier={recipient.recipientIdentifier} programUuid={recipient.programUuid} agencyUuid={recipient.agencyUuid}/>

		</>
	)
}
