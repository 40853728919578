import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet, getBenefitsApiUrl, apiPost, getAdminApiUrl, getAccountsApiUrl } from '../common/AppApi';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { stripTime, decodeValues, formatCurrency } from '../common/Formats';
import {BenefitStatusArray, PageSize, PrivilegeCodes} from '../common/Constants';
import { selectOptions } from '../common/Forms';

import { ExpiredIcon, ExpiringIcon} from '../common/AppIcons';

import DatePicker from 'react-date-picker';

import 'react-day-picker/lib/style.css';
import moment from 'moment';

export default function Benefits(props) {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();
	let {user} = useUserContext();
	let [downloadUrl, setDownloadUrl] = useState('');

	let emptyForm = {
		startDate:'',
		endDate:'',
		firstname:'',
		lastname:'',
		accountId:'',
		authNo:'',
		programUuid:'',
		agencyUuid:''
	};

	let maxDate = new Date();
	maxDate.setDate(new Date().getDate() + 183);

	let { recipientId } = useParams();
	if (recipientId && !emptyForm.accountId)
		emptyForm.accountId = recipientId;

	let [searchForm, setSearchForm] = useState(emptyForm);
	const history = useHistory();
	let GoTo = (id) => history.push((props.admin ? '/admin' : '/program') + '/benefit/' + id);

	let [programs, setPrograms] = useState([]);
	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		if (props.admin)
			url = getAdminApiUrl() + '/programs';

		setSpinner(true);
		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let viewable = user.privileges.includes(PrivilegeCodes.agencyAdminAccess) || user.privileges.includes(PrivilegeCodes.agencyUserAccess) ? '?viewable' : '';
		let url = getAccountsApiUrl() + '/agency' + viewable;
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let expiryAlert = (expDate, days) => {
		let today = new Date();
		let expiringDate = new Date();
		expiringDate.setDate(today.getDate() + days);
		let expiredIcon = (today > expDate) ? (<ExpiredIcon />) : null;
		let expiringIcon = (!expiredIcon && expiringDate > expDate) ? (<ExpiringIcon />) : null;
		return (expiredIcon ? expiredIcon : (expiringIcon ? expiringIcon : null));
	};

	let TableRow = (props) => {
		let alerts = expiryAlert(new Date(stripTime(props.row.benefitExpiryDate)), 14);
		let expiryDate = moment(props.row.benefitExpiryDate).format("YYYY-MM-DD");

		return (
			<tr onClick={()=>GoTo(props.row.benefitUuid)} className="clickable">
			<td>{props.row.programName}</td>
			<td>{props.row.recipientIdentifier}</td>
			<td>{props.row.firstname} {props.row.middlename} {props.row.lastname}</td>
			<td>{props.row.benefitAuthNumber}</td>
			<td>{formatCurrency(props.row.benefitAmount)}</td>
			<td>{formatCurrency(props.row.benefitBalance)}</td>
			<td>{decodeValues(props.row.benefitStatusId, BenefitStatusArray)}</td>
			<td>{stripTime(props.row.benefitAvailableDate)}</td>
			<td>{expiryDate} {alerts}</td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return <TableRow key={data.benefitUuid} row={data} />
	});

	let nextPage = ()=> {
		let nextData = {...pagingData};
		nextData.currentPage = pagingData.currentPage + 1;
		setPagingData(nextData);
		handleSubmit2(nextData.currentPage - 1);
	};

	let prevPage = ()=> {
		let nextData = {...pagingData};
		nextData.currentPage = pagingData.currentPage - 1;
		setPagingData(nextData);
		handleSubmit2(nextData.currentPage - 1);
	};

	let gotoPage = (pageNo)=> {
		let nextData = {...pagingData};
		nextData.currentPage = pageNo;
		setPagingData(nextData);
		handleSubmit2(nextData.currentPage - 1);
	};

	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<div className="pull-right">
					<ExpiringIcon /> = Expiring soon, <ExpiredIcon /> = Expired
				</div>
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Program</th>
							<th>Account ID</th>
							<th>Name</th>
							<th>Auth Number</th>
							<th>Issued Amount</th>
							<th>Balance</th>
							<th>Status</th>
							<th>Available Date</th>
							<th>Expiration Date</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>

					<tfoot><tr><td className='text-right' colSpan={9}>
						Export:&nbsp;
						<a href={downloadUrl + '&exportType=xlsx'} className='btn btn-primary btn-sm' download="benefits.xlsx" title="Export as excel">Excel</a>
						&nbsp;
						<a href={downloadUrl} className='btn btn-primary btn-sm' download="benefits.csv" title="Export as csv">CSV</a>
					</td></tr></tfoot>

				</table>
				<Pagination recordCount={pagingData.recordCount} pageCount={pagingData.pageCount} pagingData={pagingData} setPagingData={() => {}} pageSize={PageSize}
				 nextPage={nextPage} prevPage={prevPage} gotoPage={gotoPage}/>
				</>
			);
		return null;
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let handleDayChange = (inputName) => {
		return function onChange(newDate){
			setSearchForm({...searchForm, [inputName]:newDate});
		}
	}

	let disableSearch = () => {
		for(const searchField in searchForm){
			if(searchForm[searchField])
				return false;
		}
		return true;
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		handleSubmit2(0);
	};

	let handleSubmit2 = (currentPage) => {
		//console.debug(searchForm);

		setSpinner(true);
		let url = getBenefitsApiUrl() + '/benefit';

		let queryParams = [];
		for(const searchField in searchForm){
			if(searchForm[searchField]){
				if(searchField == "startDate"){
					queryParams.push(searchField + '=' + encodeURIComponent(moment(searchForm.startDate).format("YYYY-MM-DD")));
				}
				else if(searchField == "endDate"){
					queryParams.push(searchField + '=' + encodeURIComponent(moment(searchForm.endDate).format("YYYY-MM-DD")));
				}
				else{
					queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
				}
			}
		}
		queryParams.push("pageSize=" + PageSize);
		queryParams.push("page=" + currentPage);
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		// create file download url with query parameters
		let fileDownload = window.location.protocol + '//' + window.location.host + getBenefitsApiUrl() + '/benefit/export?' + queryParams.join('&');
		setDownloadUrl(fileDownload);

		if ('production' != process.env.NODE_ENV) {
			console.debug('downloadUrl port will be incorrect in dev mode ' + fileDownload);
		}

		apiGet(url).then((resp)=> {
			//console.debug(resp);
			setDataRows(resp.data);
			let pagingData = resetPaging();
			pagingData.currentPage = resp.page + 1;
			pagingData.recordCount = resp.totalCount;
			pagingData.pageCount = resp.totalPages;
			setPagingData(pagingData);
			if(resp && resp.data && resp.data.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No benefits found.',type:'warning'});
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		if (recipientId)
			handleSubmit({preventDefault:()=> console.debug('searching recipientId')});
		getPrograms();
		getAgencies();
	}, []);

	let recipientIdParm = () => {
		let beginPath = props.admin ? '/admin' : '/program';
		return recipientId ? beginPath + '/benefit/add?recipientId=' + recipientId : beginPath +  '/benefit/add';
	};
	let FileImportLink = () => {
		if (props.admin) {
			return null;
		} else {
			return <Link to="/program/benefit/file" className="btn btn-primary btn-sm pull-right">Import Benefits</Link>;
		}
	};

	return (
		<>
		<Processing show={showSpinner}/>
		<h3>Benefits 
		<FileImportLink />
		<Link to={recipientIdParm} className="btn btn-primary btn-sm pull-right btn-margin">Issue Benefit</Link>
		</h3>
		<MessageTag message={message} />

		<form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={searchForm.programUuid} onChange={handleFieldChange} >
						{ selectOptions(programs) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={searchForm.agencyUuid} onChange={handleFieldChange} >
						{ selectOptions(agencies) }
					</select>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="authNo">Auth No</label>
					<input type="text" id="authNo" className="form-control" onChange={handleFieldChange} value={searchForm.authNo} maxLength="20" />
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="accountId">Recipient Account ID</label>
					<input type="text" id="accountId" className="form-control" onChange={handleFieldChange} value={searchForm.accountId} maxLength="20" />
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" onChange={handleFieldChange} value={searchForm.firstname} maxLength="100" />
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" onChange={handleFieldChange} value={searchForm.lastname} maxLength="100" />
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="startDate">Start Date</label>
					<DatePicker value={searchForm.startDate} onChange={handleDayChange("startDate")} className={"form-control"} minDate={new Date(2022,1,1)} maxDate={maxDate} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				<div className="form-group col-12 col-sm-3">
					<label htmlFor="endDate">End Date</label>
					<DatePicker value={searchForm.endDate} onChange={handleDayChange("endDate")} className={"form-control"} minDate={new Date(2022,7,1)} maxDate={maxDate} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</div>
			<button className="btn btn-primary" disabled={disableSearch()} title={Strings.warning.searchCritera}>Search</button>
		</form>

		<br/>
		<Table />

		</>
	)
}
