import React from 'react';

import {Route, Switch} from 'react-router-dom';

import NotFound from './NotFound';
import SiteHome from './SiteHome';

import ProgramContent from './program/Content';
import AdminContent from './admin/Content';
import RecipientContent from './recipient/Content';
import Login from './Login';
import Logout from './Logout';
import ProtectedRoute from './common/ProtectedRoute';
import SessionExpiration from './common/SessionExpiration';
import Validate from './Validate';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';

export default function Content() {

  return (
    <main className="container">
      <Switch>
        <Route path="/" exact component={Login} />

        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />

        <Route path="/validate" component={Validate} />
        <Route path="/resetpass" component={ResetPassword} />
        <Route path="/forgotpass" component={ForgotPassword} />

        <Route path="/forgotusername" component={ForgotUsername} />

        <ProtectedRoute path="/recipient" privilege={'RECIPIENT_ACCESS'}>
          <RecipientContent />
          <SessionExpiration />
        </ProtectedRoute>

        <ProtectedRoute path="/program" privileges={['PROGRAMADMIN_ACCESS','AGENCYADMIN_ACCESS','AGENCYUSER_ACCESS']} >
          <ProgramContent />
          <SessionExpiration />
        </ProtectedRoute>
        
        <ProtectedRoute path="/admin" privilege={'NDGADMIN_ACCESS'}>
          <AdminContent />
          <SessionExpiration />
        </ProtectedRoute>

        <Route component={NotFound} />
      </Switch>
    </main>
  );
}
