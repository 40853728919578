import React, { useState, useEffect } from 'react';
import useUserContext from '../common/UserContext';
import { useHistory, useParams } from "react-router-dom";

import { apiPut, apiGet, getAccountsApiUrl, apiMessages } from '../common/AppApi';
import { patternMessage, invalidInputStyle, notEqualMessage } from '../common/Forms';
import { selectOptions } from '../common/Forms';

import Strings from  '../common/Strings';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import { isNumericPAN, isLuhn } from './EBTCards';

export default function EBTCardsEdit() {
    let {user, setUser} = useUserContext();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let blankForm = {externalPanId:'', pan:'',name:'',programUuid:'', externalPanTypeId:0, snap:false, wic:false, externalPanStatusId:0, active:false};
	let [ebtCard, setEbtCard] = useState(blankForm);

    let { id } = useParams();

	const history = useHistory();
	let GoBack = () => history.push('/recipient/ebtcards');

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...ebtCard, [id]:value};
		setEbtCard(cloned);
	}

	let handleCheckboxChange = (event) => {
		const {id, value, checked} = event.target;
		setEbtCard({...ebtCard, [id]:checked});
	};

	let handleSave = (e) => {
		e.preventDefault();

		if (ebtCard.pan.length < 16 || isNumericPAN(ebtCard.pan) == false || isLuhn(ebtCard.pan) == false) {
			setMessage({text:'Invalid EBT card number.', type:'warning'});
			return;
		} else if (ebtCard.name.length < 2) {
			setMessage({text:'Invalid EBT card name.', type:'warning'});
			return;
		}

		let cardName = ebtCard.name.replace(/[\W_]+/g,'');
		for (var i=0; i<10; i++) {
			if (cardName.includes(ebtCard.pan.substring(i, i + 4))) {
				setMessage({text:Strings.validation.cardNumberInName, type:'warning'});
				return;
			}
		}

		if (ebtCard.snap == false && ebtCard.wic == false) {
			setMessage({text:Strings.validation.cardTypeRequired, type:'warning'});
			return;
		}

		let body = {
			pan:ebtCard.pan,
			name:ebtCard.name,
			programUuid:ebtCard.programUuid,
			externalPanStatusId: ebtCard.active ? 1 : 2
		};

		if (ebtCard.snap && ebtCard.wic) {
			body.externalPanTypeId = 3;
		} else if (ebtCard.wic) {
			body.externalPanTypeId = 2;
		} else {
			body.externalPanTypeId = 1;
		}

		setSpinner(true);
		let url = getAccountsApiUrl() + '/external-pan/' + ebtCard.externalPanId;
		apiPut(url, body).then( resp => {
			console.log('all good');
			GoBack();
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 409){
				messageObject.text = Strings.error.externalPanDupe;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [programs, setPrograms] = useState([]);
	let getPrograms = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/account/currentuser/programs';
		apiGet(url).then((resp)=> {
			let progs = resp.map( (item) => {
				return {id:item.programUuid,text:item.programName,id2:item.programId};
			});
			//if (progs.length > 1)
				progs.unshift({id:null,text:''});
			setPrograms(progs);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => setSpinner(false));
	};

	let loadEbtCard = (id) => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/external-pan/' + id;
		apiGet(url).then((resp) => {
			console.log(resp);
			let externalPanTypeId = resp.externalPanType.externalPanTypeId;
			resp.snap = (externalPanTypeId == 1 || externalPanTypeId == 3);
			resp.wic = (externalPanTypeId == 2 || externalPanTypeId == 3);
			resp.active = resp.externalPanStatus.externalPanStatusId == 1;
			setEbtCard(resp);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		patternMessage("#pan", Strings.validation.panFormat);
		patternMessage("#name", Strings.validation.cardNameFormat);
		getPrograms();
		loadEbtCard(id);
	}, []);
	useEffect( () => {
        invalidInputStyle();
	});

    return (
        <>
		<Processing show={showSpinner}/>
		<h3>Edit EBT Card</h3>
		<MessageTag message={message} />

			<form className="dataEntry" onSubmit={handleSave}>
				<div className="form-group required">
					<label className="asterisk-label" htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={ebtCard.programUuid} onChange={handleFieldChange} required={true} >
						{ selectOptions(programs) }
					</select>
				</div>
				<div className="form-group required">
					<label className="asterisk-label" htmlFor="pan">Pan</label>
					<input type="text" id="pan" className="form-control" autoComplete="off" value={ebtCard.pan} onChange={handleFieldChange} required maxLength="19" pattern="^\d{16,19}$" placeholder='PAN'/>
				</div>
				<div className="form-group required">
					<label className="asterisk-label" htmlFor="name">Name</label>
					<input type="text" id="name" className="form-control" autoComplete="off" value={ebtCard.name} onChange={handleFieldChange} required maxLength="35" pattern="^.{2,35}$" placeholder='Card Name'/>
				</div>
				<div className="form-group">
					<div className="form-check">
						<label className='form-check-label' htmlFor="snap">
						<input className="form-check-input" type="checkbox" value="true" id="snap" checked={ebtCard.snap} onChange={handleCheckboxChange} />
							SNAP
						</label>
					</div>
				</div>
				<div className="form-group">
					<div className="form-check">
						<label className='form-check-label' htmlFor="wic">
						<input className="form-check-input" type="checkbox" value="true" id="wic" checked={ebtCard.wic} onChange={handleCheckboxChange} />
							WIC
						</label>
					</div>
				</div>
				<div className="form-group">
					<div className="form-check">
						<label className='form-check-label' htmlFor="active">
						<input className="form-check-input" type="checkbox" value="true" id="active" checked={ebtCard.active} onChange={handleCheckboxChange} />
							Active
						</label>
					</div>
				</div>

				<button className="btn btn-primary" id="btnSave">Save</button>&nbsp;
				<button className="btn btn-primary" onClick={GoBack}>Cancel</button>
			</form>

        </>
        )
}
