import React, { useState, useEffect } from 'react';
import useUserContext from '../common/UserContext';
import { useHistory } from "react-router-dom";

import { apiDelete, apiGet, getAccountsApiUrl, apiMessages } from '../common/AppApi';

import Strings from  '../common/Strings';

import ConfirmDialog, {showConfirmDialog, closeConfirmDialog} from '../common/ConfirmDialog';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

export default function EBTCards() {
    let {user, setUser} = useUserContext();
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [ebtCards, setEbtCards] = useState([]);
	let [idToDelete, setIdToDelete] = useState(null);

	let getEBTCards = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/external-pan/all';
		apiGet(url).then((resp)=> {
			if (resp && resp.length > 0) {
				setEbtCards(resp);
			} else {
				setEbtCards([]);
				setMessage({text:Strings.warning.noEBTCards, type:'light'});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		getEBTCards();
	}, []);

	let DisplayRow = (props) => {
		let pan = props.card.pan.length > 6 ? '*' + props.card.pan.substring(props.card.pan.length - 4) : props.card.pan;
		return <tr>
			<td>{props.card.name}</td>
			<td>{pan}</td>
			<td>{props.card.externalPanType.descr}</td>
			<td>{props.card.externalPanStatus.descr}</td>
			<td>{props.card.programName}</td>
			<td><button
			 type="button" className='btn btn-primary btn-sm' onClick={ () => GoToEdit(props.card.externalPanId)}>Edit</button> <button
			 type="button" className='btn btn-primary btn-sm btn-danger' onClick={ () => confirmDelete(props.card.externalPanId)}>Delete</button></td>
			</tr>
	};

	let TableBody = ebtCards.map( (item, index) => {
		return <DisplayRow key={index} card={item} />;
	});

	const history = useHistory();
	let GoToAdd = () => history.push('/recipient/ebtcards/add');
	let GoToEdit = (id) => history.push('/recipient/ebtcard/' + id);

	let DisplayCards = () => {
		if (ebtCards && ebtCards.length) {
			return <>
				<table className='table table-bordered' id="tblCards">
					<thead>
						<tr>
							<th>Name</th>
							<th>Pan</th>
							<th>Type</th>
							<th>Status</th>
							<th>Program</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
			</>
		} else {
			return null;
		}
	};

	let confirmDelete = (id) => {
		setIdToDelete(id);
		showConfirmDialog();
	};

	let handleDelete = () => {
		closeConfirmDialog();

		setSpinner(true);
		let url = getAccountsApiUrl() + '/external-pan/' + idToDelete;
		apiDelete(url).then( resp => {
			setIdToDelete(null);
			getEBTCards();
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let confirmDeleteSettings = {
		body: Strings.confirm.deletePAN,
		type:'danger',
		confirm:() => handleDelete()
	};

    return (
        <>
		<Processing show={showSpinner}/>
		<h3>EBT Cards
		<button type="button" id="btnAddCard" className='btn btn-primary btn-sm pull-right' onClick={GoToAdd}>Attach Card</button>
		</h3>
		<MessageTag message={message} />

		<DisplayCards />

		<ConfirmDialog settings={confirmDeleteSettings}/>
        </>
        )
}

export const isNumericPAN = (str) => {
	return /^\d{16,19}$/.test(str);
}

export const isLuhn = (cardNo) => {
	let sum = 0;
	let isSecond = false;
	for (let i = cardNo.length - 1; i >= 0; i--) {
		let d = cardNo[i].charCodeAt() - '0'.charCodeAt();
		if (isSecond == true)
			d = d * 2;
		sum += parseInt(d / 10, 10);
		sum += d % 10;
		isSecond = !isSecond;
	}
	return (sum % 10 == 0);
}
