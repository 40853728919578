import React, { useState, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import useUserContext from '../common/UserContext';

import { apiGet } from '../common/AppApi';
import { getAccountsApiUrl, getAdminApiUrl } from '../common/AppApi';

import Processing from '../common/Processing';
import Pagination, { resetPaging } from '../common/Pagination';
import { formatPhone, cleanPhone, decodeValues } from '../common/Formats';
import { PageSize, PrivilegeCodes } from '../common/Constants';
import MessageTag from '../common/MessageTag';
import Strings from '../common/Strings';
import { selectOptions } from '../common/Forms';

export default function Recipients(props) {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();
	let {user} = useUserContext();

	let emptyForm = {
		recipientIdentifier:'',
		firstname:'',
		lastname:'',
		email:'',
		mobilePhone:'',
		programUuid:'',
		agencyUuid:''
	};
	let [searchForm, setSearchForm] = useState(emptyForm);
	let [programs, setPrograms] = useState([]);
	let [programsId, setProgramsId] = useState([]);
	let [downloadUrl, setDownloadUrl] = useState('');

	const history = useHistory();
	let GoTo = (id, progId, identifier, agencyUuid) => {
		let pathToUse = props.admin ? '/admin/recipient/' : '/program/recipient/';
		if (props.searchOnly) {
			props.copyId(identifier);
			if (props.setAddParms) {
				let dataIds = {programUuid:getProgramUuid(progId), agencyUuid:agencyUuid};
				props.setAddParms(dataIds);
			}
		} else {
			history.push(pathToUse + id + '/' + progId);
		}
	};

	let TableRow = (propsz) => {
		let title = props.searchOnly ? 'Copy ID' : 'View Details';
		return (
			<tr onClick={()=>GoTo(propsz.row.accountUuid, propsz.row.programId, propsz.row.recipientIdentifier, propsz.row.agencyUuid)} className="clickable" title={title}>
				<td>{propsz.row.recipientIdentifier}</td>
				<td>{propsz.row.firstname} {propsz.row.middlename} {propsz.row.lastname}</td>
				<td>{propsz.row.email}</td>
				<td>{formatPhone(propsz.row.mobilePhone)}</td>
				<td>{decodeValues(propsz.row.programId, programsId)}</td>
				<td>{decodeValues(propsz.row.agencyUuid, agencies)}</td>
			</tr>
		);
	};

	let getProgramUuid = (id) => {
		for (var i = 0; i < programsId.length; i++) {
			if (programsId[i].id == id) {
				return programsId[i].programUuid;
			}
		}
		return null;
	};

	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={index} row={data} /> : null;
	});

	let Table = () => {
		if(dataRows && dataRows.length) {
			let exportHtml = null;
			let copyInfo = null;
			if (!props.searchOnly) {
				exportHtml = <>
					<tfoot><tr><td className='text-right' colSpan={5}>
						Export:&nbsp;
						<a href={downloadUrl + '&exportType=xlsx'} className='btn btn-primary btn-sm' download="recipients.xlsx" title="Export as excel">Excel</a>
						&nbsp;
						<a href={downloadUrl} className='btn btn-primary btn-sm' download="recipients.csv" title="Export as csv">CSV</a>
					</td></tr></tfoot>
				</>
			} else {
				copyInfo = <strong>Click row to copy Recipient Account ID.</strong>;
			}

			return (
				<>
				{copyInfo}
				<table className="table table-hover" id="recipientsTbl">
					<thead>
						<tr>
							<th>Account ID</th>
							<th>Name</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Program</th>
							<th>Agency</th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
					{exportHtml}
				</table>

				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />

				</>
			);
		}
		return null;
	};

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		if (props.admin)
			url = getAdminApiUrl() + '/programs';

		setSpinner(true);
		apiGet(url).then((resp) => {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
			// for decode values
			let programs2 = [];
			resp.forEach( (p) => {
				programs2.push({id:p.programId, text:p.programName, programUuid:p.programUuid});
			});
			setProgramsId(programs2);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [agencies, setAgencies] = useState([]);
	let getAgencies = () => {
		let viewable = user.privileges.includes(PrivilegeCodes.agencyAdminAccess) || user.privileges.includes(PrivilegeCodes.agencyUserAccess) ? '?viewable' : '';
		let url = getAccountsApiUrl() + '/agency' + viewable;
		setSpinner(true);
		apiGet(url).then((resp) => {
			let agencies = [];
			agencies.push({id:'',text:''});
			resp.forEach( (a) => {
				agencies.push({id:a.agencyUuid, text:a.agencyName});
			});
			setAgencies(agencies);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect(() => {
		getPrograms();
		getAgencies();
	}, []);

	let disableSearch = () => {
		for(const searchField in searchForm){
			if(searchForm[searchField])
				return false;
		}
		return true;
	};

	let handleSubmit = (e) => {
		e.preventDefault();
		//console.debug(searchForm);

		setSpinner(true);
		let url = getAccountsApiUrl() + '/account';

		let queryParams = [];
		for(const searchField in searchForm){
			if(searchField == 'mobilePhone'){
				queryParams.push(searchField + '=' + cleanPhone(searchForm[searchField]));
			}
			else{
				queryParams.push(searchField + '=' + encodeURIComponent(searchForm[searchField]));
			}
		}
		queryParams.push('pageSize=1000'); //TODO use backend paging
		if(queryParams.length)
			url = url + '?' + queryParams.join('&');

		// The port needs to be replaced when running in dev mode
		let fileDownload = window.location.protocol + '//' + window.location.host + getAccountsApiUrl() + '/account/export?' + queryParams.join('&');
		setDownloadUrl(fileDownload);

		if ('production' != process.env.NODE_ENV) {
			console.debug('downloadUrl port will be incorrect in dev mode ' + fileDownload);
		}

		apiGet(url).then((resp) => {
			//console.log(resp);
			setDataRows(resp.data);
			setPagingData(resetPaging());
			if(resp && resp.data && resp.data.length){
				if(message)
					setMessage(null);
			}
			else{
				setMessage({text:'No recipients found.',type:'warning'});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		setSearchForm({...searchForm, [id]:value});
	}

	let newRecipientLink = props.searchOnly ? null : props.admin ? 
	<>
		<Link to="/admin/recipient/add" className="btn btn-primary btn-sm pull-right">New Recipient</Link>
	</> : 
	<>
		<Link to="/program/recipient/file" className="btn btn-primary btn-sm pull-right">Import Recipients</Link>
		<Link to="/program/recipient/add" className="btn btn-primary btn-sm pull-right btn-margin">New Recipient</Link>
	</>;

	return (
		<>

		<h3>Recipients
			{newRecipientLink}
		</h3>
		<MessageTag message={message} />

		<Processing show={showSpinner}/>
		
		<form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={searchForm.programUuid} onChange={handleFieldChange} >
						{ selectOptions(programs) }
					</select>
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="agencyUuid">Agency</label>
					<select id="agencyUuid" className="form-control custom-select" value={searchForm.agencyUuid} onChange={handleFieldChange} >
						{ selectOptions(agencies) }
					</select>
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="acrecipientIdentifierctNo">Recipient Account ID</label>
					<input type="text" id="recipientIdentifier" className="form-control" onChange={handleFieldChange} value={searchForm.recipientIdentifier} maxLength="20" />
				</div>
				</div>
				<div className="row">
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="firstname">First Name</label>
					<input type="text" id="firstname" className="form-control" onChange={handleFieldChange} value={searchForm.firstname} maxLength="100" />
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="lastname">Last Name</label>
					<input type="text" id="lastname" className="form-control" onChange={handleFieldChange} value={searchForm.lastname} maxLength="100" />
				</div>
				<div className="form-group col-12 col-md-6 col-lg-3">
					<label htmlFor="email">Email</label>
					<input type="text" id="email" className="form-control" onChange={handleFieldChange} value={searchForm.email} maxLength="255" />
				</div>
				<div className="form-group col-12 col-md-6 col-lg-2">
					<label htmlFor="mobilePhone">Phone</label>
					<input type="text" id="mobilePhone" className="form-control" onChange={handleFieldChange} value={searchForm.mobilePhone} maxLength="20" />
				</div>
			</div>
			<button className="btn btn-primary" disabled={disableSearch()} title={Strings.warning.searchCritera} id="btnSearch">Search</button>
		</form>

		<br/>
		<Table />

		</>
	)
}
