import React, { useState, useEffect } from 'react';
import { clearSession} from './common/AppAuth';
import { Redirect } from 'react-router-dom';
import useUserContext from './common/UserContext';
import Processing from './common/Processing';
import { apiGet, getApiUrl } from './common/AppApi';
import {deleteUser} from './common/AppAuth';

export default function Logout() {
    let {user, setUser} = useUserContext();
	let [doRedirect, setDoRedirect] = useState(false);

	clearSession();
	
	useEffect( ()=>{
		if (user.userId && !doRedirect){
			let url = getApiUrl() + "/logout?" + Date.now();
			apiGet(url).then( (response) => {
				console.debug("logout success");
			}).catch((error) => {
				console.debug("logout failed");
				console.debug(error);
			});
		}

		setUser({});
		deleteUser();
		setDoRedirect(true);
	},[doRedirect]);

	if(doRedirect)
		return (<Redirect to="/login" />);

	return (
		<>
		<h3>Logging out...</h3>
		<Processing show={true} />
		</>
	);
}
