import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from "react-router-dom";

import { apiGet, getAccountsApiUrl, apiPut, apiPost, apiMessages, apiDelete } from '../common/AppApi';
import Strings from '../common/Strings';

import { PageSize, SelectOneArray } from '../common/Constants';

import Pagination, { resetPaging } from '../common/Pagination';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import ConfirmDialog, {showConfirmDialog, closeConfirmDialog} from '../common/ConfirmDialog';

import { selectOptions } from '../common/Forms';

import DatePicker from 'react-date-picker';

import moment, { min } from 'moment';

export default function RecipientIncentives(props) {
	let [showSpinner, setSpinner] = useState(false);
	let [dataRows, setDataRows] = useState([]);
	let [pagingData, setPagingData] = useState(resetPaging());
	let [message, setMessage] = useState();

	let [incentives, setIncentives] = useState([]);
	let [addMinDate, setAddMinDate] = useState(new Date());

	let { id, progId } = useParams();

	const history = useHistory();
	let GoBack = () => history.goBack();

	let getIncentives = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive';
		apiGet(url).then((resp)=> {
			if(resp && resp.length){
				let newResp = resp.map( (item) => {
					item.id = item.incentiveUuid;
					item.text = item.name;
					return item;
				});
				newResp.unshift({id:'',text:'Select...'});
				setIncentives(newResp);
			}
		}).catch( (err)=> {
			console.error(err);
			if(err.privilegeError){
				setMessage({text:err.message,type:'danger'});
			}
			else {
				setMessage({text:Strings.error.general,type:'danger'});
			}
		}).finally(() => setSpinner(false));
	};

	let getRecipientIncentives = () => {
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive/recipient-incentive/' + id;

		apiGet(url).then((resp) => {
			setDataRows(resp);
			setPagingData(resetPaging());
			if(resp && resp.length){
				// no message
			}
			else{
				setMessage({text:'The recipient is not enrolled in any incentives.', type:'light'});
			}
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 404 || err.status == 403){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let editEndDate = (e, data) => {
		data.updateMode = 1;
		data.startDate = moment(data.startDate).toDate();
		if (data.endDate)
			data.endDate = moment(data.endDate).toDate();
		setUpdateIncentive(data);
	};
	let editStartEndDate = (e, data) => {
		data.updateMode = 2;
		data.startDate = moment(data.startDate).toDate();
		if (data.endDate)
			data.endDate = moment(data.endDate).toDate();
		setUpdateIncentive(data);
	};

	let updateForm = {
		recipientIncentiveId:'',
		startDate:'',
		endDate:'',
		updateMode:0
	};
	let [updateIncentive, setUpdateIncentive] = useState(updateForm);

	let handleUpdate = (e) => {
		e.preventDefault();
		setSpinner(true);

		let payload = {
			startDate:moment(updateIncentive.startDate).format("YYYY-MM-DD")
		};

		if (updateIncentive.endDate)
			payload.endDate = moment(updateIncentive.endDate).format("YYYY-MM-DD");

		let url = getAccountsApiUrl() + '/incentive/recipient-incentive/' + updateIncentive.recipientIncentiveId;
		apiPut(url, payload).then((resp) => {
			setUpdateIncentive(updateForm);
			setMessage({text: 'Recipient incentive updated.', type:'success'});
			getRecipientIncentives();
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let handleDayChange2 = (id, value) => {
		console.debug(value);
		setUpdateIncentive({...updateIncentive, [id]:value});
	}

	let StartDateInput = () => {
		if (updateIncentive.updateMode == 2) {
			return <>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="startDate">Start Date</label>
					<br />
					<DatePicker id="startDate" name="startDate" value={updateIncentive.startDate} onChange={(val)=>handleDayChange2('startDate', val)} className={"form-control"} minDate={new Date(2023,1,1)} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</>
		} else {
			return <>
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="startDate">Start Date</label>
					<br />
					<DatePicker id="startDate" name="startDate" value={updateIncentive.startDate} onChange={(val)=>handleDayChange2('startDate', val)} className={"form-control"} disabled={true} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</>
		}
	};

	let cancelUpdate = () => {
		setUpdateIncentive(updateForm);
		setMessage(null);
	};

	let UpdateForm = () => {
		if (updateIncentive.updateMode) {
			let inlineStyle = {maxWidth:'80%'};
			return <>

			<div className="card border-primary mb-3">
			<div className="card-header">Update Incentive Enrollment</div>
			<div className="card-body">

			<form onSubmit={handleUpdate}>
				<div className="row">
				<StartDateInput />
				<div className="form-group col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="endDate">End Date</label>
					<br />
					<DatePicker id="endDate" name="endDate" value={updateIncentive.endDate} onChange={(val)=>handleDayChange2('endDate', val)} className={"form-control"} minDate={new Date()} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
				</div>
				<button className="btn btn-primary" id="btnUpdateIncentive">Update</button>
				 &nbsp; <button className="btn btn-primary" id="btnCancel" type="button" onClick={() => cancelUpdate()}>Cancel</button>
			</form>

			</div>
			</div>

			</>;
		} else {
			return null;
		}
	};

	let [deleteId, setDeleteId] = useState();
	let handleDelete = (dId) => {
		closeConfirmDialog();
		setSpinner(true);
		let url = getAccountsApiUrl() + '/incentive/recipient-incentive/' + dId;
		apiDelete(url).then((resp) => {
			setMessage({text: 'Recipient incentive deleted.', type:'success'});
			getRecipientIncentives();
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};
	let confirmDeleteSettings = {
		body: Strings.confirm.deleteEntry,
		type:'danger',
		confirm:()=>handleDelete(deleteId)
	};

	let TableRow = (props) => {
		let today = moment(moment().format("YYYY-MM-DD"));
		let editStart = moment().isBefore(moment(props.row.startDate)) ? 'Start' : '';
		let editEnd = !props.row.endDate || moment(props.row.endDate).isSameOrAfter(today) ? 'End' : '';
		let deleteRow = moment(props.row.startDate).isAfter(today) ? true : false;

		let editAction = null;
		let showButton = false;
		if (editStart || editEnd) {
			if (editStart && editEnd) {
				editAction = (e) => editStartEndDate(e, {
					recipientIncentiveId:props.row.recipientIncentiveId,
					startDate:props.row.startDate,
					endDate:props.row.endDate
				});
			} else if (editEnd) {
				editAction = (e) => editEndDate(e, {
					recipientIncentiveId:props.row.recipientIncentiveId,
					startDate:props.row.startDate,
					endDate:props.row.endDate
				});
			}
			showButton = true;
		}

		let deleteFunction = (id) => {
			setDeleteId(id);
			showConfirmDialog();
		};

		let EditButton = (props) => {
			if (props.show) {
				return <>
				<button type="button" className='btn btn-sm btn-primary' onClick={props.action}>Edit</button>
				</>
			} else {
				return null;
			}
		};
		let DeleteButton = (props) => {
			if (props.show) {
				return <>
				<button type="button" className='btn btn-sm btn-danger' onClick={()=>deleteFunction(props.recipientIncentiveId)}>Delete</button>
				</>
			} else {
				return null;
			}
		};

		let formattedDt = moment(props.row.updated).utc().format("YYYY-MM-DD HH:mm:ss");
		return (
			<tr>
			<td>{props.row.incentiveName}</td>
			<td>{props.row.startDate}</td>
			<td>{props.row.endDate}</td>
			<td>{formattedDt}</td>
			<td> <EditButton show={showButton} action={editAction}/> <DeleteButton show={deleteRow} recipientIncentiveId={props.row.recipientIncentiveId} /> </td>
			</tr>
		);
	};

	let TableBody = dataRows.map( (data, index) => {
		return ( (index >= pagingData.beginIndex) && (index < (pagingData.beginIndex + PageSize)) ) ? <TableRow key={index} row={data} /> : null;
	});
	let Table = () => {
		if(dataRows && dataRows.length)
			return (
				<>
				<table className="table table-hover">
					<thead>
						<tr>
							<th>Name</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Updated</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{TableBody}
					</tbody>
				</table>
				<Pagination recordCount={dataRows.length} pagingData={pagingData} setPagingData={setPagingData} pageSize={PageSize} />
				</>
			);
		return null;
	};

	useEffect( () => {
		getIncentives();
		getRecipientIncentives();
	}, []);

	let blankForm = {
		incentiveUuid:'',
		startDate:new Date(),
		endDate:''
	};
	let [newIncentive, setNewIncentive] = useState(blankForm);

	let handleDayChange = (id, value) => {
		setNewIncentive({...newIncentive, [id]:value});
	}

	let handleSelectChange = (event) => {
		const {id, value} = event.target;
		setNewIncentive({...newIncentive, [id]:value});
		checkStartDate(value);
	};

	let checkStartDate = (incentiveUuid) => {
		let newMinDate = new Date();
		if (incentiveUuid) {
			for (var i=0; i<incentives.length; i++) {
				if (incentiveUuid == incentives[i].incentiveUuid) {
					let incentiveStartDate = moment(incentives[i].startDate).toDate();
					if (incentiveStartDate > newMinDate)
						newMinDate = incentiveStartDate;
					break;
				}
			}
		}
		setAddMinDate(newMinDate);
	};
	
	useEffect( () => {
		setNewIncentive({...newIncentive, ...{startDate:addMinDate}});
	}, [addMinDate]);

	let handleSubmit = (e) => {
		e.preventDefault();
		setSpinner(true);

		let payload = {
			recipientUuid:id,
			incentiveUuid:newIncentive.incentiveUuid,
			startDate:moment(newIncentive.startDate).format("YYYY-MM-DD")
		};

		if (newIncentive.endDate)
			payload.endDate = moment(newIncentive.endDate).format("YYYY-MM-DD");

		let url = getAccountsApiUrl() + '/incentive/recipient-incentive';
		apiPost(url, payload).then((resp) => {
			setMessage({text: 'Recipient incentive added.', type:'success'});
			getRecipientIncentives();
			setNewIncentive(blankForm);
			setUpdateIncentive(updateForm);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let ViewRecipient = () => {
		return <a href='#' onClick={GoBack}>Back to Recipient</a>
	};

	return (
		<>

		<Processing show={showSpinner}/>
		<h3>Recipient Incentives </h3>
		<MessageTag message={message} />

		<p>To end an incentive, set an end date. To restart the incentive, add a new row with a new start date.</p>
		<ViewRecipient />

		<UpdateForm />

		<br/>
		<Table />

		<hr/>
		<h5>Add Incentive</h5>
        <form onSubmit={handleSubmit}>
			<div className="row">
				<div className="form-group required col-12 col-sm-6">
					<label className="asterisk-label" htmlFor="incentiveUuid">Incentive</label>
					<select id="incentiveUuid" className="form-control custom-select" value={newIncentive.incentiveUuid} onChange={handleSelectChange} required>
						{ selectOptions(incentives) }
					</select>
				</div>
			</div>

			<div className="row">
				<div className="form-group required col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="startDate">Start Date</label>
					<br />
					<DatePicker id="startDate" name="startDate" value={newIncentive.startDate} onChange={(val)=>handleDayChange('startDate', val)} className={"form-control"} minDate={addMinDate} required format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD" />
				</div>
				<div className="form-group col-12 col-sm-3">
					<label className="asterisk-label" htmlFor="endDate">End Date</label>
					<br />
					<DatePicker id="endDate" name="endDate" value={newIncentive.endDate} onChange={(val)=>handleDayChange('endDate', val)} className={"form-control"} minDate={new Date()} format="yyyy-MM-dd" monthPlaceholder="MM" yearPlaceholder="YYYY" dayPlaceholder="DD"/>
				</div>
			</div>
			<button className="btn btn-primary" id="btnAddIncentive">Add Incentive</button>
		</form>

		<ConfirmDialog settings={confirmDeleteSettings}/>

		</>
	)
}
