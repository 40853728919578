import React, { useState, useEffect } from 'react';

import AdminAddRecipient from '../program/AddRecipient';

export default function AddRecipient() {

	return (
		<>
		<AdminAddRecipient admin={true}/>
		</>
	)

}
