export function formatPhone(phone){
	if(phone){
		var allNumbers = phone.replace(/\D/g,'');
		var match = allNumbers.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match)
			return match[1] + '-' + match[2] + '-' + match[3];
	}
	return phone;
}

export function cleanPhone(phone){
	if(phone)
		return phone.replace(/\D/g,'');
	return phone;
}

export function stripTime(datetime){
	if(datetime && datetime.includes('T'))
		return datetime.split('T')[0];
	return datetime;
}

export function formatCurrency(value){
	if (value) {
		let currencyFormat = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		return currencyFormat.format(value);
	}
	else {
		return '$0.00';
	}
}

export function decodeValues(id, values, idName, valueName) {
	let idN = idName || 'id';
	let valueN = valueName || 'text';
	let item = values.find(element => element[idN] == id);
	return (item ? item[valueN] : null);
};

// deprecated, use decodeValues with parameters
export function decodeValues2(id, values) {
	let item = values.find(element => element.id2 == id);
	return (item ? item.text : null);
};

// deprecated, use decodeValues with parameters
export function decodeResponseCode(id, values) {
	let item = values.find(element => element.responseCodeId == id);
	return (item ? item.responseCode : null);
};

// deprecated, use decodeValues with parameters
export function decodeResponseText(id, values) {
	let item = values.find(element => element.responseCodeId == id);
	return (item ? item.descr : null);
};
