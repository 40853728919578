import React from 'react';

export function DisplayName(props) {
	if ( props.user ){
		let user = props.user;
		let firstInitial = user.firstname ? user.firstname.substring(0,1) : '';
		return (<>{firstInitial} {user.middlename} {user.lastname}</>);
	}
	else {
		return null;
	}
}
