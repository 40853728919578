import React, { useState, useEffect } from 'react';
import { apiPut, getUserApiUrl, apiMessages } from './AppApi';

import Processing from './Processing';
import MessageTag from './MessageTag';

import Strings from './Strings';
import { patternMessage, invalidInputStyle, notEqualMessage } from './Forms';

import PasswordChecklist from './PasswordChecklist';

export default function AccountPassword() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

    let newPasswordForm = {
        currentPassword:'',
        newPassword:'',
        confirmPassword:''
    };
    let [passwordForm, setPasswordForm] = useState(newPasswordForm);

	useEffect( () => {
		patternMessage("#newPassword", Strings.validation.password);
        notEqualMessage("#confirmPassword", Strings.validation.confirmPassword, "#newPassword");
	}, []);
	useEffect( () => {
        invalidInputStyle();
	});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...passwordForm, [id]:value};
		setPasswordForm(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();

        let url = getUserApiUrl() + '/user/password';
        let body = {...passwordForm};

		apiPut(url, body).then((resp)=> {
			console.debug(resp);
			setMessage({text: 'Password updated.', type:'success'});
            setPasswordForm(newPasswordForm);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
        }).finally(() => setSpinner(false));
	};

    return (
    <>
    <Processing show={showSpinner}/>

    <h4>Change Password</h4>
    <MessageTag message={message} id="msgChgPass"/>

    <form className="dataEntry" onSubmit={e => handleSubmit(e)}>
        <div className="form-group">
            <label htmlFor="currentPassword">Current Password</label>
            <input type="password" id="currentPassword" className="form-control" autoComplete="off" value={passwordForm.currentPassword} onChange={handleFieldChange} required maxLength="40" />
        </div>
        <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input type="password" id="newPassword" className="form-control" autoComplete="off" value={passwordForm.newPassword} onChange={handleFieldChange} required maxLength="30" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!\x22#\\\$%&'()*+,-./:;<>=?@\[\]\^_`|{}~]).{8,30}$" />
            <div><PasswordChecklist password={passwordForm.newPassword}/></div>
        </div>
        <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input type="password" id="confirmPassword" className="form-control" autoComplete="off" value={passwordForm.confirmPassword} onChange={handleFieldChange} required maxLength="40" />
        </div>
        <button className="btn btn-primary" id="btnChgPass">Change Password</button>
    </form>

    </>
    )
}
