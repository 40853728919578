import React, { useState, useEffect } from 'react';
import { apiPut, apiGet, getUserApiUrl, apiMessages } from '../common/AppApi';

import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';

import Strings from '../common/Strings';
import { patternMessage, invalidInputStyle, selectOptions } from '../common/Forms';

export default function AccountSecurityQuestion() {
	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();
	let [questions, setQuestions] = useState([{id:null,text:''}]);

    let [securityForm, setSecurityForm] = useState({questionId:'',answer:''});

	let getSecurityQuestions = () => {
		setSpinner(true);
		let url = getUserApiUrl() + '/security-questions';
		apiGet(url).then((resp) => {
			resp.unshift({id:null,text:''});
			setQuestions(resp);
		}).catch( (err) => {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
		});
	};
	let getSecurityAnswers = () => {
		setSpinner(true);
		let url = getUserApiUrl() + '/user/current/security-answers';
		apiGet(url).then((resp) => {
			if(resp && resp.length)
				setSecurityForm({questionId:resp[0].securityQuestionId,answer:resp[0].answer});
		}).catch( (err) => {
			console.error(err);
		}).finally(() => {
			setSpinner(false);
		});
	};


    useEffect( () => {
		getSecurityQuestions();
		getSecurityAnswers();
    }, []);

	useEffect( () => {
        invalidInputStyle();
	});

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...securityForm, [id]:value};
		setSecurityForm(cloned);
	}

	let handleSubmit = (e) => {
		e.preventDefault();

        let url = getUserApiUrl() + '/user/current/security-answers';
        let body = {...securityForm};

		apiPut(url, body).then((resp) => {
			setMessage({text: 'Security Question updated.', type:'success'});
			//setSecurityForm({questionId:'',answer:''});
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 409){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

    return (
    <>
    <Processing show={showSpinner}/>

    <h4>Security Question</h4>
    <MessageTag message={message} id="msgChgAns"/>

    <form className="dataEntry" onSubmit={e => handleSubmit(e)}>
		<div className="form-group">
			<label htmlFor="questionId">Security Question</label>
			<select id="questionId" className="form-control custom-select" value={securityForm.questionId} onChange={handleFieldChange} required >
			{ selectOptions(questions) }
			</select>
		</div>
        <div className="form-group">
            <label htmlFor="answer">Answer</label>
            <input type="text" id="answer" className="form-control" autoComplete="off" value={securityForm.answer} onChange={handleFieldChange} required minLength="2" maxLength="100" />
        </div>
        <button className="btn btn-primary" id="btnSaveAns">Save Security Answer</button>
    </form>

    </>
    )
}
