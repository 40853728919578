import React, { useState, useEffect } from 'react';

import { apiPost, apiPostFile, getAccountsApiUrl, apiMessages, apiGet } from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { StateArray } from '../common/Constants';
import { selectOptions } from '../common/Forms';
import { cleanPhone } from '../common/Formats';
import Strings from  '../common/Strings';
import useUserContext from '../common/UserContext';
import AlertDialog, {showAlertDialog} from '../common/AlertDialog';

export default function AccountFile() {
    let {user} = useUserContext();

	let blankForm = {
		programUuid:'',
		accountFile:''
	}
	let [uploadForm, setUploadForm] = useState(blankForm);

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();

	let handleFieldChange = (event) => {
		const {id, value} = event.target;
		let cloned = {...uploadForm, [id]:value};
		setUploadForm(cloned);
	}
	let handleFileChange = (event) => {
		let cloned = {...uploadForm};
		cloned.accountFile = event.target.files[0];
		setUploadForm(cloned);
	}

	let alertSettings = {body:'You must select an input file.',title:'Input File'};

	let handleSubmit = (e) => {
		e.preventDefault();

		if (!uploadForm.accountFile || uploadForm.accountFile.size < 1) {
			showAlertDialog();
			return ;
		}

		console.debug('file size ' + uploadForm.accountFile.size);
		const maxSize = 1024 * 1024;
		if (uploadForm.accountFile.size > maxSize) {
			setMessage({text:'Account file size cannot be greater than 1MB.',type:'warning'});
			return false;
		}
		setSpinner(true);

		let url = getAccountsApiUrl() + '/account/file/' + uploadForm.programUuid;
		let formData = new FormData();
		formData.append('accountFile', uploadForm.accountFile);

		apiPostFile(url, formData).then((resp)=> {
			console.debug(resp);
			//setUploadForm(blankForm);
			let messageObject = {text:'Account file processed.',type:'success'};
			let messages = [];
			if(resp && resp.processed)
				messages.push(resp.processed + ' account rows processed.');
			if(resp && resp.added)
				messages.push(resp.added + ' account added.');
			if(resp && resp.updated)
				messages.push(resp.updated + ' accounts updated.');

			let messageText = messages.map( (value, index) => <div key={index}>{value}</div>);
			messageObject.text = <>{messageText}</>;

			setMessage(messageObject);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 409){
				messageObject.text = apiMessages(err);
			} else if (err.status == 422){
				messageObject.text = 'Invalid file content.';
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let [programs, setPrograms] = useState([]);

	let getPrograms = () => {
		let url = getAccountsApiUrl() + '/account/programs';
		setSpinner(true);
		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid, text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err) => {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	useEffect( () => {
		getPrograms();
	}, []);

	let getFileName = () => uploadForm.accountFile ? uploadForm.accountFile.name : null;

	return (
		<>
		<Processing show={showSpinner} />

		<h3>Upload Account File</h3>
        <MessageTag message={message} />
		
		<label style={{fontStyle: 'italic', fontSize: 13}}>Account Files need to be CSV or Excel files in a specific format. <br /> Example files are available for download below for assistance. </label>
		<div>	
			<a href="/ExampleAccountFiles/AccountFileExample.csv" download="AccountFileExample.csv">Download Example CSV File</a>
			<br />
			<a href="/ExampleAccountFiles/AccountFileExample.xlsx" download="AccountFileExample.xlsx">Download Example Excel File</a>
		</div>

		<br />

        <form onSubmit={e => handleSubmit(e)}>
			<div className="row">
				<div className="form-group col-12 col-md-6">
					<label htmlFor="programUuid">Program</label>
					<select id="programUuid" className="form-control custom-select" value={uploadForm.programUuid} onChange={handleFieldChange} required>
						{ selectOptions(programs) }
					</select>
				</div>
			</div>
			<div className="row">
				<div className="form-group col-12 col-md-6">
					<label htmlFor="accountFile" className="btn btn-info">Select Account File <input type="file" id="accountFile" name="accountFile" className="form-control-file d-none" onChange={handleFileChange} accept=".csv,.xls,.xlsx"/></label>
					<br/><h4>&nbsp;{getFileName()}</h4>
				</div>
			</div>
			<button className="btn btn-primary">Upload File</button>
		</form>

		<AlertDialog settings={alertSettings} />

		</>
	)
}
