import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import {apiGet, apiPut, getAccountsApiUrl, getAdminApiUrl, getMerchantsApiUrl, apiMessages} from '../common/AppApi';
import Processing from '../common/Processing';
import MessageTag from '../common/MessageTag';
import { cleanPhone, formatPhone } from '../common/Formats';

import Strings from '../common/Strings';
import {MerchantStatusArray} from '../common/Constants';

export default function Merchant() {
	let blankMerchant = {
		merchantUuid:'',
		merchantIdentifier:'',
		merchantStatusId:'',
		merchantName:'',
		fnsNumber:'',
		email:'',
		federalTaxId:'',
		businessPhone:'',
		mobilePhone:'',
		firstName:'',
		middleName:'',
		lastName:'',
		addressOne:'',
		addressTwo:'',
		city:'',
		zipcode:'',
		stateCode:'',
	};

	let [merchant, setMerchant] = useState(blankMerchant);
	let [programs, setPrograms] = useState([]);
    let { id } = useParams();

	let [showSpinner, setSpinner] = useState(false);
	let [message, setMessage] = useState();


	let getPrograms = () => {
		setSpinner(true);
		let url = getAdminApiUrl() + '/programs/list';

		apiGet(url).then((resp)=> {
			let programs = [];
			programs.push({id:'',text:''});
			resp.forEach( (p) => {
				programs.push({id:p.programUuid,text:p.programName});
			});
			setPrograms(programs);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

	let getMerchant = (id) => {
		setSpinner(true);
		let url = getMerchantsApiUrl() + '/merchant/' + id;
		apiGet(url).then((resp)=> {
			console.debug(resp);
			let cloned = {...resp};
			 setMerchant(cloned);
		}).catch( (err)=> {
			console.error(err);
			let messageObject = {text:Strings.error.general,type:'danger'};
			if(err.privilegeError){
				messageObject.text = <>{err.message}</>;
			} else if(err.status == 400 || err.status == 404){
				messageObject.text = apiMessages(err);
			}
			setMessage(messageObject);
		}).finally(() => setSpinner(false));
	};

    useEffect( ()=> {
        getMerchant(id);
    }, []);

	let decodeStatus = (id) => {
		let item = MerchantStatusArray.find(element => element.id == id);
		return (item ? item.text : null);
	};

	let AddressInfo = (props) => {
		if (props.info) {
			let addressInfo = props.info;
			return (<>
				{addressInfo.addressOne}<br/>{addressInfo.addressTwo ? (<>{addressInfo.addressTwo}<br/></>) : null}
				{addressInfo.city} {addressInfo.stateCode} {addressInfo.zipcode}
				</>
			);
		} else {
			return null;
		}
	};

	return (
		<>
		<Processing show={showSpinner}/>
		<h3>Merchant</h3>
        <MessageTag message={message}/>
			<table className="table table-bordered">
				<tbody>
				<tr>
					<th>Merchant ID</th>
					<th>Name</th>
					<th>FNS Number</th>
				</tr>
				<tr>
					<td>{merchant.merchantIdentifier}</td>
					<td>{merchant.merchantName}</td>
					<td>{merchant.fnsNumber}</td>
				</tr>
				<tr>
					<th>Merchant Status</th>
					<th>Email</th>
					<th>Business Phone</th>
				</tr>
				<tr>
					<td>{decodeStatus(merchant.merchantStatusId)} </td>
					<td>{merchant.email}</td>
					<td>{formatPhone(merchant.businessPhone)}</td>
				</tr>
				<tr>
					<th colSpan="3">Address</th>
				</tr>
				<tr>
					<td colSpan="3"><AddressInfo info={merchant.addressInfo}/></td>
				</tr>
				</tbody>
			</table>

		</>
	)
}
